import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../../../styledComponent/Inputs/Input";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import LoadingBox from "../../../DialogBox/LoadingBox";
import Select from "../../../../styledComponent/Inputs/Select";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { saveIncome44ADA } from "../../../../redux/itr/action";
import styled from 'styled-components';
import { toast } from "react-toastify";

export default function Income44ADA({
  handleCallback,
  formsData,
  professionType,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [activeProfessionType, setActiveProfessionType] = useState(0);
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  
  const validationSchema = Yup.object().shape({
    grossRevenue: Yup.number().required(strings.validation_message.required),
    totalExpenses: Yup.number()
    .required(strings.validation_message.required)
    .test(
      'is-more-than-50-percent',
      'Should be at least 50% of Gross Revenue',
      function(value) {
        const { grossRevenue } = this.parent;
        return value >= (grossRevenue * 0.5);
      }
    ),
    professionalIncome44ADA: Yup.array().of(
      Yup.object().shape({
        professionType: Yup.string().required(
          strings.validation_message.required
        ),
        natureOfProfession: Yup.string().required(
          strings.validation_message.required
        ),
        nameOfCompany: Yup.string().required(
          strings.validation_message.required
        ),
        description: Yup.string().max(255, 'Only 255 Characters Allowed'),
      })
    ),
  });

  useEffect(() => {
    setLoader(true);
    setFormData({
      itrId: ItrData.id,
      grossRevenue: formsData?.grossRevenue || "",
      totalExpenses: formsData?.totalExpenses || "",
      professionalIncome44ADA: formsData?.professionalIncome44ADA?.map(
        (item) => {
          return {
            professionType: item.professionType.id || item.professionType,
            natureOfProfession:
              item.natureOfProfession.id || item.natureOfProfession,
            nameOfCompany: item.nameOfCompany,
            description: item.description,
          };
        }
      ) || [
        {
          professionType: "",
          natureOfProfession: "",
          nameOfCompany: "",
          description: "",
        },
      ],
    });
    if(formsData?.professionalIncome44ADA?.length){
      setActiveProfessionType(formsData?.professionalIncome44ADA[0]?.professionType?.type);
    }
    setLoader(false);
  }, [formsData]);
  
  return (
    <Root>
      <LoadingBox open={loader} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleCallback(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues, setFieldValue }) => (
          <div>
            <StyleFormBox>
              <h4>{strings.income.provide_details_of_profession} </h4>
              <p>{strings.income.first_select_profession_type}</p>

              <FieldArray name="professionalIncome44ADA">
                {({ insert, remove, push }) => (
                  <div>
                    {values?.professionalIncome44ADA?.map((item, index) => (
                      <StyleFormBox key={index}>
                        <button
                          className="delete_button mb-2 block ml-auto"
                          onClick={() => remove(index)}
                        >
                          <RiDeleteBin6Fill />
                        </button>

                        <div className="flex_input my-2">
                          <label>{strings.income.profession_type} *</label>

                          <Select
                            name={`professionalIncome44ADA.${index}.professionType`}
                            error={
                              errors?.professionalIncome44ADA &&
                              errors?.professionalIncome44ADA[index]
                                ?.professionType
                            }
                            value={item.professionType}
                            onChange={(e) => {
                              setActiveProfessionType(e.target.value);
                              handleChange(e);
                            }}
                          >
                            <option value="" disabled>
                              {strings.label.choose_option}
                            </option>
                            {professionType?.map((i, ix) => (
                              <option value={i.id} key={ix}>
                                {i.name}
                              </option>
                            ))}
                          </Select>
                        </div>
                        <div className="flex_input my-2">
                          <label>{strings.income.nature_of_profession} *</label>
                          <Select
                            name={`professionalIncome44ADA.${index}.natureOfProfession`}
                            error={
                              errors?.professionalIncome44ADA &&
                              errors?.professionalIncome44ADA[index]
                                ?.natureOfProfession
                            }
                            value={item.natureOfProfession}
                            onChange={handleChange}
                          >
                            <option value="">
                              {strings.label.choose_option}
                            </option>
                            {professionType
                              ?.filter(
                                (item) => item.id == activeProfessionType || 0
                              )[0]
                              ?.subCategory?.map((i, ix) => (
                                <option value={i.id} key={ix}>
                                  {i.name}
                                </option>
                              ))}
                          </Select>
                        </div>

                        <div className="flex_input my-2">
                          <label>
                            {strings.other_disclosures.name_of_company} *
                          </label>

                          <Input
                            name={`professionalIncome44ADA.${index}.nameOfCompany`}
                            value={item.nameOfCompany}
                            error={
                              errors?.professionalIncome44ADA &&
                              errors?.professionalIncome44ADA[index]
                                ?.nameOfCompany
                            }
                            onChange={handleChange}
                          />
                        </div>

                        <div className="flex_input mt-4">
                          <label>{strings.label.description}</label>
                          <Input
                            inputType={"message"}
                            name={`professionalIncome44ADA.${index}.description`}
                            value={item.description}
                            error={
                              errors?.professionalIncome44ADA &&
                              errors?.professionalIncome44ADA[index]
                                ?.description
                            }
                            onChange={handleChange}
                          />
                        </div>
                      </StyleFormBox>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          if(values?.professionalIncome44ADA?.length < 3){
                            push({
                              professionType: "",
                              natureOfProfession: "",
                              nameOfCompany: "",
                              description: "",
                            });
                          }else{
                            toast.error('Only 3 Entries Allowed!')
                          }
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </div>
                )}
              </FieldArray>

              <hr />

              <div className="flex_input my-2">
                <h4>{strings.income.gross_revenue_total} *</h4>
                <div>
                  <Input
                    inputType={"currency"}
                    name={"grossRevenue"}
                    error={errors?.grossRevenue}
                    value={values?.grossRevenue}
                    onChange={(e) => {
                      setFieldValue('grossRevenue', e.target.value);
                      setFieldValue('totalExpenses', e.target.value ? e.target.value * 0.5 : "");
                    }}
                  />
                  <p className="pt-1">{strings.message.max_gross_revenue}</p>
                </div>
              </div>

              <div className="flex_input my-2">
                <h4>{strings.income.total_expenses} *</h4>
                <div>
                  <Input
                    inputType={"currency"}
                    name={"totalExpenses"}
                    error={errors?.totalExpenses}
                    value={values?.totalExpenses}
                    onChange={handleChange}
                  />
                  <p className="pt-1">{strings.message.max_total_expenses}</p>
                </div>
              </div>

              <div className="flex_input my-2 ">
                <h4>{strings.income.taxable_income_under_44ADA}</h4>
                <h4 className="" style={{ flex: 0 }}>
                  {Number(values.grossRevenue) + Number(values.totalExpenses)}
                </h4>
              </div>
            </StyleFormBox>

            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </Root>
  );
}
const Root = styled.section`
.error.curr {
    bottom: -18px;
}`
