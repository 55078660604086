import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";

export default function AboutUs() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <AboutSection className="container_fluid mt-20" theme={theme}>
      <div className="about-us-main flex items-center justify-center">
        <div className="about-content">
          <h4>About Us</h4>
          <h2>
            We are<span>Clear.</span> <br></br>
            Connected finances<br></br>
            for Indian tax payers.
          </h2>
          <p>
          Welcome to our place, your one-stop solution for all your income tax filing needs. At SamosaTax, we understand the complexities and problems that come with filing your income tax returns (ITR). That's why we've developed a user-friendly platform that allows you to file your ITR at your own convenience, with the option to access expert assistance if needed.
Our mission is to simplify the process of filing ITR for individuals. We believe that everyone should have access to reliable and affordable tax filing services, which is why we offer our services at an affordable range without compromising on quality.
Whether you're a seasoned taxpayer or new to the process, our platform serves all your needs. With step-by-step guidance and intuitive interfaces, we make filing your ITR an Ideal experience. And if you ever find yourself in need of assistance or have any queries, our team of expert professionals is always ready to help.
At SamosaTax, we work to be more than just a service provider; we aim to be your trusted partner in navigating the complexities of income tax filing. Our commitment to customer satisfaction and excellence sets us apart, ensuring that your experience with us is nothing short of exceptional.
Join us on our mission to make income tax filing simpler, more accessible, and hassle-free. Let SamosaTax be your partner in achieving financial peace of mind.
          </p>
        </div>
        <div className="about-img">
          <img src="/Images/1-1.png" />
        </div>
      </div>

      <div className="b2b-invoice-main flex items-center justify-center">
        <div className="invoice-left">
          <h2>
            $300B worth B2B invoices<br></br> flow through Clear,<br></br>
            each year
          </h2>
          <p>
            With this massive network graph and our cloud-based<br></br>{" "}
            offerings, we are India’s leading SaaS platform and our<br></br>{" "}
            technology has touched 10% of Indian MSMEs, second only to<br></br>{" "}
            the Government of India
          </p>
          <p># 1 GST platform for Enterprises</p>
          <p># 1 tax platform for tax experts</p>
          <p># 1 income tax returns platform for tax-payers</p>
        </div>
        <div className="invoice-right">
          <img src="Images/1611726964_maskgroup.png"></img>
        </div>
      </div>

      <div className="financial-services-main">
        <div className="financial-heading flex items-center justify-center">
          <h2>
            From <span>compliance to financial</span> services,<br></br>
            we cover them all.
          </h2>
        </div>
        <div className="services-first flex items-center justify-center gap-3 mb-4">
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/clear-gst.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>ClearGST</h4>
              <p> From G1 to G9, file 3x faster</p>
            </div>
          </div>
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/clear-invoice.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>ClearE-Invoicing</h4>
              <p> Fast, dependable, affordable</p>
            </div>
          </div>
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/clear-ewill.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>ClearE-Waybill</h4>
              <p> Simple, intelligent, error-free</p>
            </div>
          </div>
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/clear-tax-cloud.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>ClearTaxCloud</h4>
              <p>ITR for people & businesses</p>
            </div>
          </div>
        </div>
        <div className="services-first flex items-center justify-center gap-3 mb-4">
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/clear-tds.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>ClearTDS</h4>
              <p> Never miss a challan again</p>
            </div>
          </div>
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/clear-airtravel.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>ClearAirTravel</h4>
              <p>Claim every tax credit</p>
            </div>
          </div>
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/clear-protect.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>ClearProtect</h4>
              <p> Employee health benefits</p>
            </div>
          </div>
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/clear-vendor.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>ClearVendor</h4>
              <p> Manage supplier relations</p>
            </div>
          </div>
        </div>
        <div className="services-first flex items-center justify-center gap-3">
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/file-itr.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>File ITR</h4>
              <p> Tax returns in under 7 min</p>
            </div>
          </div>
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/clear-invest.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>ClearInvest</h4>
              <p> Save and grow wealth</p>
            </div>
          </div>
          <div className="first-card">
            <div className="card-icon flex justify-center mb-3">
              <img src="Images/clear-services.png"></img>
            </div>
            <div className="card-content mb-3">
              <h4>ClearServices</h4>
              <p> 200+ expert assisted plans </p>
            </div>
          </div>
          <div className="first-card none">
            <div className="card-icon flex justify-center">
              <HiOutlineBuildingStorefront />
            </div>
            <div className="card-content">
              <h4>ClearTaxCloud</h4>
              <p>ITR for people & businesses</p>
            </div>
          </div>
        </div>
      </div>
      <div className="income-tax-main">
        <div className="income-tax-heading">
          <h2>
            We are proud to admit that we are fastest<br></br> growing{" "}
            <span>income tax return filing</span> portal in India.<br></br> This
            is our journey so far-
          </h2>
        </div>
        <div className="incometax-img flex justify-center">
          <img src="Images/milestone.webp"></img>
        </div>
      </div>

      <div className="any-devices-main">
        <div className="any-devices-heading">
          <h2>
            Experience Clear from<br></br>
            <span>any place, any time,</span> any device
          </h2>
        </div>
        <div className="main-box flex justify-center gap-5">
          <div className="devices-images-main">
            <div className="devices-image-first">
              <img src="Images/1612183389_black11.png"></img>
            </div>
            <div className="devices-image-second">
              <img src="Images/1612183454_black21.png"></img>
            </div>
          </div>
          <div className="devices-images-main">
            <div className="devices-image-first">
              <img src="Images/1612183547_cleartaxpro1.png"></img>
            </div>
            <div className="devices-image-second">
              <img src="Images/1612183540_cleartaxpro2.png"></img>
            </div>
          </div>
          <div className="devices-images-main">
            <div className="devices-image-first">
              <img src="Images/1612183575_clear11.png"></img>
            </div>
            <div className="devices-image-second">
              <img src="Images/1612183567_clear21.png"></img>
            </div>
          </div>
        </div>
      </div>
    </AboutSection>
  );
}
const AboutSection = styled.section`
  .about-us-main {
    width: 100%;
    margin-bottom: 50px;
    .about-content {
      width: 50%;
      h2 {
        font-weight: 700;
        font-size: 42px;
        span {
          color: #ffa53b;
        }
      }
      p {
        font-weight: 500;
        font-size: 20px;
      }
      @media (max-width: 1024px) {
        width: 100%;
        text-align: center;
      }
    }
    .about-img {
      width: 30%;
      @media (max-width: 1024px) {
        width: 60%;
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .b2b-invoice-main {
    width: 100%;
    background-color: #ffa53b;
    padding: 50px 0;

    .invoice-left {
      width: 50%;
      h2 {
        font-size: 42px;
        font-weight: 700;
        color: #fff;
        @media (max-width: 1024px) {
          text-align: center;
          font-size: 36px;
        }
      }
      p {
        font-size: 22px;
        color: #fff;
        margin-bottom: 10px;
        @media (max-width: 1024px) {
          text-align: center;
        }
      }
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    .invoice-right {
      width: 30%;
      @media (max-width: 1024px) {
        width: 50%;
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .financial-services-main {
    width: 100%;
    margin-top: 50px;
    .financial-heading {
      h2 {
        font-size: 42px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 50px;
        span {
          color: #ffa53b;
        }
        @media (max-width: 1024px) {
          font-size: 36px;
        }
      }
    }
    .services-first {
      width: 100%;
      .first-card {
        width: 20%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 20px 10px;
        .card-content {
          h4 {
            font-size: 24px;
            font-weight: 700;
          }
          p {
            font-size: 14px;
          }
        }
        .card-icon {
          width: 60px;
        }
        @media (max-width: 1024px) {
          width: 50%;
        }
      }
      @media (max-width: 1024px) {
        flex-direction: column;
      }
      .none {
        visibility: hidden;
      }
    }
  }
  .income-tax-main {
    width: 100%;
    margin-top: 50px;
    .income-tax-heading {
      h2 {
        font-size: 42px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 50px;
        span {
          color: #ffa53b;
        }
        @media (max-width: 1024px) {
          font-size: 36px;
        }
      }
    }
    incometax-img {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }

  .any-devices-main {
    width: 100%;
    margin-top: 50px;
    .any-devices-heading {
      h2 {
        font-size: 42px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 50px;
        span {
          color: #ffa53b;
        }
        @media (max-width: 1024px) {
          font-size: 36px;
        }
      }
    }
    .devices-images-main {
      width: 25%;
      position: relative;
      .devices-image-second {
        position: absolute;
        left: 0;
        top: 0;
        display: none;
      }
    }
    .devices-images-main:hover .devices-image-second {
      display: block;
    }
    .main-box {
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;
