import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../styledComponent/Inputs/Input";
import { Formik } from "formik";
import LoadingBox from "../../DialogBox/LoadingBox";
import * as Yup from "yup";

import {
  saveTaxPaidChallanAction,
  updateTaxPaidChallanAction,
} from "../../../redux/itr/action";
import { dateFormat } from "../../../utils/helpers";
import FormDialogBox from "../../DialogBox/FormDialogBox";

export default function TaxPaidChallan({
  handleClose,
  editData,
  handleCallback,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [sample, setSample] = useState("");
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const handleSubmitForm = (values) => {
    if (values.id !== 0) {
      dispatch(
        updateTaxPaidChallanAction(values, (e) => {
          console.log(e);
          if (e.status == 200) {
            handleCallback("update", e.data);
          }
        })
      );
    } else {
      let { id, ...newData } = values;
      dispatch(
        saveTaxPaidChallanAction(newData, (e) => {
          console.log(e);
          if (e.status == 200) {
            handleCallback("add", e.data);
          }
        })
      );
    }
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required(strings.validation_message.required),
    challanDate: Yup.string().required(strings.validation_message.required),
    bsrCode: Yup.string()
      .required(strings.validation_message.required)
      .length(7, "BSR code must be a 7 digit number"),
    challanSerialNo: Yup.string()
      .required(strings.validation_message.required)
      .length(5, "Challan Serial Number must be a 5 digit number"),
  });

  useEffect(() => {
    setLoader(true);
    setFormData({
      amount: editData?.amount || "",
      challanDate: editData?.challanDate
        ? dateFormat(editData?.challanDate)
        : "",
      bsrCode: editData?.bsrCode || "",
      challanSerialNo: editData?.challanSerialNo || "",
      id: editData?.id || 0,
      itrId: ItrData?.id,
    });
    setLoader(false);
  }, [editData]);

  return (
    <>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <div>
            <StyleFormBox>
              <h4>{strings.tax_saving.add_tax_paid_challan}</h4>
              <hr />
              <div className="flex_input my-2">
                <label>{strings.income.amount}</label>
                <Input
                  inputType={"currency"}
                  name={"amount"}
                  value={values.amount}
                  error={errors.amount}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-4">
                <label>{strings.profile.date}</label>
                <Input
                  inputType={"date"}
                  name={"challanDate"}
                  value={values.challanDate}
                  error={errors.challanDate}
                  onChange={handleChange}
                  // handleChange={(e) =>
                  //   setValues({
                  //     ...values,
                  //     challanDate: dateFormat(e.target.value),
                  //   })
                  // }
                />
              </div>
              <div className="flex_input my-3">
                <div>
                  <div className="flex justify-between items-center">
                    <label>{strings.tax_saving.bsr_code}</label>
                    <button
                      className="add_item_button"
                      onClick={() => setSample("bsr")}
                    >
                      {strings.button.view_sample}
                    </button>
                  </div>
                  <Input
                    type="number"
                    placeholder={strings.placeholder.bsr_code_usually_7_dig}
                    name={"bsrCode"}
                    value={values.bsrCode}
                    error={errors.bsrCode}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <div className="flex justify-between items-center">
                    <label>{strings.tax_saving.challan_serial_no}</label>
                    <button
                      className="add_item_button"
                      onClick={() => setSample("challan")}
                    >
                      {strings.button.view_sample}
                    </button>
                  </div>
                  <Input
                    type={"number"}
                    name={"challanSerialNo"}
                    placeholder={strings.placeholder.challan_no_is_5_digits}
                    value={values.challanSerialNo}
                    error={errors.challanSerialNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex_input">
                <button className="secandary_button" onClick={handleClose}>
                  {strings.button.cancel}
                </button>
                <button
                  className="primary_button"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {strings.button.save_and_close}
                </button>
              </div>
            </StyleFormBox>
          </div>
        )}
      </Formik>

      <FormDialogBox
        open={sample === "challan" || sample === "bsr"}
        handleClose={() => setSample("")}
        width={"400px"}
      >
        <div className="flex items-center justify-center">
          {
            sample === "bsr"?
            <img src="/Images/BSRNumber.png" alt="BRS Sample"></img>:
            <img src="/Images/ChallanNumber.png" alt="BRS Sample"></img>
          }
        </div>
      </FormDialogBox>
    </>
  );
}
