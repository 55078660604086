import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from '../../utils/helpers';
export default function DateField({ placeholder,error }) {
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
      <input type="date" placeholder={placeholder}/>
      <p className="error">{error}</p>
    </Root>
  );
}
const Root = styled.div`
  width: 100%;
  input {
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid ${(p) =>p.theme.border_clr};
    color: ${(p) => p.theme.primary_txt};
    background-color:transparent;
    &::placeholder {
      color: ${(p) => p.theme.primary_txt};
      font-weight: 400;
      font-size: 14px;
    }
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(50%);
      font-size:20px;
    }

`;
