// sagas.js

import { takeLatest, put } from "redux-saga/effects";
import {
  CONTACT_US,
  CREATE_TICKET,
  FORGOT_PASSWORD,
  GET_FAQ,
  GET_GLOSSARY,
  GET_GLOSSARY_ID,
  GET_QUESTIONS,
  GET_SERVICE_LIST,
  GET_TICKET_TAGS,
  ITR_STATUS,
  LOGOUT_USER,
  RESET_PASSWORD,
  SEND_VERIFY_OTP,
  SOCIAL_LOGIN,
  SUBMIT_QUESTIONS,
  UPDATE_USER,
  USER_LOGIN,
  USER_REGISTRATION,
  VERIFY_OTP,
  VERIFY_OTP_FORGOT_PASSWORD,
} from "../constant";
import Urls from "../../utils/Urls";
import { httpGet, httpPost } from "../../utils/https";
import { toast } from "react-toastify";
import { userLoginDataAction } from "./action";

// User Registration
function* userRegistrationSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.userRegistration, payload.data);
    payload.handleCallback(res);
  } catch (e) {
    if (e.data.message) {
      toast.error(e.data.message);
    }
    payload.handleCallback(e);
  }
}

// Update user
function* updateUserSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateUser, payload.data);
    console.log('updateUserSaga =============>',res.data.data)
    
    if(res.data){
      yield put(userLoginDataAction(res.data.data));
    }
    payload.handleCallback(res);
  } catch (e) {
    if (e.data.message) {
      toast.error(e.data.message);
    }
    payload.handleCallback(e);
  }
}

// User Login
function* userLoginSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.userLogin, payload.data);
    if (res.data) {
      localStorage.setItem("token", res.data.data.token);
      yield put(userLoginDataAction(res.data.data));
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// =============================================================  Social Login  =================================================

function* userSocialLoginSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.userSocialLogin, payload.data);
    if (res.data) {
      localStorage.setItem("token", res.data.data.token);
      yield put(userLoginDataAction(res.data.data));
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}



// Logout user
function* userLogoutSaga() {
  try {
    const res = yield httpPost(Urls.userLogout);
    if (res.data) {
      localStorage.setItem("token", "");
      yield put(userLoginDataAction(res.data.data));
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
  }
}

// Send Verification Link
function* sendVerificationOtpSaga({ payload }) {
  try {
    const data = {
      userEmail: payload.email,
    };
    const res = yield httpPost(Urls.sendVerificationOtp, data);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Verify Otp
function* verifyOtpSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.verificationOtp, payload.data);
    if (res.data.status === 200) {
      toast.success(res.data.data);
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Forgot password
function* forgotPasswordSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.forgotPassword, payload.data);
    if (res.data.status === 200) {
      // toast.success(res.data.message)
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Verify OTP of Forgot password
function* verifyOtpForgotPasswordSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.verifyOtpForgotPassword, payload.data);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Reset Password
function* resetPasswordSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.resetPassword, payload.data);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getGlossaryList({ payload }) {
  try {
    const res = yield httpGet(Urls.getGlossary, payload.data);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getGlossaryById({ payload }) {
  try {
    const res = yield httpGet(Urls.getGlossaryById + payload.id, payload.data);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getFaq({ payload }) {
  try {
    const res = yield httpGet(Urls.getFaq, payload.data);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getQuestion({ payload }) {
  try {
    const res = yield httpGet(Urls.getQuestions);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* submitQuestionSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.submitAnswer, payload.data);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getTicketTagSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getTicketTags + `?search=${payload.parm}`);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* createTicketSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.createTicket, payload.data);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getServiceListSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.serviceList);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* contactUsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.contactUs, payload.data);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getItrStatusSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getItrStatus);
    if (res.data.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Saga watcher
export function* ProfileData() {
  yield takeLatest(USER_REGISTRATION, userRegistrationSaga);
  yield takeLatest(UPDATE_USER, updateUserSaga);
  yield takeLatest(USER_LOGIN, userLoginSaga);
  yield takeLatest(SOCIAL_LOGIN, userSocialLoginSaga);
  yield takeLatest(SEND_VERIFY_OTP, sendVerificationOtpSaga);
  yield takeLatest(VERIFY_OTP, verifyOtpSaga);
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLatest(VERIFY_OTP_FORGOT_PASSWORD, verifyOtpForgotPasswordSaga);
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(GET_GLOSSARY, getGlossaryList);
  yield takeLatest(GET_GLOSSARY_ID, getGlossaryById);
  yield takeLatest(GET_FAQ, getFaq);
  yield takeLatest(GET_QUESTIONS, getQuestion);
  yield takeLatest(SUBMIT_QUESTIONS, submitQuestionSaga);
  yield takeLatest(GET_TICKET_TAGS, getTicketTagSaga);
  yield takeLatest(CREATE_TICKET, createTicketSaga);
  yield takeLatest(GET_SERVICE_LIST, getServiceListSaga);
  yield takeLatest(CONTACT_US, contactUsSaga);
  yield takeLatest(ITR_STATUS, getItrStatusSaga);
  yield takeLatest(LOGOUT_USER, userLogoutSaga);
}
