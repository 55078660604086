import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import CurrencyField from "../../../../styledComponent/Inputs/CurrencyField";
import SecandaryButton from "../../../../styledComponent/Button/SecandaryButton";
import PrimaryButton from "../../../../styledComponent/Button/PrimaryButton";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import DetailBalanceSheet from "./DetailBalanceSheet";
import {
  delBalanceSheetAction,
  getBalanceSheetAction,
  saveBalanceSheetAction,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { removeBlankKey } from "../../../../utils/helpers";
import { RiDeleteBin6Fill } from "react-icons/ri";
import DeleteConfirmation from "../../../DialogBox/DeleteConfirmation";
import { useNavigate } from "react-router-dom";
export default function BalanceSheet() {
  const [balanceSheetConfirmBox, setBalanceSheetConfirmBox] = useState(false);
  const [detailedBalanceSheetForm, setDetailedBalanceSheetForm] =
    useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [balanceSheet, setBalanceSheet] = useState({});
  const [totalAsset, setTotalAsset] = useState(0);
  const [totalLiability, setTotalLiability] = useState(0);
  const [delBox, setDelBox] = useState(false);
  const [balanceId, setBalanceId] = useState();
  const navigate = useNavigate();

  const saveData = () => {
    let sendData = removeBlankKey(balanceSheet);
    setLoading(true);
    dispatch(
      saveBalanceSheetAction(sendData, (e) => {
        if (e.status == 200) {
          setBalanceId(e.data.id);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    );
  };

  const delData = () => {
    dispatch(
      delBalanceSheetAction({ id: balanceId }, (e) => {
        if (e.status == 200) {
          getBalanceSheet();
          setDelBox(false);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    );
  };

  const getBalanceSheet = () => {
    const data = `?itrId=${ItrData.id}`;
    dispatch(
      getBalanceSheetAction(data, (e) => {
        if (e.status == 200) {
          setBalanceId(e?.data?.id || null);
          setBalanceSheet({
            longTermInvestmentShares:
              Number(e.data.longTermInvestmentShares) || "",
            shortTermInvestmentShares:
              Number(e.data.shortTermInvestmentShares) || "",
            shortTermInvestmentDebentures:
              Number(e.data.shortTermInvestmentDebentures) || "",
            amountReceivedClients: Number(e.data.amountReceivedClients) || "",
            cashInHand: Number(e.data.cashInHand) || "",
            balanceInBank: Number(e.data.balanceInBank) || "",
            loanGivenToFriends: Number(e.data.loanGivenToFriends) || "",
            capitalInvestment: Number(e.data.capitalInvestment) || "",
            securedLoan: Number(e.data.securedLoan) || "",
            unsecuredLoan: Number(e.data.unsecuredLoan) || "",
            itrId: ItrData.id,
          });
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    getBalanceSheet();
  }, []);

  useEffect(() => {
    let sumObj = removeBlankKey(balanceSheet);
    let { unsecuredLoan = 0, securedLoan = 0, capitalInvestment = 0 } = sumObj;
    setTotalLiability(unsecuredLoan + securedLoan + capitalInvestment);
    let {
      longTermInvestmentShares = 0,
      shortTermInvestmentShares = 0,
      shortTermInvestmentDebentures = 0,
      amountReceivedClients = 0,
      cashInHand = 0,
      balanceInBank = 0,
      loanGivenToFriends = 0,
    } = sumObj;

    setTotalAsset(
      longTermInvestmentShares +
        shortTermInvestmentShares +
        shortTermInvestmentDebentures +
        amountReceivedClients +
        cashInHand +
        balanceInBank +
        loanGivenToFriends
    );
  }, [balanceSheet]);

  const handleNavitate = () => {
    if (ItrData.form === "ITR_3") {
      navigate(
        "/form/filing/ITR_3/grossincome/professionalincome/detailBalanceSheet"
      );
    } else if (ItrData.form === "ITR_5") {
      navigate(
        "/form/filing/ITR_5/grossincome/professionalincome/detailBalanceSheet5"
      );
    } else if (ItrData.form === "ITR_6") {
      navigate(
        "/form/filing/ITR_6/grossincome/professionalincome/detailBalanceSheet"
      );
    } else {
      toast.error("Invalid URL");
    }
  };

  console.log("ItrbalanceSheetData", balanceSheet);

  return (
    <div>
      <LoadingBox open={loading} />
      <StyleFormBox>
        <h4>
          {
            strings.balance_sheet
              .you_run_sme_msme_business_provide_balance_sheet
          }{" "}
        </h4>
        <SecandaryButton
          title={strings.button.detailed_balance_sheet}
          handleClick={() => {
            setBalanceSheetConfirmBox(true);
          }}
        />
      </StyleFormBox>

      <StyleFormBox>
        {balanceId && (
          <button
            className="delete_button mb-2 block ml-auto"
            onClick={() => setDelBox(true)}
          >
            <RiDeleteBin6Fill />
          </button>
        )}
        <DeleteConfirmation
          open={delBox}
          handleClose={() => setDelBox(false)}
          hendleDelete={() => {
            delData();
          }}
        />
        <div className="flex items-center justify-between">
          <h4>{strings.balance_sheet.assets}</h4>
          <h4>
            {strings.income.total_assets} :{" "}
            {balanceSheet.totalAssets || totalAsset}
          </h4>
        </div>
        <p>{strings.balance_sheet.enter_details_of_assets}</p>
        <hr />
        <StyleFormBox>
          <h4>
            {strings.balance_sheet.have_you_invested_in_shares_mutual_funds}
          </h4>
          <div className="flex_input my-2">
            <label>
              {strings.balance_sheet.long_term_investments_shares_mf}
            </label>
            <CurrencyField
              error={""}
              value={balanceSheet?.longTermInvestmentShares}
              handleChange={(e) =>
                setBalanceSheet({
                  ...balanceSheet,
                  longTermInvestmentShares: Number(e.target.value),
                })
              }
            />
          </div>
          <div className="flex_input my-2">
            <label>
              {strings.balance_sheet.short_term_investments_share_mf}
            </label>
            <CurrencyField
              error={""}
              value={balanceSheet?.shortTermInvestmentShares}
              handleChange={(e) => {
                setBalanceSheet({
                  ...balanceSheet,
                  shortTermInvestmentShares: Number(e.target.value),
                });
              }}
            />
          </div>
          <div className="flex_input my-2">
            <label>
              {strings.balance_sheet.short_term_investments_debentures}
            </label>
            <CurrencyField
              error={""}
              value={balanceSheet?.shortTermInvestmentDebentures}
              handleChange={(e) => {
                setBalanceSheet({
                  ...balanceSheet,
                  shortTermInvestmentDebentures: Number(e.target.value),
                });
              }}
            />
          </div>
        </StyleFormBox>

        <StyleFormBox>
          <h4>{strings.income.any_other_assets}</h4>
          <div className="flex_input my-2">
            <label>
              {strings.balance_sheet.amount_receivable_from_your_clients}
            </label>
            <CurrencyField
              error={""}
              value={balanceSheet?.amountReceivedClients}
              handleChange={(e) => {
                setBalanceSheet({
                  ...balanceSheet,
                  amountReceivedClients: Number(e.target.value),
                });
              }}
            />
          </div>
          <div className="flex_input my-2">
            <label>{strings.balance_sheet.cash_in_hand}</label>
            <CurrencyField
              error={""}
              value={balanceSheet?.cashInHand}
              handleChange={(e) => {
                setBalanceSheet({
                  ...balanceSheet,
                  cashInHand: Number(e.target.value),
                });
              }}
            />
          </div>
          <div className="flex_input my-2">
            <label>{strings.balance_sheet.balance_in_bank}</label>
            <CurrencyField
              error={""}
              value={balanceSheet?.balanceInBank}
              handleChange={(e) => {
                setBalanceSheet({
                  ...balanceSheet,
                  balanceInBank: Number(e.target.value),
                });
              }}
            />
          </div>
          <div className="flex_input my-2">
            <label>
              {strings.balance_sheet.loan_given_to_friends_and_family}
            </label>
            <CurrencyField
              error={""}
              value={balanceSheet?.loanGivenToFriends}
              handleChange={(e) => {
                setBalanceSheet({
                  ...balanceSheet,
                  loanGivenToFriends: Number(e.target.value),
                });
              }}
            />
          </div>
        </StyleFormBox>
      </StyleFormBox>

      <StyleFormBox>
        <div className="flex items-center justify-between">
          <h4>{strings.balance_sheet.liabilities}</h4>
          <h4>
            {strings.income.total_liabilities} :{" "}
            {balanceSheet?.totalLiabilities || totalLiability}
          </h4>
        </div>
        <p>
          {
            strings.balance_sheet
              .enter_details_of_payables_long_term_and_short_term_loans
          }
        </p>
        <hr />
        <div className="flex_input my-2">
          <label>{strings.balance_sheet.capital_investment}</label>
          <CurrencyField
            error={""}
            value={balanceSheet?.capitalInvestment}
            handleChange={(e) => {
              setBalanceSheet({
                ...balanceSheet,
                capitalInvestment: Number(e.target.value),
              });
            }}
          />
        </div>
        <div className="flex_input my-2">
          <label>{strings.balance_sheet.secured_loan}</label>
          <CurrencyField
            error={""}
            value={balanceSheet?.securedLoan}
            handleChange={(e) => {
              setBalanceSheet({
                ...balanceSheet,
                securedLoan: Number(e.target.value),
              });
            }}
          />
        </div>
        <div className="flex_input my-2">
          <label>{strings.balance_sheet.unsecured_loan}F</label>
          <CurrencyField
            error={""}
            value={balanceSheet?.unsecuredLoan}
            handleChange={(e) => {
              setBalanceSheet({
                ...balanceSheet,
                unsecuredLoan: Number(e.target.value),
              });
            }}
          />
        </div>
      </StyleFormBox>

      <PrimaryButton
        title={strings.button.save_and_continue}
        addClass={"mt-5"}
        handleClick={saveData}
      />

      <FormDialogBox
        open={balanceSheetConfirmBox}
        width={"610px"}
        formTitle={
          strings.balance_sheet.are_you_see_you_want_to_fill_the_balance_sheet
        }
        handleClose={() => {
          setBalanceSheetConfirmBox(false);
        }}
      >
        <div>
          <h4 className="my-4">
            {strings.balance_sheet.applicable_only_you_have_these}
          </h4>
          <ul style={{ listStyleType: "disc" }} className="ml-4">
            <li>{strings.balance_sheet.reserves_surplus}</li>
            <li>{strings.balance_sheet.foreign_bank_loans}</li>
            <li>
              {strings.balance_sheet.finished_goods_inventories_other_assets}
            </li>
            <li>{strings.balance_sheet.sme_msme_businesses}</li>
            <li>
              {strings.balance_sheet.professions_with_revenue_above_lakhs}
            </li>
          </ul>
          <div className="flex gap-3 items-center mt-4">
            <SecandaryButton
              title={strings.button.go_back}
              handleClick={() => setBalanceSheetConfirmBox(false)}
            />
            <PrimaryButton
              title={strings.button.yes_fill_detailed}
              handleClick={() => {
                // setDetailedBalanceSheetForm(true);
                handleNavitate();
              }}
            />
          </div>
        </div>
      </FormDialogBox>

      <FormDialogBox
        open={detailedBalanceSheetForm}
        width={"700px"}
        formTitle={strings.balance_sheet.balance_sheet_for_itr3}
        handleClose={() => {
          setDetailedBalanceSheetForm(false);
        }}
      >
        <DetailBalanceSheet />
      </FormDialogBox>
    </div>
  );
}
