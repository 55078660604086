import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../../../../styledComponent/Inputs/Input";
import CheckBox from "../../../../styledComponent/Inputs/CheckBox";
import Select from "../../../../styledComponent/Inputs/Select";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import {
  addMembersAction,
  getMembersAction,
  updateMembersAction,
} from "../../../../redux/itr/action";
import { removeEmptyKeys } from "../../../../utils/helpers";
import { toast } from "react-toastify";
import LoadingBox from "../../../DialogBox/LoadingBox";

export default function AddMembers({ handleCallback, memberId }) {
  const [formData, setFormData] = useState({});

  const [loader, setLoader] = useState(true);
  const [citiesList, setCitiesList] = useState([]);
  const [activeState, setActiveState] = useState(1);
  const [memberStatus, setMemberStatus] = useState([]);
  const { strings, states } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(strings.validation_message.required),
    lastName: Yup.string().required(strings.validation_message.required),
    dob: Yup.string().required(strings.validation_message.required),
    status: Yup.string().required(strings.validation_message.required),
    partnerRemuneration: Yup.string().required(strings.validation_message.required),
    sharePercentage: Yup.number()
      .required(strings.validation_message.required)
      .min(0.001, 'value must be more than 0')
      .max(100, 'value must be less than 100'),
    partnerInterestPercent: Yup.number()
    .required(strings.validation_message.required)
    .min(0.001, 'value must be more than 0')
    .max(100, 'value must be less than 100'),
    aadhaarNumber: Yup.string().matches(
      /^[2-9]{1}[0-9]{3}-[0-9]{4}-[0-9]{4}$/,
      "Invalid Aadhaar card number format"
    )
    .max(28, "Aadhaar card number must be at most 16 characters long"),
    address: Yup.object().shape({
      flatDoorNo: Yup.string().required(strings.validation_message.required),
      area: Yup.string().required(strings.validation_message.required),
      city: Yup.string().required(strings.validation_message.required),
      state: Yup.string().required(strings.validation_message.required),
      pinCode: Yup.string()
        .required(strings.validation_message.required)
        .matches(/^[1-9][0-9]{5}$/, strings.validation_message.invalid_pin),
      country: Yup.string().required(strings.validation_message.required),
    }),
  });

  //   ?itrId=142&id=1

  const handleFormSubmit = (data) => {
    if(data?.id){
      dispatch(
        updateMembersAction(removeEmptyKeys(data), (e) => {
          if (e.status === 200) {
            console.log("eee=== > ", e);
            toast.success("Successfully Added Member");
            handleCallback();
          } else {
          }
        })
      );
    }else{
      dispatch(
        addMembersAction(removeEmptyKeys(data), (e) => {
          if (e.status === 200) {
            console.log("eee=== > ", e);
            toast.success("Successfully Added Member");
            handleCallback();
          } else {
          }
        })
      );
    }
  };

  const getMembersData = (id = 0) => {
    const parm = `?itrId=${ItrData.id}&id=${id}`;
    dispatch(
      getMembersAction(parm, (e) => {
        console.log("eee=== > ", e);
        setMemberStatus(e?.data?.memberStatus);
        let editData = e.data?.members;
        setActiveState(editData?.address?.state?.id ||  "")
        setFormData({
          itrId: ItrData.id,
          firstName: editData?.firstName || "",
          lastName: editData?.lastName || "",
          PAN: editData?.PAN || "",
          // gender: editData?.gender || "M",
          dob: editData?.dob?.split("T")[0] || "",
          status: editData?.status?.id || editData?.status || "",
          sharePercentage: editData?.sharePercentage || "",
          aadhaarNumber: editData?.aadhaarNumber || "",
          partnerRemuneration: editData?.partnerRemuneration || "",
          partnerInterest: editData?.partnerInterest || "",
          partnerInterestAllowable: editData?.partnerInterestAllowable || "",
          partnerInterestPercent: editData?.partnerInterestPercent || "",
          id:editData?.id || "",
          address: {
            flatDoorNo: editData?.address?.flatDoorNo || "",
            premiseName: editData?.address?.premiseName || "",
            street: editData?.address?.street || "",
            area: editData?.address?.area || "",
            city:  editData?.address?.city?.id ||  "",
            state: editData?.address?.state?.id ||  "",
            country: editData?.address?.country || 101,
            pinCode: editData?.address?.pinCode || "",
          },
        });
        setLoader(false);
      })
    );
  };
  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };

  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    setLoader(true);
    getMembersData(memberId);
  }, [memberId]);

  console.log("formData", loader);
  return (
    <div>
    <LoadingBox open={loader}/>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues, setFieldValue }) => (
          <>
            <StyleFormBox>
              <p>
                {strings.personal_info.additional_info_about_partner_member}
              </p>

              <div className="flex_input">
                <div>
                  <label>{strings.label.first_name} *</label>
                  <Input
                    name={"firstName"}
                    placeholder={strings.label.first_name}
                    error={errors.firstName}
                    value={values?.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.label.last_name} *</label>
                  <Input
                    name={"lastName"}
                    placeholder={strings.label.last_name}
                    error={errors.lastName}
                    value={values?.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.pan_number}</label>
                  <Input
                    name={"PAN"}
                    placeholder={strings.label.pan_number}
                    error={errors.PAN}
                    value={values?.PAN}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setFieldValue("PAN", e.target.value.toUpperCase());
                      }
                    }}
                  />
                </div>
                {/* <div>
                  <label>{strings.personal_info.gender}</label>
                  <div className="flex items-center">
                    <CheckBox
                      value={values.gender == "M"}
                      label={strings.personal_info.male}
                      handleChange={(e) => {
                        setValues({ ...values, gender: "M" });
                      }}
                    />
                    <CheckBox
                      value={values.gender == "F"}
                      label={strings.personal_info.female}
                      handleChange={(e) => {
                        setValues({ ...values, gender: "F" });
                      }}
                    />
                  </div>
                </div> */}
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.date_of_birth} *</label>
                  <Input
                    name={"dob"}
                    type={"date"}
                    // placeholder={strings.label.pan_number}
                    error={errors.dob}
                    value={values?.dob}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>
                    {strings.personal_info.choose_status_of_member_partner} *
                  </label>
                  <Select
                    name={"status"}
                    error={errors.status}
                    value={values?.status}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>
                    {memberStatus?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex_input">
                <div>
                  <label>{strings.personal_info.share_percentage} *</label>
                  <Input
                    name={"sharePercentage"}
                    // placeholder={strings.label.pan_number}
                    error={errors.sharePercentage}
                    value={values?.sharePercentage}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label>{strings.label.aadhar_number} </label>
                  <Input
                    name={"aadhaarNumber"}
                    inputType={"aadhaar"}
                    placeholder={strings.placeholder.enter_12_digit}
                    error={errors.aadhaarNumber}
                    value={values?.aadhaarNumber}
                    onChange={(e) => {
                      if (e.target.value.length <= 14) {
                        handleChange(e);
                      }
                    }}
                    // onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.personal_info.partner_remuneration} *</label>
                  <Input
                    name={"partnerRemuneration"}
                    // placeholder={strings.label.pan_number}
                    error={errors.partnerRemuneration}
                    value={values?.partnerRemuneration}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label>{strings.personal_info.partner_interest} </label>
                  <Input
                    name={"partnerInterest"}
                    // placeholder={strings.label.aadhar_number}
                    error={errors.partnerInterest}
                    value={values?.partnerInterest}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>
                    {strings.personal_info.partner_interest_allowable}{" "}
                  </label>
                  <Input
                    name={"partnerInterestAllowable"}
                    // placeholder={strings.label.pan_number}
                    error={errors.partnerInterestAllowable}
                    value={values?.partnerInterestAllowable}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label>
                    {strings.personal_info.partner_interest_percent} *
                  </label>
                  <Input
                    name={"partnerInterestPercent"}
                    // placeholder={strings.label.aadhar_number}
                    error={errors.partnerInterestPercent}
                    value={values?.partnerInterestPercent}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </StyleFormBox>
            <StyleFormBox>
              <h4>{strings.personal_info.partner_member_address}</h4>
              <div className="flex_input">
                <div>
                  <label>
                    {strings.address.flat} / {strings.address.door_no} /
                    {strings.address.block_no} *
                  </label>
                  <Input
                    name={"address.flatDoorNo"}
                    placeholder={`${strings.address.flat} / ${strings.address.door_no} / ${strings.address.block_no}`}
                    error={errors.address && errors.address.flatDoorNo}
                    value={values?.address && values?.address.flatDoorNo}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>
                    {strings.address.premise_name}{" "}
                    {strings.label.this_field_is_optional}
                  </label>
                  <Input
                    name={"address.premiseName"}
                    placeholder={strings.address.premise_name}
                    error={errors.address && errors.address.premiseName}
                    value={values?.address && values?.address.premiseName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>
                    {strings.address.road} / {strings.address.street}{" "}
                    {strings.label.this_field_is_optional}
                  </label>
                  <Input
                    name={"address.street"}
                    placeholder={`${strings.address.road} / ${strings.address.street}`}
                    error={errors.address && errors.address.street}
                    value={values?.address && values?.address.street}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.address.area_locality} *</label>
                  <Input
                    name={"address.area"}
                    placeholder={strings.address.area_locality}
                    error={errors.address && errors.address.area}
                    value={values?.address && values?.address.area}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.address.pincode} *</label>
                  <Input
                    type={"number"}
                    name={"address.pinCode"}
                    placeholder={strings.address.pincode}
                    error={errors.address && errors.address.pinCode}
                    value={values?.address && values?.address.pinCode}
                    onChange={(e) => {
                      if (e.target.value.length < 7) {
                        handleChange(e);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.address.state} *</label>
                  <Select
                    name="address.state"
                    error={errors.address && errors.address.state}
                    value={values?.address && values?.address.state}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        address: {
                          ...values?.address,
                          state: e.target.value,
                          city: "",
                        },
                      });
                      setActiveState(Number(e.target.value));
                    }}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {states?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div>
                  <label>{strings.address.city} *</label>
                  <Select
                    name={"address.city"}
                    error={errors.address && errors.address.city}
                    value={values?.address && values?.address.city}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>
                    {citiesList?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </StyleFormBox>
            <button className="secandary_button mt-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </>
        )}
      </Formik>
    </div>
  );
}
