import { Field, FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import AccordianBox from "../../../styledComponent/Form/AccordianBox";
import Input from "../../../styledComponent/Inputs/Input";
import Select from "../../../styledComponent/Inputs/Select";
import CurrencyAccordianBox from "../../../styledComponent/Form/CurrencyAccordianBox";
import { RiDeleteBin6Fill } from "react-icons/ri";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import { toast } from "react-toastify";
import {
  arrayToObject,
  convertObjectIntoArray,
  removeBlankObjects,
  removeEmptyKeys,
} from "../../../utils/helpers";
import {
  getUserSalaryBreakup,
  setGrossIncome,
} from "../../../redux/itr/action";
import LoadingBox from "../../DialogBox/LoadingBox";
import CompLoadingBox from "../../DialogBox/CompLoadingBox";
export default function SalaryIncome({ getData, handleNext }) {
  const [formData, setFormData] = useState({});
  const [tempData, setTempData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingBox, setLoadingBox] = useState(false);
  const [allowanceOptions, setAllowanceOptions] = useState();
  const { strings, theme, categories } = useSelector((s) => s.platformSetting);

  const dispatch = useDispatch();
  const categoriesEmployeeList = categories?.filter(
    (item) => item.tag === "employee"
  );
  const { ItrData } = useSelector((s) => s.itr);

  const validationSchema = Yup.object().shape({
    nameOfEmployer: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, {
        message: strings.validation_message.only_string_value,
        excludeEmptyString: true,
      })
      .required(strings.validation_message.required),
    employerCategory: Yup.string()
      .required(strings.validation_message.required)
      .notOneOf(["0"], strings.validation_message.required),
    tanOfEmployer: Yup.string()
      .required(strings.validation_message.required)
      .matches(/^([a-zA-Z]{4})([0-9]{5})([a-zA-Z]{1})$/, "Invalid TAN number"),
    tdsSalary: Yup.number()
      .required(strings.validation_message.required)
      .positive("TDS Salary must be greater than 0"),
  });

  const handleSubmitForm = (value) => {
    value = { ...formData, ...value };
    if (
      !(
        value.salaryAsPerSec17 ||
        value.valueOfPerqs ||
        value.retirementBenefit89AOther ||
        value.retirementBenefit89A ||
        value.profitsInLieuOfSal
      )
    ) {
      toast.error("Please Select At Least One Value in Salary Breakup");
      return;
    }

    if (
      !(
        value.otherExemptionsUs10.length > 0 ||
        value.hRAExemptionUnder10 ||
        value.lTAExemptionUnder10
      )
    ) {
      toast.error("Please Select At Least One Value in Exempt allowances");

      return;
    }

    const addObjects = {
      ...value,
      itrId: ItrData.id,
      salaryAsPerSec17: value.salaryAsPerSec17 || 0,
      valueOfPerqs: value.valueOfPerqs || 0,
      profitsInLieuOfSal: value.profitsInLieuOfSal || 0,
      retirementBenefit89A: value.retirementBenefit89A || 0,
      retirementBenefit89AOther: value.retirementBenefit89AOther || 0,
      uiModeForExemptions:
        value.otherExemptionsUs10 &&
        Object.keys(value.otherExemptionsUs10).length !== 0
          ? "BREAKUP_SPECIFIED"
          : "NOT_SPECIFIED",
      otherExemptionsUs10: arrayToObject(value.otherExemptionsUs10),
      retirementBenefit89ANotifiedBreakup: arrayToObject(
        value.retirementBenefit89ANotifiedBreakup
      ),
    };

    const filterObject = removeEmptyKeys(addObjects);
    setLoading(true);
    dispatch(
      setGrossIncome(filterObject, (e) => {
        if (e.status === 200) {
          toast.success("Successfully submit data");
        }
        setLoading(false);
      })
    );
  };

  const getIncomeData = () => {
    setLoadingBox(true);
    dispatch(
      getUserSalaryBreakup(ItrData.id, (e) => {
        const { salaryDetails: data, ...options } = e.data;
        setAllowanceOptions(options);
        setFormData({
          employerCategory: String(data?.employerCategory?.id || ""),
          nameOfEmployer: data?.nameOfEmployer || "",
          tanOfEmployer: data?.tanOfEmployer || "",
          tdsSalary: data?.tdsSalary || "",
          salaryAsPerSec17: data?.salaryAsPerSec17 || "",
          uiModeForSalaryUS17i: "NOT_SPECIFIED",
          valueOfPerqs: data?.valueOfPerqs || "",
          uiModeForPerquisites: "NOT_SPECIFIED",
          profitsInLieuOfSal: data?.profitsInLieuOfSal || "",
          uiModeForProfits: "NOT_SPECIFIED",
          profitsInLieuUs17Breakup: {},
          retirementBenefit89ANotifiedBreakup: convertObjectIntoArray(
            data?.retirementBenefit89ANotifiedBreakup || {}
          ),
          retirementBenefit89AOther: data?.retirementBenefit89AOther || "",
          uiModeFor89A: data?.uiModeFor89A || "NOT_SPECIFIED",
          retirementBenefit89A: data?.retirementBenefit89A || "",
          uiModeForExemptions: data?.uiModeForExemptions || "NOT_SPECIFIED",
          hRAExemptionUnder10: data?.hRAExemptionUnder10 || "",
          lTAExemptionUnder10: data?.lTAExemptionUnder10 || "",
          otherExemptionsUs10: convertObjectIntoArray(
            data?.otherExemptionsUs10 || {}
          ),
          standardDeduction4AUs16ia: data?.standardDeduction4AUs16ia || "",
          professionalTax4AUs16iii: data?.professionalTax4AUs16iii || "",
          reliefUs4BUs89A: data?.reliefUs4BUs89A || "",
          reliefIncomeClaimed4CUs89A: data?.reliefIncomeClaimed4CUs89A || "",
        });

        if (data) {
          setTempData({
            employerCategory: String(data?.employerCategory?.id || ""),
            nameOfEmployer: data?.nameOfEmployer || "",
            tanOfEmployer: data?.tanOfEmployer || "",
            tdsSalary: data?.tdsSalary || "",
            salaryAsPerSec17: data?.salaryAsPerSec17 || "",
            uiModeForSalaryUS17i: "NOT_SPECIFIED",
            valueOfPerqs: data?.valueOfPerqs || "",
            uiModeForPerquisites: "NOT_SPECIFIED",
            profitsInLieuOfSal: data?.profitsInLieuOfSal || "",
            uiModeForProfits: "NOT_SPECIFIED",
            profitsInLieuUs17Breakup: {},
            retirementBenefit89ANotifiedBreakup: convertObjectIntoArray(
              data?.retirementBenefit89ANotifiedBreakup || {}
            ),
            retirementBenefit89AOther: data?.retirementBenefit89AOther || "",
            uiModeFor89A: data?.uiModeFor89A || "NOT_SPECIFIED",
            retirementBenefit89A: data?.retirementBenefit89A || "",
            uiModeForExemptions: data?.uiModeForExemptions || "NOT_SPECIFIED",
            hRAExemptionUnder10: data?.hRAExemptionUnder10 || "",
            lTAExemptionUnder10: data?.lTAExemptionUnder10 || "",
            otherExemptionsUs10: convertObjectIntoArray(
              data?.otherExemptionsUs10 || {}
            ),
            standardDeduction4AUs16ia: data?.standardDeduction4AUs16ia || "",
            professionalTax4AUs16iii: data?.professionalTax4AUs16iii || "",
            reliefUs4BUs89A: data?.reliefUs4BUs89A || "",
            reliefIncomeClaimed4CUs89A: data?.reliefIncomeClaimed4CUs89A || "",
          });
        }
        setLoadingBox(false);
      })
    );
  };

  useEffect(() => {
    getIncomeData();
  }, []);

  return (
    <div>
      <LoadingBox open={loading} />
      {loadingBox ? (
        <CompLoadingBox open={loadingBox} />
      ) : (
        <Formik
          initialValues={tempData}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmitForm(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <div>
              <AccordianBox
                title={strings.tax.salary_income}
                description={strings.tax.you_can_add_salary_income}
                initial={false}
              >
                <AccordianBox title={strings.tax.employer_tds_details}>
                  <div className="flex_input">
                    <div>
                      <label>{strings.label.employer_name} *</label>
                      <Input
                        name={"nameOfEmployer"}
                        placeholder={strings.label.employer_name}
                        error={
                          touched?.nameOfEmployer && errors?.nameOfEmployer
                        }
                        value={values?.nameOfEmployer}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>{strings.label.employer_category} *</label>
                      <Select
                        name={"employerCategory"}
                        error={errors.employerCategory}
                        value={values?.employerCategory}
                        onChange={handleChange}
                      >
                        <option value="">{strings.label.choose_option}</option>
                        {categoriesEmployeeList?.map((i, ix) => (
                          <option value={i.id} key={ix}>
                            {i.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="flex_input">
                    <div>
                      <label>{strings.label.total_tax_deduxted_source} *</label>
                      <Input
                        name={"tdsSalary"}
                        inputType={"currency"}
                        error={errors.tdsSalary}
                        value={values?.tdsSalary}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>{strings.label.employer_tax} *</label>
                      <Input
                        name={"tanOfEmployer"}
                        className={"uppercase"}
                        placeholder={strings.label.employer_tax}
                        error={errors.tanOfEmployer}
                        value={values?.tanOfEmployer}
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            setFieldValue("tanOfEmployer", e.target.value.toUpperCase());
                          }
                        }}
                      />
                    </div>
                  </div>
                </AccordianBox>

                {/* ====================================  Salary Breakup   ==================================== */}
                {ItrData.form === "ITR_4" || ItrData.form === "ITR_1" ? (
                  <AccordianBox
                    title={1 + ". " + strings.tax.salary_breakup + " *"}
                  >
                    <div className="flex justify-between">
                      <h4>{strings.label.gross_salary}</h4>
                      <h4>
                        {strings.label.rupee}
                       
                        {Number(values?.salaryAsPerSec17 || 0) +
                          Number(values?.valueOfPerqs || 0) +
                          Number(values?.profitsInLieuOfSal || 0) +
                          Number(values?.retirementBenefit89A || 0) +
                          Number(values?.retirementBenefit89AOther || 0)}
                      </h4>
                    </div>
                    <span className="note_message">
                      {strings.message.select_atleast_one}
                    </span>

                    <div className="flex_input my-2">
                      <label>{strings.tax.salary_per_section_17}</label>
                      <Input
                        name={"salaryAsPerSec17"}
                        type={"number"}
                        inputType={"currency"}
                        error={errors.salaryAsPerSec17}
                        value={values?.salaryAsPerSec17}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="flex_input my-2">
                      <label>{strings.tax.perquisites_under_section}</label>
                      <Input
                        name={"valueOfPerqs"}
                        type={"number"}
                        inputType={"currency"}
                        error={errors.valueOfPerqs}
                        value={values?.valueOfPerqs}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex_input my-2">
                      <label>{strings.tax.profits_in_lieu_of_salary}</label>
                      <Input
                        name={"profitsInLieuOfSal"}
                        type={"number"}
                        inputType={"currency"}
                        error={errors.profitsInLieuOfSal}
                        value={values?.profitsInLieuOfSal}
                        onChange={handleChange}
                      />
                    </div>
                    <CurrencyAccordianBox
                      title={
                        strings.tax.income_under_section_89a_notified_country
                      }
                      inputBox={
                        <Input
                          name={"retirementBenefit89A"}
                          type={"number"}
                          inputType={"currency"}
                          error={errors.retirementBenefit89A}
                          value={values?.retirementBenefit89A}
                          onChange={handleChange}
                          disabled={
                            values?.uiModeFor89A == "BREAKUP_SPECIFIED"
                              ? true
                              : false
                          }
                        />
                      }
                    >
                      <label> {strings.label.other_allowances}</label>

                      <FieldArray name="retirementBenefit89ANotifiedBreakup">
                        {({ insert, remove, push }) => (
                          <StyleFormBox>
                            {values?.retirementBenefit89ANotifiedBreakup?.map(
                              (item, index) => (
                                <div
                                  className="flex_input my-2 items-center"
                                  key={index}
                                >
                                  <select
                                    name={`retirementBenefit89ANotifiedBreakup.${index}.keyname`}
                                    value={item.keyname}
                                    onChange={handleChange}
                                  >
                                    <option disabled value="">
                                      {strings.label.choose_option}
                                    </option>
                                    {allowanceOptions?.retirementBenefit89NotifiedBreakup.map(
                                      (i, ix) => {
                                        const isDisabled =
                                          values?.retirementBenefit89ANotifiedBreakup.some(
                                            (obj) => obj.keyname === i.key
                                          );
                                        return (
                                          <option
                                            disabled={isDisabled}
                                            value={i.key}
                                            key={ix}
                                          >
                                            {i.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>

                                  <Input
                                    name={`retirementBenefit89ANotifiedBreakup.${index}.value`}
                                    inputType={"currency"}
                                    value={item.value}
                                    onChange={handleChange}
                                  />

                                  <button
                                    className="delete_button mb-2"
                                    onClick={() => remove(index)}
                                  >
                                    <RiDeleteBin6Fill />
                                  </button>
                                </div>
                              )
                            )}
                            <div className="text-right mt-5">
                              <button
                                className="add_item_button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  push({ keyname: "", value: 0 });
                                }}
                              >
                                {strings.button.add_more_item}
                              </button>
                            </div>
                          </StyleFormBox>
                        )}
                      </FieldArray>

                      <div className="flex_input save_amount">
                        <h3>
                          {strings.tax.total_amount} :{" "}
                          <span className="text-xl font-semibold">
                            {strings.label.rupee}
                            {values?.retirementBenefit89ANotifiedBreakup?.reduce(
                              (acc, obj) => acc + parseInt(obj.value || 0),
                              0
                            )}
                          </span>
                        </h3>
                        <button
                          className="primary_button"
                          onClick={() => {
                            setFieldValue("uiModeFor89A", "BREAKUP_SPECIFIED");
                            setFieldValue(
                              "retirementBenefit89A",
                              values?.retirementBenefit89ANotifiedBreakup?.reduce(
                                (acc, obj) => acc + parseInt(obj.value || 0),
                                0
                              )
                            );
                          }}
                        >
                          {strings.button.save_amount}
                        </button>
                      </div>
                    </CurrencyAccordianBox>
                    <div className="flex_input my-2">
                      <label>
                        {strings.tax.income_under_section_89a_other_country}
                      </label>
                      <Input
                        name={"retirementBenefit89AOther"}
                        type={"number"}
                        inputType={"currency"}
                        error={errors.retirementBenefit89AOther}
                        value={values?.retirementBenefit89AOther}
                        onChange={handleChange}
                      />
                    </div>
                  </AccordianBox>
                ) : (
                  <AccordianBox
                    title={1 + ". " + strings.tax.salary_breakup + " *"}
                  >
                    <div className="flex justify-between">
                      <h4>{strings.label.gross_salary}</h4>
                      <h4>
                        {strings.label.rupee}
                       
                        {Number(values?.salaryAsPerSec17 || 0) +
                          Number(values?.valueOfPerqs || 0) +
                          Number(values?.profitsInLieuOfSal || 0) +
                          Number(values?.retirementBenefit89A || 0) +
                          Number(values?.retirementBenefit89AOther || 0)}
                      </h4>
                    </div>
                    <span className="note_message">
                      {strings.message.select_atleast_one}
                    </span>

                    <CurrencyAccordianBox
                      title={strings.tax.salary_per_section_17}
                      inputBox={
                        <Input
                          name={"salaryAsPerSec17"}
                          inputType={"currency"}
                          error={errors?.salaryAsPerSec17}
                          value={values?.salaryAsPerSec17}
                          onChange={handleChange}
                          disabled={
                            values?.uiModeForSalaryUS17i == "BREAKUP_SPECIFIED"
                              ? true
                              : false
                          }
                        />
                      }
                    >
                      <div className="flex_input">
                        <label>{strings.label.base_pay} </label>
                        <Input
                          name={"basicSalary"}
                          type={"number"}
                          inputType={"currency"}
                          error={errors?.basicSalary}
                          value={values?.basicSalary}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="flex_input">
                        <label>{strings.label.house_rent_allowace}</label>
                        <Input
                          name={"hraAllowance"}
                          inputType={"currency"}
                          error={errors?.hraAllowance}
                          value={values?.hraAllowance}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="flex_input">
                        <label>{strings.label.lta_allowance}</label>
                        <Input
                          name={"ltaAllowance"}
                          inputType={"currency"}
                          error={errors?.ltaAllowance}
                          value={values?.ltaAllowance}
                          onChange={handleChange}
                        />
                      </div>
                      <label> {strings.label.other_allowances}</label>

                      <FieldArray name="otherAllowances">
                        {({ insert, remove, push }) => (
                          <StyleFormBox>
                            {values?.otherAllowances?.map((item, index) => (
                              <div
                                className="flex_input my-2 items-center"
                                key={index}
                              >
                                <select
                                  name={`otherAllowances.${index}.keyname`}
                                  value={item.keyname}
                                  onChange={handleChange}
                                >
                                  <option disabled value="">
                                    {strings.label.choose_option}
                                  </option>
                                  {allowanceOptions?.salaryBreakup.map(
                                    (i, ix) => {
                                      const isDisabled =
                                        values.otherAllowances.some(
                                          (obj) => obj.keyname === i.key
                                        ) ||
                                        [
                                          "basicSalary",
                                          "hraAllowance",
                                          "ltaAllowance",
                                        ].includes(i.key);

                                      return (
                                        <option
                                          disabled={isDisabled}
                                          value={i.key}
                                          key={ix}
                                        >
                                          {i.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>

                                <Input
                                  name={`otherAllowances.${index}.value`}
                                  inputType={"currency"}
                                  value={item.value}
                                  onChange={handleChange}
                                />

                                <button
                                  className="delete_button mb-2"
                                  onClick={() => remove(index)}
                                >
                                  <RiDeleteBin6Fill />
                                </button>
                              </div>
                            ))}
                            <div className="text-right mt-5">
                              <button
                                className="add_item_button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  push({ keyname: "", value: 0 });
                                }}
                              >
                                {strings.button.add_more_item}
                              </button>
                            </div>
                          </StyleFormBox>
                        )}
                      </FieldArray>

                      <div className="flex_input save_amount">
                        <h3>
                          {strings.tax.total_amount} :{" "}
                          <span className="text-xl font-semibold">
                            {strings.label.rupee}{" "}
                            {values?.basicSalary +
                              values?.hraAllowance +
                              values?.ltaAllowance +
                              values?.otherAllowances?.reduce(
                                (acc, obj) => acc + parseInt(obj.value || 0),
                                0
                              )}
                          </span>
                        </h3>
                        <button
                          className="primary_button"
                          onClick={() => {
                            setFieldValue(
                              "uiModeForSalaryUS17i",
                              "BREAKUP_SPECIFIED"
                            );
                            setFieldValue(
                              "salaryAsPerSec17",
                              values?.basicSalary +
                                values?.hraAllowance +
                                values?.ltaAllowance +
                                values?.otherAllowances?.reduce(
                                  (acc, obj) => acc + parseInt(obj.value || 0),
                                  0
                                )
                            );
                          }}
                        >
                          {strings.button.save_amount}
                        </button>
                      </div>
                    </CurrencyAccordianBox>

                    <CurrencyAccordianBox
                      title={strings.tax.perquisites_under_section}
                      inputBox={
                        <Input
                          name={"valueOfPerqs"}
                          type={"number"}
                          inputType={"currency"}
                          error={errors?.valueOfPerqs}
                          value={values?.valueOfPerqs}
                          onChange={handleChange}
                          disabled={
                            values?.uiModeForPerquisites == "BREAKUP_SPECIFIED"
                              ? true
                              : false
                          }
                        />
                      }
                    >
                      <label> {strings.label.other_allowances}</label>

                      <FieldArray name="valueOfPerqsBreakup">
                        {({ insert, remove, push }) => (
                          <StyleFormBox>
                            {values?.valueOfPerqsBreakup?.map((item, index) => (
                              <div
                                className="flex_input my-2 items-center"
                                key={index}
                              >
                                <select
                                  name={`valueOfPerqsBreakup.${index}.keyname`}
                                  value={item.keyname}
                                  onChange={handleChange}
                                >
                                  <option disabled value="">
                                    {strings.label.choose_option}
                                  </option>
                                  {allowanceOptions?.perqsBreakup.map(
                                    (i, ix) => {
                                      const isDisabled =
                                        values.valueOfPerqsBreakup.some(
                                          (obj) => obj.keyname === i.key
                                        );
                                      return (
                                        <option
                                          disabled={isDisabled}
                                          value={i.key}
                                          key={ix}
                                        >
                                          {i.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>

                                <Input
                                  name={`valueOfPerqsBreakup.${index}.value`}
                                  inputType={"currency"}
                                  value={item.value}
                                  onChange={handleChange}
                                />

                                <button
                                  className="delete_button mb-2"
                                  onClick={() => remove(index)}
                                >
                                  <RiDeleteBin6Fill />
                                </button>
                              </div>
                            ))}
                            <div className="text-right mt-5">
                              <button
                                className="add_item_button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  push({ keyname: "", value: 0 });
                                }}
                              >
                                {strings.button.add_more_item}
                              </button>
                            </div>
                          </StyleFormBox>
                        )}
                      </FieldArray>

                      <div className="flex_input save_amount">
                        <h3>
                          {strings.tax.total_amount} :{" "}
                          <span className="text-xl font-semibold">
                            {strings.label.rupee}{" "}
                            {values?.valueOfPerqsBreakup?.reduce(
                              (acc, obj) => acc + parseInt(obj.value || 0),
                              0
                            )}
                          </span>
                        </h3>
                        <button
                          className="primary_button"
                          type="button"
                          onClick={() => {
                            setFieldValue(
                              "uiModeForPerquisites",
                              "BREAKUP_SPECIFIED"
                            );
                            setFieldValue(
                              "valueOfPerqs",
                              values?.valueOfPerqsBreakup?.reduce(
                                (acc, obj) => acc + parseInt(obj.value || 0),
                                0
                              )
                            );
                          }}
                        >
                          {strings.button.save_amount}
                        </button>
                      </div>
                    </CurrencyAccordianBox>

                    <CurrencyAccordianBox
                      title={
                        strings.tax.income_under_section_89a_notified_country
                      }
                      inputBox={
                        <Input
                          name={"retirementBenefit89A"}
                          type={"number"}
                          inputType={"currency"}
                          error={errors.retirementBenefit89A}
                          value={values?.retirementBenefit89A}
                          onChange={handleChange}
                          disabled={
                            values?.uiModeFor89A == "BREAKUP_SPECIFIED"
                              ? true
                              : false
                          }
                        />
                      }
                    >
                      <label> {strings.label.other_allowances}</label>

                      <FieldArray name="retirementBenefit89ANotifiedBreakup">
                        {({ insert, remove, push }) => (
                          <StyleFormBox>
                            {values?.retirementBenefit89ANotifiedBreakup?.map(
                              (item, index) => (
                                <div
                                  className="flex_input my-2 items-center"
                                  key={index}
                                >
                                  <select
                                    name={`retirementBenefit89ANotifiedBreakup.${index}.keyname`}
                                    value={item.keyname}
                                    onChange={handleChange}
                                  >
                                    <option disabled value="">
                                      {strings.label.choose_option}
                                    </option>
                                    {allowanceOptions?.retirementBenefit89NotifiedBreakup.map(
                                      (i, ix) => {
                                        const isDisabled =
                                          values?.retirementBenefit89ANotifiedBreakup.some(
                                            (obj) => obj.keyname === i.key
                                          );
                                        return (
                                          <option
                                            disabled={isDisabled}
                                            value={i.key}
                                            key={ix}
                                          >
                                            {i.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>

                                  <Input
                                    name={`retirementBenefit89ANotifiedBreakup.${index}.value`}
                                    inputType={"currency"}
                                    value={item.value}
                                    onChange={handleChange}
                                  />

                                  <button
                                    className="delete_button mb-2"
                                    onClick={() => remove(index)}
                                  >
                                    <RiDeleteBin6Fill />
                                  </button>
                                </div>
                              )
                            )}
                            <div className="text-right mt-5">
                              <button
                                className="add_item_button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  push({ keyname: "", value: 0 });
                                }}
                              >
                                {strings.button.add_more_item}
                              </button>
                            </div>
                          </StyleFormBox>
                        )}
                      </FieldArray>

                      <div className="flex_input save_amount">
                        <h3>
                          {strings.tax.total_amount} :{" "}
                          <span className="text-xl font-semibold">
                            {strings.label.rupee}
                            {values?.retirementBenefit89ANotifiedBreakup?.reduce(
                              (acc, obj) => acc + parseInt(obj.value || 0),
                              0
                            )}
                          </span>
                        </h3>
                        <button
                          className="primary_button"
                          onClick={() => {
                            setFieldValue("uiModeFor89A", "BREAKUP_SPECIFIED");
                            setFieldValue(
                              "retirementBenefit89A",
                              values?.retirementBenefit89ANotifiedBreakup?.reduce(
                                (acc, obj) => acc + parseInt(obj.value || 0),
                                0
                              )
                            );
                          }}
                        >
                          {strings.button.save_amount}
                        </button>
                      </div>
                    </CurrencyAccordianBox>

                    <div className="flex_input my-2">
                      <label>{strings.tax.profits_in_lieu_of_salary}</label>
                      <Input
                        name={"profitsInLieuOfSal"}
                        type={"number"}
                        inputType={"currency"}
                        error={errors.profitsInLieuOfSal}
                        value={values?.profitsInLieuOfSal}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="flex_input my-2">
                      <label>
                        {strings.tax.income_under_section_89a_other_country}
                      </label>
                      <Input
                        name={"retirementBenefit89AOther"}
                        type={"number"}
                        inputType={"currency"}
                        error={errors.retirementBenefit89AOther}
                        value={values?.retirementBenefit89AOther}
                        onChange={handleChange}
                      />
                    </div>
                  </AccordianBox>
                )}

                <AccordianBox
                  title={2 + ". " + strings.tax.exempt_allowances + " *"}
                >
                  <div className="flex justify-between">
                    <h4>{strings.tax.exempt_allowances_under_section_10}</h4>
                    <h4>
                      {strings.label.rupee}
                      {Number(values?.hRAExemptionUnder10 || 0) +
                        Number(values?.lTAExemptionUnder10 || 0) +
                        Number(values?.valueOfPerqs || 0) +
                        Number(values?.profitsInLieuOfSal || 0)}
                    </h4>
                  </div>
                  <span className="note_message">
                    {strings.message.select_atleast_one}
                  </span>

                  <div className="flex_input">
                    <label>{strings.label.house_rent_exemption} </label>
                    <Input
                      name={"hRAExemptionUnder10"}
                      inputType={"currency"}
                      error={errors.hRAExemptionUnder10}
                      value={values?.hRAExemptionUnder10}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input">
                    <label>{strings.label.lta_exemption}</label>
                    <Input
                      name={"lTAExemptionUnder10"}
                      inputType={"currency"}
                      error={errors.lTAExemptionUnder10}
                      value={values?.lTAExemptionUnder10}
                      onChange={handleChange}
                    />
                  </div>
                  <label> {strings.label.other_exemption}</label>

                  <FieldArray name="otherExemptionsUs10">
                    {({ insert, remove, push }) => (
                      <StyleFormBox>
                        {values?.otherExemptionsUs10?.map((item, index) => (
                          <div
                            className="flex_input my-2 items-center"
                            key={index}
                          >
                            <select
                              name={`otherExemptionsUs10.${index}.keyname`}
                              value={item.keyname}
                              onChange={handleChange}
                            >
                              <option disabled value="">
                                {strings.label.choose_option}
                              </option>
                              {allowanceOptions?.allowancesBreakup.map(
                                (i, ix) => {
                                  const isDisabled =
                                    values?.otherExemptionsUs10.some(
                                      (obj) => obj.keyname === i.key
                                    ) ||
                                    [
                                      "hRAExemptionUnder10",
                                      "lTAExemptionUnder10",
                                    ].includes(i.key);
                                  return (
                                    <option
                                      disabled={isDisabled}
                                      value={i.key}
                                      key={ix}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                }
                              )}
                            </select>

                            <Input
                              name={`otherExemptionsUs10.${index}.value`}
                              inputType={"currency"}
                              value={item.value}
                              onChange={handleChange}
                            />

                            <button
                              className="delete_button mb-2"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        ))}
                        <div className="text-right mt-5">
                          <button
                            className="add_item_button"
                            onClick={(e) => {
                              e.preventDefault();
                              push({ keyname: "", value: "" });
                            }}
                          >
                            {strings.button.add_more_item}
                          </button>
                        </div>
                      </StyleFormBox>
                    )}
                  </FieldArray>
                </AccordianBox>

                <StyleFormBox>
                  <div className="flex-1 flex items-center justify-between">
                    <h4 style={{ color: theme.primary_txt }}>
                      3. {strings.label.balance} (1 -2)
                    </h4>
                    <h4 style={{ color: theme.primary_txt }}>
                      {strings.label.rupee}{" "}
                      {Number(values?.salaryAsPerSec17 || 0) +
                        Number(values?.retirementBenefit89AOther || 0) +
                        Number(values?.valueOfPerqs || 0) +
                        Number(values?.profitsInLieuOfSal || 0)}
                    </h4>
                  </div>
                </StyleFormBox>

                <AccordianBox
                  title={4 + ". " + strings.tax.deductions_and_relief}
                >
                  <label>{strings.tax.deduction_under_section_16}</label>
                  <div className="flex_input">
                    <div>
                      <label>{strings.label.standard_deductions_16ia}</label>
                      <Input
                        name={"standardDeduction4AUs16ia"}
                        inputType={"currency"}
                        error={errors.standardDeduction4AUs16ia}
                        value={values?.standardDeduction4AUs16ia}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>
                        {strings.label.professional_tax_under_section_16iii}
                      </label>
                      <Input
                        name={"professionalTax4AUs16iii"}
                        inputType={"currency"}
                        error={errors.professionalTax4AUs16iii}
                        value={values?.professionalTax4AUs16iii}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex_input">
                    <div>
                      <label>{strings.label.relief_under_section_89}</label>
                      <Input
                        name={"reliefUs4BUs89A"}
                        inputType={"currency"}
                        error={errors.reliefUs4BUs89A}
                        value={values?.reliefUs4BUs89A}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>
                        {
                          strings.label
                            .income_claimed_for_relief_under_sectio_89a
                        }
                      </label>
                      <Input
                        name={"reliefIncomeClaimed4CUs89A"}
                        inputType={"currency"}
                        error={errors.reliefIncomeClaimed4CUs89A}
                        value={values?.reliefIncomeClaimed4CUs89A}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </AccordianBox>

                <StyleFormBox>
                  <div className="flex-1 flex items-center justify-between">
                    <h4 style={{ color: theme.primary_txt }}>
                      5. {strings.tax.income_chargeable_under_head_salaries}
                    </h4>
                    <h4 style={{ color: theme.primary_txt }}>
                      {strings.label.rupee}
                      {Number(values?.salaryAsPerSec17 || 0) -
                        (Number(values?.standardDeduction4AUs16ia || 0) +
                          Number(values?.professionalTax4AUs16iii || 0)) -
                        Number(values?.reliefIncomeClaimed4CUs89A || 0)}
                    </h4>
                  </div>
                </StyleFormBox>

                <button
                  type="submit"
                  className="primary_button mt-4"
                  onClick={handleSubmit}
                >
                  {strings.button.save}
                </button>
              </AccordianBox>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
}
