import { useSelector } from "react-redux";
import styled from "styled-components";

export default function Newsletter() {
  const {theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <div className="instant-help">
        <div className="container_box">
          <div className="instant-main flex items-center">
            <div className="instant-left">
              <div className="instant-heading">
                <h2>
                  Become a pro at<span>all things GST.</span>
                  <br></br> Get Edge!
                </h2>
              </div>
              <div className="instant-content-first flex items-baseline gap-2">
                <div className="instant-img">
                  <img src="Images/yellow-check-removebg-preview.png"></img>
                </div>
                <div className="instant-text">
                  <p>Get FREE access</p>
                </div>
              </div>
              <div className="instant-content-first flex items-baseline gap-2">
                <div className="instant-img">
                  <img src="Images/yellow-check-removebg-preview.png"></img>
                </div>
                <div className="instant-text">
                  <p>
                    Exclusively curated insights, updates, tips, announcements
                    and more
                  </p>
                </div>
              </div>
              <div className="instant-content-first flex items-baseline gap-2">
                <div className="instant-img">
                  <img src="Images/yellow-check-removebg-preview.png"></img>
                </div>
                <div className="instant-text">
                  <p>Every week, no spam!</p>
                </div>
              </div>
              <div className="btm-line">
                <p>
                  Join 25,000+ tax experts, company CFOs and finance enthusiast!
                </p>
              </div>
            </div>
            <div className="instant-right">
              <div className="instant-right-img">
                <img className="img-fluid" src="Images/rightside-image.png"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Newsletter-main">
        <div className="container_box">
          <div className="newsletter-content flex items-center justify-center">
            <div className="newsletter-left-img">
              <img
                className="img-fluid"
                src="Images/cn-removebg-preview.png"
              ></img>
            </div>
            <div className="newsletter-right-cntn">
              <div className="heading">
                <h4>
                  Subscribe To Our <br></br>Newsletter
                </h4>
              </div>
              <div className="input-main flex items-center">
                <div className="cls-input">
                  <input
                    type="text"
                    placeholder="Enter Your Email Address"
                  ></input>
                </div>
                <div className="btm-button">
                  <button className="subscribe">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  .instant-help {
    width: 100%;
    margin-top: 50px;
    .instant-main {
      .instant-left {
        width: 60%;
        .instant-heading {
          margin-bottom: 20px;
          h2 {
            font-weight: 700;
            font-size: 42px;
            span {
              color: #ffa53b;
            }
          }
        }
        .instant-content-first {
          margin-bottom: 10px;
          .instant-img {
            img {
              width: 30px;
            }
          }
          .instant-text {
            margin-bottom: 10px;
            p {
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
      }
      .instant-right {
        width: 40%;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
    .btm-line {
      p {
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
  .Newsletter-main {
    width: 100%;
    .newsletter-content {
      width: 100%;
      background: ${(p) => p.theme.secandary_bg};
      border-radius: 0 20px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      .newsletter-left-img {
        width: 50%;
        @media (max-width: 991px) {
          width: 100%;
        }
        img {
          @media (max-width: 991px) {
            margin: auto;
          }
        }
      }
      .newsletter-right-cntn {
        width: 50%;
        .heading {
          h4 {
            font-size: 42px;
            font-weight: 700;
            color: #1a2b5e;
          }
          @media (max-width: 991px) {
            text-align: center;
          }
        }
        .input-main {
          margin-top: 30px;
          .cls-input > input {
            background: transparent;
            border: 1px solid #1a2b5e;
            padding: 10px 20px;
            border-radius: 50px;
          }
          .btm-button {
            .subscribe {
              background-color: #ffa53b;
              color: #fafafa;
              padding: 10px 20px;
              border-radius: 50px;
              font-weight: 600;
            }
          }
          @media (max-width: 991px) {
            justify-content: center;
            margin-bottom: 20px;
          }
        }
        @media (max-width: 991px) {
          width: 100%;
        }
      }
      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
  }
`;
