import React from "react";
import { useSelector } from "react-redux";
import { FaFileUpload } from "react-icons/fa";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { FiArrowRightCircle } from "react-icons/fi";
export default function Products() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <ProductSection theme={theme}>
      <div className="main-product">
      <div className="section_tool w-[60%]">
        <div>
          <div className="icon_box">
          <img src={'/Images/icon-tpo.png'} />
          </div>
          <h4>Claim Tax Refund</h4>
          <p>
          If you have paid more income tax, you are eligible for a tax refund
          </p>
        </div>
        <div>
          <div className="icon_box">
          <img src={'/Images/icon-eca.png'} />
          </div>
          <h4>Avoid Late Filing Fee</h4>
          <p>
          A delay in filing ITR makes you liable to pay a fee of up to 5,000 INR.
          </p>
        </div>
        <div>
          <div className="icon_box">
          <img src={'/Images/icon-tpo.png'} />
          </div>
          <h4>Obtaining Visa</h4>
          <p>
          Foreign consulates may ask you to furnish your income tax returns for obtaining Visa
          </p>
        </div>
        <div>
          <div className="icon_box">
          <img src={'/Images/icon-eca.png'} />
          </div>
          <h4>Easy Loan Approval</h4>
          <p>
          The income tax return serves as an important document for loan approval
          </p>
        </div>
      </div>
      <div className=" w-[40%] our-services">
        <h4 className="text-3xl font-bold" style={{ color: theme.primary_bg }}>Benefits of filing an ITR</h4>
        <p className="text-lg">
        Easily file your tax returns and enjoy a hassle-free tax season with e-filing.
        </p>
         <button className="action_button mt-4">File Now <FiArrowRightCircle /></button>
      </div>
      </div>
    </ProductSection>
  );
}
const ProductSection = styled.section`
 .main-product {
  padding: 40px 20px;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin:auto;
  gap:40px;
  .section_tool {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    > div {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 30px 15px;
    border-radius: 10px;
      .icon_box {
        background-color: ${(p) => addOpacity(p.theme.primary_txt, 0.1)};
        width: fit-content;
        color: ${(p) => p.theme.primary_bg};
        padding: 6px;
        font-size: 30px;
        border-radius: 8px;
        margin-bottom: 18px;
      } 
    
      > h4 {
        font-size: 28px;
        font-weight:700;
      }
      > p {
        font-size: 16px;
        margin: 10px 0px;
      }
   
    }
    @media (max-width:1024px)
    {
      width:100%;
    }
    @media (max-width:500px){
      grid-template-columns:1fr;
    }
  }
  .action_button {
        background-color: ${(p) => p.theme.primary_bg};
        padding: 6px 20px;
        border-radius: 50px;
        font-weight: 500;
        color: ${(p) => p.theme.secandary_txt};
        display:flex;
        gap:10px;
        align-items:center;
        font-size: 18px;
      }
  .our-services {
    @media (min-width:320px) and (max-width:768px) 
    {
      width:100%;
      margin-top:20px;
    }
  }
  @media (min-width:320px) and (max-width:1024px)
  {
    flex-direction:column;
  }
}
`;
