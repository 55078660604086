import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Input from "../../../../styledComponent/Inputs/Input";
import Select from "../../../../styledComponent/Inputs/Select";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FieldArray, Formik } from "formik";
import LoadingBox from "../../../DialogBox/LoadingBox";
import * as Yup from "yup";

export default function ExemptIncome({
  handleCallback,
  incomeSourceOption,
  incomeData,
}) {
  const [formData, setFormData] = useState();
  const [loader, setLoader] = useState(true);
  const { ItrData } = useSelector((s) => s.itr);
  const { strings, theme } = useSelector((s) => s.platformSetting);

  const validationSchema1 = Yup.object().shape({
    interestIncomeFromPpf: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
    interestIncomeFromNREAccount: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
    anyOtherExemptIncome: Yup.array().of(
      Yup.object().shape({
        source: Yup.string().required(strings.validation_message.required),
        description: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
  });

  const validationSchema2 = Yup.object().shape({
    anyOtherExemptIncome: Yup.array().of(
      Yup.object().shape({
        source: Yup.string().required(strings.validation_message.required),
        description: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
  });
  useEffect(() => {
    setLoader(true);
    setFormData({
      typeOfIncome: 106,
      ...(ItrData.form === "ITR_2" || ItrData.form === "ITR_3"
        ? {
            interestIncomeFromPpf: incomeData
            ? (incomeData?.interestIncomeFromPpf.length &&
              incomeData?.interestIncomeFromPpf[0].description == "" &&
              incomeData?.interestIncomeFromPpf[0].amount == 0
              ? [] : incomeData?.interestIncomeFromPpf)
            : [{
                description: "",
                amount: "",
              },
            ],
            interestIncomeFromNREAccount: incomeData
            ? (incomeData?.interestIncomeFromNREAccount.length &&
              incomeData?.interestIncomeFromNREAccount[0].description == "" &&
              incomeData?.interestIncomeFromNREAccount[0].amount == 0
              ? [] : incomeData?.interestIncomeFromNREAccount)
            : [{
                description: "",
                amount: "",
              },
            ],
          }
        : {}),
      anyOtherExemptIncome: incomeData
        ? (incomeData?.anyOtherExemptIncome.length &&
          incomeData?.anyOtherExemptIncome[0].source == "" &&
          incomeData?.anyOtherExemptIncome[0].description == "" &&
          incomeData?.anyOtherExemptIncome[0].amount == 0
          ? [] : incomeData?.anyOtherExemptIncome)
        : [{
            source: "",
            description: "",
            amount: "",
          },
        ],
    });

    setLoader(false);
  }, [incomeData]);

  return (
    <div>
      <LoadingBox open={loader} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={
          ItrData.form == "ITR_2" || ItrData.form == "ITR_3"
            ? validationSchema1
            : validationSchema2
        }
        onSubmit={(values) => {
          handleCallback(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            {ItrData.form == "ITR_2" || ItrData.form == "ITR_3" ? (
              <>
                <FieldArray name="interestIncomeFromPpf">
                  {({ insert, remove, push }) => (
                    <StyleFormBox>
                      <h4 className="mt-1">
                        {strings.income.interest_income_from_ppf}
                      </h4>
                      <p>{strings.income.interest_income_from_ppf}</p>

                      <div className="flex_input my-2">
                        <label>{strings.label.description}</label>
                        <label>{strings.income.amount}</label>
                      </div>
                      {values?.interestIncomeFromPpf?.map((item, index) => (
                        <div
                          className="flex_input my-2 items-center"
                          key={index}
                        >
                          <Input
                            name={`interestIncomeFromPpf.${index}.description`}
                            placeholder={strings.label.description}
                            value={item.description}
                            error={
                              errors?.interestIncomeFromPpf &&
                              errors?.interestIncomeFromPpf[index]?.description
                            }
                            onChange={handleChange}
                          />
                          <Input
                            name={`interestIncomeFromPpf.${index}.amount`}
                            inputType={"currency"}
                            error={
                              errors?.interestIncomeFromPpf &&
                              errors?.interestIncomeFromPpf[index]?.amount
                            }
                            value={item.amount}
                            onChange={handleChange}
                          />

                          <button
                            className="delete_button mb-2"
                            onClick={() => remove(index)}
                          >
                            <RiDeleteBin6Fill />
                          </button>
                        </div>
                      ))}

                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({ description: "", amount: "" });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </StyleFormBox>
                  )}
                </FieldArray>

                <FieldArray name="interestIncomeFromNREAccount">
                  {({ insert, remove, push }) => (
                    <StyleFormBox>
                      <h4 className="mt-1">
                        {strings.income.interest_income_from_nre_account}
                      </h4>
                      <p>
                        {
                          strings.income
                            .interest_earned_from_non_residential_external
                        }
                      </p>

                      <div className="flex_input my-2">
                        <label>{strings.label.description}</label>
                        <label>{strings.income.amount}</label>
                      </div>
                      {values?.interestIncomeFromNREAccount?.map(
                        (item, index) => (
                          <div
                            className="flex_input my-2 items-center"
                            key={index}
                          >
                            <Input
                              name={`interestIncomeFromNREAccount.${index}.description`}
                              placeholder={strings.label.description}
                              value={item.description}
                              error={
                                errors?.interestIncomeFromNREAccount &&
                                errors?.interestIncomeFromNREAccount[index]
                                  ?.description
                              }
                              onChange={handleChange}
                            />
                            <Input
                              name={`interestIncomeFromNREAccount.${index}.amount`}
                              inputType={"currency"}
                              value={item.amount}
                              error={
                                errors?.interestIncomeFromNREAccount &&
                                errors?.interestIncomeFromNREAccount[index]
                                  ?.amount
                              }
                              onChange={handleChange}
                            />

                            <button
                              className="delete_button mb-2"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        )
                      )}

                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({ description: "", amount: "" });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </StyleFormBox>
                  )}
                </FieldArray>
              </>
            ) : (
              ""
            )}
            <FieldArray name="anyOtherExemptIncome">
              {({ insert, remove, push }) => (
                <StyleFormBox>
                  <h4 className="mt-1">
                    {strings.income.any_other_exempt_income}
                  </h4>
                  <p>{strings.income.specify_other_exempt_income}</p>
                  <div className="flex_input my-2">
                    <label>{strings.income.source}</label>
                    <label>{strings.label.description}</label>
                    <label>{strings.income.amount}</label>
                  </div>
                  {values?.anyOtherExemptIncome?.map((item, index) => (
                    <div className="flex_input my-2 items-center" key={index}>
                      <Select
                        name={`anyOtherExemptIncome.${index}.source`}
                        value={item.source}
                        error={
                          errors?.anyOtherExemptIncome &&
                          errors?.anyOtherExemptIncome[index]?.source
                        }
                        onChange={handleChange}
                      >
                        <option value={""} disabled>
                          {strings.label.choose_option}
                        </option>
                        {incomeSourceOption?.map((i, ix) => (
                          <option value={i.id} key={ix}>
                            {i.name}
                          </option>
                        ))}
                      </Select>

                      <Input
                        placeholder={strings.label.description}
                        name={`anyOtherExemptIncome.${index}.description`}
                        value={item.description}
                        error={
                          errors?.anyOtherExemptIncome &&
                          errors?.anyOtherExemptIncome[index]?.description
                        }
                        onChange={handleChange}
                      />
                      <Input
                        name={`anyOtherExemptIncome.${index}.amount`}
                        inputType={"currency"}
                        value={item.amount}
                        error={
                          errors?.anyOtherExemptIncome &&
                          errors?.anyOtherExemptIncome[index]?.amount
                        }
                        onChange={handleChange}
                      />

                      <button
                        className="delete_button mb-2"
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  ))}

                  <div className="text-right mt-5">
                    <button
                      className="add_item_button"
                      onClick={(e) => {
                        e.preventDefault();
                        push({ source: "", description: "", amount: "" });
                      }}
                    >
                      {strings.button.add_more_item}
                    </button>
                  </div>
                </StyleFormBox>
              )}
            </FieldArray>

            <button className="primary_button my-4" type="submit">
              {strings.button.save_and_close}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}
