import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Input from "../../styledComponent/Inputs/Input";
import CheckField from "../../styledComponent/Inputs/CheckField";
import OtpField from "../../styledComponent/Inputs/OtpField";
import { validatePan } from "../../utils/helpers";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
export default function PanVerify() {
  const [formData, setFormData] = useState();
  const [activeScreen, setActiveScreen] = useState(1);
  const [panData, setPanData] = useState({
    pan_number: "",
    date_of_birth: "",
  });
  const [error, setError] = useState({
    pan_number: "",
  });
  const [otp, setOtp] = useState();

  const { theme, strings } = useSelector((s) => s.platformSetting);
  const navigate = useNavigate();
  const handleData = (key, value) => {
    if (key == "pan_number") {
      // if (validatePan(value) === true) {
      //   setError({ ...error, pan_number: "" });
      // } else {
      //   setError({
      //     ...error,
      //     pan_number: strings.validation_message.invalid_pan,
      //   });
      // }
      setPanData({ ...panData, pan_number: value });
    } else {
      setPanData({ ...panData, [key]: value });
    }
  };
  function areAllValuesEmpty(obj) {
    for (const key in obj) {
      if (obj[key] !== "") {
        return false;
      }
    }
    return true;
  }

  const getOtpApi = (data) => {
    setActiveScreen(2);
  };

  const verifyOtpApiCall = () => {
    if (otp?.join("").length === 4) {
      navigate("/filing/itr/questions");
    } else {
      toast.error("Please fill valid otp");
    }
  };

  const validationSchema = Yup.object().shape({
    pan_number: Yup.string()
      .required(strings.validation_message.required)
      .matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/, strings.validation_message.invalid_pan),
      date_of_birth:Yup.string().required(strings.validation_message.required)
  });

  return (
    <Root theme={theme} className="min_container">
      <Formik
        initialValues={{
          pan_number: "",
          date_of_birth:'',
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          getOtpApi(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <>
            <h4>{strings.signUp.verify_your_pan} ?</h4>
            <div>
              <label>{strings.signUp.pan_card_number}</label>
              <Input
                placeholder={strings.placeholder.enter_10_digit_pan}
                name={"pan_number"}
                className={"uppercase"}
                value={values.pan_number}
                error={errors.pan_number}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setFieldValue("pan_number", e.target.value.toUpperCase());
                  }
                }}
              />
              <label>{strings.label.date_of_birth}</label>

              <Input
                inputType={"date"}
                name={'date_of_birth'}
                placeholder={strings.income.date_of_receipt}
                value={values.date_of_birth}
                error={errors.date_of_birth}
                onChange={handleChange}
              />
            </div>
            {activeScreen == 1 ? (
              <>
                <h4 className="my-4">
                  {strings.signUp.choose_method_for_verifying_otp}{" "}
                </h4>
                <CheckField
                  error={""}
                  value={strings.signUp.it_department_registered}
                  handleChange={() => {}}
                  options={[
                    strings.signUp.it_department_registered,
                    strings.signUp.aadhaar_registered_mobile_number,
                  ]}
                />
                <button
                  className="primary_button mt-10"
                  onClick={handleSubmit}
                >
                  {strings.button.next}
                </button>
              </>
            ) : activeScreen == 2 ? (
              <>
                <div className="text-center">
                  <label>{strings.signUp.enter_otp_to_verify}</label>
                  <OtpField
                    handleClick={(e) => {
                      setOtp(e);
                    }}
                    otpLength={4}
                  />
                </div>
                <h6 className="text-center mt-4 font-regular">
                  {strings.signUp.if_dont_receive_code} ?{" "}
                  <button style={{ color: theme.primary_bg }}>
                    {strings.button.resend}
                  </button>
                </h6>
                <button className="primary_button" onClick={verifyOtpApiCall}>
                  {strings.signUp.verify_otp}
                </button>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </Formik>
    </Root>
  );
}
const Root = styled.div`
  margin-top: 30px;
  border-radius: 10px;
  background-color: ${(p) => p.theme.secandary_bg};
`;
