import React from 'react'
import { IoInformationCircleSharp } from "react-icons/io5";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
export default function InformationNote({note}) {
  const { theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
    <IoInformationCircleSharp />
    {note}
    </Root>
  )
}
const Root = styled.div`
  margin-top:10px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${p => p.theme.tertiary_bg};
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
  >svg{
    font-size:20px;
  }
`