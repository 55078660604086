import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";

export default function HraCalculator() {
  const {theme } = useSelector((s) => s.platformSetting);

  return (
    <Root theme={theme}>
      <div className="header-section">
        <div className="header-content flex justify-center items-center">
          <div className="text-line">
            <h5>Filing for AY 2024-25 is coming soon</h5>
          </div>
          <div className="pre-button">
            <button type="button">Pre-Book Now</button>
            <span className="off_coupon">Grab 25% OFF</span>
          </div>
        </div>
      </div>
      <div className="Hracalculator-section">
        <div className="calculator">
          <div className="container_box">
            <div className="calculator-heading">
              <h2>HRA Calculator</h2>
            </div>
            <div className="btm-heading">
              <h6>Calculate House Rent Allowance Online</h6>
              <p>
                Please Note:- You can now claim up to Rs. 60,000 deduction under
                Section 80GG even if you do not receive HRA.
              </p>
            </div>
            <div className="calculator-main">
              <div className="calculator-left-main">
                <div className="calculator-left-box">
                  <div className="left-box-heading">
                    <h4>
                      Calculate Your HRA<br></br> Exemption
                    </h4>
                    <p>
                      The calculation is done by taking the salary as<br></br>{" "}
                      Basic Pay + D.A. (if considered for retirement<br></br>{" "}
                      benefits)
                    </p>
                  </div>
                  <div className="left-calculator-menus">
                    <ul>
                      <li>Actual HRA received</li>
                      <li>Rent paid less 10% of Salary</li>
                      <li>
                        40% of Salary (50% of Salary in Mumbai, Delhi,<br></br>{" "}
                        Chennai and Kolkata)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="calculator-right-main">
                <div className="Calcuator-right-box">
                  <div className="right-top-bar flex">
                    <div className="right-first">
                      <h5>Living In:</h5>
                    </div>
                    <div className="right-second">
                      <p>
                        <input
                          type="radio"
                          id="html"
                          name="fav_language"
                          value="HTML"
                        ></input>
                        Metro City
                      </p>
                      <div className="btm-tx">
                        <span>Delhi, Kolkata, Mumbai, Chennai.</span>
                      </div>
                    </div>
                    <div className="right-second">
                      <p>
                        <input
                          type="radio"
                          id="html"
                          name="fav_language"
                          value="HTML"
                        ></input>
                        Other City
                      </p>
                      <div className="btm-tx">
                        <span>Other than Metro City.</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="first-field">
                      <div className="abc">
                        <label className="abc" for="css">
                          Basic Salary + DA + Commission Received
                        </label>
                        <br></br>
                      </div>
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="Basic Salary + DA + Commission Received"
                      ></input>
                    </div>
                    <div className="second-field">
                      <div className="second-main">
                        <div className="abc">
                          <label className="abc" for="css">
                            HRA Received
                          </label>
                          <br></br>
                        </div>
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="Basic Salary + DA + Commission Received"
                        ></input>
                      </div>
                      <div className="second-main">
                        <div className="abc">
                          <label className="abc" for="css">
                            Actual Rent Paid
                          </label>
                          <br></br>
                        </div>
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="Basic Salary + DA + Commission Received"
                        ></input>
                      </div>
                    </div>
                    <div className="first-field">
                      <div className="abc">
                        <label className="abc" for="css">
                          Basic Salary + DA + Commission Received
                        </label>
                        <br></br>
                      </div>
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="Basic Salary + DA + Commission Received"
                      ></input>
                    </div>
                    <div className="submit-btn">
                      <button type="button">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cta-hra-section">
        <div className="cta-box">
          <div className="">
            <div className="cta-mn">
              <div className="cta-hra-img">
                <img src="Images/businessman-character.png"></img>
              </div>
              <div className="cta-hra-content">
                <h4>
                  File Today Save Tomorrow<br></br>Avoid Penalties And Maximize
                  Your Refund!
                </h4>
                <div className="cta-hra-btn">
                  <button type="button">
                    File ITR Today <FaArrowRightLong />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btm-content-section">
        <div className="container_box">
          <div className="wrapper-main">
            <div className="wrapper">
              <h4>What is HRA?</h4>
              <p>
                HRA or <a href="#">House Rent Allowance,</a> is an allowance
                that salaried individuals receive from their employer for
                meeting the rental expenses of their house. The amount received
                is partly exempted from tax for the employees who are residing
                in a rented house. In case the employee lives in his/her own
                house and does not pay any rent, the entire amount would be
                taxable
              </p>
              <hr className="hr"></hr>
            </div>
            <div className="top-first">
              <h4>How is HRA calculated for tax saving?</h4>
            </div>
            <div className="table-responsive">
              <table width="100%" border="1" className="table">
                <tbody>
                  <tr className="table-row-faq">
                    <th className="table-faq" scope="col">
                      HRA received
                    </th>
                    <th className="table-faq" scope="col">
                      xxx
                    </th>
                  </tr>
                  <tr className="table-row-faq">
                    <td className="table-faq" scope="col">
                      Less: Exempted HRA (minimum of following three amounts)
                      <ul>
                        <li>Actual HRA received</li>
                        <li>Rent paid less 10% of Salary</li>
                        <li>
                          40% of Salary (50% of Salary in Mumbai, Delhi, Chennai
                          and Kolkata)
                        </li>
                      </ul>
                    </td>
                    <td
                      className="table-faq"
                      align="center"
                      valign="center"
                      scope="col"
                    >
                      (xxx)
                    </td>
                  </tr>
                  <tr className="table-row-faq">
                    <td className="table-faq" scope="col">
                      Taxable HRA (to form part of salary for tax purpose)
                    </td>
                    <td
                      className="table-faq"
                      align="center"
                      valign="center"
                      scope="col"
                    >
                      xxx
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-btm-text">
              <p>
                *For above calculations, the salary would be taken as Basic Pay
                + D.A. (if considered for retirement benefits) + Commission. You
                can use our free HRA Calculator to calculate your exemption
                amount.
              </p>
            </div>
            <div className="Calculator-Works-section">
              <div className="works-content">
                <div className="works-heading">
                  <h4>How Tax2win's HRA Calculator Works?</h4>
                </div>
                <div className="following-step">
                  <p>Here are the steps to use the HRA Calculator -</p>
                  <ul>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Select whether you are living in a ‘Metro City’ or ‘Other
                      city’.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Enter the amount of Basic Salary + DA + Commission
                      Received
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Enter the actual amount of HRA you have received from your
                      employer
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Enter the actual rent paid by you.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Now fill in your email address and click ‘Submit’.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      The HRA calculator will automatically calculate the amount
                      of HRA exemption that you can avail of.
                    </li>
                    <p className="reduce-tax">
                      To reduce your tax burden and save your hard-earned money
                      from taxes, it is important to plan your investments
                      (exemptions and deductions).
                      <a href="#">Plan your Taxes Today.</a>{" "}
                    </p>
                    <hr className="hr"></hr>
                  </ul>
                </div>
                <div className="benefits-hra">
                  <h4>Benefits of HRA Calculator</h4>
                  <p>
                    <b>Quick:</b> HRA calculators are very fast and effective.
                    You don’t have to spend long time doing manual calculations.
                    You can calculate HRA using HRA calculator in a matter of
                    minutes. All you have to do is enter a few basic details
                    about the HRA received and rent paid, and you can calculate
                    your exemption.
                  </p>
                  <p>
                    <b>Easy to Use:</b> HRA calculators are extremely easy to
                    use and understand. Even laymen with zero knowledge of taxes
                    can easily compute their HRA exemption using an HRA
                    calculator.
                  </p>
                  <p>
                    <b>Accurate:</b> HRA calculators do not make mistakes and
                    are accurate. It considers all the rules and regulations to
                    give you the most accurate results.
                  </p>
                  <hr className="hr"></hr>
                </div>
                <div className="hra-claim">
                  <h4>Documents Required for HRA Exemption claim</h4>
                  <h6>It depends on the amount of rent paid:</h6>
                  <ul>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      If your monthly rent is less than Rs. 3,000- No rent
                      receipts are required
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      If your monthly rent is more than Rs. 3,000- Rent receipts
                      are to be submitted. Click here to Generate Rent Receipts.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      If your monthly rent is more than Rs. 8,333, the PAN of
                      the landlord is also required. If the landlord does not
                      have a PAN, a declaration from the landlord is to be
                      submitted. Read further details here.
                    </li>
                    <hr className="hr"></hr>
                  </ul>
                </div>
                <div className="HRA-Exemption">
                  <h4>Eligibility for HRA Exemption Claim</h4>
                  <p>
                    To claim House Rent Allowance (HRA) exemption in India, you
                    need to meet the following eligibility criteria:
                  </p>
                  <ul>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Salaried Individual: You must be a salaried individual
                      receiving HRA as part of your compensation package.
                      Self-employed individuals or those not receiving HRA are
                      not eligible for this exemption.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Payment of Rent: You must pay rent for the residential
                      accommodation you occupy. If you live in a house owned by
                      you, HRA exemption does not apply.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      HRA Component: Your salary structure should include an HRA
                      component. It's essential that your employer provides you
                      with HRA, and this amount should be specified in your
                      salary slip.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Actual Rent Paid: You must be paying actual rent for your
                      accommodation. The rent should be paid for the house you
                      are currently residing in, and you should have documentary
                      evidence of these payments.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Location: The house for which you pay rent should be in a
                      city, town, or area where HRA is applicable. HRA
                      exemptions vary based on the location, with higher
                      exemptions typically available in metropolitan cities.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Not Owning a House in the Specified Location: You should
                      not own a house in the city where you are claiming the HRA
                      exemption. If you own a house in a different city and live
                      in rented accommodation, you can claim the exemption for
                      the rent paid.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Part of Salary: HRA exemption is a part of your taxable
                      salary, so you need to declare your HRA as income while
                      filing your income tax return.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Furnish Rent Receipts: To claim the exemption, you need to
                      provide rent receipts or a rental agreement as evidence of
                      rent payment to your employer. You should also have your
                      landlord's PAN (Permanent Account Number) for rent
                      payments exceeding Rs. 1 lakh per year.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Exemption Calculation: The HRA exemption is calculated
                      based on the least of the following:a. The actual HRA was
                      received
                    </li>
                    <li>
                      b. Rent is paid minus 10% of your salary. c. 50% of your
                      salary (if you live in a metro city) or 40% of your salary
                      (if you live in a non-metro city).
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Submit Proof: You must submit your rent receipts and any
                      other required documents to your employer to claim the HRA
                      exemption.
                    </li>
                    <hr className="hr"></hr>
                  </ul>
                </div>
                <div className="calculate-HRA">
                  <h4>What is the formula to calculate HRA?</h4>
                  <p>
                    The amount of HRA that can be availed as an exemption from
                    the taxable salary is calculated using the following HRA
                    calculation formula.
                  </p>
                  <p>
                    The least among the following can be claimed as an exemption
                    or deduction:
                  </p>
                  <ul>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      The actual amount of HRA received by the employee.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      50% of the basic salary, if the employee resides in a
                      metropolitan city.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      40% of the basic salary, if the employee resides in a
                      non-metropolitan city.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Actual rent paid by the employee – 10% of basic salary
                    </li>
                    <p>
                      The least among the above is considered exempt from the
                      taxable salary. Here, Basic salary = Basic salary + DA
                      (Dearness allowance)
                    </p>
                  </ul>
                </div>
                <div className="calculated">
                  <p>
                    Let's assume you receive a monthly HRA of Rs. 20,000, and
                    you live in a metro city. Your basic salary is Rs. 40,000
                    per month, and you pay a monthly rent of Rs. 15,000 for your
                    accommodation.
                  </p>
                  <p>
                    To calculate your HRA exemption, you need to consider three
                    scenarios and select the one with the least amount:
                  </p>
                  <ul>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Actual HRA Received: Rs. 20,000 per month.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      Rent Paid minus 10% of Salary: Rent paid (Rs. 15,000)
                      minus 10% of your salary (10% of Rs. 40,000 = Rs. 4,000) =
                      Rs. 11,000.
                    </li>
                    <li>
                      <IoMdCheckmarkCircleOutline />
                      50% of Salary (since you live in a metro city): 50% of Rs.
                      40,000 = Rs. 20,000.
                    </li>
                    <p>
                      In this case, the least of these three scenarios is Rs.
                      11,000 (Rent Paid minus 10% of Salary). Therefore, your
                      HRA exemption is Rs. 11,000 per month.
                    </p>
                    <p>
                      Your taxable HRA is the actual HRA received minus the
                      exemption: Rs. 20,000 - Rs. 11,000 = Rs. 9,000 per month.
                      This is the amount that will be added to your taxable
                      income for the purpose of income tax calculation
                    </p>
                    <hr className="hr"></hr>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  .header-section {
    width: 100%;
    background: linear-gradient(90.09deg, #fbd63f 1.01%, #fc466b 99.06%);
    padding: 20px 0;
    .header-content {
      width: 100%;
      .text-line {
        width: 30%;
        h5 {
          color: #fff;
          text-align: center;
        }
        @media (max-width: 576px) {
          margin-bottom: 10px;
          width: 100%;
        }
      }
      .pre-button {
        button {
          width: 150px;
          background: ${(p) => p.theme.secandary_bg};
          padding: 12px;
          border-radius: 5px;
        }
        .off_coupon {
          border: dashed 2px #fff;
          font-weight: 600;
          background: rgba(255, 255, 255, 0.1);
          margin-left: 1rem;
          border-radius: 0.5rem;
          padding: 0.5rem 1rem;
          color: #fff;
        }
      }
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }
  }
  .Hracalculator-section {
    width: 100%;
    margin: 50px 0;
    .calculator {
      .calculator-heading {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        h2 {
          font-size: 32px;
          font-weight: 700;
        }
      }
      .btm-heading {
        width: 100%;
        text-align: Center;
        h6 {
          font-size: 14px;
          font-weight: 600;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 40px;
        }
      }
      .calculator-main {
        display: flex;
        width: 100%;
        .calculator-left-main {
          width: 40%;

          .calculator-left-box {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background: ${(p) => p.theme.primary_bg};
            padding: 40px;
            height: 532px;
            max-height: 100%;
            .left-box-heading {
              width: 100%;
              h4 {
                font-size: 28px;
                font-weight: 500;
                color: #fff;
              }
              p {
                font-size: 16px;
                color: #fff;
                font-weight: 400;
                margin: 10px 0;
              }
            }
            .left-calculator-menus {
              width: 100%;
              ul,
              li {
                font-size: 18px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 10px;
                list-style-type: disc;
              }
            }
            @media (max-width: 768px) {
              height: 450px;
            }
          }
          @media (max-width: 1024px) {
            width: 70%;
            margin-bottom: 20px;
          }
        }
        @media (max-width: 1024px) {
          flex-direction: column;
          align-items: center;
        }
      }
      .calculator-right-main {
        width: 60%;
        .Calcuator-right-box {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          background: ${(p) => p.theme.secandary_bg};
          padding: 40px;
          .right-top-bar {
            background: ${(p) => p.theme.tertiary_bg};
            padding: 20px 15px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
            .right-first {
              width: 20%;
              h5 {
                font-size: 12px;
                font-weight: 700;
              }
            }
            .right-second {
              width: 40%;
              text-align: center;
              p {
                font-size: 15px;
                font-weight: 700;
                display: flex;
                align-items: center;
                gap: 4px;
                justify-content: center;
              }
              .btm-tx {
                span {
                  font-size: 14px;
                }
              }
            }
            @media (max-width: 768px) {
              flex-direction: column;
            }
          }
        }
        .form-section {
          width: 100%;
          margin: 20px 0;
          .first-field {
            .abc {
              margin: 10px 0;
              font-size: 12px;
            }
            input {
              margin-bottom: 10px;
              padding: 10px 15px;
              height: auto;
              width: 100%;
              border-radius: 3px;
              background-clip: padding-box;
              border-color: #d9dbde;
              box-shadow: 0 0 10px #f3f3f3;
              transition: 0.5s;
            }
            input::placeholder {
              font-size: 12px;
            }
          }
          .second-field {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            .second-main {
              width: 50%;
              input {
                margin-bottom: 10px;
                padding: 10px 15px;
                height: auto;
                width: 100%;
                border-radius: 3px;
                background-clip: padding-box;
                border-color: #d9dbde;
                box-shadow: 0 0 10px #f3f3f3;
                transition: 0.5s;
              }
              input::placeholder {
                font-size: 12px;
              }
              @media (max-width: 768px) {
                width: 100%;
              }
            }
            .abc {
              margin: 10px 0;
              font-size: 12px;
            }
            @media (max-width: 768px) {
              flex-direction: column;
            }
          }
          .submit-btn {
            display: flex;
            justify-content: end;
            margin-top: 15px;
            button {
              width: 140px;
              background: ${(p) => p.theme.primary_bg};
              padding: 10px;
              border-radius: 4px;
              color: #fff;
            }
          }
        }
        @media (max-width: 1024px) {
          width: 70%;
        }
      }
    }
  }

  .cta-hra-section {
    width: 100%;
    .cta-box {
      background: ${(p) => p.theme.primary_bg};

      border-radius: 12px;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      justify-content: center;
      margin-bottom: 30px;
      .cta-mn {
        display: flex;
        .cta-hra-img {
          width: 40%;
        }
        .cta-hra-content {
          width: 60%;
          margin-top: 36px;
          h4 {
            color: #fff;
            font-size: 32px;
            font-weight: 700;
            line-height: 35px;
            margin-bottom: 10px;
            @media (max-width: 576px) {
              font-size: 22px;
            }
          }
          .cta-hra-btn {
            button {
              background: #fff;
              width: 170px;
              border-radius: 20px;
              padding: 10px;
              color: #5c5f62;
              font-weight: 700;
              display: flex;
              align-items: center;
              gap: 6px;
              justify-content: center;
            }
          }
          @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 10px;
          }
        }
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: center;
        }
      }
    }
    .cta-box:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 120px 0 170px 53px;
      border-color: transparent transparent transparent #5c5f62;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .cta-box:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 130px 53px 120px 0;
      border-color: transparent #5c5f62 transparent transparent;
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .btm-content-section {
    width: 100%;
    margin: 40px 0;
    .wrapper-main {
      width: 100%;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      background: ${(p) => p.theme.secandary_bg};
      padding: 30px 15px;
      border-radius: 10px;
      .wrapper {
        width: 100%;
        h4 {
          font-size: 24px;
          font-weight: 700;
        }
        p {
          font-size: 15px;
          line-height: 23px;
        }
        .hr {
          width: 100%;
          height: 1px;
          background: #e8ecf0 !important;
          margin: 20px auto 20px !important;
          border: none !important;
          position: relative;
        }
      }
      .top-first {
        h4 {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 18px;
        }
      }
      table tr th {
        border: solid 1px #e6e6e6;
        padding: 10px 15px;
        text-align: left;
      }
      table tr td,
      table tr th {
        border: solid 1px #e6e6e6;
        padding: 10px 15px;
        font-size: 14px;
      }
      ul {
        padding: 0 0 0 20px;
      }
      ul li {
        padding: 5px 20px 5px 35px;
        list-style-type: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 25px;
      }
      .table-btm-text {
        margin: 30px 0;
        p {
          font-size: 15px;
          line-height: 23px;
        }
      }
      .Calculator-Works-section {
        width: 100%;
        .works-content {
          width: 100%;
          .works-heading {
            h4 {
              font-size: 24px;
              font-weight: 700;
            }
          }
          .following-step {
            width: 100%;
            p {
              font-size: 15px;
            }
            li {
              display: flex;
              align-items: center;
              gap: 10px;
            }
            .reduce-tax {
              margin: 20px 0;
            }
          }
          .benefits-hra {
            h4 {
              font-size: 24px;
              font-weight: 700;
              margin: 15px 0;
            }
            p {
              font-size: 15px;
              line-height: 23px;
              margin-bottom: 15px;
            }
          }
          .hra-claim {
            h4 {
              font-size: 24px;
              font-weight: 700;
              margin: 15px 0;
            }
            h6 {
              font-size: 14px;
              font-weight: 700;
              margin: 15px 0;
            }
            li {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
          .HRA-Exemption {
            h4 {
              font-size: 24px;
              font-weight: 700;
              margin: 15px 0;
            }
            li {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
          .calculate-HRA {
            h4 {
              font-size: 24px;
              font-weight: 700;
              margin: 15px 0;
            }
            p {
              font-size: 15px;
              line-height: 23px;
              margin-bottom: 15px;
            }
            li {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
          .calculated {
            p {
              font-size: 15px;
              line-height: 23px;
              margin-bottom: 15px;
            }
            li {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }
    }
  }
`;
