import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useSelector } from "react-redux";
import Input from "../../../../styledComponent/Inputs/Input";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import LoadingBox from "../../../DialogBox/LoadingBox";

export default function DividendIncome({ handleCallback, incomeData }) {
  const [formData, setFormData] = useState({});
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [loader, setLoader] = useState(true);

  const validationSchemaItr5 = Yup.object().shape({
    dividendIncome: Yup.array().of(
      Yup.object().shape({
        narrationIncome: Yup.string().required(
          strings.validation_message.required
        ),
        dateOfReceipt: Yup.string().required(
          strings.validation_message.required
        ),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
    dividendIncomeMutualFunds: Yup.array().of(
      Yup.object().shape({
        narrationIncome: Yup.string().required(
          strings.validation_message.required
        ),
        dateOfReceipt: Yup.string().required(
          strings.validation_message.required
        ),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
    dividendIncomeAnyOther: Yup.array().of(
      Yup.object().shape({
        narrationIncome: Yup.string().required(
          strings.validation_message.required
        ),
        dateOfReceipt: Yup.string().required(
          strings.validation_message.required
        ),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
  });

  const validationSchema = Yup.object().shape({
    dividendIncome: Yup.array().of(
      Yup.object().shape({
        narrationIncome: Yup.string().required(
          strings.validation_message.required
        ),
        dateOfReceipt: Yup.string().required(
          strings.validation_message.required
        ),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
  });

  useEffect(() => {
    setLoader(true);
    setFormData({
      typeOfIncome: 104,
      dividendIncome: incomeData
      ? (incomeData?.dividendIncome.length &&
      incomeData?.dividendIncome[0].narrationIncome == "" &&
      incomeData?.dividendIncome[0].amount == 0 &&
      incomeData?.dividendIncome[0].dateOfReceipt == 0 
        ? [] : incomeData?.dividendIncome)
      : [{
          narrationIncome: "",
          amount: "",
          dateOfReceipt: "",
        }],
      dividendIncomeMutualFunds: incomeData
      ? (incomeData?.dividendIncomeMutualFunds.length &&
      incomeData?.dividendIncomeMutualFunds[0].narrationIncome == "" &&
      incomeData?.dividendIncomeMutualFunds[0].amount == 0 &&
      incomeData?.dividendIncomeMutualFunds[0].dateOfReceipt == 0 
        ? [] : incomeData?.dividendIncomeMutualFunds)
      : [{
          narrationIncome: "",
          amount: "",
          dateOfReceipt: "",
        }],
      dividendIncomeAnyOther: incomeData
      ? (incomeData?.dividendIncomeAnyOther.length &&
      incomeData?.dividendIncomeAnyOther[0].narrationIncome == "" &&
      incomeData?.dividendIncomeAnyOther[0].amount == 0 &&
      incomeData?.dividendIncomeAnyOther[0].dateOfReceipt == 0 
        ? [] : incomeData?.dividendIncomeAnyOther)
      : [{
          narrationIncome: "",
          amount: "",
          dateOfReceipt: "",
        }],
    });
    setLoader(false);
  }, [incomeData]);

  return (
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={
          ItrData.form == "ITR_5" ? validationSchemaItr5 : validationSchema
        }
        onSubmit={(values) => {
          handleCallback(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <StyleFormBox>
              <h4 className="mb-5" style={{ color: theme.primary_txt }}>
                {strings.income.dividend_income_from_equities}
              </h4>
              <label>{strings.income.dividend_from_investment}</label>

              <div className="flex_input">
                <label>{strings.income.total_dividend_income}</label>
                <h5>50000</h5>
              </div>

              <div className="text-right">
                <button className="add_item_button">
                  {strings.button.export_data}
                </button>
                <button className="add_item_button">
                  {strings.button.import_data}
                </button>
                <button className="add_item_button">
                  {strings.button.download_template}
                </button>
              </div>
            </StyleFormBox>

            <FieldArray name="dividendIncome">
              {({ insert, remove, push }) => (
                <StyleFormBox>
                  <h4>Dividend Income from Domestic Company</h4>
                  <div className="flex_input my-2 items-center">
                    <label>{strings.income.narration}</label>
                    <label>{strings.income.amount}</label>
                    <label>{strings.income.date_of_receipt}</label>
                  </div>
                  {values?.dividendIncome?.map((item, index) => (
                    <div className="flex_input my-2 items-center" key={index}>
                      <Input
                        name={`dividendIncome.${index}.narrationIncome`}
                        placeholder={strings.label.narration}
                        value={item.narrationIncome}
                        error={
                          errors?.dividendIncome &&
                          errors?.dividendIncome[index]?.narrationIncome
                        }
                        onChange={handleChange}
                      />

                      <Input
                        inputType={"currency"}
                        name={`dividendIncome.${index}.amount`}
                        value={item.amount}
                        error={
                          errors?.dividendIncome &&
                          errors?.dividendIncome[index]?.amount
                        }
                        onChange={handleChange}
                      />

                      <Input
                        type={"date"}
                        name={`dividendIncome.${index}.dateOfReceipt`}
                        value={item.dateOfReceipt}
                        error={
                          errors?.dividendIncome &&
                          errors?.dividendIncome[index]?.dateOfReceipt
                        }
                        onChange={handleChange}
                      />

                      <button
                        className="delete_button mb-2"
                        onClick={(e) => {
                          e.preventDefault();
                          remove(index);
                        }}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  ))}

                  <div className="text-right mt-5">
                    <button
                      className="add_item_button"
                      onClick={(e) => {
                        e.preventDefault();
                        push({
                          narrationIncome: "",
                          amount: "",
                          dateOfReceipt: "",
                        });
                      }}
                    >
                      {strings.button.add_more_item}
                    </button>
                  </div>
                </StyleFormBox>
              )}
            </FieldArray>

            {ItrData.form == "ITR_5" ? (
              <>
                {" "}
                <FieldArray name="dividendIncomeMutualFunds">
                  {({ insert, remove, push }) => (
                    <StyleFormBox>
                      <h4>Dividend Income from Mutual Funds</h4>
                      <p>
                        Dividend from investments in Mutual Funds, ULIPs, UTI
                      </p>
                      <div className="flex_input my-2 items-center">
                        <label>{strings.income.narration}</label>
                        <label>{strings.income.amount}</label>
                        <label>{strings.income.date_of_receipt}</label>
                      </div>
                      {values?.dividendIncomeMutualFunds?.map((item, index) => (
                        <div
                          className="flex_input my-2 items-center"
                          key={index}
                        >
                          <Input
                            name={`dividendIncomeMutualFunds.${index}.narrationIncome`}
                            placeholder={strings.label.narration}
                            value={item.narrationIncome}
                            error={
                              errors?.dividendIncomeMutualFunds &&
                              errors?.dividendIncomeMutualFunds[index]
                                ?.narrationIncome
                            }
                            onChange={handleChange}
                          />

                          <Input
                            inputType={"currency"}
                            name={`dividendIncomeMutualFunds.${index}.amount`}
                            value={item.amount}
                            error={
                              errors?.dividendIncomeMutualFunds &&
                              errors?.dividendIncomeMutualFunds[index]?.amount
                            }
                            onChange={handleChange}
                          />

                          <Input
                            type={"date"}
                            name={`dividendIncomeMutualFunds.${index}.dateOfReceipt`}
                            value={item.dateOfReceipt}
                            error={
                              errors?.dividendIncomeMutualFunds &&
                              errors?.dividendIncomeMutualFunds[index]
                                ?.dateOfReceipt
                            }
                            onChange={handleChange}
                          />

                          <button
                            className="delete_button mb-2"
                            onClick={(e) => {
                              e.preventDefault();
                              remove(index);
                            }}
                          >
                            <RiDeleteBin6Fill />
                          </button>
                        </div>
                      ))}

                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({
                              narrationIncome: "",
                              amount: "",
                              dateOfReceipt: "",
                            });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </StyleFormBox>
                  )}
                </FieldArray>
                <FieldArray name="dividendIncomeAnyOther">
                  {({ insert, remove, push }) => (
                    <StyleFormBox>
                      <h4>Any Other Income</h4>
                      <p>
                        Report any other income which is not part of Income from
                        Salary, House Property, Capital Gain or Business and
                        Profession. Gifts can be declared as Income here.
                      </p>
                      <div className="flex_input my-2 items-center">
                        <label>{strings.income.narration}</label>
                        <label>{strings.income.amount}</label>
                        <label>{strings.income.date_of_receipt}</label>
                      </div>
                      {values?.dividendIncomeAnyOther?.map((item, index) => (
                        <div
                          className="flex_input my-2 items-center"
                          key={index}
                        >
                          <Input
                            name={`dividendIncomeAnyOther.${index}.narrationIncome`}
                            placeholder={strings.label.narration}
                            value={item.narrationIncome}
                            error={
                              errors?.dividendIncomeAnyOther &&
                              errors?.dividendIncomeAnyOther[index]
                                ?.narrationIncome
                            }
                            onChange={handleChange}
                          />

                          <Input
                            inputType={"currency"}
                            name={`dividendIncomeAnyOther.${index}.amount`}
                            value={item.amount}
                            error={
                              errors?.dividendIncomeAnyOther &&
                              errors?.dividendIncomeAnyOther[index]?.amount
                            }
                            onChange={handleChange}
                          />

                          <Input
                            type={"date"}
                            name={`dividendIncomeAnyOther.${index}.dateOfReceipt`}
                            value={item.dateOfReceipt}
                            error={
                              errors?.dividendIncomeAnyOther &&
                              errors?.dividendIncomeAnyOther[index]
                                ?.dateOfReceipt
                            }
                            onChange={handleChange}
                          />

                          <button
                            className="delete_button mb-2"
                            onClick={(e) => {
                              e.preventDefault();
                              remove(index);
                            }}
                          >
                            <RiDeleteBin6Fill />
                          </button>
                        </div>
                      ))}

                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({
                              narrationIncome: "",
                              amount: "",
                              dateOfReceipt: "",
                            });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </StyleFormBox>
                  )}
                </FieldArray>
              </>
            ) : (
              ""
            )}

            <button className="primary_button my-4" type="submit">
              {strings.button.save_and_close}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}
