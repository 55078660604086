import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaRegSquareCaretRight } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import { useSelector } from "react-redux";
export default function RentRecepit() {
  const { theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <div className="header-section">
        <div className="header-content flex justify-center items-center">
          <div className="text-line">
            <h5>Filing for AY 2024-25 is coming soon</h5>
          </div>
          <div className="pre-button">
            <button type="button">Pre-Book Now</button>
            <span className="off_coupon">Grab 25% OFF</span>
          </div>
        </div>
      </div>
      <div className="tds-Calculator-section">
        <div className="container_box">
          <div className="tds-main">
            <div className="tds-left">
              <div className="tds-left-heading">
                <h2>Rent Receipt Generator</h2>
                <p>
                  Escape the last-minute rush of gathering rent receipts for
                  your HRA exemption claim. Utilize Tax2win free rent receipt
                  generator and maximize your tax savings effortlessly. It's
                  fast, convenient, and easy to use.
                </p>
                <p>
                  Whether you're a landlord or a tenant, our user-friendly
                  platform streamlines the process, ensuring accuracy and
                  compliance every time. Simply input your details, and in just
                  a few clicks, receive a customized rent receipt tailored to
                  your specific needs.
                </p>
                <h4>
                  Please share if you found this helpful - We'd greatly
                  appreciate it.
                </h4>
              </div>
            </div>
            <div className="tds-right">
              <div className="tds-box">
                <div className="tds-heading-topbar">
                  <strong>Financial Year :2021-2022 </strong>
                </div>

                <div className="cal-btn">
                  <button type="button">Calculate</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btm-main">
        <div className="container_box">
          <div className="content">
            <h4>GENERATE FREE RENT RECEIPTS IN 4 STEPS</h4>
            <p className="my-3">
              For claiming HRA exemptions it is mandatory to submit Rent
              Receipts to the employer
            </p>
            <p className="my-3">
              1. Fill the required details like Monthly rent, Address of the
              rented house, email-Id, PAN number etc.
            </p>
            <p className="my-3">
              2. After selecting the time period of rent slip generator-monthly,
              quarterly, half yearly or yearly, the rent receipt will be shared
              to your email address.
            </p>
            <p className="my-3">
              3. Download the generated rent receipt slip in pdf form and get it
              stamped & signed by landlord.
            </p>
            <p className="my-4">
              4. Submit Rent Receipts to your employer and gain exemption on
              HRA.
            </p>
          </div>
        </div>
      </div>
      <div className="certificate-img">
        <div className="container_box">
          <img src="Images/Rent-recept-format (1).jpg"></img>
        </div>
      </div>
      <div className="btm-main-2">
        <div className="container_box">
          <div className="content">
            <h4>What are the details required in rent receipts?</h4>
            <p className="my-3">
              The following are the details which are required in the rent
              receipt:
            </p>
            <p className="my-3">
              1. Tenant Name (If you are the tenant, fill in your name)
            </p>
            <p className="my-3">2. Landlord Name.</p>
            <p className="my-3">3. Amount of Payment.</p>
            <p className="my-4">4. Date of Payment.</p>
            <p className="my-4">5. Rental Period.</p>
            <p className="my-4">6. House Address (Rented Property)</p>
            <p className="my-4">7. Signature of Landlord or Manager.</p>
          </div>
        </div>
      </div>
      <div className="heading">
        <h4>FAQ’S ON RENT RECEIPTS</h4>
      </div>
      <div className="btm-main-2">
        <div className="container_box">
          <div className="content">
            <h4>Q- Is it mandatory to have rent receipt to claim HRA?</h4>
            <p className="my-3">
              Ans. Yes, if an employee receives HRA in excess of Rs. 3000/- per
              month, it is mandatory to provide Rent receipt to employer as an
              evidence for claiming HRA. Even if your HRA is less than Rs.
              3000/-, try to keep rent receipts with you in case the Income Tax
              Officer asks for them at a later date.
            </p>
            <hr></hr>
            <h4>Q- Do I need to produce a rent receipt for every month?</h4>
            <p className="py-3">
              Ans. Generally employers ask for rent receipts for 3-4 months.
            </p>
            <hr></hr>
            <h4>Q- How to generate monthly rent receipts?</h4>
            <p className="py-3">
              Ans. While generating rent receipts online in the third step when
              you select the period you can make a choice of generating rent
              receipts yearly, monthly or quarterly for the specified time
              period.
            </p>
            <hr></hr>
            <h6>SAMPLE DECLARATION FORM</h6>
            <img src="Images/declaration.jpg"></img>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  .header-section {
    width: 100%;
    background: linear-gradient(90.09deg, #fbd63f 1.01%, #fc466b 99.06%);
    padding: 20px 0;
    .header-content {
      width: 100%;
      .text-line {
        width: 30%;
        h5 {
          color: #fff;
          text-align: center;
        }
        @media (max-width: 576px) {
          margin-bottom: 10px;
          width: 100%;
        }
      }
      .pre-button {
        button {
          width: 150px;
          background: #fff;
          padding: 12px;
          border-radius: 5px;
        }
        .off_coupon {
          border: dashed 2px #fff;
          font-weight: 600;
          background: rgba(255, 255, 255, 0.1);
          margin-left: 1rem;
          border-radius: 0.5rem;
          padding: 0.5rem 1rem;
          color: #fff;
        }
      }
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }
  }
  .tds-Calculator-section {
    width: 100%;
    background: ${(p) => p.theme.primary_bg};
    padding: 50px 0;
    .tds-main {
      width: 100%;
      display: flex;
      .tds-left {
        width: 40%;
        .tds-left-heading {
          h2 {
            font-size: 32px;
            font-weight: 700;
            color: #fff;
          }
          h4 {
            color: #fff;
          }
          p {
            font-size: 18px;
            font-weight: 400;
            color: #fff;
          }
          h5 {
            font-size: 20px;
            font-weight: 700;
            color: #fff;
            margin: 10px 0;
          }
        }
        @media (max-width: 991px) {
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .tds-right {
        width: 60%;
        .tds-box {
          background: ${(p) => p.theme.secandary_bg};
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          padding: 30px 15px;
          border-radius: 10px;
          .tds-heading-topbar {
            width: 100%;
            text-align: center;
            margin-bottom: 15px;
          }

          .second-field {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            margin: 10px 0;
            .second-main {
              width: 50%;
              position: relative;
              .abc {
                margin: 10px 0;
                font-size: 12px;
              }
              select {
                margin-bottom: 10px;
                padding: 10px 15px;
                height: auto;
                width: 100%;
                border-radius: 3px;
                background-clip: padding-box;
                border-color: #d9dbde;
                box-shadow: 0 0 10px #f3f3f3;
                transition: 0.5s;
                font-size: 12px;
                option {
                  font-size: 14px;
                }
              }
              input {
                margin-bottom: 10px;
                padding: 10px 15px;
                height: auto;
                width: 100%;
                border-radius: 3px;
                background-clip: padding-box;
                border-color: #d9dbde;
                box-shadow: 0 0 10px #f3f3f3;
                transition: 0.5s;
                font-size: 12px;
              }
              .btns {
                top: 49px;
                position: absolute;
                right: 10px;
              }
            }
          }
          .first-field {
            .abc {
              margin: 10px 0;
              font-size: 12px;
            }
            select {
              margin-bottom: 10px;
              padding: 10px 15px;
              height: auto;
              width: 100%;
              border-radius: 3px;
              background-clip: padding-box;
              border-color: #d9dbde;
              box-shadow: 0 0 10px #f3f3f3;
              transition: 0.5s;
              font-size: 12px;
              color: red;
              option {
                font-size: 12px;
                color: red;
              }
            }
          }
          .cal-btn {
            button {
              width: 140px;
              background: #ffa53b;
              padding: 10px;
              color: #fff;
              border-radius: 4px;
            }
          }
        }
        @media (max-width: 991px) {
          width: 80%;
        }
      }
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .btm-main {
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: ${(p) => p.theme.secandary_bg};
    .content {
      h4 {
        font-size: 24px;
        font-weight: 400;
      }
      p {
        font-size: 14px;
        line-height: 23px;
      }
    }
  }
  .btm-main-2 {
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: ${(p) => p.theme.secandary_bg};
    .content {
      h4 {
        font-size: 24px;
        font-weight: 700;
      }
      p {
        font-size: 14px;
        line-height: 23px;
      }
    }
  }
  .heading {
    text-align: center;
    margin: 20px 0;
    h4 {
      font-size: 24px;
      font-weight: 700;
    }
  }
`;
