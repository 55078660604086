
import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { addOpacity } from "../../utils/helpers";
export default function StyleFormBox({children }) {
    const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
    {children}
    </Root>
  );
}
const Root = styled.section`
  margin-top: 8px;
  background-color: ${(p) => p.theme.secandary_bg};
  padding: 18px;
  color:${(p) => p.theme.primary_txt};
  border-radius: 10px;
  border:1px solid ${(p) => p.theme.border_clr};
  .title_bar{
    display: flex;
    color: ${p=>p.theme.primary_txt};
    justify-content: space-between;
  }
  .flex_row{
    margin:20px 0px;
    display: flex;
    color: ${p=>p.theme.primary_txt};
    justify-content: space-between;
  }
  hr{
    margin-top:10px;
    border-top: 1px solid ${(p) => p.theme.border_clr};
  }
  .bottom_bar{
    padding:10px 0px;
    color: ${p=>p.theme.primary_txt};
    display: flex;
    justify-content: space-between;
  }
`;
