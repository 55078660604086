import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../utils/helpers";
import { FaRegCircleCheck } from "react-icons/fa6";
import StyleFormBox from "../styledComponent/Form/StyleFormBox";

export default function Subscription() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root className="container_box" theme={theme}>
      <div className="subscription_container">
        <div>
          <h4 className="title_heading">
            Standard <span>Essential Plan</span>
          </h4>
          <p>Everything you need for a smooth filing experience.</p>
          <div className="price_section">
            <h3>{strings.label.rupee} 800 /</h3>
            <span>
              <s>1200</s> Yearly
            </span>
          </div>
          <hr className="my-4" />
          <ul className="list">
            <li>
              <FaRegCircleCheck />
              Taxable Income &lt; 5L
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Self Employed Incoome
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Business Income
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Captial Gains(Indian)
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Crypto Income
            </li>
          </ul>
          <button className="primary_button">Buy Plan</button>
        </div>
        <div>
          <h4 className="title_heading">
            Advance <span>Pro Advantage Plan</span>
          </h4>
          <p>Enhanced features and personalized support for added ease.</p>
          <div className="price_section">
            <h3>{strings.label.rupee} 1200 /</h3>
            <span>
              <s>1600</s> Yearly
            </span>
          </div>
          <hr className="my-4" />
          <ul className="list">
            <li>
              <FaRegCircleCheck />
              Taxable Income &gt; 5L
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Self Employed Incoome
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Business Income
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Captial Gains(Indian)
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Crypto Income
            </li>
          </ul>
          <button className="primary_button">Buy Plan</button>
        </div>
        <div>
          <h4 className="title_heading">
            Premium <span>Elite Expert Plan</span>
          </h4>
          <p>Everything you need for a smooth filing experience.</p>
          <div className="price_section">
            <h3>{strings.label.rupee} 1600 /</h3>
            <span>
              <s>2000</s> Yearly
            </span>
          </div>
          <hr className="my-4" />
          <ul className="list">
            <li>
              <FaRegCircleCheck />
              Taxable Income &lt; 5L
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Self Employed Incoome
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Business Income
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Captial Gains(Indian)
            </li>

            <li>
              {" "}
              <FaRegCircleCheck /> Crypto Income
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Foreign Income
            </li>
            <li>
              {" "}
              <FaRegCircleCheck /> Capital Gains(Foreign)
            </li>
          </ul>
          <button className="primary_button">Buy Plan</button>
        </div>
      </div>
      <StyleFormBox>
        <div className="flex items-center connect_box">
          <h1 className="flex">
            Not sure which plan suits you? Let our experts guide you to the
            right choice
          </h1>
          <button className="primary_button">Connect</button>
        </div>
      </StyleFormBox>
    </Root>
  );
}

const Root = styled.section`
  .subscription_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    > div {
      background-color: ${(p) => p.theme.secandary_bg};
      padding: 10px;
      border-radius: 10px;
      flex: 1;
      display: flex;
      flex-direction: column;
      >button{
        margin-top:auto;
      }
    }
  }
  .title_heading {
    > span {
      border-radius: 50px;
      padding: 0px 10px;
      color: ${(p) => p.theme.primary_bg};
      background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.2)};
    }
  }
  .price_section {
    font-size: 30px;
    color: ${(p) => p.theme.primary_bg};
    font-weight: 700;
    display: flex;
    align-items: end;
    > span {
      font-size: 18px;
      color: ${(p) => p.theme.primary_txt};
      font-weight: 400;
    }
  }
  .list {
    li {
      list-style-type: style none;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 10px 0px;
      > svg {
        color: ${(p) => p.theme.primary_bg};
      }
    }
  }
  .connect_box {
    > h1 {
      flex: 1;
    }
    > button {
      width: fit-content;
    }
  }
`;
