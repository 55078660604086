import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGrossIncomeAction } from "../../redux/itr/action";
import SalaryIncome from "../CommonScreens/GrossIncome/SalaryIncome";
import ProfessionalIncome from "../CommonScreens/GrossIncome/ProfessionalIncome";
import OtherSourceIncome from "../CommonScreens/GrossIncome/OtherSourceIncome";
import HousePropertyIncome from "../CommonScreens/GrossIncome/HousePropertyIncome";

export default function GrossIncome({ handleNext }) {
  const [incomeData, setIncomeData] = useState({});
  const { ItrData } = useSelector((s) => s.itr);
  const { strings } = useSelector((s) => s.platformSetting);
  const dispatch = useDispatch();
  const getGrossIncomeApi = () => {
    dispatch(
      getGrossIncomeAction(ItrData.id, (data) => {
        if (data.status == 200) {
          setIncomeData(data.data.grossIncomeDetails);
        }
      })
    );
  };
  useEffect(() => {
    getGrossIncomeApi();
  }, []);

  return (
    <div>
      <SalaryIncome getData={incomeData} />
      <ProfessionalIncome 
        incomeData={incomeData?.businessProfessionIncome}
        gstData={incomeData?.businessProfessionIncome?.gstDetails}
        financialParticularsData={incomeData?.financialParticulars}
        handleCallback={() => getGrossIncomeApi()}
      />
      <OtherSourceIncome
        getData={incomeData?.otherIncomeSource}
        handleRefresh={() => getGrossIncomeApi()}
      />
      <HousePropertyIncome data={incomeData?.housePropertyIncome} />
      <button className="primary_button mt-4" onClick={handleNext}>
        {strings.button.save_and_continue}
      </button>
    </div>
  );
}
