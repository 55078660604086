import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import FormDialogBox from "../../../../DialogBox/FormDialogBox";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import AddToProfitLoss from "./AddToProfitLoss";
import SummarySection43And40 from "./SummarySection43And40";
import {
  deleteAdditionToPAndL,
  deleteDetailOfSection40And43B,
  getDetailOfSection40And43B,
} from "../../../../../redux/itr/action";
import { toast } from "react-toastify";
import { addOpacity } from "../../../../../utils/helpers";
import LoadingBox from "../../../../DialogBox/LoadingBox";

export default function AdditionProfitLossAccount() {
  const [profitLossForm, setProfitLossForm] = useState(false);
  const [sectionData, setSectionData] = useState({});
  const [editData, setEditData] = useState({});
  const [section43Form, setSection43Form] = useState(false);
  const { ItrData } = useSelector((s) => s.itr);
  const [section40Form, setSection40Form] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const getAdditionToPAndLApi = async () => {
    setLoading(true);
    let id = ItrData.id;
    dispatch(
      getDetailOfSection40And43B(id, (e) => {
        if (e.status == 200) {
          setSectionData(e.data);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteOtherScheduleDataApi = async (id, type) => {
    setLoading(true);
    if (type == "other") {
      dispatch(
        deleteAdditionToPAndL({ id }, (e) => {
          if (e.status == 200) {
            setSectionData({
              ...sectionData,
              additionToPAndL: sectionData.additionToPAndL.filter(
                (i) => i.id != id
              ),
            });
            toast.success(e?.data);
            setLoading(false);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "Section40And43B") {
      dispatch(
        deleteDetailOfSection40And43B({ id }, (e) => {
          if (e.status == 200) {
            setSectionData({
              ...sectionData,
              detailsSection43B: sectionData.detailsSection43B.filter(
                (i) => i.id != id
              ),
            });
            toast.success(e?.data);
            setLoading(false);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "Section40And40") {
      dispatch(
        deleteDetailOfSection40And43B({ id }, (e) => {
          if (e.status == 200) {
            setSectionData({
              ...sectionData,
              detailsSection40: sectionData.detailsSection40.filter(
                (i) => i.id != id
              ),
            });
            toast.success(e?.data);
            setLoading(false);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    }
  };

  useEffect(() => {
    getAdditionToPAndLApi();
  }, []);

  console.log("sectionData?.additionToPAndL",sectionData?.additionToPAndL)
  return (
    <div>
      <LoadingBox open={loading} />
      {/* == On this page, enter information related to Section 36, 37, 40A and 41 == */}
      <StyleFormBox>
        <h4>
          {strings.income.page_enter_information_related_section_36_37_40a_41}
        </h4>
        <div>
          {sectionData?.additionToPAndL?.map((item) => (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <h3>{item?.section?.name}</h3>
                <p>{item?.subSection?.name}</p>
              </div>

              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    // setScheduleBpForm(true);
                    setProfitLossForm(true)
                    setEditData(item);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() => deleteOtherScheduleDataApi(item?.id, "other")}
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={() => {setProfitLossForm(true); setEditData({})}}
          className="mt-2 secandary_button"
        >
          {strings.button.add_entry}
        </button>
      </StyleFormBox>

      {/* == To enter details of Section 43B == */}
      <StyleFormBox>
        <h4>{strings.income.details_section_43b}</h4>
        {sectionData?.detailsSection43B?.map((item) => (
          <div
            className="flex gap-2 p-3 my-3 align-center justify-between"
            style={{
              background: addOpacity(theme.primary_bg, 0.2),
              borderRadius: "10px",
            }}
          >
            <div>
              <h3>{item?.section?.name}</h3>
              <p>{item?.broughtForwardFY}</p>
            </div>

            <div className="action_buttons">
              <button
                className="edit_btn"
                onClick={() => {
                  setSection43Form(true);
                  setEditData(item);
                }}
              >
                <FaRegEdit />
              </button>
              <button
                className="delete_btn"
                onClick={() =>
                  deleteOtherScheduleDataApi(item?.id, "Section40And43B")
                }
              >
                <RiDeleteBin6Fill />
              </button>
            </div>
          </div>
        ))}

        <button
          onClick={() => setSection43Form(true)}
          className="mt-2 secandary_button"
        >
          {strings.button.add_entry}
        </button>
      </StyleFormBox>

      {/* ==To enter details of Section 40 == */}
      <StyleFormBox>
        <h4>{strings.income.details_section_40}</h4>

        {sectionData?.detailsSection40?.map((item) => (
          <div
            className="flex gap-2 p-3 my-3 align-center justify-between"
            style={{
              background: addOpacity(theme.primary_bg, 0.2),
              borderRadius: "10px",
            }}
          >
            <div>
              <h3>{item?.section?.name}</h3>
              <p>{item?.broughtForwardFY}</p>
            </div>

            <div className="action_buttons">
              <button
                className="edit_btn"
                onClick={() => {
                  setSection40Form(true);
                  setEditData(item);
                }}
              >
                <FaRegEdit />
              </button>
              <button
                className="delete_btn"
                onClick={() =>
                  deleteOtherScheduleDataApi(item?.id, "Section40And40")
                }
              >
                <RiDeleteBin6Fill />
              </button>
            </div>
          </div>
        ))}

        <button
          onClick={() => setSection40Form(true)}
          className="mt-2 secandary_button"
        >
          {strings.button.add_entry}
        </button>
      </StyleFormBox>

      <FormDialogBox
        open={profitLossForm}
        formTitle={strings.income.addition_to_profit_loss}
        handleClose={() => {
          setProfitLossForm(false);
        }}
      >
        <AddToProfitLoss
          section={sectionData.section}
          data={editData}
          handleCallback={(e,type) => {
            const updatedAddition = type==="edit"? sectionData?.additionToPAndL?.map((item)=>
              item.id===e.id?e:item):
            [...sectionData.additionToPAndL,e]
            setSectionData((prev) => ({
              ...prev, additionToPAndL: updatedAddition
            }));
            setProfitLossForm(false);
          }}
        />
      </FormDialogBox>

      <FormDialogBox
        open={section43Form}
        formTitle={strings.income.summary_income_under_section_43b}
        handleClose={() => {
          setSection43Form(false);
          setEditData({});
        }}
      >
        <SummarySection43And40
          section={sectionData.section43B}
          isSection40={false}
          data={editData}
          handleCallback={(type, e) => {
            const updatedRemuneration =
              type === "edit"
                ? sectionData.detailsSection43B.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...sectionData.detailsSection43B, e];
            setSectionData((prev) => ({
              ...prev,
              detailsSection43B: updatedRemuneration,
            }));
            setSection43Form(false);
            setEditData({});
          }}
        />
      </FormDialogBox>

      <FormDialogBox
        open={section40Form}
        formTitle={strings.income.summary_income_under_section_40}
        handleClose={() => {
          setSection40Form(false);
          setEditData({});
        }}
      >
        <SummarySection43And40
          section={sectionData.section40}
          isSection40={true}
          data={editData}
          handleCallback={(type, e) => {
            const updatedRemuneration =
              type === "edit"
                ? sectionData.detailsSection40.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...sectionData.detailsSection40, e];
            setSectionData((prev) => ({
              ...prev,
              detailsSection40: updatedRemuneration,
            }));
            setSection40Form(false);
            setEditData({});
          }}
        />
      </FormDialogBox>
    </div>
  );
}
