import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Input from "../../styledComponent/Inputs/Input";
import { toast } from "react-toastify";
import {
  forgotPasswordAction,
  resetPasswordAction,
  verifyOtpForgotPasswordAction,
} from "../../redux/profile/action";
import LoadingBox from "../DialogBox/LoadingBox";
import { useNavigate } from "react-router-dom";
import OtpField from "../../styledComponent/Inputs/OtpField";
export default function ForgotPassword() {
  const [activeScreen, setActiveScreen] = useState(1);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [hash, setHash] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [passwords, setPasswords]= useState ({
    password:'',
    confirmPassword:''
  })
  const [seconds, setSeconds] = useState(0);
  const { strings, theme } = useSelector((s) => s.platformSetting);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSendOtpApi = () => {
    if (userName.length < 2) {
      toast.error(strings.validation_message.phone_email);
    } else {
      let data = {
        input: userName,
      };
      setLoading(true);
      dispatch(
        forgotPasswordAction(data, (e) => {
          toast.success("Succesfully send otp ");
          setHash(e.data);
          setLoading(false);
          setSeconds(60)
          setActiveScreen(2);
        })
      );
    }
  };

  const handleVerifyOtpApi = () => {
    if (otp.join("").length !== 4) {
      toast.error(strings.validation_message.valid_otp);
    } else {
      let data = {
        hash,
        otp: otp.join(""),
      };
      setLoading(true);
      dispatch(
        verifyOtpForgotPasswordAction(data, (e) => {
          setToken(e.data);
          toast.success("Succesfully Verify OTP ");
          setLoading(false);
          setActiveScreen(3);
        })
      );
    }
  };

  const resetPasswordApi = () => {
    if (passwords.password.length < 4) {
      toast.error(strings.validation_message.valid_otp);
    }else if(passwords.password !== passwords.confirmPassword){
      toast.error(strings.validation_message.password_must_match);
    } else {
      let data = {
        token,
        newPassword:passwords.password,
        
      };
      setLoading(true);
      dispatch(
        resetPasswordAction(data, (e) => {
          console.log('======== handle callback ====> ',e)
          toast.success("Succesfully Reset Password");
          setLoading(false);
          if(e.status == 200){
            navigate("/login")
          }
        })
      );
    }
  };

  useEffect(() => {
    if (seconds === 0) return; // Stop the timer when seconds reach 0
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds])



  return (
    <Root theme={theme}>
      {activeScreen == 1 ? (
        <>
          <img src="/images/lock.png" className="mx-auto" />
          <h4 className="text-center mt-12">
            {strings.signUp.forgot_password} ?
          </h4>
          <p className="text-center my-4">
            {strings.signUp.enter_email_or_number}{" "}
          </p>
          <div className="text-left">
            <label>{`${strings.label.phone_number} / ${strings.label.email}`}</label>
            <Input
              placeholder={`${strings.label.phone_number} / ${strings.label.email}`}
              // error={}
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
          </div>

          <button
            className="primary_button"
            onClick={() => {
              handleSendOtpApi();
            }}
          >
            {strings.button.send_otp}
          </button>
        </>
      ) : activeScreen == 2 ? (
        <>
          <img src="/images/password.png" className="mx-auto" />
          <h4 className="text-center mt-12">{strings.signUp.verify_otp} ?</h4>
          <p className="text-center my-4">
            {strings.signUp.enter_code_sent_to_email}{" "}
          </p>
          <OtpField
            handleClick={(e) => {
              setOtp(e);
            }}
            otpLength={4}
          />
         
          <p className="text-center my-4">
            {strings.signUp.if_dont_receive_code} ?{" "} 
            <button style={{ color: theme.primary_bg }} onClick={handleSendOtpApi} disabled={seconds !== 0 ? true :false}>
              {strings.button.resend} {seconds !== 0 && `in 00:${seconds}`}
            </button>
          </p>
          <button
            className="primary_button"
            onClick={() => {
              handleVerifyOtpApi();
            }}
          >
            {strings.signUp.verify_otp}
          </button>
        </>
      ) : activeScreen == 3 ? (
        <>
          <img src="/images/keylock.png" className="mx-auto" />
          <h4 className="text-center mt-12">
            {strings.signUp.create_new_password} ?
          </h4>
          <p className="text-center my-4">
            {strings.signUp.new_pass_must_be_different}{" "}
          </p>
          <div className="text-left">
            <label>{strings.label.password}</label>
            <Input
              inputType={"password"}
              placeholder={strings.label.password}
              value={passwords.password}
              onChange={(e)=>{setPasswords({...passwords,password:e.target.value})}}
            />
            <label>{strings.label.confirm_password}</label>
             <Input
              inputType={"password"}
              placeholder={strings.label.confirm_password}
              value={passwords.confirmPassword}
              onChange={(e)=>{setPasswords({...passwords,confirmPassword:e.target.value})}}
            />
          </div>

          <button
            className="primary_button"
            onClick={() => {
              resetPasswordApi();
            }}
          >
            {strings.signUp.reset_password}
          </button>
        </>
      ) : (
        <></>
      )}

      <LoadingBox open={loading} />
    </Root>
  );
}
const Root = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  background-color: ${(p) => p.theme.secandary_bg};
  text-align: center;
  .otp_input {
    color: ${(p) => p.theme.primary_txt};
    background-color: transparent;
  }
`;
