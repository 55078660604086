import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
export default function AddMoreButton({ title, handleChange }) {
  const { theme } = useSelector((state) => state.platformSetting);

  return <Root theme={theme} onClick={()=>{handleChange()}}>{title}</Root>;
}
const Root = styled.button`
  color: ${(p) => p.theme.primary_bg};
  border-radius: 50px;
  padding: 4px 14px;
  font-weight:600;
  background: none;
  border: none;
  cursor: pointer;
`;
