import React from "react";
import AccordianTab from "../../styledComponent/Form/AccordianTab";
import styled from "styled-components";
import { useSelector } from "react-redux";
export default function PurchaseHistory() {
  const { strings } = useSelector((s) => s.platformSetting);
  return (
    <Root>
      <AccordianTab title={"Purchase History"}>
        <div className="my-4 flex justify-between">
          <div className="flex gap-4 items-center">
            <img src="/images/dummy_service.png" />
            <div>
              <h4>Salary And House Property plan</h4>
              <p>10 June 2022 at 05:00 pm</p>
            </div>
          </div>
          <h4>{strings.label.rupee} 605.00</h4>
        </div>
        <div className="my-4 flex justify-between">
          <div className="flex gap-4 items-center">
            <img src="/images/dummy_service.png" />
            <div>
              <h4>Capital Gain Plan</h4>
              <p>10 June 2022 at 05:00 pm</p>
            </div>
          </div>
          <h4>{strings.label.rupee} 605.00</h4>
        </div>
    
      </AccordianTab>
    </Root>
  );
}
const Root = styled.div`
  .box_section {
    border: none;
  }
`;
