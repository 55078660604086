import { SET_ITR_ID, SET_PERSONAL_INFO } from "../constant";

const initialState = {
  personalInfo:{},
  ItrId:'',
  ItrData:{},
};

export const itrReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERSONAL_INFO:
      return {
        ...state,
        personalInfo: action.payload,
      };
    case SET_ITR_ID:
      return {
        ...state,
        ItrId: action.payload.id,
        ItrData:action.payload,
      };
    default:
      return state;
  }
};
