import React, { useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import { useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Input from "../../../../styledComponent/Inputs/Input";
import ClauseSeventhProviso from "./ClauseSeventhProviso";

export default function OtherDisclosuser() {
  const [moreInfoForm, setMoreInfoForm] = useState(false);
  const [clauseProvisoForm, setClausProvisoForm] = useState(false);

  const { strings } = useSelector((s) => s.platformSetting);
  return (
    <div>
      <AccordianBox
        title={strings.other_disclosures.other_disclosure}
        description={strings.tax_saving.are_you_of_these_applicable_to_you}
      >
        <ol>
          <li className="mb-2">
            You are a NRI or have spent time outside India
          </li>
          <li className="mb-2">
            You own shares of an Unlisted company (shares that are not listed on
            any stock exchange).
          </li>
          <li className="mb-2">You are a Director of any company in India.</li>
          <li className="mb-2">
            You are a resident and have Foreign Assets or Income or you have
            paid taxes outside India.
          </li>
          <li className="mb-2">You have total income more than ₹50 Lakhs.</li>
          <li className="mb-2">
            You have deposited more than ₹1 crore in one or more current
            accounts, during the previous year.
          </li>
          <li className="mb-2">
            You have incurred expenditure of more than ₹1 Lakh on electricity
            consumption or more than ₹2 lakhs on foreign country travel, during
            the previous year.
          </li>
        </ol>

        {moreInfoForm && (
          <StyleFormBox>
            <h1>{strings.other_disclosures.other_disclosure}</h1>
         
            <StyleFormBox>
              <h4>{strings.other_disclosures.expenditure_foreign_travel}</h4>
              <div className="flex_input">
                <p className="flex-1">
                  {
                    strings.other_disclosures
                      .incurred_expenditure_of_amount_more_than_2lakh
                  }
                </p>
                <div>
                  <Input
                  // value={salaryData.name}
                  // onChange={(e) => {
                  //   setSalaryData({ ...salaryData, name: e.target.value });
                  // }}
                  />
                  <span className="note_message">
                    {strings.message.value_not_less_than_2lakh}
                  </span>
                </div>
              </div>
            </StyleFormBox>

            <StyleFormBox>
              <h4>
                {strings.other_disclosures.expenditure_electricity_consumption}
              </h4>
              <div className="flex_input">
                <p className="flex-1">
                  {
                    strings.other_disclosures
                      .incurred_expenditure_amount_more_than_1lakh
                  }
                </p>
                <Input
                // value={salaryData.name}
                // onChange={(e) => {
                //   setSalaryData({ ...salaryData, name: e.target.value });
                // }}
                />
              </div>
            </StyleFormBox>

            <StyleFormBox>
              <div className="flex items-center justify-between">
                <h4>
                  {strings.other_disclosures.clause_iv_seventh_proviso_to_139}
                </h4>
                <button
                  className="add_button"
                  onClick={() => setClausProvisoForm(true)}
                >
                  {strings.button.add}
                </button>
              </div>
              <p>
                {
                  strings.other_disclosures
                    .condition_clause_4_seventh_proviso_return_filed
                }
              </p>
              <ul className="mt-6">
                <li>
                  {
                    strings.other_disclosures
                      .turnover_gross_recipts_person_in_business
                  }
                </li>
                <li>{strings.other_disclosures.gross_receipts_profession}</li>
                <li>
                  {
                    strings.other_disclosures
                      .total_tax_deducted_and_collected_source
                  }
                </li>
                <li>
                  {
                    strings.other_disclosures
                      .deposited_in_lakh_during_previous_year
                  }
                </li>
              </ul>
              {clauseProvisoForm && <ClauseSeventhProviso />}
            </StyleFormBox>

          </StyleFormBox>
        )}

        <button
          className="secandary_button mt-3"
          onClick={() => setMoreInfoForm(!moreInfoForm)}
        >
          {moreInfoForm
            ? strings.button.close
            : strings.button.one_of_these_conditions_applies_to_me}
        </button>
      </AccordianBox>
    </div>
  );
}
