import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { LuEye, LuEyeOff } from "react-icons/lu";
import PhoneInput from "react-phone-input-2";
import { BiRupee } from "react-icons/bi";
import { FaPercent } from "react-icons/fa6";
import { Field } from "formik";
export default function Input(props) {
  const [showPassword, setShowPassword] = useState(true);
  const { inputType, error, value, ...prop } = props;
  const { theme } = useSelector((state) => state.platformSetting);

  const handleAadharNumber = (value) => {
    const cleanedNumber = value?.replace(/[^\d]/g, ""); // Remove non-numeric characters
    const chunks = cleanedNumber.match(/.{1,4}/g); // Split into groups of 4
    const formattedNumber = chunks ? chunks.join("-") : value;
    return formattedNumber;
  };

  const validatePAN = (pan) => {
    const normalizedPAN = pan.trim().toUpperCase();
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    if (!panRegex.test(normalizedPAN)) {
      return "Invalid PAN";
    } else {
      return "";
    }
  };

  const validateCurrency = (e) => {
    const inputValue = e.target.value;
    
    // Allow only integers (no decimal values)
    if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
     return (inputValue);
    }
  };

  return (
    <Root theme={theme}>
      {inputType === "password" ? (
        <>
          <div className="relative">
            <input
              type={showPassword ? "password" : "text"}
              value={value || ""}
              {...prop}
            />
            <span
              className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <LuEyeOff /> : <LuEye />}
            </span>
          </div>
        </>
      ) : inputType === "phone" ? (
        <div className="input_field">
          <PhoneInput
            country={"in"}
            containerClass={"phone_field"}
            value={value || ""}
            {...prop}
          />
        </div>
      ) : inputType === "aadhaar" ? (
        <>
          <input
            value={
              value && value.length > 1 ? handleAadharNumber(value) : value
            }
            maxlength={14}
            {...prop}
          />
        </>
      ) : inputType === "currency" ? (
        <div className="currency_field">
          <span>
            <BiRupee />
          </span>
          <Field
            value={value || ""}
            placeholder={"0000"}
            type="number"
            onInput={validateCurrency}
            onKeyDown={(e) => {if (e.key === '.' || e.target.value.length > 14) { e.preventDefault(); }}}
            onWheel={(e) => e.target.blur()}
            {...prop}
          />
        </div>
      ) : inputType === "percentage" ? (
        <div className="currency_field">
          <input type="number" placeholder="" value={value || ""} {...prop} />
          <span className="percentage">
            <FaPercent />
          </span>
        </div>
      ) : inputType === "date" ? (
        // <Field value={value || ""} type="date" {...prop} />
        <input value={value || ""} type="date" {...prop} onKeyDown={(e) => {e.preventDefault(); return false}}/>
      ) : inputType === "message" ? (
        <textarea value={value || ""} {...prop}></textarea>
      ) : inputType === "default" ? (
        <input
          style={{ margin: "0 0 0 0" }}
          value={value || ""}
          type="text"
          {...prop}
        />
      ) : inputType === "pan" ? (
        <>
          <input
            style={{ margin: "0 0 0 0" }}
            value={value?.toUpperCase() || ""}
            type="text"
            {...prop}
          />
          <p style={{ color: "red" }}>
            {value?.length > 2 ? validatePAN(value) : ""}
          </p>
        </>
      ) : props.type == "number" ? (
        <Field
          value={value || ""}
          {...prop}
          onKeyDown={(evt) =>
            ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()
          }
          maxLength={14}
        />
      ) : (
        <Field value={value || ""} {...prop} />
      )}
      <p className={inputType === "currency" ? "error curr" : "error"}>{error}</p>
    </Root>
  );
}
const Root = styled.div`
  width: 100%;
  position: relative;
  input,
  select,
  textarea {
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.border_clr};
    color: ${(p) => p.theme.primary_txt};
    background: ${(p) => p.theme.secandary_bg};
    margin-bottom: 10px;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(50%);
    font-size: 20px;
  }
  input:disabled {
    background: ${(p) => addOpacity(p.theme.primary_bg, 0.1)} !important;

    cursor: no-drop;
  }
  .input_field {
    position: relative;
    padding-bottom: 10px;
    input {
      margin: 14px 0px;
      width: 100%;
      height: 50px;
      padding: 12px;
      border-radius: 12px;
      border: 1px solid ${(p) => p.theme.border_clr};
      color: ${(p) => p.theme.primary_txt};
      background-color: transparent;
      &::placeholder {
        color: ${(p) => p.theme.primary_txt};
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .flag-dropdown {
    background-color: ${(p) => p.theme.secandary_bg};
    color: ${(p) => p.theme.primary_txt};
    :hover {
      background-color: ${(p) => p.theme.secandary_bg};
    }
  }
  .react-tel-input {
    .country-list .country:hover {
      background-color: ${(p) => p.theme.tertiary_bg};
    }
    .country-list {
      background-color: ${(p) => p.theme.secandary_bg};
    }
    .country-list .country.highlight {
      background-color: ${(p) => p.theme.tertiary_bg};
    }
    .flag-dropdown.open .selected-flag {
      background-color: ${(p) => p.theme.tertiary_bg};
    }
    input {
      padding-left: 50px;
    }
    .flag-dropdown,
    .selected-flag,
    .selected-flag.open {
      border: 1px solid ${(p) => p.theme.border_clr};
      border-radius: 10px 0 0 10px;
    }
  }
  .currency_field {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 10px;
    border: 1px solid ${(p) => p.theme.border_clr};
    span {
      padding: 10px;
      font-size: 28px;
      background: ${(p) => addOpacity(p.theme.primary_bg, 0.2)};
      border-radius: 12px 0px 0px 12px;
      color: ${(p) => p.theme.primary_txt};
      &.percentage {
        border-radius: 0px 12px 12px 0px;
      }
    }
    input {
      margin-bottom: 0px;
      padding: 12px;
      border-radius: 0px 12px 12px 0px;
      border: none;
      width: 100%;
      color: ${(p) => p.theme.primary_txt};
      background-color: transparent;
    }
  }
`;
