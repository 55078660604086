import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
export default function Select(props) {
  const { options, error,children, ...selectInput } = props;
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
      <select {...selectInput}>
        {children}
      </select>
      <p className="error">{error}</p>
    </Root>
  );
}
const Root = styled.div`
  width: 100%;
  position: relative;
  select {
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.border_clr};
    color: ${(p) => p.theme.primary_txt};
    background: ${(p) => p.theme.secandary_bg};
    margin-bottom: 10px;
  }
`;
