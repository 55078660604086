import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../../../../styledComponent/Inputs/CheckBox";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { Formik } from "formik";
import { toast } from "react-toastify";
import {
  editDepreciationEntryAction,
  saveDepreciationEntryAction,
} from "../../../../redux/itr/action";
import Input from "../../../../styledComponent/Inputs/Input";
import Select from "../../../../styledComponent/Inputs/Select";
import { removeBlankKey, removeEmptyKeys, dateFormat } from "../../../../utils/helpers";

export default function Depreciation({
  data,
  handleCallback,
  depreciationBlock,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);

  const { strings, theme } = useSelector((s) => s.platformSetting);

  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();
  const handleSubmitForm = (values) => {
    setLoader(true);
    let { type, ...data } = values;
    const action =
      type == "edit"
        ? editDepreciationEntryAction
        : saveDepreciationEntryAction;
    if (type == "edit") {
      delete data.itrId;
    }
    dispatch(
      action(removeEmptyKeys(data), (e) => {
        if (e.status === 200) {
          handleCallback(type, e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    setFormData({
      id: data?.id || "",
      type: data.id ? "edit" : "add",
      itrId: ItrData.id,
      depreciationBlock: data?.depreciationBlock || "",
      description: data?.description || "",
      openingAmount: data?.openingAmount || "",
      purchaseAmount1: data?.purchaseAmount1 || "",
      purchaseAmount2: data?.purchaseAmount2 || "",
      saleAmount1: data?.saleAmount1 || "",
      saleAmount2: data?.saleAmount2 || "",
      dateOfSale: data?.dateOfSale ? dateFormat(data?.dateOfSale)  : "",
      personalUsagePercentage: data?.personalUsagePercentage || "",
      additionalDepreciationClaimed: data?.additionalDepreciationClaimed || "",
      isBlockNil: data?.isBlockNil || false,
      AdditionalDepreciation: data?.AdditionalDepreciation || false,
    });
    setLoader(false);
  }, [data]);
console.log('Depreciation -> formData', formData?.isBlockNil)
  return (
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <div>
            <h4 className="mt-3">
              {strings.balance_sheet.add_depreciation_entry}
            </h4>
            <div className="flex_input mt-2">
              <label>{strings.balance_sheet.depreciation_block}</label>
              <Select
                name={"depreciationBlock"}
                error={errors.depreciationBlock}
                value={values?.depreciationBlock}
                onChange={handleChange}
              >
                <option value="">{strings.label.choose_option}</option>
                {depreciationBlock?.map((i, ix) => (
                  <option value={i.id} key={ix}>
                    {i.name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex_input my-3">
              <label>
                {strings.label.description +
                  strings.label.this_field_is_optional}
              </label>
              <Input
                inputType={"message"}
                name={"description"}
                value={values?.description}
                error={errors?.description}
                onChange={handleChange}
              />
            </div>
            <div className="flex_input my-2">
              <label>{strings.balance_sheet.opening_amount}</label>
              <Input
                inputType={"currency"}
                name={"openingAmount"}
                value={values?.openingAmount}
                error={errors?.openingAmount}
                onChange={handleChange}
              />
            </div>
            <div className="flex_input my-2">
              <label>{strings.balance_sheet.purchase_amount_upto_oct_3}</label>
              <Input
                inputType={"currency"}
                name={"purchaseAmount1"}
                value={values?.purchaseAmount1}
                error={errors?.purchaseAmount1}
                onChange={handleChange}
              />
            </div>
            <div className="flex_input my-2">
              <label>
                {
                  strings.balance_sheet
                    .sale_amount_on_assets_purchased_upto_oct_3
                }
              </label>
              <Input
                inputType={"currency"}
                name={"saleAmount1"}
                value={values?.saleAmount1}
                error={errors?.saleAmount1}
                onChange={handleChange}
              />
            </div>

            <div className="flex_input my-2">
              <label>{strings.balance_sheet.purchase_amount_upto_oct_4}</label>
              <Input
                inputType={"currency"}
                name={"purchaseAmount2"}
                value={values?.purchaseAmount2}
                error={errors?.purchaseAmount2}
                onChange={handleChange}
              />
            </div>
            <div className="flex_input my-2">
              <label>
                {
                  strings.balance_sheet
                    .sale_amount_on_assets_purchased_upto_oct_4
                }
              </label>
              <Input
                inputType={"currency"}
                name={"saleAmount2"}
                value={values?.saleAmount2}
                error={errors?.saleAmount2}
                onChange={handleChange}
              />
            </div>
            <div className="flex_input my-2">
              <label>{strings.balance_sheet.date_on_which_sale_was_made}</label>
              <Input
                inputType={"date"}
                name={"dateOfSale"}
                value={values?.dateOfSale}
                error={errors?.dateOfSale}
                onChange={handleChange}
              />
            </div>
            <p className="opacity-60">
              {strings.message.specify_date_of_sale_if_you_had_any_asset}
            </p>
            <div className="flex_input my-2">
              <label>{strings.balance_sheet.personal_usage_percentage}</label>
              <Input
                inputType={"percentage"}
                name={"personalUsagePercentage"}
                value={values?.personalUsagePercentage}
                error={errors?.personalUsagePercentage}
                onChange={handleChange}
              />
            </div>

            <div className="flex_input my-2">
              <label>
                {
                  strings.balance_sheet
                    .additional_depreciation_claimed_during_previous_ay
                }
              </label>
              <Input
                name={"additionalDepreciationClaimed"}
                value={values?.additionalDepreciationClaimed}
                error={errors?.additionalDepreciationClaimed}
                onChange={handleChange}
              />
            </div>

            <p className="opacity-60">
              {strings.message.during_year_for_assets_purchased_from}
            </p>
            <CheckBox
              value={values?.isBlockNil}
              label={strings.balance_sheet.is_block_nil}
              handleChange={() =>
                setValues({
                  ...values,
                  isBlockNil: !values.isBlockNil,
                })
              }
            />

            <CheckBox
              value={values?.AdditionalDepreciation}
              label={strings.balance_sheet.additional_depreciation}
              handleChange={() =>
                setValues({
                  ...values,
                  AdditionalDepreciation: !values.AdditionalDepreciation,
                })
              }
            />

            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
