import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { useSelector } from "react-redux";
export default function PpfCalculator() {
    const {theme} = useSelector((s) => s.platformSetting);
    return (
        <Root theme={theme}>
            <div className="ppf-section">
                <div className="container_box flex gap-3">
                    <div className="ppf-left">
                        <div className="ppf-header">
                            <h4>PPF Interest Calculator Online</h4>
                        </div>
                        <div className="ppf-main">
                            <div className="ppf-content flex">
                                <h5>Number of Deposits Per Year</h5>
                                <select name="cars" id="cars">
                                    <option value="volvo">Volvo</option>
                                    <option value="saab">Saab</option>
                                    <option value="mercedes">Mercedes</option>
                                    <option value="audi">Audi</option>
                                </select>
                                <h5>Deposit Amount</h5>
                                <input type="text"></input>
                            </div>
                            <div className="slidecontainer"><input type="range" min="1" max="100" className="slider" id="myRange" value="50"></input></div>
                            <div className="ppf-content flex mt-4">
                                <h5>Duration (Years): 15</h5>

                                <h5>Rate Of Interest: 7.1%</h5>

                            </div>
                            <div className="btm-btns flex mt-4">
                                <button type="button">CALCULATE</button>
                                <button className="reset" type="button">RESET</button>
                            </div>
                        </div>
                        <div className="btm-left-box">
                            <div className="left-btm-cntn">
                                <h5>What is PPF Calculator?</h5>
                                <p>The PPF calculator is a tool that helps you calculate the maturity value of the funds that lie in your Public Provident Fund (PPF) account. The Government of India has extended the benefit of PPF a long-term savings scheme, that allows individuals to save money and earn interest on it. The interest rate on PPF is set by the Ministry of Finance on a quarterly basis and is subject to change.</p><br></br>
                                <p>HDFC Bank’s PPF calculator takes into account the deposit amount, number of deposits that you make in a year along with prevailing interest rate and the number of years for which the money is invested to help you calculate value of your investment at maturity. HDFC Bank’s PPF calculator also calculates the potential interest earned over the time period invested and gives you an indicator of where you could be, should you choose to start investing today.</p><br></br>
                                <p>The HDFC Bank PPF calculator negates the need to carry out complex manual calculations, and allows you to get the answers you need in just a matter of seconds. It also serves as a tool to help you plan investments and finances intelligently, so that you are able to make informed decisions about the future.

                                    The ability to adjust the amount, number of installments and calculate PPF online on tapping a screen or pushing a button means that you can explore various permutations and combinations till you find something that works for you.</p>
                            </div>
                        </div>
                        <div className="btm-left-box">
                            <div className="left-btm-cntn">
                               <p>*The amount mentioned in the calculator is an indicative figure .</p>
          
                            </div>
                        </div>
                        <div className="btm-left-box">
                            <div className="left-btm-cntn">
                                <h6>FAQ'S</h6>    
                                <h5>What is the maturity period for PPF?</h5> 
                                <p>The lock-in period for a PPF Account is 15 years from the date of opening. However, you can extend this tenure by five years by submitting an application. You can extend your PPF by a block of 5 years several times. With HDFC Bank’s PPF Calculator, you can check your projected maturity value multiple times to help you plan ahead. Setting a financial goal with our PPF Account Calculator will also allow you to have a clear plan in mind about how much you need to invest every year</p> <br></br>  
                                <h5>When can I withdraw the amount from my PPF Account?</h5>  
                                <p>You can withdraw the entire PPF balance after the lock-in period of 15 years expires. Additionally, you can opt for partial withdrawal prior to the expiration of the maturity period. This feature only becomes available from the 7th financial year for emergency purposes. Even this amount can be accounted for in advance with our PPF calculator, as it allows you to identify your return at maturity.</p><br></br>
                                <h5>How is PPF Calculated?</h5>
                                <p>PPF interest is computed on the lowest balance amount in the account between the 5th and last day of the month. Interest is paid to the account at the end of every financial year, regardless of where the account stands. Thus, although PPF interest is calculated on a monthly basis, it is credited to your account on a yearly basis. Our PPF Account Calculator can also be used as a PPF Interest Calculator to estimate the returns you would earn at maturity.</p>
                            </div>
                        </div>
                    </div>
                    <div className="ppf-right">
                        <div className="ppf-first-box mb-4">
                            <img src="Images/ppf-side.jpg"></img>
                            <div className="ppf-content">
                                <p>Step by Step Guide On </p>
                                <h5>How To Open A PPF Account</h5>
                            </div>
                        </div>
                        <div className="ppf-first-box mb-4">
                            <img src="Images/ppf-side.jpg"></img>
                            <div className="ppf-content">
                                <p>Step by Step Guide On </p>
                                <h5>How To Open A PPF Account</h5>
                            </div>
                        </div>
                        <div className="ppf-first-box mb-4">
                            <img src="Images/ppf-side.jpg"></img>
                            <div className="ppf-content">
                                <p>Step by Step Guide On </p>
                                <h5>How To Open A PPF Account</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Root>
    );
}


const Root = styled.section`
.ppf-section {
    width:100%;
    .ppf-left {
        width:80%;
        .ppf-header {
            width:100%;
            background-image: url(../Images/bg-1.jpg);
            height: 60px;
            width: 100%;
            padding: 13px 20px;
            display: table;
            position:relative;
        }
        .ppf-main {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            padding:40px 20px;
            width:100%;
            background: ${(p) => p.theme.secandary_bg};
            .ppf-content {
                width:100%;
                align-items:center;
                gap:10px;
                justify-content:space-between;
                select {
                    width: 24%;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    text-indent: 1px;
                    text-overflow: '';
                    border: 1px solid #ccc;
                    padding: 6px 12px;
                    border-radius: 4px;
                    background-color: #fff;
                    background-image: url(../Images/dropdown-arrow.png);
                    background-repeat: no-repeat;
                    background-position: 95% 56%;
                    padding-right: 26px;
                    color: #555;
                    box-shadow: 0 1px 1px rgba(0, 0, 0, .075) inset;
                    font-size: 14px;
                    overflow-y: auto;
                    height: 34.5px;
                    background-size: 25px 14px;
                    outline: 0;
                }
                input{
                    width: 24%;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    text-indent: 1px;
                    text-overflow: '';
                    border: 1px solid #ccc;
                    padding: 6px 12px;
                    border-radius: 4px;
                    background-color: #fff;
                    background-image: url(../Images/dropdown-arrow.png);
                    background-repeat: no-repeat;
                    background-position: 95% 56%;
                    padding-right: 26px;
                    color: #555;
                    box-shadow: 0 1px 1px rgba(0, 0, 0, .075) inset;
                    font-size: 14px;
                    overflow-y: auto;
                    height: 34.5px;
                    background-size: 25px 14px;
                    outline: 0;
                }
                h5 {
                    font-size:14px;
                }
            }
            .slidecontainer {
                margin-top: 20px;
                width: 100%;
                display:flex;
                justify-content:end;
                input  {
                    width:24%;
                }
            }
            .btm-btns {
                width:100%;
                display:flex;
                justify-content:end;
                gap:20px;
                button {
                    background: #1d86ff;
                    border: 1px solid transparent;
                    font-size: 16px;
                    padding: 6px 20px;
                    color: #fff;
                    position: relative;
                }
                .reset {
                    background: none;
                    border: 1px solid transparent;
                    font-size: 16px;
                    padding: 6px 20px;
                    color: #fff;
                    position: relative;
                    color:#1d86ff;
                    border:1px solid #1d86ff;
                }
            }
        }
        .btm-left-box {
            background: ${(p) => p.theme.secandary_bg};
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            width:100%;
            padding:40px 20px;
            margin:40px 0;
            
            .left-btm-cntn {
                h5 {
                    font-size:18px;
                    font-weight:700;
                }
                p {
                    font-size:14px;
                    font-weight:400;
                }
            }
        }
    }
    .ppf-right {
        width:30%;
        .ppf-first-box {
            width:100%;
            position:relative;
            img {
                width:100%;
            }
            .ppf-content {
                width:100%;
                position:absolute;
                background: ${(p) => p.theme.secandary_bg};
                bottom:0;
                padding:20px;
              
                p {
                    font-size:14px;
                    font-weight:400;
                }
                h5 {
                    font-size:16px;
                    font-weight:500;
                }
            }
        }
    }
    .container_box {
        @media (max-width:991px) {
            flex-direction:column;
            align-items:center;
        }
    }
}

`