import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createTicketAction,
  getServiceListAction,
  getTicketTagAction,
} from "../redux/profile/action";
import StyleFormBox from "../styledComponent/Form/StyleFormBox";
import Select from "../styledComponent/Inputs/Select";
import Input from "../styledComponent/Inputs/Input";
import { IoClose } from "react-icons/io5";
import styled from "styled-components";
import { IoMdAdd } from "react-icons/io";

export default function CreateTickets() {
  const [searchTags, setSearchTags] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [ticketForm, setTicketForm] = useState({
    title: "",
    service: "",
    description: "",
    tags: [],
    screenshots: [],
  });
  const [files, setFiles] = useState([]);
  const [tagSearch, setTagSearch] = useState("");
  const dispatch = useDispatch();
  const { strings } = useSelector((s) => s.platformSetting);

  const handleFormEdit = (key, value) => {
    if (key === "tags") {
      setTicketForm({ ...ticketForm, tags: [...ticketForm.tags, value] });
    } else {
      setTicketForm({ ...ticketForm, [key]: value });
    }
  };

  const handleSubmitForm = () => {
    const formData = new FormData();
    formData.append("title", ticketForm.title);
    formData.append("service", ticketForm.service);
    formData.append("description", ticketForm.description);
    ticketForm.tags.forEach((tag) => {
      formData.append("tags", tag.id);
    });
    files.forEach((file) => {
      formData.append("screenshots", file);
    });

    dispatch(
      createTicketAction(formData, (res) => {
        console.log("response ========>", res);
      })
    );
  };

  useEffect(() => {
    if (tagSearch.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        setSearchTags([]);
        dispatch(
          getTicketTagAction(tagSearch, (tag) => {
            if (tag.status == 200) {
              setSearchTags(tag.data);
            }
          })
        );
        console.log("Searching for:", tagSearch);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [tagSearch]);

  useEffect(() => {
    dispatch(
      getServiceListAction((data) => {
        if (data.status === 200) {
          setServiceList(data.data);
        }
      })
    );
  }, []);

  console.log("ticketForm ====>", ticketForm);

  return (
    <Root className="min_container">
      <StyleFormBox>
        <h4> {strings.profile.ticket_number} : UTPB01</h4>
        <label>Title</label>
        <Input
          placeholder={strings.placeholder.search}
          value={ticketForm.title}
          onChange={(e) => handleFormEdit("title", e.target.value)}
        />
        <label>{strings.profile.select_service}</label>
        <Select
          value={ticketForm.service}
          onChange={(e) => handleFormEdit("service", e.target.value)}
        >
          <option value="" disabled>
            {strings.profile.select_service}
          </option>
          {serviceList?.map((i, ix) => (
            <option value={i.id} key={ix}>
              {i.serviceName}
            </option>
          ))}
        </Select>
        <label>Tags</label>
        <Input
          placeholder={strings.placeholder.search}
          value={tagSearch}
          onChange={(e) => setTagSearch(e.target.value)}
        />

        <div className="selected_tags flex flex-wrap gap-3">
          {ticketForm.tags.map((tag, index) => (
            <button
              className="add_button flex items-center font-bold"
              key={index}
              onClick={() => handleFormEdit("tags", tag)}
            >
              {tag.name}
              {"tags"}
              <span>
                <IoMdAdd />
              </span>
            </button>
          ))}

          {searchTags
            .filter((item) => !ticketForm.tags.some((i) => i.id === item.id))
            .map((tag, index) => (
              <button
                className="add_button flex items-center font-bold opacity-20"
                key={index}
                onClick={() => handleFormEdit("tags", tag)}
              >
                {tag.name}
                {"tags"}
                <span>
                  <IoMdAdd />
                </span>
              </button>
            ))}
        </div>

        <hr />
        <label>{strings.label.description}</label>
        <Input
          inputType={"message"}
          placeholder={strings.placeholder.type_your_text}
          value={ticketForm.description}
          onChange={(e) => handleFormEdit("description", e.target.value)}
        />
      </StyleFormBox>
      <StyleFormBox>
        <div className="flex items-center justify-between">
          <div>
            <h4> {strings.profile.screenshots}</h4>
            <p> {strings.profile.no_screen_upload_yet}</p>
          </div>

          {/* Input type file */}
          <div
            className="add_button relative cursor-pointer  h-9"
            style={{ padding: "0px", overflow: "hidden" }}
          >
            <input
              type="file"
              style={{
                width: "86px",
                padding: "0px",
                position: "relative",
                zIndex: "99",
                height: "80px",
                opacity: "0",
                cursor: "pointer",
              }}
              onChange={(e) => setFiles([...files, e.target.files[0]])}
            />
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              {strings.button.upload}
            </span>
          </div>
        </div>
        <div className="flex gap-3 mt-3 flex-wrap">
          {files.map((item, index) => (
            <div className="image_preview">
              <img src={URL.createObjectURL(item)} />
              <span
                className="cursor-pointer"
                onClick={() => setFiles(files.filter((i, ix) => ix !== index))}
              >
                <IoClose />
              </span>
            </div>
          ))}
        </div>
      </StyleFormBox>
      <button className="primary_button mt-2" onClick={handleSubmitForm}>
        {strings.button.submit}
      </button>
    </Root>
  );
}
const Root = styled.div`
  .selected_tags {
  }
  .image_preview {
    position: relative;
    width: fit-content;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 4px;
    }
    &:hover {
      img {
        filter: brightness(0.5);
      }
    }
    span {
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: 0px;
      color: red;
      font-size: 20px;
    }
  }
`;
