import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../../../styledComponent/Inputs/Input";
import CheckBox from "../../../../styledComponent/Inputs/CheckBox";
import Select from "../../../../styledComponent/Inputs/Select";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import {
  addMembersAction,
  getAdditionalInfoAction,
  deleteAdditionalInfoAction,
  saveAdditionalInfoAction,
  updateAdditionalInfoAction,
} from "../../../../redux/itr/action";
import { addOpacity, removeEmptyKeys } from "../../../../utils/helpers";
import { toast } from "react-toastify";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { FaRegEdit } from "react-icons/fa";

export default function AdditionalInfo({ form, handleCallback }) {
  const [formData, setFormData] = useState({
    changesDuringPrevYear: false,
    additionalPartners: [
      {
        partnerName: "",
        PAN: "",
        dateOfAdmissionRetirement: "",
        RemunerationAmount: "",
        percentOfShare: "",
        partnerStatus: "",
      },
    ],
    recognitionNumber: "",
    llpPin: "",
    isRecognizedMSME: false,
    recognizedMSMENumber: "",
  });

  const [formBox, setFormBox] = useState(false);
  const [additionalInfoData, setAdditionalInfoData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [memberStatus, setMemberStatus] = useState([]);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    recognizedMSMENumber: Yup.string().when('isRecognizedMSME', {
      is: true,
      then: () => Yup.string().required(strings.validation_message.required),

    }),
    
    additionalPartners: Yup.array().when('changesDuringPrevYear', {
      is: true,
      then: () => Yup.array().of(
        Yup.object().shape({
          partnerName: Yup.string().required(
            strings.validation_message.required
          ),
          dateOfAdmissionRetirement: Yup.string().required(
            strings.validation_message.required
          ),
          RemunerationAmount: Yup.number().required(
            strings.validation_message.required
          ),
          PAN: Yup.string().required(strings.validation_message.required).matches(
            /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
            strings.validation_message.invalid_pan
          ),
          percentOfShare: Yup.number()
            .required(strings.validation_message.required)
            .min(0.01, "Percentage should be more than 0")
            .max(100, "Percentage cannot exceed 100"),
          partnerStatus: Yup.string().required(
            strings.validation_message.required
          ),
        }),
      ),
    }),
  });
  const validationSchemaLLP = Yup.object().shape({
    llpPin: Yup.string().required(strings.validation_message.required).matches(
      /^[A-Z]{3}-[0-9]{4}$/,
      "Invalid LLP Pin format"
    ),
  });
  const handleDeleteData = (id) => {
    let parm = `?id=${id}&itrId=${ItrData.id}`;
    setLoader(true);
    dispatch(
      deleteAdditionalInfoAction(parm, (e) => {
        if (e.status == 200) {
          getMembersData();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
    )
  };

  const handleFormSubmit = (data) => {
    setLoader(true);
    if(!data.changesDuringPrevYear){
      delete data.additionalPartners
    }
    if(data.id) {
      dispatch(
        updateAdditionalInfoAction(removeEmptyKeys({ itrId: ItrData.id, ...data }), (e) => {
          if (e.status === 200) {
            const addInfo = additionalInfoData.findIndex((item) => item.id === data.id );
            additionalInfoData[addInfo] = data;
            setAdditionalInfoData([...additionalInfoData]);
            setFormBox(false);
          }
          setLoader(false);
        })
      );
    } else{
      dispatch(
        saveAdditionalInfoAction(removeEmptyKeys({ itrId: ItrData.id, ...data }), (e) => {
          if (e.status === 200) {
            setAdditionalInfoData([...additionalInfoData, e.data]);
            setFormBox(false);
          }
          setLoader(false);
        })
      );
    }
  };

  const handleOpenForm = () => {
    setFormData({
      personalInfoId: "",
      changesDuringPrevYear: false,
      additionalPartners: [
        {
          partnerName: "",
          PAN: "",
          dateOfAdmissionRetirement: "",
          RemunerationAmount: "",
          percentOfShare: "",
          partnerStatus: "",
        },
      ],
      recognitionNumber: "",
      llpPin: "",
      isRecognizedMSME: false,
      recognizedMSMENumber: "",
    });
    setFormBox(true);
  };
  const getMembersData = () => {
    setLoader(true);
    const parm = `?itrId=${ItrData.id}`;
    dispatch(
      getAdditionalInfoAction(parm, (e) => {
        setMemberStatus(e?.data?.memberAdditionInfoStatus);
        setAdditionalInfoData(e.data?.membersAdditionInfo);
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    getMembersData();
  }, []);
  const handleLLPPin = (value) => {
    value = value.replace(/[^a-zA-Z0-9]/g, '');

    // Apply the regex to format the input as needed
    if (value.length <= 3) {
      value = value.toUpperCase(); // Make sure first part is upper case letters
    } else if (value.length > 3 && value.length <= 7) {
      value = `${value.slice(0, 3).toUpperCase()}-${value.slice(3, 7)}`;
    }
    return value
  }
  return (
    <div>
      <LoadingBox open={loader} />

      {additionalInfoData?.length > 0 &&
        additionalInfoData?.map((item) => (
          <div
            key={item.id}
            className="flex gap-2 p-3 my-3 align-center justify-between"
            style={{
              background: addOpacity(theme.primary_bg, 0.2),
              borderRadius: "10px",
            }}
          >
            <div>
              <p>Recognition Number : {item.recognitionNumber}</p>
            </div>

            <div className="action_buttons">
              <button
                className="edit_btn"
                onClick={() => {
                  setFormBox(true);
                  setFormData({
                    id: item?.id,
                    personalInfoId: item?.personalInfoId,
                    changesDuringPrevYear: item?.changesDuringPrevYear || false,
                    additionalPartners: item?.additionalPartners?.map((i) => ({
                      id: i.id ?? "",
                      partnerName: i.partnerName || "",
                      PAN: i.PAN || "",
                      dateOfAdmissionRetirement:
                        i.dateOfAdmissionRetirement || "",
                      RemunerationAmount: i.RemunerationAmount || "",
                      percentOfShare: i.percentOfShare || "",
                      partnerStatus: i.partnerStatus?.id || "",
                      membersAdditionInfoId: item?.id
                    })) || [
                      {
                        partnerName: "",
                        PAN: "",
                        dateOfAdmissionRetirement: "",
                        RemunerationAmount: "",
                        percentOfShare: "",
                        partnerStatus: "",
                      },
                    ],
                    recognitionNumber: item?.recognitionNumber || "",
                    llpPin: item?.llpPin || "",
                    isRecognizedMSME: item?.isRecognizedMSME || false,
                    recognizedMSMENumber: item?.recognizedMSMENumber || "",
                  });
                }}
              >
                <FaRegEdit />
              </button>
              <button
                className="delete_btn"
                onClick={() => handleDeleteData(item?.id)}
              >
                <RiDeleteBin6Fill />
              </button>
            </div>
          </div>
        ))}
      <button
        className="secandary_button mt-2"
        onClick={() => setFormBox(true)}
      >
        {strings.button.add_more_item}{" "}
      </button>
      <FormDialogBox
        open={formBox}
        formTitle={strings.address.additional_info}
        handleClose={() => {
          setFormData({});
          setFormBox(false);
        }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          validationSchema={ItrData?.userType !== 4 ? validationSchema : validationSchema.concat(validationSchemaLLP)}
          onSubmit={(values) => {
            handleFormSubmit(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues, setFieldValue }) => (
            <div>
              {/* Recognition number allotted by the DPIIT for a startup  */}
              <StyleFormBox>
                <div className="flex_input">
                  <label>
                    {
                      strings.personal_info
                        .recognition_number_alootted_by_dpiit_for_startup
                    }
                  </label>
                  <Input
                    name={"recognitionNumber"}
                    error={errors.recognitionNumber}
                    value={values?.recognitionNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="">
                  <CheckBox
                    value={values?.isRecognizedMSME}
                    label={"Whether you are recognized as MSM"}
                    handleChange={(e) =>
                      {setValues({
                        ...values,
                        isRecognizedMSME: !values.isRecognizedMSME,
                      })
                    }
                    }
                  />
                </div>

                {values?.isRecognizedMSME && (
                  <div className="flex_input">
                    <label>
                      If yes, please provide registration number allotted as per
                      MSMED Act, 2006
                    </label>
                    <Input
                      name={"recognizedMSMENumber"}
                      error={errors.recognizedMSMENumber}
                      value={values?.recognizedMSMENumber}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </StyleFormBox>

              {/* Was there any change during the previous year in the  */}
              <StyleFormBox>
                <p>
                  {strings.personal_info.additional_info_about_partner_member}
                </p>
                <hr />
                <div>
                  <label>
                    {strings.personal_info.was_and_change_in_previous_year} *
                  </label>
                  <div className="flex items-center">
                    <CheckBox
                      value={values?.changesDuringPrevYear === true}
                      label={strings.placeholder.yes}
                      handleChange={() => {
                        setValues({ ...values, changesDuringPrevYear: true });
                      }}
                    />

                    <CheckBox
                      value={values?.changesDuringPrevYear === false}
                      label={strings.placeholder.no}
                      handleChange={() => {
                        setValues({ ...values, changesDuringPrevYear: false });
                      }}
                    />
                  </div>
                </div>
              </StyleFormBox>
              {/* Form will show only if changesDuringPrevYear is yes */}
              {values?.changesDuringPrevYear ?
                <FieldArray name="additionalPartners">
                  {({ insert, remove, push }) => (
                    <StyleFormBox>
                      {values?.additionalPartners?.map((item, index) => (
                        <div key={index}>
                          <StyleFormBox>
                            {index ? (
                              <button
                                className="delete_button block ml-auto"
                                onClick={() => remove(index)}
                              >
                                <RiDeleteBin6Fill />
                              </button>
                            ) : ""}
                            <div className="flex_input">
                              <div>
                                <label>
                                  {strings.personal_info.partner_name}{" "}
                                </label>
                                <Input
                                  value={item.partnerName}
                                  name={`additionalPartners.${index}.partnerName`}
                                  error={
                                    errors?.additionalPartners &&
                                    errors?.additionalPartners[index]?.partnerName
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                              <div>
                                <label>
                                  {strings.personal_info.admitted_retired}{" "}
                                </label>
                                <Select
                                  value={item.partnerStatus}
                                  name={`additionalPartners.${index}.partnerStatus`}
                                  error={
                                    errors?.additionalPartners &&
                                    errors?.additionalPartners[index]
                                      ?.partnerStatus
                                  }
                                  onChange={handleChange}
                                >
                                  <option value="" disabled>
                                    {strings.label.choose_option}
                                  </option>
                                  {memberStatus?.map((i, ix) => (
                                    <option value={i.id} key={ix}>
                                      {i.name}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </div>

                            <div className="flex_input">
                              <div>
                                <label>{strings.label.pan_number} </label>
                                {/* <Input
                                  inputType={""}
                                  value={item.PAN}
                                  name={`additionalPartners.${index}.PAN`}
                                  error={
                                    errors?.additionalPartners &&
                                    errors?.additionalPartners[index]?.PAN
                                  }
                                  onChange={handleChange}
                                /> */}

                                <Input
                                  className={"uppercase"}
                                  placeholder={strings.placeholder.enter_10_digit}
                                  value={item.PAN}
                                  name={`additionalPartners.${index}.PAN`}
                                  error={
                                    errors?.additionalPartners &&
                                    errors?.additionalPartners[index]?.PAN
                                  }
                                  onChange={(e) => {
                                    if (e.target.value.length <= 10) {
                                      setFieldValue(`additionalPartners.${index}.PAN`, e.target.value.toUpperCase());
                                    }
                                  }}
                                  // Just in case when backend set validation on pan number
                                  // onChange={(e) => {
                                  //   if (e.target.value.length <= 10) {
                                  //     setValues((prevValues) => {
                                  //       const updatedValues = { ...prevValues };
                                  //       const path = `additionalPartners.${index}.PAN`;
                                  //       const keys = path.split(".");
                                  //       let current = updatedValues;
                                  //       for (
                                  //         let i = 0;
                                  //         i < keys.length - 1;
                                  //         i++
                                  //       ) {
                                  //         current = current[keys[i]];
                                  //       }
                                  //       current[keys[keys.length - 1]] =
                                  //         e.target.value.toUpperCase();
                                  //       return updatedValues;
                                  //     });
                                  //   }
                                  // }}
                                />
                              </div>
                              <div>
                                <label>
                                  {
                                    strings.personal_info
                                      .date_of_admission_retirement
                                  }
                                  *
                                </label>
                                <Input
                                  type={"date"}
                                  value={item.dateOfAdmissionRetirement}
                                  name={`additionalPartners.${index}.dateOfAdmissionRetirement`}
                                  error={
                                    errors?.additionalPartners &&
                                    errors?.additionalPartners[index]
                                      ?.dateOfAdmissionRetirement
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className="flex_input">
                              <div>
                                <label>
                                  {
                                    strings.personal_info
                                      .remuneration_paid_in_retiring_partner
                                  }
                                </label>
                                <Input
                                  type="number"
                                  value={item.RemunerationAmount}
                                  name={`additionalPartners.${index}.RemunerationAmount`}
                                  error={
                                    errors?.additionalPartners &&
                                    errors?.additionalPartners[index]
                                      ?.RemunerationAmount
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                              <div>
                                <label>
                                  {strings.personal_info.percent_of_share}{" "}
                                </label>
                                <Input
                                  type="number"
                                  value={item.percentOfShare}
                                  name={`additionalPartners.${index}.percentOfShare`}
                                  error={
                                    errors?.additionalPartners &&
                                    errors?.additionalPartners[index]
                                      ?.percentOfShare
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </StyleFormBox>
                        </div>
                      ))}

                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({
                              PAN: "",
                              RemunerationAmount: "",
                              dateOfAdmissionRetirement: "",
                              partnerName: "",
                              partnerStatus: "",
                              percentOfShare: "",
                            });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </StyleFormBox>
                  )}
                </FieldArray>
              : ""}
              
              {/* LLP PIN issued By MCA */}
              {ItrData.userType === 4 && (
              <StyleFormBox>
                <div className="flex_input">
                  <label>{strings.personal_info.llp_pin_by_mca}</label>
                  <Input
                    name={"llpPin"}
                    error={errors.llpPin}
                    value={values?.llpPin ? handleLLPPin(values?.llpPin) : ""}
                    onChange={handleChange}
                    maxlength={8}
                  />
                </div>
              </StyleFormBox>
              )}
              <button
                className="primary_button my-4"
                type="submit"
                onClick={handleSubmit}
              >
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
