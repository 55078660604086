import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import ToggelButton from "../../../../styledComponent/Button/ToggelButton";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../../../styledComponent/Inputs/Input";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import Select from "../../../../styledComponent/Inputs/Select";
import { RiDeleteBin6Fill } from "react-icons/ri";
import {
  saveSaleOfLandAction,
  updateSaleOfLandAction,
} from "../../../../redux/itr/action";
import { removeEmptyKeys, dateFormat } from "../../../../utils/helpers";
import { toast } from "react-toastify";

export default function SaleOfLandBuilding({ handleCallback, editForm }) {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [citiesList, setCitiesList] = useState([]);
  const [activeState, setActiveState] = useState(1);

  const { strings, theme, states } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    dateOfSale: Yup.string().required(strings.validation_message.required),
    dateOfPurchase: Yup.string().required(strings.validation_message.required),
    descriptionOfAssets: Yup.string().required(
      strings.validation_message.required
    ).max(255, 'Only 255 Characters Allowed'),
    totalSalePrice: Yup.string().required(strings.validation_message.required),
    totalPurchasePrice: Yup.string().required(
      strings.validation_message.required
    ),
    propertyAddress: Yup.object().shape({
      addressLine1: Yup.string().required(strings.validation_message.required),
      city: Yup.string().required(strings.validation_message.required),
      state: Yup.string().required(strings.validation_message.required),
      pinCode: Yup.string().required(strings.validation_message.required).matches(
        /^[1-9][0-9]{5}$/, strings.validation_message.invalid_pin
      ),
    }),
    improvementCost:  Yup.array().when('includeCostOfImprovement', {
      is:  true,
      then: () => Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required(strings.validation_message.required),
          amount: Yup.number()
            .required(strings.validation_message.required)
            .moreThan(0, strings.validation_message.amount_greater_than_0),
        })
      ),
    }),
    propertyBuyer: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(strings.validation_message.required),
        pan: Yup.string()
          .required(strings.validation_message.required)
          .matches(
            /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
            strings.validation_message.invalid_pan
          ),
        aadhaar: Yup.string()
          .required(strings.validation_message.required)
          .matches(
            /^[2-9]{1}[0-9]{3}-[0-9]{4}-[0-9]{4}$/,
            "Invalid Aadhaar card number format"
          )
          .max(16, "Aadhaar card number must be at most 16 characters long"),
        amountPaid: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
        ownershipPercentage: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
  });

  const handleSubmitForm = (values) => {
    console.log("values ========>", values);
    if (values.id) {
      setLoader(true);
      dispatch(
        updateSaleOfLandAction(removeEmptyKeys(values), (e) => {
          if (e.status === 200) {
            toast.success("Successfully update data");
            setLoader(false);
            handleCallback(e.data);
          } else {
            setLoader(false);
          }
        })
      );
    } else {
      setLoader(true);
      let { id, ...newData } = values;
      dispatch(
        saveSaleOfLandAction(removeEmptyKeys(newData), (e) => {
          if (e.status === 200) {
            toast.success("Successfully upload data");
            setLoader(false);
            handleCallback(e.data);
          } else {
            setLoader(false);
          }
        })
      );
    }
  };

  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };

  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    setLoader(true);
    setFormData({
      itrId: ItrData.id,
      id: editForm?.id || "",
      typeOfAssets: editForm?.typeOfAssets || "",
      dateOfSale: editForm?.dateOfSale ? dateFormat(editForm?.dateOfSale) : "",
      dateOfPurchase: editForm?.dateOfPurchase ? dateFormat(editForm?.dateOfPurchase) : "",
      descriptionOfAssets: editForm?.descriptionOfAssets || "",
      totalSalePrice: editForm?.totalSalePrice || "",
      transferExpenses: editForm?.transferExpenses || "",
      totalPurchasePrice: editForm?.totalPurchasePrice || "",
      propertyValueOnStampDutyPaid:
        editForm?.propertyValueOnStampDutyPaid || "",
      includeCostOfImprovement: editForm?.includeCostOfImprovement || false,
      improvementCost: editForm?.improvementCost || [],
      propertyAddress: {
        addressLine1: editForm?.propertyAddress?.addressLine1 || "",
        city: editForm?.propertyAddress?.city?.id || "",
        state: editForm?.propertyAddress?.state?.id || "1",
        country: editForm?.propertyAddress?.country?.id || "101",
        pinCode: editForm?.propertyAddress?.pinCode || "",
      },
      propertyBuyer:
        editForm?.propertyBuyer?.map((item, ix) => ({
          name: item.name,
          pan: item.pan,
          aadhaar: item.aadhaar,
          amountPaid: item.amountPaid,
          ownershipPercentage: item.ownershipPercentage,
        })) || [],
    });
    setLoader(false);
  }, [editForm]);
console.log(formData);
  return (
    <section>
      <LoadingBox open={loader} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
          handleSubmitForm(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setValues,
          setFieldValue,
        }) => (
          
          <div>{errors.length}
            <StyleFormBox>
              <h4>{strings.income.add_property_details}</h4>
              <p>{strings.income.applicable_if_you_have_sold_land}</p>
              <hr />

              <div className="flex_input my-2">
                <label>{strings.income.date_of_sale} *</label>
                <Input
                  inputType={"date"}
                  name={"dateOfSale"}
                  value={values.dateOfSale}
                  error={errors.dateOfSale}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.date_of_purchase} *</label>
                <Input
                  inputType={"date"}
                  name={"dateOfPurchase"}
                  value={values.dateOfPurchase}
                  error={errors.dateOfPurchase}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            <StyleFormBox>
              <h4>{strings.income.add_details_asset}</h4>
              <hr />
              <div className="flex_input my-2">
                <label>{strings.income.description_of_asset_sold} *</label>
                <Input
                  name={"descriptionOfAssets"}
                  value={values.descriptionOfAssets}
                  error={errors.descriptionOfAssets}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.total_sale_price} *</label>
                <Input
                  inputType={"currency"}
                  name={"totalSalePrice"}
                  value={values.totalSalePrice}
                  error={errors.totalSalePrice}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.total_purchase_price} *</label>
                <Input
                  inputType={"currency"}
                  name={"totalPurchasePrice"}
                  value={values.totalPurchasePrice}
                  error={errors.totalPurchasePrice}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.income.transfer_expenses} </label>
                <Input
                  inputType={"currency"}
                  name={"transferExpenses"}
                  value={values.transferExpenses}
                  error={errors.transferExpenses}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>
                  {strings.income.value_pf_property_which_stamp_duty}
                </label>
                <Input
                  inputType={"currency"}
                  name={"propertyValueOnStampDutyPaid"}
                  value={values.propertyValueOnStampDutyPaid}
                  error={errors.propertyValueOnStampDutyPaid}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            <StyleFormBox>
              <div className="flex_input my-2 justify-between">
                <h4>
                  {strings.income.do_you_want_add_cose_improvment_details}
                </h4>
                <ToggelButton
                  value={values.includeCostOfImprovement}
                  handleClick={(e) => {
                    setFieldValue(
                      "includeCostOfImprovement",
                      !values.includeCostOfImprovement
                    )
                    if(e){
                      setFieldValue('improvementCost', [{description: "", amount: 0}]);
                    }
                  }}
                />
              </div>

              {values.includeCostOfImprovement === true ? (
                <FieldArray name="improvementCost">
                  {({ insert, remove, push }) => (
                    <>
                      {values?.improvementCost?.map((item, index) => (
                        <StyleFormBox>
                          <div className="text-right">
                            <button
                              className="delete_button mb-2"
                              onClick={(e) => {
                                e.preventDefault();
                                remove(index);
                              }}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                          <div className="flex_input my-2">
                            <label>{strings.label.description} </label>
                            <Input
                              inputType={"message"}
                              name={`improvementCost.${index}.description`}
                              value={item.description}
                              error={
                                errors?.improvementCost &&
                                errors?.improvementCost[index]?.description
                              }
                              onChange={handleChange}
                            />
                          </div>

                          <div className="flex_input my-2">
                            <label>{strings.income.amount} </label>
                            <Input
                              inputType={"currency"}
                              name={`improvementCost.${index}.amount`}
                              value={item.amount}
                              error={
                                errors?.improvementCost &&
                                errors?.improvementCost[index]?.amount
                              }
                              onChange={handleChange}
                            />
                          </div>
                        </StyleFormBox>
                      ))}

                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({
                              description: "",
                              amount: "",
                            });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </>
                  )}
                </FieldArray>
              ) : (
                ""
              )}
            </StyleFormBox>

            <StyleFormBox>
              <h4>{strings.address.property_address}</h4>
              <hr />
              <div className="flex_input my-2">
                <label>{strings.address.address_line} *</label>
                <Input
                  name={"propertyAddress.addressLine1"}
                  value={values?.propertyAddress?.addressLine1}
                  error={errors?.propertyAddress?.addressLine1}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="flex_input my-2">
                <label>
                  {strings.address.town + "/" + strings.address.city}
                </label>
                <Input
                  name={"propertyAddress.city"}
                  value={values?.propertyAddress?.city}
                  error={errors?.propertyAddress?.city}
                  onChange={handleChange}
                />
              </div> */}
              <div className="flex_input my-2">
                <label>{strings.address.pincode} *</label>
                <Input
                  type={"number"}
                  name={"propertyAddress.pinCode"}
                  value={values?.propertyAddress?.pinCode}
                  error={errors?.propertyAddress?.pinCode}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input">
                <label>{strings.address.state} *</label>
                <Select
                  name="propertyAddress.state"
                  error={errors?.propertyAddress?.state}
                  value={values?.propertyAddress?.state}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      propertyAddress: {
                        ...values.propertyAddress,
                        state: e.target.value,
                          city:""
                      },
                    });
                    setActiveState(Number(e.target.value));
                  }}
                >
                  <option value="">{strings.label.choose_option}</option>
                  {states?.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="flex_input">
                <label>{strings.address.city} *</label>
                <Select
                  name={"propertyAddress.city"}
                  error={errors?.propertyAddress?.city}
                  value={values?.propertyAddress?.city}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {citiesList?.map((i, ix) => (
                    <option value={i.id} key={ix}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </div>

              {/* <div className="flex_input my-2">
                <label>{strings.address.country}</label>
                <SelectOption
                  options={[
                    strings.address.country,
                    strings.address.country,
                    strings.address.country,
                  ]}
                  error={""}
                  // value={activeTab}
                  handleChange={() => {
                    // setActiveTab(e);
                  }}
                />
              </div> */}
            </StyleFormBox>

            <StyleFormBox>
              <h4>{strings.income.buyer_details}</h4>
              <p>{strings.income.please_provide_details_of_property_buyer}</p>
              <hr />
              <FieldArray name="propertyBuyer">
                {({ insert, remove, push }) => (
                  <>
                    {values?.propertyBuyer?.map((item, index) => (
                      <StyleFormBox>
                        <div className="text-right">
                          <button
                            className="delete_button mb-2"
                            onClick={(e) => {
                              e.preventDefault();
                              remove(index);
                            }}
                          >
                            <RiDeleteBin6Fill />
                          </button>
                        </div>

                        <div className="flex_input my-2">
                          <label>{strings.income.buyer_name} *</label>
                          <Input
                            name={`propertyBuyer.${index}.name`}
                            value={item.name}
                            error={
                              errors?.propertyBuyer &&
                              errors?.propertyBuyer[index]?.name
                            }
                            onChange={handleChange}
                          />
                        </div>

                        <div className="flex_input my-2">
                          <label>{strings.income.ownership_percentage} *</label>
                          <Input
                            inputType={"percentage"}
                            name={`propertyBuyer.${index}.ownershipPercentage`}
                            value={item.ownershipPercentage}
                            error={
                              errors?.propertyBuyer &&
                              errors?.propertyBuyer[index]?.ownershipPercentage
                            }
                            onChange={handleChange}
                          />
                        </div>

                        <div className="flex_input my-2">
                          <label>{strings.label.aadhar_number} *</label>
                          <Input
                            inputType={"aadhaar"}
                            name={`propertyBuyer.${index}.aadhaar`}
                            value={item.aadhaar}
                            error={
                              errors?.propertyBuyer &&
                              errors?.propertyBuyer[index]?.aadhaar
                            }
                            onChange={(e) => {
                              if (e.target.value.length <= 14) {
                                handleChange(e);
                              }
                            }}
                          />
                        </div>

                        <div className="flex_input my-2">
                          <label>{strings.label.pan} *</label>
                          <Input
                            name={`propertyBuyer.${index}.pan`}
                            value={item.pan}
                            className={"uppercase"}
                            error={
                              errors?.propertyBuyer &&
                              errors?.propertyBuyer[index]?.pan
                            }
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                setFieldValue(`propertyBuyer.${index}.pan`, e.target.value.toUpperCase());
                              }
                            }}
                          />
                        </div>

                        <div className="flex_input my-2">
                          <label>{strings.income.amount_paid} *</label>
                          <Input
                            name={`propertyBuyer.${index}.amountPaid`}
                            inputType={"currency"}
                            value={item.amountPaid}
                            error={
                              errors?.propertyBuyer &&
                              errors?.propertyBuyer[index]?.amountPaid
                            }
                            onChange={handleChange}
                          />
                        </div>
                      </StyleFormBox>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            name: "",
                            pan: "",
                            aadhaar: "",
                            amountPaid: "",
                            ownershipPercentage: 0,
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </>
                )}
              </FieldArray>
            </StyleFormBox>

            <button className="primary_button mt-4" onClick={handleSubmit}>
              {strings.button.submit}
            </button>
          </div>
        )}
      </Formik>
    </section>
  );
}
