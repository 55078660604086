import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
export default function ToggelButton({value , handleClick}) {
    const [isActive , setIsActive] = useState(false)
    const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <ToggleButton className={value && 'active'} onClick={()=>{handleClick(); handleClick(!value)}} theme={theme}></ToggleButton>
  )
}
const ToggleButton = styled.button`
position: relative;
width: 60px;
max-width:60px;
height: 30px;
background-color: #6f6f6f;
border-radius: 15px;
border: none;
cursor: pointer;
outline: none;
transition: background-color 0.3s ease;

&::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.5s;
}
&.active{
  background-color: ${(p) => p.theme.primary_bg};
    &::before{
        left: 30px;
       
    }
}
`
