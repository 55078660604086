import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { userLogoutAction } from "../../redux/profile/action";

export default function ProfileDropDown() {
  const [profileBox, setProfileBox] = useState(false);

  const { strings, theme } = useSelector((s) => s.platformSetting);
  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch(userLogoutAction());
  };

  useEffect(()=>{
    if(profileBox === true){
      document.body.style.overflow  ='hidden';
    }else{
      document.body.style.overflow =  'auto';
    }
  },[profileBox])

  return (
    <div className="relative">
      <button
        onClick={() => {
          setProfileBox(true);
        }}
        style={{ background: addOpacity(theme.primary_bg, 0.8) }}
        className="block p-2 rounded"
      >
        <FaUser />
      </button>
      {profileBox && (
        <div>
          <div
            className="absolute right-0 top-11 z-20 p-4 w-60 max-h-[calc(100vh-82px)] overflow-scroll"
            style={{ backgroundColor: theme.primary_bg }}
          >
            <NavLink
              to="/settings"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Settings
            </NavLink>
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Profile
            </NavLink>
            <NavLink
              to="/about-us"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              {strings.profile.about_us}
            </NavLink>
            <NavLink
              to="/terms-conditions"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Terms and Conditions
            </NavLink>
            <NavLink
              to="/faq"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              FAQ ?
            </NavLink>
            <NavLink
              to="/glossary"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Glossary{" "}
            </NavLink>
            <NavLink
              to="/questions"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Questions{" "}
            </NavLink>
            <NavLink
              to="/service"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Service{" "}
            </NavLink>
            <NavLink
              to="/ticket"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Ticket{" "}
            </NavLink>
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              {strings.profile.contact_us}
            </NavLink>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
                logoutUser();
              }}
            >
              Logout
            </NavLink>
            <NavLink
              to="/details"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Details
            </NavLink>
            <NavLink
              to="/resources"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Resources
            </NavLink>
            <NavLink
              to="/newsletter"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Newsletter
            </NavLink>
            <NavLink
              to="/taxoptimizer"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Taxoptimizer
            </NavLink>
            <NavLink
              to="/upload-form-16"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Upload16
            </NavLink>
            <NavLink
              to="/terms"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Terms
            </NavLink>
            <NavLink
              to="/tax-tool"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              TaxTool
            </NavLink>
            <NavLink
              to="/gratuity-calculator"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              gratuity-calculator
            </NavLink>
            <NavLink
              to="/hra-calculator"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              hra-calculator
            </NavLink>
            <NavLink
              to="/your-itr_form"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              your-itr_form
            </NavLink>
            <NavLink
              to="/income-tax-calculator"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              Income-tax-calculator
            </NavLink>
            <NavLink
              to="/accommodation"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              accommodation
            </NavLink>
            <NavLink
              to="/emicalculator"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              emicalculator
            </NavLink>
            <NavLink
              to="/ppfcalculator"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              ppfcalculator
            </NavLink>
            <NavLink
              to="/rent-recepit"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              rent-recepit
            </NavLink>
            <NavLink
              to="/taxestimator"
              className={({ isActive }) =>
                isActive ? "active block" : "block"
              }
              onClick={() => {
                setProfileBox(false);
              }}
            >
              taxestimator
            </NavLink>
          </div>
          <span
            className="fixed inset-0 bg-[#15141487] z-10"
            style={{ backdropFilter: 'blur(1px)' }}
            onClick={() => {
              setProfileBox(false);
            }}
          ></span>
        </div>
      )}
    </div>
  );
}
