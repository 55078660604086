import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import Input from "../../../styledComponent/Inputs/Input";
import LoadingBox from "../../DialogBox/LoadingBox";
import { FaRegEdit } from "react-icons/fa";
import {
  deleteDonationToLAAction,
  editDonationToLAAction,
  getDonationToLAAction,
  saveDonationToLAAction,
} from "../../../redux/itr/action";
import Select from "../../../styledComponent/Inputs/Select";
import { addOpacity } from "../../../utils/helpers";
import FormDialogBox from "../../DialogBox/FormDialogBox";
export default function Schedule80LA({
  LASubSection,
  LATypeOfEntiry,
  LAIncomeOfUnit,
  LAAuthorityGrantingRegistration,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [formBox, setFormBox] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    subSectionDeductionClaimed: Yup.string().required(strings.validation_message.required),
    typeOfInput: Yup.string().required(strings.validation_message.required),
    typeOfIncome: Yup.string().required(strings.validation_message.required),
    authorityGrantingRegistration: Yup.string().required(strings.validation_message.required),
    dateOfRegistration: Yup.string().required(strings.validation_message.required),
    registrationNumber: Yup.string().required(strings.validation_message.required),
    firstAYDuringDeductionClaimed: Yup.string().required(strings.validation_message.required),
    amountOfDeductionClaimedCurrent: Yup.string().required(strings.validation_message.required),
  });

  const saveSchedule80LAApi = async (obj) => {
    setLoader(true);
    const { type, ...value } = obj; // Destructure type and remaining data
    const action =
      type === "edit" ? editDonationToLAAction : saveDonationToLAAction;

    dispatch(
      action({ itrId: ItrData.id, ...value }, (e) => {
        setLoader(false);
        if (e.status === 200) {
          if (type === "edit") {
            const updateData = data.map((i) => (i.id === obj.id ? obj : i));
            setData(updateData);
          } else setData([...data, e.data]);
          toast.success(
            `Successfully ${type === "edit" ? "updated" : "added"} data`
          );
          setFormBox(false);
        } else {
          toast.error(e.message);
        }
        setLoader(false);
      })
    );
  };
  const handleDelete = (id) => {
    setLoader(true);
    dispatch(
      deleteDonationToLAAction({ id }, (e) => {
        if (e.status === 200) {
          setData((prev) => prev.filter((i) => i.id !== id));
          toast.success("Successfully deleted");
        } else {
          toast.error(e.message);
        }
        setLoader(false);
      })
    );
  };
  const getScheduleDataApi = async () => {
    setLoader(true);
    dispatch(
      getDonationToLAAction(ItrData.id, (e) => {
        if (e.status === 200) {
          setData(e.data.deductions);
        } else {
          toast.error(e.message);
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    getScheduleDataApi();
  }, []);

  return (
    <div>
      <LoadingBox open={loader} />

      {data?.length > 0
        ? data.map((item) => (
            <div
              key={item.id}
              className="flex gap-2 p-3 my-3 align-center"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
              // key={ix}
            >
              <h4 className="mr-auto">Amount Of Deduction Claimed Current </h4>

              <h4>{item?.amountOfDeductionClaimedCurrent}</h4>
              <div className="action_buttons">
                <button
                  className="edit_btn"
                  onClick={() => {
                    setFormData({
                      id: item?.id,
                      type: "edit",
                      subSectionDeductionClaimed:
                        item?.subSectionDeductionClaimed?.id ||
                        item?.subSectionDeductionClaimed ||
                        "",
                      typeOfInput:
                        item?.typeOfInput?.id || item?.typeOfInput || "",
                      typeOfIncome:
                        item?.typeOfIncome?.id || item?.typeOfIncome || "",
                      authorityGrantingRegistration:
                        item?.authorityGrantingRegistration?.id ||
                        item?.authorityGrantingRegistration ||
                        "",
                      dateOfRegistration: item?.dateOfRegistration || "",
                      registrationNumber: item?.registrationNumber || "",
                      firstAYDuringDeductionClaimed:
                        item?.firstAYDuringDeductionClaimed || "",
                      amountOfDeductionClaimedCurrent:
                        item?.amountOfDeductionClaimedCurrent || "",
                    });
                    setFormBox(true);
                  }}
                >
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() => handleDelete(item.id)}
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          ))
        : ""}

      <button
        className="primary_button"
        style={{ flex: 0 }}
        onClick={() => {
          setFormData({
            subSectionDeductionClaimed: "",
            typeOfInput: "",
            typeOfIncome: "",
            authorityGrantingRegistration: "",
            dateOfRegistration: "",
            registrationNumber: "",
            firstAYDuringDeductionClaimed: "",
            amountOfDeductionClaimedCurrent: "",
          });
          setFormBox(!formBox);
        }}
      >
        {strings.button.add}
      </button>

      <FormDialogBox
        open={formBox}
        formTitle={"Deduction under section 80LA"}
        handleClose={() => {
          setFormBox(false);
        }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            saveSchedule80LAApi(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <div>
              <StyleFormBox>
                <h4 className="mb-4">Deductions under section 80LA</h4>
                <h4>Details of donations to research associations</h4>
                <p>
                  Deduction under sections 35(1)(ii) or 35(1)(iia) or 35(1)(iii)
                  or 35(2AA)
                </p>
                <hr className="my-4" />

                <div className="flex_input my-2 items-center">
                  <label>Sub-section in which deduction is claimed*</label>
                  <Select
                    name={"subSectionDeductionClaimed"}
                    value={values?.subSectionDeductionClaimed}
                    error={errors?.subSectionDeductionClaimed}
                    onChange={handleChange}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {LASubSection?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Type of Entity*</label>
                  <Select
                    name={`typeOfInput`}
                    value={values?.typeOfInput}
                    error={errors?.typeOfInput}
                    onChange={handleChange}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {LATypeOfEntiry?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Type of income of the unit*</label>
                  <Select
                    name={`typeOfIncome`}
                    value={values?.typeOfIncome}
                    error={errors?.typeOfIncome}
                    onChange={handleChange}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {LAIncomeOfUnit?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Authority granting registration*</label>
                  <Select
                    name={`authorityGrantingRegistration`}
                    value={values?.authorityGrantingRegistration}
                    error={errors?.authorityGrantingRegistration}
                    onChange={handleChange}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {LAAuthorityGrantingRegistration?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Authority granting registration*</label>
                  <Input
                    inputType={"date"}
                    name={"dateOfRegistration"}
                    error={errors.dateOfRegistration}
                    value={values.dateOfRegistration}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Registration number*</label>
                  <Input
                    name={"registrationNumber"}
                    error={errors.registrationNumber}
                    value={values.registrationNumber}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-2 items-center">
                  <label>First AY during which deduction is claimed*</label>
                  <Input
                    name={"firstAYDuringDeductionClaimed"}
                    error={errors.firstAYDuringDeductionClaimed}
                    value={values.firstAYDuringDeductionClaimed}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-2 items-center">
                  <label>Amount of deduction claimed for current*</label>
                  <Input
                    inputType={"currency"}
                    name={"amountOfDeductionClaimedCurrent"}
                    error={errors.amountOfDeductionClaimedCurrent}
                    value={values.amountOfDeductionClaimedCurrent}
                    onChange={handleChange}
                  />
                </div>
              </StyleFormBox>
              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
