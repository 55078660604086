import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { HiOutlineWallet } from "react-icons/hi2";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
export default function Youritrform() {
    const {theme } = useSelector((s) => s.platformSetting);
    return (
        <Root theme={theme}>
            <div className="ifsc-banner-section">
                <div className="container_box">
                    <div className="banner-main flex">
                        <div className="banner-left">
                            <div className="cntn">
                                <h4>Get Ready for Tax Filing 2024-25</h4>
                                <button type="button">Start Now</button>
                            </div>
                        </div>
                        <div className="banner-right">
                            <div className="right-content">
                                <p>Limited Period Offer</p>
                                <h2>25%</h2>
                                <h5>DISCOUNT</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ifsc-main-section">
                <div className="container_box">
                    <div className="ifsc-top">
                        <div className="ifsc-left">
                            <div className="ifsc-left-cntn">
                                <h4>IFSC And MICR Codes Directory</h4>
                                <p>List of IFSC Code, MICR Code and Addresses of all Banks in India</p>
                                <h5 className="mt-4">Find IFSC Code for Banks in India</h5>
                                <select name="cars" id="cars">
                                    <option value="volvo">Zila Sahakari Bank Ghaziabad</option>
                                    <option value="saab">Zoroastrian Co-operative Bank</option>
                                    <option value="volvo">Yes Bank</option>
                                    <option value="saab">Vasai Vikas Sahakari Bank</option>
                                    <option value="volvo">Vishweshwar Sahakari Bank</option>
                                    <option value="saab">Vijaya Bank</option>
                                    <option value="volvo">Vijay Co-operative Bank</option>
                                    <option value="saab">Vasai Janata Sahakari Bank</option>
                                    <option value="volvo">Varachha Co-operative Bank</option>
                                    <option value="saab">Utkarsh Small Finance Bank</option>
                                    <option value="volvo">Axis Bank</option>
                                    <option value="saab">United Bank of India</option>
                                    <option value="volvo">Urban Co-operative Bank Perinthalmanna</option>
                                    <option value="saab">Uttar Pradesh Co-operative Bank</option>
                                    <option value="volvo">United Overseas Bank</option>
                                    <option value="saab">UCO Bank</option>
                                    <option value="volvo">Union Bank of India</option>
                                    <option value="saab">Textile Traders Co-operative Bank</option>
                                    <option value="volvo">Satara Shakari Bank</option>
                                    <option value="saab">Telangana State Co-operative Apex Bank</option>
                                    <option value="volvo">Punjab State Co-operative Bank</option>
                                    <option value="saab">Nawanagar Co-operative Bank</option>
                                    <option value="volvo">The Malad Sahakari Bank</option>
                                    <option value="saab">Tamilnad Mercantile Bank</option>
                                    <option value="volvo">TJSB Sahakari Bank</option>
                                    <option value="saab">Thrissur District Co-operative Bank</option>
                                    <option value="volvo">Tumkur Grain Merchant's Co-operative Bank</option>
                                    <option value="saab">Thane Bharat Sahakari Bank</option>
                                    <option value="volvo">Banaskantha Mercantile Co-operative Bank</option>
                                    <option value="saab">Ankola Urban Co-operative Bank</option>
                                </select>
                                <select name="cars" id="cars" placeholder="Select State">
                                    <option value="volvo">Select State</option>
                                    <option value="saab">Zoroastrian Co-operative Bank</option>
                                    <option value="volvo">Yes Bank</option>
                                    <option value="saab">Vasai Vikas Sahakari Bank</option>
                                    <option value="volvo">Vishweshwar Sahakari Bank</option>
                                    <option value="saab">Vijaya Bank</option>
                                    <option value="volvo">Vijay Co-operative Bank</option>
                                    <option value="saab">Vasai Janata Sahakari Bank</option>
                                    <option value="volvo">Varachha Co-operative Bank</option>
                                    <option value="saab">Utkarsh Small Finance Bank</option>
                                    <option value="volvo">Axis Bank</option>
                                    <option value="saab">United Bank of India</option>
                                    <option value="volvo">Urban Co-operative Bank Perinthalmanna</option>
                                    <option value="saab">Uttar Pradesh Co-operative Bank</option>
                                    <option value="volvo">United Overseas Bank</option>
                                    <option value="saab">UCO Bank</option>
                                    <option value="volvo">Union Bank of India</option>
                                    <option value="saab">Textile Traders Co-operative Bank</option>
                                    <option value="volvo">Satara Shakari Bank</option>
                                    <option value="saab">Telangana State Co-operative Apex Bank</option>
                                    <option value="volvo">Punjab State Co-operative Bank</option>
                                    <option value="saab">Nawanagar Co-operative Bank</option>
                                    <option value="volvo">The Malad Sahakari Bank</option>
                                    <option value="saab">Tamilnad Mercantile Bank</option>
                                    <option value="volvo">TJSB Sahakari Bank</option>
                                    <option value="saab">Thrissur District Co-operative Bank</option>
                                    <option value="volvo">Tumkur Grain Merchant's Co-operative Bank</option>
                                    <option value="saab">Thane Bharat Sahakari Bank</option>
                                    <option value="volvo">Banaskantha Mercantile Co-operative Bank</option>
                                    <option value="saab">Ankola Urban Co-operative Bank</option>
                                </select>
                                <select name="cars" id="cars">
                                    <option value="volvo">Select City</option>
                                    <option value="saab">Zoroastrian Co-operative Bank</option>
                                    <option value="volvo">Yes Bank</option>
                                    <option value="saab">Vasai Vikas Sahakari Bank</option>
                                    <option value="volvo">Vishweshwar Sahakari Bank</option>
                                    <option value="saab">Vijaya Bank</option>
                                    <option value="volvo">Vijay Co-operative Bank</option>
                                    <option value="saab">Vasai Janata Sahakari Bank</option>
                                    <option value="volvo">Varachha Co-operative Bank</option>
                                    <option value="saab">Utkarsh Small Finance Bank</option>
                                    <option value="volvo">Axis Bank</option>
                                    <option value="saab">United Bank of India</option>
                                    <option value="volvo">Urban Co-operative Bank Perinthalmanna</option>
                                    <option value="saab">Uttar Pradesh Co-operative Bank</option>
                                    <option value="volvo">United Overseas Bank</option>
                                    <option value="saab">UCO Bank</option>
                                    <option value="volvo">Union Bank of India</option>
                                    <option value="saab">Textile Traders Co-operative Bank</option>
                                    <option value="volvo">Satara Shakari Bank</option>
                                    <option value="saab">Telangana State Co-operative Apex Bank</option>
                                    <option value="volvo">Punjab State Co-operative Bank</option>
                                    <option value="saab">Nawanagar Co-operative Bank</option>
                                    <option value="volvo">The Malad Sahakari Bank</option>
                                    <option value="saab">Tamilnad Mercantile Bank</option>
                                    <option value="volvo">TJSB Sahakari Bank</option>
                                    <option value="saab">Thrissur District Co-operative Bank</option>
                                    <option value="volvo">Tumkur Grain Merchant's Co-operative Bank</option>
                                    <option value="saab">Thane Bharat Sahakari Bank</option>
                                    <option value="volvo">Banaskantha Mercantile Co-operative Bank</option>
                                    <option value="saab">Ankola Urban Co-operative Bank</option>
                                </select>
                                <select name="cars" id="cars">
                                    <option value="volvo">Select Branch</option>
                                    <option value="saab">Zoroastrian Co-operative Bank</option>
                                    <option value="volvo">Yes Bank</option>
                                    <option value="saab">Vasai Vikas Sahakari Bank</option>
                                    <option value="volvo">Vishweshwar Sahakari Bank</option>
                                    <option value="saab">Vijaya Bank</option>
                                    <option value="volvo">Vijay Co-operative Bank</option>
                                    <option value="saab">Vasai Janata Sahakari Bank</option>
                                    <option value="volvo">Varachha Co-operative Bank</option>
                                    <option value="saab">Utkarsh Small Finance Bank</option>
                                    <option value="volvo">Axis Bank</option>
                                    <option value="saab">United Bank of India</option>
                                    <option value="volvo">Urban Co-operative Bank Perinthalmanna</option>
                                    <option value="saab">Uttar Pradesh Co-operative Bank</option>
                                    <option value="volvo">United Overseas Bank</option>
                                    <option value="saab">UCO Bank</option>
                                    <option value="volvo">Union Bank of India</option>
                                    <option value="saab">Textile Traders Co-operative Bank</option>
                                    <option value="volvo">Satara Shakari Bank</option>
                                    <option value="saab">Telangana State Co-operative Apex Bank</option>
                                    <option value="volvo">Punjab State Co-operative Bank</option>
                                    <option value="saab">Nawanagar Co-operative Bank</option>
                                    <option value="volvo">The Malad Sahakari Bank</option>
                                    <option value="saab">Tamilnad Mercantile Bank</option>
                                    <option value="volvo">TJSB Sahakari Bank</option>
                                    <option value="saab">Thrissur District Co-operative Bank</option>
                                    <option value="volvo">Tumkur Grain Merchant's Co-operative Bank</option>
                                    <option value="saab">Thane Bharat Sahakari Bank</option>
                                    <option value="volvo">Banaskantha Mercantile Co-operative Bank</option>
                                    <option value="saab">Ankola Urban Co-operative Bank</option>
                                </select>
                            </div>
                            <div className="btm-name my-3">
                                <h4>Or Enter IFSC Code to know Bank details</h4>
                            </div>
                            <div className="form-group cutom_select">
                                <input type="text" name="ifsc_input" value="" id="ifsc_input" className="form-control text-uppercase" placeholder="Enter IFSC Code"></input>

                                <button className="ifsc_search_code" type="submit">Get Details</button>
                            </div>
                        </div>
                        <div className="ifsc-right ">
                            <div className="ifsc-right-cntn mb-4">
                                <strong>Popular Bank</strong>
                            </div>
                            <div className="logo-main flex">
                                <div className="ifsc-logos">
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ifsc-right-cntn my-4">
                                <strong>All Bank</strong>
                            </div>
                            <div className="logo-main">
                                <div className="ifsc-logos">
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="logo-main">
                                <div className="ifsc-logos">
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="logo-main">
                                <div className="ifsc-logos">
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="logo-main">
                                <div className="ifsc-logos">
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="logo-main">
                                <div className="ifsc-logos">
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                    <div className="first-logo">
                                        <img src="Images/yes-bank.png"></img>
                                        <div className="yes-text">
                                            <h6>Yes Bank</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-btm">
                <div className="container_box">
                    <div className="btm-content">
                        <h4 className="mb-4">What does the IFSC Code Stand for?</h4>
                        <p>IFSC is a short abbreviation of the term “Indian Financial System Code.” IFSC code is a standardized code facilitated by The Reserve Bank of India for identifying a particular branch of any bank. It is a unique, 11-character-long alphanumeric code that streamlines the online fund transaction by fetching any bank details.</p><br></br>
                        <p>With an IFSC code, it is possible to make any online transaction successful in India. As we know that NEFT (National Electronic Funds Transfer), IMPS (Immediate Payment Service), and RTGS (Real Time Gross Settlement) systems are three mainstream digital fund transfer systems in India that are frequently used by most millennials and Gen-Z. It is essential to learn more about IFSC.</p><br></br>
                        <h4 className="mb-4">Understand the Format of the IFSC Code</h4>
                        <p>IFSC code is an 11-character-long code. In this, the first 4-characters are alphabetic, depicting the bank code assigned by the government authority of India. The next character is zero, reserved for future usage. And the last 6-characters are usually numeric (can be alphabetic also), depicting the branch name of that particular bank.</p><br></br>
                        <p>For example- The IFSC code for Axis bank, Ambikapur, is UTIB0000041.</p><br></br>
                        <h4 className="mb-4">Perks of Using IFSC Code</h4>
                        <ul>
                            <li><FaCheckCircle />Quick & secure payment transfer</li>
                            <li><FaCheckCircle />Facilitates online banking or net banking operations</li>
                            <li><FaCheckCircle />Instant money transfer</li>
                            <li><FaCheckCircle />Error-free money transactions</li>
                            <li><FaCheckCircle />Instant transfer of funds via NEFT and RTGS</li>
                            <li><FaCheckCircle />Immediate payment validation and reconciliation</li>
                            <li><FaCheckCircle />Paperless cash transaction</li>
                        </ul>
                    </div>
                </div>
            </div>



        </Root>
    );
}

const Root = styled.section`
.ifsc-banner-section {
    background: ${(p) => p.theme.primary_bg};
    width:100%;
    padding:50px 25px;
    .banner-main {
        width:100%;
        .banner-left {
            width:70%;
            .cntn {
                width:100%;
                h4 {
                    font-size:32px;
                    font-weight:700;
                    color:#fff;
                    margin-bottom:20px;
                }
                button {
                    color:#000;
                    background:#fff;
                    width:140px;
                    border-radius:4px;
                    padding:10px;
                }
            }
        }
        .banner-right {
            .right-content {
                h2 {
                    font-size:62px;
                    font-weight:bold;
                    color:#fff;
                }
                h5 {
                    font-size:42px;
                    font-weight:900;
                    color:#000080;
                    margin-top:-20px;
                }
                p {
                    font-weight:400;
                    font-size:22px;
                    color:#fff;
                    margin-bottom: -28px;
                }
            }
        }
    }
}
.ifsc-main-section {
    width:100%;
    .ifsc-top {
        width:100%;
        background: ${(p) => p.theme.secandary_bg};
        display:flex;
        padding:30px 15px;
        .ifsc-left {
            width:40%;
            padding: 20px 17px;
            border-right: 2px solid #eee;
            .ifsc-left-cntn {
                width:100%;
                h4 {
                    font-size:24px;
                    font-weight:700;
                }
                p {
                    font-size:14px;
                    font-weight:400;
                }
                h5 {
                    font-size:20px;
                    font-weight:500;
                    margin-bottom:20px;
                }
                select {
                    margin-bottom: 10px;
                    padding: 15px;
                    height: auto;
                    width: 100%;
                    border-radius: 3px;
                    background-clip: padding-box;
                    border-color: #d9dbde;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    transition: .5s;
                    font-size: 12px;
                }
            }
            .btm-name {
                h4 {
                    font-size:20px;
                    font-weight:700;
                }
            }
            .cutom_select {
                display: flex;
                justify-content: space-between;
                .form-control {
                    width: 60%;
                    border: 1px solid #56ba4b;
                    padding:10px;
                }
                .ifsc_search_code {
                    background: #56ba4b;
                    color: #fff;
                    font-size: 16px;
                    border-radius: 5px;
                    padding: 10px;
                    box-shadow: none;
                    border: none;
                }
            }
        }
        .ifsc-right {
            width:60%;
            padding:20px 15px;
            .ifsc-right-cntn {

            }
            .ifsc-logos {
                width:100%;
                display:flex;
                gap:20px;
                justify-content:center;
                .first-logo {
                    width:20%;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    padding:20px;

                    img {
                        width:90px;
                    }
                    .yes-text {
                        text-align:center;
                        h6 {
                            font-size:12px;
                        }
                    }
                }
            }
        }
     }
}
.bg-btm {
    background: ${(p) => p.theme.secandary_bg};
    padding:40px 0;
    .btm-content {
        h4 {
            font-size:24px;
            font-weight:700;
        }
        p {
            font-size:14px;
            line-height:23px;

        }
        ul {
            li {
                font-size:14px;
                font-weight:500;
                display:flex;
                align-items:center;
                gap:10px;
                margin-bottom:10px;
            }
        }
    }
}

`