import React, { useRef, useState } from 'react'
import PrimaryButton from '../../styledComponent/Button/PrimaryButton';
import { useSelector } from 'react-redux';
import PhoneField from '../../styledComponent/Inputs/PhoneField';
import styled from 'styled-components';
export default function OtpScreen({handleClick}) {
    const [phoneNumber , setPhoneNumber] = useState('')
    const [activeScreen , setActiveScreen] = useState(1)
    const [buttonDisable , setButtonDisable] = useState(true)
    const [otp, setOtp] = useState(['', '', '', '','','']);
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null),useRef(null),useRef(null)];

    const { strings ,theme} = useSelector((s) => s.platformSetting);

    const handleChange = (index, e) => {
     if(e.target.value.length > 1){
       return
     }
     const newOtp = [...otp];
     newOtp[index] = e.target.value;
      if (e.target.value !== '' && index < otp.length - 1) {
        inputRefs[index + 1].current.focus();
      }
      setOtp(newOtp);
    };
  
    const handlePaste = (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData.getData('Text');
      if (pastedData.length === otp.length) {
        const newOtp = pastedData.split('').slice(0, otp.length);
        setOtp(newOtp);
      }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
          inputRefs[index - 1].current.focus();
        }
    };

  return (
    <Root theme={theme}>
    {activeScreen == 1 ? 
        <div> 
        <h3 className='font-medium mb-10' style={{color:theme.primary_txt}}>{strings.signUp.enter_number_to_otp}</h3>
        <PhoneField number={phoneNumber} handleChange={(number)=>{setPhoneNumber(number)}}/>
        <PrimaryButton title={strings.button.send_otp} handleClick={()=>{setActiveScreen(2)}} addClass={'mt-10'}/>
        </div>
    :
    <div > 
    <h3 className='font-medium' style={{color:theme.primary_txt}}>{strings.signUp.enter_opt_sent_to} {phoneNumber}</h3>
        <div className="flex items-center justify-center mt-8">
      {otp.map((value, index) => (
        <input
          key={index}
          ref={inputRefs[index]}
          type="number"
          maxLength={1}
          className="w-12 h-12 mx-1 text-2xl border border-gray-300 text-center focus:outline-none rounded-xl font-medium otp_input"
          value={value}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onPaste={handlePaste}
        />
      ))}
    </div>
        <PrimaryButton title={strings.button.continue} addClass={'mt-5'} disable={!otp.every((value) => value !== '')} handleClick={()=>{handleClick()}}/>
    </div>
    }
    </Root>
  )
}
const Root = styled.section`
 background-color: ${(p) => p.theme.secandary_bg};
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  .otp_input{
    color: ${(p) => p.theme.primary_txt};
    background-color:transparent;
  }
`