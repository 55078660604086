import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
export default function Footer() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const TrendingMutualFund = [
    {
      id: 1,
      link: "/link",
      name: "ICICI Prudential Technology Fund Direct Plan Growth",
    },
    {
      id: 2,
      link: "/link",
      name: "Tata Digital India Fund Direct Growth",
    },
    {
      id: 3,
      link: "/link",
      name: "Axis Bluechip Fund Growth",
    },
    {
      id: 4,
      link: "/link",
      name: "ICICI Prudential Technology Fund Growth",
    },
    {
      id: 5,
      link: "/link",
      name: "Aditya Birla Sun Life Tax Relief 96 Growth",
    },
    {
      id: 6,
      link: "/link",
      name: "Aditya Birla Sun Life Digital India Fund Direct Plan Growth",
    },
    {
      id: 7,
      link: "/link",
      name: "Quant Tax Plan Growth Option Direct Plan",
    },
    {
      id: 8,
      link: "/link",
      name: "Axis Long Term Equity Fund Growth",
    },
    {
      id: 9,
      link: "/link",
      name: "SBI Technology Opportunities Fund Direct Growth",
    },
  ];
  const topAmc = [
    {
      id: 1,
      link: "/link",
      name: "SBI Mutual Fund",
    },
    {
      id: 2,
      link: "/link",
      name: "Nippon India mutual fund",
    },
    {
      id: 3,
      link: "/link",
      name: "HDFC Mutual Fund",
    },
    {
      id: 4,
      link: "/link",
      name: "UTI mutual fund",
    },
    {
      id: 5,
      link: "/link",
      name: "Kotak Mahindra Mutual Fund",
    },
    {
      id: 6,
      link: "/link",
      name: "ICICI Prudential Mutual Fund",
    },
    {
      id: 7,
      link: "/link",
      name: "Axis mutual fund",
    },
    {
      id: 8,
      link: "/link",
      name: "Aditya Birla Mutual Fund",
    },
  ];
  const stockMarket = [
    {
      id: 1,
      link: "/link",
      name: "Stock Market Live",
    },
    {
      id: 2,
      link: "/link",
      name: "Yes Bank Share Price",
    },
    {
      id: 4,
      link: "/link",
      name: "SBI Share Price",
    },
    {
      id: 5,
      link: "/link",
      name: "IRCTC Share Price",
    },
    {
      id: 6,
      link: "/link",
      name: "ITC Share Price",
    },
    {
      id: 7,
      link: "/link",
      name: "TCS Share Price",
    },
    {
      id: 8,
      link: "/link",
      name: "Tata Motors Share Price",
    },
    {
      id: 9,
      link: "/link",
      name: "HDFC Bank Share Price",
    },
    {
      id: 10,
      link: "/link",
      name: "Infosys Share Price",
    },
    {
      id: 11,
      link: "/link",
      name: "Idea Share Price",
    },
  ];
  const bestFunds = [
    {
      id: 1,
      link: "/link",
      name: "Best Mutual Funds",
    },
    {
      id: 2,
      link: "/link",
      name: "Best Tax Savings Mutual Funds",
    },
    {
      id: 3,
      link: "/link",
      name: "Best Index Funds",
    },
    {
      id: 4,
      link: "/link",
      name: "Best Equity Mutual Funds",
    },
  ];
  return (
    <Root theme={theme} className="container_box">
    <hr className="mb-4"/>

      {/* <NavLink
        to="/terms-conditions"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        Terms and Conditions{" "}
      </NavLink>
      <NavLink
        to="/about-us"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        About us{" "}
      </NavLink>
      <NavLink
        to="/privacy-policy"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        Privacy and Policy
      </NavLink> */}

      <div className="top_bar">
        <div>
          <h4>Menu Links</h4>
          <div className="flex flex-col">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about-us">About</NavLink>
          <NavLink to="/">Contact</NavLink>
          <NavLink to="/">FAQ</NavLink>
          <NavLink to="/">Tax Glossary</NavLink>
          </div>
        </div>
        <div>
          <h4>Tools</h4>
          <div className="tool_box">
          <NavLink to="/">Income Tax Calculator</NavLink>
          <NavLink to="/">House Property Calculator</NavLink>
          <NavLink to="/">Rent Receipt Generator</NavLink>
          <NavLink to="/">Tax Saving Calculator</NavLink>
          <NavLink to="/">Simple Interest Calculator</NavLink>
          <NavLink to="/">Leave Encashment Calculator</NavLink>
          <NavLink to="/">80TTA Calculator</NavLink>
          <NavLink to="/">Compound Interest Calculator</NavLink>
          <NavLink to="/">Form 12BB</NavLink>
          <NavLink to="/">HRA Calculator</NavLink>
          <NavLink to="/">TDS Calculator</NavLink>
          <NavLink to="/">Calculator on Section 234F</NavLink>
          <NavLink to="/">SSY Calculator</NavLink>
          <NavLink to="/">Cryptocurrency Tax Calculator</NavLink>
          <NavLink to="/">80DD Calculator</NavLink>
          <NavLink to="/">IFSC Code Search</NavLink>
          <NavLink to="/">Gratuity Calculator</NavLink>
          <NavLink to="/">Transport Allowance Calculator</NavLink>
          <NavLink to="/">NSC Calculator</NavLink>
          <NavLink to="/">ITR Eligibility Checker</NavLink>
          <NavLink to="/">80D Calculator</NavLink>
          <NavLink to="/">80U Calculator</NavLink>
          <NavLink to="/">Old vs New Tax Slab Regime Calculator</NavLink>
          </div>
        </div>
        <div>
          <h4>Products</h4>
          <div className="flex flex-col">
          <NavLink to="/">File Income Tax Return</NavLink>
          <NavLink to="/">Upload Form 16</NavLink>
          <NavLink to="/">CA Assisted ITR Filing</NavLink>
          <NavLink to="/">Tax Planning Optimizer</NavLink>
          <NavLink to="/">Check ITR Refund Status</NavLink>
          <NavLink to="/">TDS Solution</NavLink>
          <NavLink to="/">Plan Tax & File ITR</NavLink>
          <NavLink to="/">NRI Taxes & ITR Filing</NavLink>
          <NavLink to="/">Tax Advisory Services</NavLink>
          </div>
        </div>

      </div>
      {/* <div className="my-5 quick_links">
        <h4>Trending Mutual Funds</h4>
        <hr />
        <div className="links mt-4">
          {TrendingMutualFund.map((item,ix) => {
            return <NavLink to="/terms-conditions" key={ix}>{item.name}</NavLink>;
          })}
        </div>
      </div>
      <div className="my-5 quick_links">
        <h4>Top AMC</h4>
        <hr />
        <div className="links mt-4">
          {topAmc.map((item,ix) => {
            return <NavLink to="/terms-conditions" key={ix}>{item.name}</NavLink>;
          })}
        </div>
      </div>
      <div className="my-5 quick_links">
        <h4>Stock Markets</h4>
        <hr />
        <div className="links mt-4">
          {stockMarket.map((item,ix) => {
            return <NavLink to="/terms-conditions" key={ix}>{item.name}</NavLink>;
          })}
        </div>
      </div>
      <div className="my-5 quick_links">
        <h4>Best Funds</h4>
        <hr />
        <div className="links mt-4">
          {bestFunds.map((item,ix) => {
            return <NavLink to="/terms-conditions" key={ix}>{item.name}</NavLink>;
          })}
        </div>
      </div> */}
      <div className="bottom_bar my-16 flex items-center justify-between">
        <h5>© 2024 . All Rights Reserved.</h5>
        <div>
          <NavLink
            to="/terms-conditions"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Terms and Conditions{" "}
          </NavLink>

          <NavLink
            to="/privacy-policy"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Privacy and Policy
          </NavLink>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.footer`
margin-top:40px;
  .quick_links {
    hr {
      margin-top: 6px;
      border-top: 2px solid ${(p) => p.theme.border_clr};
    }
  }
  .links {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    > a {
      border: 1px solid ${(p) => p.theme.primary_bg};
      background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.3)};
      padding: 4px 10px;
      border-radius: 4px;
    }
  }
  .top_bar{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
    >div:nth-child(1){
      grid-column-start:1;
      grid-column-end:2;
      @media (min-width:342px) and (max-width:768px)
      {
           /* text-align:center; */
      }
    }
    >div:nth-child(2){
      grid-column-start:2;
      grid-column-end:5;
      @media (min-width:342px) and (max-width:768px)
      {
           /* text-align:center; */
      }
    }
    >div:nth-child(3){
      grid-column-start:5;
      grid-column-end:6;
      @media (min-width:342px) and (max-width:768px)
      {
           /* /text-align:center; */
      }
    }
    @media (min-width:320px) and (max-width:768px)
    {
      display:block;
      grid-template-columns: none;
    }
  }
  .tool_box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media (min-width:342px) and (max-width:768px)
    {
      grid-template-columns:none;
    }
  }

`;
