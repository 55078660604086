import React, { useState } from "react";
import { FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { socialLoginAction } from "../../redux/profile/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../DialogBox/LoadingBox";

// Move Firebase initialization outside of the component

const firebaseConfig = {
  apiKey: "AIzaSyA2h_jFl9uKH9Uk7zZP5xtfkIZhRDjDxgo",
  authDomain: "octroi-505cf.firebaseapp.com",
  projectId: "octroi-505cf",
  storageBucket: "octroi-505cf.appspot.com",
  messagingSenderId: "902741854271",
  appId: "1:902741854271:web:fe8103172ec4dde50fd08a",
  measurementId: "G-FNPFHLSKRG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export default function SocialLogin() {
  const [loading, setLoading] = useState(false);
  const { strings, theme } = useSelector((state) => state.platformSetting);

  const dispatch = useDispatch();
  const navigator = useNavigate();

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken();
      console.log("User info: ", result);

      const data = {
        login_token: result._tokenResponse.idToken,
        type: "gmail",
      };

      handleSocialLoginApi(data);
    } catch (error) {
      console.error("Error during sign-in: ", error);
    }
  };

  const handleSocialLoginApi = (data) => {
    setLoading(true);
    dispatch(
      socialLoginAction(data, (response) => {
        console.log("response social", response);
        if (response.status === 200) {
          toast.success(strings.toast_message.login_success);
          navigator("/");
        }
        setLoading(false);
      })
    );
  };

  return (
    <Root theme={theme}>
      <LoadingBox open={loading} />
      <h5 className="or_tab">{strings.signUp.or_continue_with}</h5>
      <div className="action_buttons">
        <button onClick={handleGoogleLogin} className="social_login_btn">
          <FcGoogle />
        </button>
        <button onClick={() => {}} className="social_login_btn">
          <FaApple />
        </button>
      </div>
    </Root>
  );
}

const Root = styled.section`
  .or_tab {
    color: ${(p) => addOpacity(p.theme.primary_txt, 0.9)};
    font-weight: 500;
    text-align: center;
    position: relative;
    margin-top: 30px;
    &::after {
      content: "";
      background: linear-gradient(to right, #bdbdbd, #c1c1c100);
      width: 32%;
      height: 4px;
      display: block;
      top: 50%;
      right: 0px;
      position: absolute;
      transform: translateY(-50%);
    }
    &::before {
      content: "";
      background: linear-gradient(to left, #bdbdbd, #c1c1c100);
      width: 32%;
      height: 4px;
      display: block;
      top: 50%;
      left: 0px;
      position: absolute;
      transform: translateY(-50%);
    }
  }
  .action_buttons {
    display: flex;
    justify-content: center;
    gap: 14px;
    margin-top: 30px;
  }
  .social_login_btn {
    background: transparent;
    border: 2px solid ${(p) => p.theme.border_clr};
    border-radius: 12px;
    font-size: 32px;
    padding: 16px 20px !important;
    display: flex;
    cursor: pointer;
  }
`;
