import React, { useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import styled from "styled-components";
export default function ToolTip(title ,description) {
  const [infoBox , setInfoBox] = useState(false)
  return (
    <Root onClick={()=>setInfoBox(!infoBox)}>
      <IoInformationCircleOutline />
      {/* <div className="dialogue_box">
        <h2 className="title"> dialogue_box</h2>
        <p className="desc">description</p>
      </div> */}
    </Root>
  );
}
const Root = styled.button`
  width: 50px;
  position:relative;
  svg {
    font-size: 26px;
    cursor: pointer;
  }
`;
