import React from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useSelector } from "react-redux";
import InputField from "../../../../styledComponent/Inputs/InputField";

export default function ClauseSeventhProviso() {
  const { strings } = useSelector((s) => s.platformSetting);

  return (
    <div>
      <StyleFormBox>
        <h4>{strings.other_disclosures.clause_iv_seventh_proviso_to_139}</h4>
        <hr />

       

     
        <div className="flex_input my-3">
          <label>
            1.{" "}
            {
              strings.other_disclosures
                .total_tax_deducted_source_collected_at_source
            }
          </label>
          <InputField error={""} value={""} handleChange={() => {}} />
        </div>

        <div className="flex_input my-3">
          <label>
            2.{" "}
            {
              strings.other_disclosures
                .deposited_total_50_lakh_or_more
            }
          </label>
          <InputField error={""} value={""} handleChange={() => {}} />
        </div>
      </StyleFormBox>
    </div>
  );
}
