import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import Input from "../../../styledComponent/Inputs/Input";
import LoadingBox from "../../DialogBox/LoadingBox";
import {
  deleteDonationToResearchAssociationAction,
  editDonationToResearchAssociationAction,
  getDonationToResearchAssociationAction,
  saveDonationToResearchAssociationAction,
} from "../../../redux/itr/action";
import Select from "../../../styledComponent/Inputs/Select";
import { getCitiesByIdAction } from "../../../redux/platformSetting/action";
import { addOpacity } from "../../../utils/helpers";
import FormDialogBox from "../../DialogBox/FormDialogBox";
import { FaRegEdit } from "react-icons/fa";
export default function DonationsToRA() {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [formBox, setFormBox] = useState(false);
  const [citiesList, setCitiesList] = useState([]);
  const { strings, states, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [activeState, setActiveState] = useState(1);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    nameOfDonee: Yup.string().required(strings.validation_message.required),
    eligibleAmountOfDonation: Yup.number().required(strings.validation_message.required),
    city: Yup.string().required(strings.validation_message.required),
    state: Yup.string().required(strings.validation_message.required),
    pinCode: Yup.string().required(strings.validation_message.required)
      .matches(
            /^[1-9][0-9]{5}$/, strings.validation_message.invalid_pin
      ),
    panOfDonee: Yup.string()
      .matches(
        /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
        strings.validation_message.invalid_pan
      ),
  });
  const saveDonationsToRAApi = async (obj) => {
    setLoader(true);
    const { type, ...data } = obj; // Destructure type and remaining data
    const action =
      type === "edit"
        ? editDonationToResearchAssociationAction
        : saveDonationToResearchAssociationAction;
    dispatch(
      action({ itrId: ItrData.id, ...data }, (e) => {
        setLoader(false); // Ensure loader is stopped even if API fails
        if (e.status === 200) {
          if (type === "edit") {
            setData((prevData) =>
              prevData.map((item) =>
                item.id === obj.id ? { ...item, ...data } : item
              )
            );
          } else {
           const newData = {...data, id: e?.data?.id ?? ""};
            setData((prevData) => [...prevData, newData]);
          }
          toast.success(
            `Successfully ${type === "edit" ? "updated" : "added"} data`
          );
          setFormBox(false);
          setFormData({});
        } else {
          toast.error("Something went wrong");
        }
      })
    );
  };

  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };

  const deleteRecord = (id) => {
    setLoader(true);
    dispatch(
      deleteDonationToResearchAssociationAction({ id }, (e) => {
        if (e.status == 200) {
          setData(data.filter((i) => i.id !== id));
          toast.success("Record Deleted Successfully");
        }
        setLoader(false);
      })
    );
  };
  
  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    setLoader(true);
    dispatch(
      getDonationToResearchAssociationAction(ItrData.id, (e) => {
        if (e.status == 200) {
          setData(e.data.deductions);
        } else {
          toast.error("Something Went Wrong");
        }
        setLoader(false);
      })
    );
  }, [ItrData]);
  return (
    <div>
      <LoadingBox open={loader} />

      {data?.map((item, ix) => (
        <div
          className="flex gap-2 p-3 my-3 align-center"
          style={{
            background: addOpacity(theme.primary_bg, 0.2),
            borderRadius: "10px",
          }}
          key={ix}
        >
          <h4 className="mr-auto">{item?.nameOfDonee}</h4>
          <h4>{item?.amountOfDeduction}</h4>
          <div className="action_buttons">
            <button
              className="edit_btn"
              onClick={() => {
                setFormData({
                  ...item,
                  id: item?.id,
                  type: "edit",
                  city: item?.city?.id || item?.city,
                  state: item?.state?.id || item?.state,
                });
                setFormBox(!formBox);
              }}
            >
              <FaRegEdit />
            </button>
            <button
              className="delete_btn"
              onClick={() => deleteRecord(item.id)}
            >
              <RiDeleteBin6Fill />
            </button>
          </div>
        </div>
      ))}

      <button
        className="primary_button"
        style={{ flex: 0 }}
        onClick={() => {
          setFormBox(!formBox);
        }}
      >
        {strings.button.add}
      </button>

      <FormDialogBox
        open={formBox}
        formTitle={"Research associations (RA)"}
        handleClose={() => {
          setFormBox(false);
        }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            saveDonationsToRAApi(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues, setFieldValue }) => (
            <div>
              <StyleFormBox>
                <h4>Details of donations to research associations</h4>
                <p>
                  Deduction under sections 35(1)(ii) or 35(1)(iia) or 35(1)(iii)
                  or 35(2AA)
                </p>
                <hr className="my-4" />

                <div className="flex_input mt-2">
                  <label>{strings.tax_saving.name_of_donee} *</label>
                  <Input
                    placeholder={strings.placeholder.name_of_donee_organization}
                    name={"nameOfDonee"}
                    value={values.nameOfDonee}
                    error={errors.nameOfDonee}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input mt-2">
                  <label>{strings.address.address_line}</label>
                  <Input
                    placeholder={strings.placeholder.address_of_donee}
                    value={values.address}
                    error={errors.address}
                    name={"address"}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input my-2">
                  <label>{strings.address.state} *</label>
                  <Select
                    value={values.state}
                    error={errors.state}
                    name={"state"}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        state: e.target.value,
                        city: "",
                      });
                      setActiveState(Number(e.target.value));
                    }}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {states?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="flex_input my-2">
                  <label>{strings.address.city} *</label>
                  <Select
                    value={values.city}
                    error={errors.city}
                    name={"city"}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>
                    {citiesList?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="flex_input my-2">
                  <label>{strings.address.pincode} *</label>
                  <Input
                    type={"number"}
                    name={"pinCode"}
                    value={values?.pinCode}
                    error={errors?.pinCode}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input mt-2">
                  <label>{strings.tax_saving.pan_of_donee}</label>
                  <Input
                    placeholder={strings.placeholder.pan_of_donee_here}
                    value={values.panOfDonee}
                    name={"panOfDonee"}
                    error={errors.panOfDonee}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setFieldValue("panOfDonee", e.target.value.toUpperCase());
                      }
                    }}
                  />
                </div>

                <div className="flex_input mt-2">
                  <label>Donation in cash</label>
                  <Input
                    inputType={"currency"}
                    value={values.donationInCash}
                    name={"donationInCash"}
                    error={errors.donationInCash}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input mt-2">
                  <label>Donation in other mode </label>
                  <Input
                    inputType={"currency"}
                    value={values.donationInOtherMode}
                    name={"donationInOtherMode"}
                    error={errors.donationInOtherMode}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input mt-2">
                  <label>Total donation </label>
                  <Input
                    inputType={"currency"}
                    value={values.totalDonation}
                    name={"totalDonation"}
                    error={errors.totalDonation}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input mt-2">
                  <label>Eligible Amount of Donation* </label>
                  <Input
                    inputType={"currency"}
                    value={values.eligibleAmountOfDonation}
                    name={"eligibleAmountOfDonation"}
                    error={errors.eligibleAmountOfDonation}
                    onChange={handleChange}
                  />
                </div>
              </StyleFormBox>
              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
