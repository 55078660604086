import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCapitalGainAction,
  getCapitalGainAction,
} from "../../../../redux/itr/action";

import FormDialogBox from "../../../DialogBox/FormDialogBox";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import LoadingBox from "../../../DialogBox/LoadingBox";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";

import AddEquityFunds from "./AddEquityFunds";
import SaleOfLandBuilding from "./SaleOfLandBuilding";
import StockOptionsRsu from "./StockOptionsRsu";
import AddOtherAssets from "./AddOtherAssets";
import BondsDebentures from "./BondsDebentures";
import DeemedCaptialGains from "./DeemedCaptialGains";
import { addOpacity } from "../../../../utils/helpers";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import CompLoadingBox from "../../../DialogBox/CompLoadingBox";

export default function CapitalGainIncome() {
  const [capitalGainData, setCapitalGainData] = useState({});
  const [loader, setLoader] = useState(false);

  // Stocks & Mutual Funds
  const [equityBox, setEquityBox] = useState(false);

  // Sale of Land or Building
  const [saleLandBox, setSaleLandBox] = useState(false);

  // Stock Options & RSUs
  const [stockOptionBox, setStockOptionBox] = useState(false);

  // Bonds and Debentures
  const [bondsDebenturesBox, setBondsDebenturesBox] = useState(false);

  // Deemed Capital Gains
  const [deemedCapitalBox, setDeemedCaptialBox] = useState(false);

  // Any Other Assets
  const [otherAssetsBox, setOtherAssetsBox] = useState(false);

  const [editForm, setEditForm] = useState({
    saleOfLand: {},
    bondsAndDebenture: {},
    deemedCapitalGain: {},
    anyOtherAssets: {},
    stockOption: {},
    stock: {},
  });
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);

  const dispatch = useDispatch();

  const handleDeleteIncomeRecord = (id, type) => {
    let data = {
      itrId: ItrData.id,
      itemId: id,
      type: type,
    };

    console.log("handleDeleteIncomeRecord ==== data", data);

    setLoader(true);

    dispatch(
      deleteCapitalGainAction(data, (e) => {
        if (e.status === 200) {
          // let newData = { ...capitalGainData,capitalGain:{...capitalGainData.capitalGain ,[type] :capitalGainData.capitalGain[type].filter((item,ix)=> item.id !== id)  }};

          getCapitalGainApiData();
          toast.success("successfully delete the record ");
        } else {
          toast.error(e.message);
          setLoader(false);
        }
      })
    );
  };

  const getCapitalGainApiData = () => {
    setLoader(true);
    dispatch(
      getCapitalGainAction(ItrData.id, (data) => {
        if (data.status === 200) {
          setCapitalGainData(data.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
    );
  };

  const updateCapitalGainData = (type, data) => {
    getCapitalGainApiData();
    // console.log('updateData new update  =============>',data , capitalGainData)
    // let updateData  = {...capitalGainData , capitalGain : {...capitalGainData.capitalGain , [type] :[...capitalGainData.capitalGain , data] } }
    // setCapitalGainData(updateData)
  };

  useEffect(() => {
    getCapitalGainApiData();
  }, []);

  return (
    <div>
      {loader ? (
        <CompLoadingBox open={loader} />
      ) : (
        <AccordianBox
          title={strings.income.capital_gain_income}
          description={strings.income.stocks_mutual_funds_land_bonds_jewellery}
        >
          <div>
            <StyleFormBox>
              <h4>{strings.income.stocks_mutual_funds}</h4>
              <p>{strings.income.import_transactions_from_all_stocks}</p>

              {capitalGainData?.capitalGain?.stocks?.map((item, ix) => (
                <div
                  className="flex gap-2 p-3 my-3 align-center"
                  style={{
                    background: addOpacity(theme.primary_bg, 0.2),
                    borderRadius: "10px",
                  }}
                  key={ix}
                >
                  <h4 className="mr-auto">{item?.typeOfAssets?.name}</h4>

                  <h4>{item?.totalPurchasePrice}</h4>
                  <div className="action_buttons">
                    <button
                      className="edit_btn"
                      onClick={() => {
                        setEditForm({ ...editForm, stock: item });
                        setEquityBox(true);
                      }}
                    >
                      <FaRegEdit />
                    </button>
                    <button
                      className="delete_btn"
                      onClick={() =>
                        handleDeleteIncomeRecord(item.id, "stocks")
                      }
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  </div>
                </div>
              ))}

              <div className="text-center">
                <button className="add_item_button">
                  {strings.button.import_data}
                </button>
              </div>
              <button
                className="secandary_button"
                onClick={() => setEquityBox(true)}
              >
                {strings.button.add_manually}
              </button>
            </StyleFormBox>

            {/*===================================  Sale of Land or Building ===================================== */}
            <StyleFormBox>
              <div className="flex justify-between items-center">
                <div>
                  <h4>{strings.income.sale_of_land_building}</h4>
                  <p>
                    {
                      strings.income
                        .gains_for_sale_of_land_residential_commercial_buildings
                    }{" "}
                  </p>
                </div>
                <button
                  className="secandary_button"
                  style={{ width: "auto" }}
                  onClick={() => setSaleLandBox(true)}
                >
                  {strings.button.add_entry}
                </button>
              </div>

              {capitalGainData?.capitalGain?.saleOfLand?.map((item, ix) => (
                <div
                  className="flex gap-2 p-3 my-3 align-center"
                  style={{
                    background: addOpacity(theme.primary_bg, 0.2),
                    borderRadius: "10px",
                  }}
                  key={ix}
                >
                  <h4 className="mr-auto">{item?.descriptionOfAssets.substring(0, 50)}{item?.descriptionOfAssets.length > 50 ? "..." : ""}</h4>

                  <h4>{item?.totalPurchasePrice}</h4>
                  <div className="action_buttons">
                    <button
                      className="edit_btn"
                      onClick={() => {
                        setEditForm({ ...editForm, saleOfLand: item });
                        setSaleLandBox(true);
                      }}
                    >
                      <FaRegEdit />
                    </button>
                    <button
                      className="delete_btn"
                      onClick={() =>
                        handleDeleteIncomeRecord(item.id, "saleOfLand")
                      }
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  </div>
                </div>
              ))}
            </StyleFormBox>

            {/* ========================================== Stock Options & RSUs ========================================= */}
            <StyleFormBox>
              <div className="flex justify-between items-center">
                <div>
                  <h4>{strings.income.stock_options_rsu}</h4>
                  <p>
                    {
                      strings.income
                        .gains_from_sale_of_vested_restricted_stock_units
                    }
                  </p>
                </div>

                <button
                  className="secandary_button"
                  style={{ width: "auto" }}
                  onClick={() => setStockOptionBox(true)}
                >
                  {strings.button.add_entry}
                </button>
              </div>
              {capitalGainData?.capitalGain?.stockOptions?.map((item, ix) => (
                <div
                  className="flex gap-2 p-3 my-3 align-center"
                  style={{
                    background: addOpacity(theme.primary_bg, 0.2),
                    borderRadius: "10px",
                  }}
                  key={ix}
                >
                  <h4 className="mr-auto">{item?.descriptionOfAssets.substring(0, 50)}{item?.descriptionOfAssets.length > 50 ? "..." : ""}</h4>

                  <h4>{item?.totalPurchasePrice}</h4>
                  <div className="action_buttons">
                    <button
                      className="edit_btn"
                      onClick={() => {
                        setEditForm({ ...editForm, stockOption: item });
                        setStockOptionBox(true);
                      }}
                    >
                      <FaRegEdit />
                    </button>
                    <button
                      className="delete_btn"
                      onClick={() =>
                        handleDeleteIncomeRecord(item.id, "stocks")
                      }
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  </div>
                </div>
              ))}
            </StyleFormBox>

            {/*============================================ Bonds and Debentures ==================================== */}
            <StyleFormBox>
              <div className="flex justify-between items-center">
                <div>
                  <h4>{strings.income.bonds_and_debentures}</h4>
                  <p>
                    {
                      strings.income
                        .gains_loss_from_bonds_and_debentures_including_govt
                    }
                  </p>
                </div>
                <button
                  className="secandary_button"
                  style={{ width: "auto" }}
                  onClick={() => setBondsDebenturesBox(true)}
                >
                  {strings.button.add_entry}
                </button>
              </div>

              {capitalGainData?.capitalGain?.bondsAndDebenture.map(
                (item, index) => (
                  <div
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                    // key={ix}
                  >
                    <h4 className="mr-auto">{item?.typeOfBonds?.name}</h4>

                    <h4>{item?.totalPurchasePrice}</h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditForm({ ...editForm, bondsAndDebenture: item });
                          setBondsDebenturesBox(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDeleteIncomeRecord(item.id, "bondsAndDebenture")
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                )
              )}
            </StyleFormBox>

            {/* =========================================== Deemed Capital Gains ============================================================= */}
            <StyleFormBox>
              <div className="flex justify-between items-center">
                <div>
                  <h4>{strings.income.deemed_capitial_gains}</h4>
                  <p>
                    {
                      strings.income
                        .capital_gains_previously_exempted_subject_to_taxation
                    }
                  </p>
                </div>

                {capitalGainData?.capitalGain?.deemedCapitalGain
                  ?.shortTermEnabled === true ||
                capitalGainData?.capitalGain?.deemedCapitalGain
                  ?.longTermEnabled === true ? (
                  <div className="flex gap-2 action_buttons align-center">
                    <button
                      className="edit_btn"
                      style={{ width: "auto" }}
                      onClick={() => {
                        setEditForm({
                          ...editForm,
                          deemedCapitalGain:
                            capitalGainData?.capitalGain?.deemedCapitalGain,
                        });
                        setDeemedCaptialBox(true);
                      }}
                    >
                      <FaRegEdit />
                    </button>
                    <button
                      className="delete_btn"
                      style={{ width: "auto" }}
                      onClick={() => {
                        handleDeleteIncomeRecord(
                          capitalGainData?.capitalGain?.deemedCapitalGain.id,
                          "deemedCapitalGain"
                        )
                        setEditForm({
                          ...editForm,
                          deemedCapitalGain: {},
                        });
                      }}
                    >
                      <RiDeleteBin6Fill />
                    </button>
                  </div>
                ) : (
                  <button
                    className="secandary_button"
                    style={{ width: "auto" }}
                    onClick={() => setDeemedCaptialBox(true)}
                  >
                    {strings.button.add_entry}
                  </button>
                )}
              </div>
              {["longTerm", "shortTerm"].map(
                (term) =>
                  capitalGainData?.capitalGain?.deemedCapitalGain?.[term] && (
                    <div
                      key={term}
                      className="flex gap-2 p-3 my-3 align-center"
                      style={{
                        background: addOpacity(theme.primary_bg, 0.2),
                        borderRadius: "10px",
                      }}
                    >
                      <h4 className="mr-auto">
                        Total {term.charAt(0).toUpperCase() + term.slice(1)}{" "}
                        Deemed Capital Gain
                      </h4>
                      <h4>
                        {
                          capitalGainData.capitalGain.deemedCapitalGain[term]
                            .totalAmountDeemed
                        }
                      </h4>
                    </div>
                  )
              )}
            </StyleFormBox>

            {/* =========================================  Any Other Assets ============================================ */}
            <StyleFormBox>
              <div className="flex justify-between items-center">
                <div>
                  <h4>{strings.income.any_other_assets}</h4>
                  <p>
                    {
                      strings.income
                        .jewellery_paintings_sculptures_archaeological_collections
                    }
                  </p>
                </div>

                <button
                  className="secandary_button"
                  style={{ width: "auto" }}
                  onClick={() => setOtherAssetsBox(true)}
                >
                  {strings.button.add_entry}
                </button>
              </div>

              {capitalGainData?.capitalGain?.anyOtherAssets.map(
                (item, index) => (
                  <div
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                    // key={ix}
                  >
                    <h4 className="mr-auto">{item?.descriptionOfAssets.substring(0, 50)}{item?.descriptionOfAssets.length > 50 ? "..." : ""}</h4>

                    <h4>{item?.totalPurchasePrice}</h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditForm({ ...editForm, anyOtherAssets: item });
                          setOtherAssetsBox(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDeleteIncomeRecord(item.id, "anyOtherAssets")
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                )
              )}
            </StyleFormBox>

            {/* Form for Add Equity Funds */}
            <FormDialogBox
              open={equityBox}
              formTitle={strings.income.add_equity_shares_mutual_funds}
              handleClose={() => {
                setEquityBox(false);
                setEditForm({});
              }}
            >
              <AddEquityFunds
                handleCallback={() => {
                  setEquityBox(false);
                  getCapitalGainApiData();
                }}
                mutualFundsAssets={capitalGainData?.typeOfAssetsMutualFunds}
                stocksAssets={capitalGainData?.typeOfAssetsShare}
                editForm={editForm?.stock}
              />
            </FormDialogBox>

            {/* Form for Sale of Land or Building */}
            <FormDialogBox
              open={saleLandBox}
              formTitle={strings.income.sale_of_land_building}
              handleClose={() => {
                setSaleLandBox(false);
                setEditForm({});
              }}
            >
              <SaleOfLandBuilding
                handleCallback={(data) => {
                  updateCapitalGainData("saleOfLand", data);
                  setSaleLandBox(false);
                }}
                editForm={editForm?.saleOfLand}
              />
            </FormDialogBox>

            {/* Form For Stock Options and RSU */}
            <FormDialogBox
              open={stockOptionBox}
              formTitle={strings.income.stock_options_rsu}
              handleClose={() => {
                setStockOptionBox(false);
                setEditForm({});
              }}
            >
              <StockOptionsRsu
                handleCallback={(data) => {
                  updateCapitalGainData("stockOptions", data);
                  setStockOptionBox(false);
                }}
                stocksAssets={capitalGainData?.typeOfStockOptions}
                editForm={editForm?.stockOption}
              />
            </FormDialogBox>

            {/* Form For Bonds and Debentures */}
            <FormDialogBox
              open={bondsDebenturesBox}
              formTitle={strings.income.bonds_debentures}
              handleClose={() => {
                setBondsDebenturesBox(false);
                setEditForm({});
              }}
            >
              <BondsDebentures
                stocksAssets={capitalGainData?.typeOfDebentures}
                handleCallback={(data) => {
                  updateCapitalGainData("stockOptions", data);
                  setBondsDebenturesBox(false);
                }}
                editForm={editForm?.bondsAndDebenture}
              />
            </FormDialogBox>

            {/* Add Deemed Captial Gains Off */}
            <FormDialogBox
              open={deemedCapitalBox}
              formTitle={strings.income.deemed_capitial_gains}
              handleClose={() => {
                setDeemedCaptialBox(false);
                setEditForm({});
              }}
            >
              <DeemedCaptialGains
                previousYear={capitalGainData?.previousYear}
                handleCallback={(data) => {
                  updateCapitalGainData("stockOptions", data);
                  setDeemedCaptialBox(false);
                }}
                capitalGainDeductionSections={
                  capitalGainData?.capitalGainDeductionSections
                }
                editForm={editForm?.deemedCapitalGain}
              />
            </FormDialogBox>

            {/* Add Any Other Assets */}
            <FormDialogBox
              open={otherAssetsBox}
              formTitle={strings.income.add_other_assets}
              handleClose={() => {
                setOtherAssetsBox(false);
                setEditForm({});
              }}
            >
              <AddOtherAssets
                handleCallback={(data) => {
                  updateCapitalGainData("stockOptions", data);
                  setOtherAssetsBox(false);
                }}
                editForm={editForm?.anyOtherAssets}
              />
            </FormDialogBox>
          </div>
        </AccordianBox>
      )}
    </div>
  );
}
