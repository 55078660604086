
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export default function PrivateRoutes() {
  const [auth ,setAuth] = useState(true)
  const { userDetails } = useSelector((state) => state.profile);
  const token = localStorage.getItem('token');
  // useEffect(()=>{
  //   if(token && userDetails.id){
  //     setAuth(true)
  //   }else{
  //     setAuth(false)
  //   }
  // },[])
return (
    auth ? <Outlet/> : <Navigate to='/login'/>
  )
}
