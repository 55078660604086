import { takeLatest, put } from 'redux-saga/effects';
import { SET_PLATFORM_THEME, GET_USER_THEME, GET_ALL_STATES, SET_ALL_STATES, GET_CITIES_BY_ID, GET_CATEGORIES, SET_CATEGORIES, GET_LANGUAGES } from '../constant';
import { httpGet } from '../../utils/https';
import Urls from '../../utils/Urls';
import { toast } from 'react-toastify';
// import { changeTheme } from '../actions'; // Import changeTheme action if not already imported


// Function to update the Theme
function* changeThemeSaga(action) {
  // yield put(changeTheme(action.payload)); // Dispatch changeTheme action with new theme
}

function* getUserThemeSaga({payload}){
  // try {
  //   const res = yield httpGet(Urls.submitAnswer, payload.data);
  //   if(res.data.status === 200){
  //     payload.handleCallback(res.data);
  //   }
  // } catch (err) {
  //  if(err.data.message){
  //   toast.error(err.data.message)
  //  }
  //  payload.handleCallback(err.data);
  // }
}

function* getAllStatesSaga (){
 try{
    const res = yield httpGet(Urls.getStates)
    if(res.status == 200){
      yield put({ type: SET_ALL_STATES, payload: res.data.data.resp });
    }
  }catch(err){
    console.log('err----->',err)
  }
}

function* getCitiesById ({payload}){
  try{
    const res = yield httpGet(Urls.getCitiesById+payload.id)
    if(res.status == 200){
      payload.handleCallback(res.data.data.resp);
    }
  }catch(err){
    console.log('err----->',err)
  }
}

function* getCategoriesSaga (){
  try{
     const res = yield httpGet(Urls.getCategories)
     if(res.status == 200){
      yield put({ type: SET_CATEGORIES, payload: res.data.data.categories });
    }
   
   }catch(err){
     console.log('err----->',err)
   }
 }


function* getLanguages({payload}){
  try {
    const res = yield httpGet(Urls.getLanguages);
    if(res.data.status === 200){
      payload.handleCallback(res.data);
    }
  } catch (err) {
   if(err.data.message){
    toast.error(err.data.message)
   }
   payload.handleCallback(err.data);
  }
}
// Saga watcher
export default function* PlatformSettingData() {
  yield takeLatest(SET_PLATFORM_THEME, changeThemeSaga);
  yield takeLatest(GET_USER_THEME,getUserThemeSaga)
  yield takeLatest(GET_ALL_STATES,getAllStatesSaga)
  yield takeLatest(GET_CITIES_BY_ID,getCitiesById)
  yield takeLatest(GET_CATEGORIES,getCategoriesSaga)
  yield takeLatest(GET_LANGUAGES,getLanguages)
  // yield takeLatest(GET_LANGUAGES,submitQuestionSaga)
}
