// This is for profile page only  ---------------  
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { FaAngleDown } from "react-icons/fa6";
import styled from "styled-components";
export default function AccordianTab({ title, children }) {
    const [dropdownTab, setDropdownTab] = useState(true);
    const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme} >
    <div className="box_heading" onClick={() => {setDropdownTab(!dropdownTab)}} >
        <h4 style={{color:theme.primary_txt}}>{title}</h4>
        <button style={{color:theme.primary_txt}} className={dropdownTab == true ?'active':''}> <FaAngleDown/> </button>
    </div>
    {dropdownTab && <div className="box_section">{children}</div>}
    
  </Root>
  )
}
const Root = styled.section`
  margin-top: 8px;
  background-color: ${(p) => p.theme.secandary_bg};
  color:${(p) => p.theme.primary_txt};
  h4{
    font-size:18px;
    font-weight: 400;
  }
  .box_heading {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:18px;
    button {
      padding: 6px;
      font-size: 16px;
      transition:all 0.5s;
      &.active {
      transform: rotate(180deg);
    }
    }
  }
  .box_section{
    padding: 18px;
    border:1px solid ${(p) => p.theme.border_clr};
    border-radius:10px;
  }
`;
