import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { useSelector } from "react-redux";

export default function Taxestimator() {
    const {theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <div className="tax-estimator-section">
        <div className="container_box">
          <div className="tax-heading">
            <h4>Advance Tax Estimator</h4>
            <p>
              Use this tool to calculate your Income-tax liability and
              Advance-tax liability as well, for the Financial Year 2023-24
              (A.Y. 2024-25).
            </p>
          </div>
          <div className="tax-estimator-box">
            <div className="tax-estimator-content">
              <div className="form-main flex mb-5">
                <label className="same" for="fname">
                  Name *
                </label>
                <input type="text" id="fname" name="fname"></input>
                <label className="same" for="fname">
                  Date of Birth *
                </label>
                <input
                  type="date"
                  name="dob"
                  id="dob"
                  className="form-control notReset"
                  value=""
                  onchange="getSlab()"
                  required=""
                  data-parsley-id="0274"
                ></input>
              </div>
              <div className="form-main flex mb-5">
                <label className="same" for="fname">
                  Residential Status *
                </label>
                <select name="cars" id="cars">
                  <option value="volvo">--select--</option>
                  <option value="saab">Resident</option>
                  <option value="mercedes">Non Resident</option>
                  <option value="audi">Not ordinery resident</option>
                </select>
                <label className="same" for="fname">
                  Assessee Status *
                </label>
                <select name="cars" id="cars">
                  <option value="volvo">--select--</option>
                  <option value="saab"> as Individual</option>
                  <option value="mercedes"> as HUF</option>
                </select>
              </div>
              <div className="form-main flex mb-5">
                <label className="same" for="fname">
                  Regime Type *
                </label>
                <select name="cars" id="cars">
                  <option value="volvo">--select--</option>
                  <option value="saab"> Old Regime</option>
                  <option value="mercedes"> New Regime</option>
                </select>
                <label className="same" for="fname">
                  Email ID *
                </label>
                <input
                  type="email"
                  name="dob"
                  id="dob"
                  className="form-control notReset"
                  value=""
                  onchange="getSlab()"
                  required=""
                  data-parsley-id="0274"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  .tax-estimator-section {
    width: 100%;
    .tax-heading {
      h4 {
        font-size: 32px;
        font-weight: 700;
      }
    }
    .tax-estimator-box {
      width: 100%;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      background: ${(p) => p.theme.secandary_bg};
      padding: 40px 20px;
      margin: 40px 0;
      .tax-estimator-content {
        width: 100%;
        .form-main {
          width: 100%;
          align-items: center;
          justify-content: space-between;
          input {
            display: inline-block;
            font-size: 18px;
            width: 230px;
            border: none;
            outline: 0;
            text-align: left;
            padding: 10px;
            font-weight: 500;
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
              rgb(246, 246, 246, 0) 0px 0px 0px 3px;
          }
          select {
            display: inline-block;
            font-size: 18px;
            width: 230px;
            border: none;
            outline: 0;
            text-align: left;
            padding: 10px;
            font-weight: 500;
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
              rgb(246, 246, 246, 0) 0px 0px 0px 3px;
          }
          .same {
            width: 150px;
          }
        }
      }
    }
  }
`;
