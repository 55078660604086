import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import Input from "../../../styledComponent/Inputs/Input";
import LoadingBox from "../../DialogBox/LoadingBox";
import {
  getDeductionIOAAAction,
  saveDeductionIOAAAction,
} from "../../../redux/itr/action";
import { addOpacity } from "../../../utils/helpers";
import FormDialogBox from "../../DialogBox/FormDialogBox";
export default function DeductionIOAA() {
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [formBox, setFormBox] = useState(false);
  const [loader, setLoader] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [activeProfessionType, setActiveProfessionType] = useState(0);
  const [assessmentYear, setAssessmentYear] = useState([]);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    totalExpenses: Yup.number().required(strings.validation_message.required),
    grossRevenue: Yup.number().required(strings.validation_message.required),

    professionalIncome44ADA: Yup.array().of(
      Yup.object().shape({
        professionType: Yup.string().required(
          strings.validation_message.required
        ),
        natureOfProfession: Yup.string().required(
          strings.validation_message.required
        ),
        nameOfCompany: Yup.string().required(
          strings.validation_message.required
        ),
      })
    ),
  });

  const saveDeductionIOAAApi = async (value) => {
    setLoader(true);
    dispatch(
      saveDeductionIOAAAction(value, (e) => {
        if (e?.status == 200) {
          setFormBox(false);
          setData(e?.data[0]?.ioaaData);
          setTotalAmount(
            e?.data[0]?.ioaaData.reduce(
              (acc, item) => acc + item.amountOfDeduction,
              0
            )
          );
          toast.success("Data Saved Successfully");
        } else {
          toast.error("Something Went Wrong");
        }
        setLoader(false);
      })
    );
  };

  const getDeductionData = async () => {
    setLoader(true);
    dispatch(
      getDeductionIOAAAction(ItrData.id, (e) => {
        setAssessmentYear(e.data.assessmentYear);
        setTotalAmount(
          e.data.ioaaData.reduce((acc, item) => acc + item.amountOfDeduction, 0)
        );
        setData(e.data.ioaaData);
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    getDeductionData();
  }, []);

  console.log("formData====>", formData);
  return (
    <div>
      <LoadingBox open={loader} />

      {data?.length > 0 && (
        <div
          className="flex gap-2 p-3 my-3 align-center"
          style={{
            background: addOpacity(theme.primary_bg, 0.2),
            borderRadius: "10px",
          }}
        >
          <h4 className="mr-auto">Total Deductions under Section 10AA</h4>
          <h4>{totalAmount}</h4>
        </div>
      )}

      <button
        className="primary_button"
        style={{ flex: 0 }}
        onClick={() => {
          if (data?.length > 0) {
            setFormData({
              itrId: ItrData.id,
              ioaaData: data?.map((item) => {
                return {
                  assessmentYear: item.assessmentYear,
                  amountOfDeduction: item.amountOfDeduction,
                };
              }) || [
                {
                  assessmentYear: "",
                  amountOfDeduction: "",
                },
              ],
            });
          }
          setFormBox(!formBox);
        }}
      >
        {data.length > 0 ? strings.button.edit : strings.button.add}
      </button>

      <FormDialogBox
        open={formBox}
        formTitle={"Deduction under section 10AA"}
        handleClose={() => {
          setFormBox(false);
        }}
      >
        {/*  ------- Form ---------- */}
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            saveDeductionIOAAApi(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <div>
              <StyleFormBox>
                <h4>Deduction under section 10AA</h4>
                <p>
                  Note: If no entry is made in first row then other rows will
                  not be considered
                </p>
                <hr className="my-4" />
                <h4>
                  Deductions in respect of units located in Special Economic
                  Zone
                </h4>
                <FieldArray name="ioaaData">
                  {({ insert, remove, push }) => (
                    <StyleFormBox>
                      {values?.ioaaData?.map((item, index) => (
                        <div key={index}>
                          <StyleFormBox>
                            <button
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                            <div className="flex_input my-2 items-center">
                              <label>
                                Assessment year in which unit begins to
                                manufacture produce/ provide services
                              </label>
                              <select
                                name={`ioaaData.${index}.assessmentYear`}
                                value={item.assessmentYear}
                                onChange={handleChange}
                              >
                                <option disabled value="">
                                  {strings.label.choose_option}
                                </option>
                                {assessmentYear?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="flex_input my-2 items-center">
                              <label>Amount of deduction</label>
                              <Input
                                name={`ioaaData.${index}.amountOfDeduction`}
                                inputType={"currency"}
                                value={item.amountOfDeduction}
                                onChange={handleChange}
                              />
                            </div>
                          </StyleFormBox>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({ assessmentYear: "", amountOfDeduction: "" });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </StyleFormBox>
                  )}
                </FieldArray>
                <hr />
                <div className="flex_input my-2 ">
                  <h4>Total Deductions under Section 10AA</h4>
                  <h4 className="" style={{ flex: 0 }}>
                    {values?.ioaaData?.reduce(
                      (a, i) => a + i.amountOfDeduction,
                      0
                    )}
                  </h4>
                </div>
              </StyleFormBox>
              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
