import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";

export default function Resources() {
  const {theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <div className="insight-banner-main">
        <div className="conatiner_box">
          <div className="insight-cntn">
            <h2>INSIGHT</h2>
          </div>
        </div>
      </div>
      <div className="insight-btm-main">
        <div className="container_box">
          <div className="flex sec-main">
            <div className="insight-left">
              <div className="insight-left-card flex">
                <div className="insight-left top-img">
                  <div className="card-img-lable">
                    <span className="tag-img">Capital Markets</span>
                  </div>
                  <div className="insight-img-mxs">
                    <img
                      className="img-fluid"
                      src="Images/insights-sec-latest-releases-insider-trading-climate-risks-600x375.jpg"
                    ></img>
                  </div>
                </div>
                <div className="insight-right-cntn">
                  <h5>March 29, 2024</h5>
                  <br></br>
                  <p>
                    From Insider Trading to Climate Risks: SEC’s Latest Releases
                    Affecting Corporate Reporting
                  </p>
                  <br></br>
                  <a href="#">READ MORE</a>
                  <hr className="btm-line"></hr>
                </div>
              </div>
              <div className="btm-card-mains">
                <div className="btm-first-card">
                  <div className="card-img-lable">
                    <span className="tag-img">Capital Markets</span>
                  </div>
                  <div className="btm-card-img">
                    <img className="img-fluid" src="Images/btm-card.jpg"></img>
                  </div>
                  <div className="btm-card-cntn">
                    <span>March 29, 2024</span>
                    <p>
                      SEC Share Repurchase Disclosure Rule Hits Judicial Wall:
                      What’s Next?
                    </p>
                  </div>
                </div>
                <div className="btm-first-card">
                  <div className="card-img-lable">
                    <span className="tag-img">Capital Markets</span>
                  </div>
                  <div className="btm-card-img">
                    <img className="img-fluid" src="Images/btm-card.jpg"></img>
                  </div>
                  <div className="btm-card-cntn">
                    <span>March 29, 2024</span>
                    <p>
                      SEC Share Repurchase Disclosure Rule Hits Judicial Wall:
                      What’s Next?
                    </p>
                  </div>
                </div>
              </div>
              <div className="btm-card-mains">
                <div className="btm-first-card">
                  <div className="card-img-lable">
                    <span className="tag-img">Capital Markets</span>
                  </div>
                  <div className="btm-card-img">
                    <img className="img-fluid" src="Images/btm-card.jpg"></img>
                  </div>
                  <div className="btm-card-cntn">
                    <span>March 29, 2024</span>
                    <p>
                      SEC Share Repurchase Disclosure Rule Hits Judicial Wall:
                      What’s Next?
                    </p>
                  </div>
                </div>
                <div className="btm-first-card">
                  <div className="card-img-lable">
                    <span className="tag-img">Capital Markets</span>
                  </div>
                  <div className="btm-card-img">
                    <img className="img-fluid" src="Images/btm-card.jpg"></img>
                  </div>
                  <div className="btm-card-cntn">
                    <span>March 29, 2024</span>
                    <p>
                      SEC Share Repurchase Disclosure Rule Hits Judicial Wall:
                      What’s Next?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Insight-right">
              <div className="first-right-box">
                <div className="first-box-content">
                  <div className="insight-right-heading">
                    <h4>SEARCH INSIGHTS</h4>
                  </div>
                  <div className="input-filed">
                    <input
                      type="text"
                      placeholder="Enter Your Email Address"
                    ></input>
                    <button type="text">
                      <span className="icon-search">
                        <FaSearch />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="first-right-box mt-5">
                <div className="first-box-content">
                  <div className="insight-right-heading">
                    <h4>NEWSLETTER</h4>
                  </div>
                  <div className="menus">
                    <ul>
                      <li>
                        <a href="#">Beyond the Numbers</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Cause & Connect</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Commercial Construction Index</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Construction Advisor</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Consumer & Industrial Insights</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Contractor Compensation Quarterly</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Deal 360</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">ESOP Advisor</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Food & Beverage Advisor</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">JOLTS Analysis</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Commercial Construction Index</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Construction Advisor</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Consumer & Industrial Insights</a>
                      </li>
                      <hr></hr>
                      <li>
                        <a href="#">Contractor Compensation Quarterly</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  .insight-banner-main {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../Images/banner-feature-19.jpg);
    background-size: cover;
    background-rpeat: no-rpeat;
    padding: 120px 0;
    .insight-cntn {
      h2 {
        text-align: center;
        font-weight: 700;
        color: #fff;
        font-size: 42px;
      }
    }
  }
  .insight-btm-main {
    width: 100%;
    margin-top: 50px;
    .insight-left {
      width: 70%;

      .insight-left-card {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      .insight-right-cntn {
        padding: 20px 15px;
        h5 {
          font-size: 20px;
          color: grey;
          font-weight: 700;
        }
        p {
          font-size: 16px;
        }
        a {
          color: #0099a9;
          font-weight: 600;
        }
        hr.btm-line {
          margin-top: 20px;
        }
      }

      .top-img {
        position: relative;
        display: block;
        overflow: hidden;
        .card-img-lable {
          position: absolute;
          top: 1.25em;
          left: 1.25em;
          z-index: 1;
          max-width: calc(100% - 2.55em);
          .tag-img {
            font-size: 0.65em;
            display: inline-block;
            margin-bottom: 0.4545em;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            padding: 0.4545em 1.3636em;
            border-radius: 3px;
            background-color: #0099a9;
            color: #fff;
          }
        }
      }
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    .btm-card-mains {
      width: 100%;
      margin-top: 50px;
      gap: 20px;
      display: flex;
      .btm-first-card {
        width: 50%;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        .btm-card-cntn {
          padding: 12px 6px;
          span {
            color: grey;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            font-weight: 700;
          }
        }
        .card-img-lable {
          position: absolute;
          top: 1.25em;
          left: 1.25em;
          z-index: 1;
          max-width: calc(100% - 2.55em);
          .tag-img {
            font-size: 0.65em;
            display: inline-block;
            margin-bottom: 0.4545em;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            padding: 0.4545em 1.3636em;
            border-radius: 3px;
            background-color: #0099a9;
            color: #fff;
          }
        }
        @media (max-width: 768px) {
          width: 80%;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
    .sec-main {
      gap: 20px;
      @media (max-width: 991px) {
        flex-direction: column !important;
      }
    }
    .Insight-right {
      width: 30%;
      .first-right-box {
        width: 100%;
        padding: 40px 15px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background: ${(p) => p.theme.secandary_bg};
        .first-box-content {
          width: 100%;
          .insight-right-heading {
            width: 100%;
            h4 {
              font-size: 28px;
              font-weight: 500;
              margin-bottom: 20px;
            }
          }
          .input-filed > input {
            background: #cde6ef;
            border: 1px solid #fafafa;
            padding: 10px 20px;
            max-width: 262px;
            font-size: 14px;
            width: 100%;
          }
          .input-filed {
            position: relative;
            margin-top: 10px;
            button {
              font-size: 1.25em;
              position: absolute;
              bottom: -4px;
              right: 60px;
              height: 2.4em;
              text-align: center;
              color: #0099a9;
              cursor: pointer;
              @media (max-width: 991px) {
                left: 224px;
              }
            }
          }
        }
        .menus {
          ul {
            display: block;
            list-style-type: none;
            li {
              margin-bottom: 10px;
              a {
                font-size: 18px;
              }
            }
          }
        }
      }
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }
`;
