import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import Input from "../../../styledComponent/Inputs/Input";
import LoadingBox from "../../DialogBox/LoadingBox";
import {
  getDeduction80PAction,
  saveDeduction80PAction,
  saveIncome44ADA,
} from "../../../redux/itr/action";
import Select from "../../../styledComponent/Inputs/Select";
import AccordianBox from "../../../styledComponent/Form/AccordianBox";
import FormDialogBox from "../../DialogBox/FormDialogBox";
import { addOpacity } from "../../../utils/helpers";
export default function Section80P({ handleCallback, formsData, optionData }) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [formBox, setFormBox] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    totalExpenses: Yup.number().required(strings.validation_message.required),
    grossRevenue: Yup.number().required(strings.validation_message.required),

    professionalIncome44ADA: Yup.array().of(
      Yup.object().shape({
        professionType: Yup.string().required(
          strings.validation_message.required
        ),
        natureOfProfession: Yup.string().required(
          strings.validation_message.required
        ),
        nameOfCompany: Yup.string().required(
          strings.validation_message.required
        ),
      })
    ),
  });

  const saveSection80PApi = async (value) => {
    setLoader(true);
    dispatch(
      saveDeduction80PAction({ itrId: ItrData.id, ...value }, (e) => {
        if (e.status == 200) {
          updateEditKeys(e.data);
          setFormBox(false);
          setLoader(false);
          toast.success('Successfully saved Data');
        } else {
          toast.error('Something went wrong');
          setLoader(false);
        }
      })
    );
  };

  const removeSpecialKey = (data) => ({
    deduction80P: Object.fromEntries(
      Object.entries(data.deduction80P)
        .filter(([_, value]) => value.natureBusinessCode)
        .map(([key, { eligibleAmount, income, natureBusinessCode }]) => [
          key,
          { eligibleAmount, income, natureBusinessCode },
        ])
    ),
  });

  const updateEditKeys = (data) => {
    const baseData = {
      bankingCreditFacilities: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title: "Sec.8OP(2)(a)(i) Banking/Credit Facilities to its members",
      },
      cottageIndustry: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title: "Sec.8OP(2)(a)(ii) Cottage Industry",
      },
      marketingOfAgricultural: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title:
          "Sec.8OP(2)(a)(iii) Marketing of Agricultural produce grown by its members",
      },
      purchaseOfAgricultural: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title:
          "Sec.8OP(2)(a)(iv) Purchase of Agricultural Implements, seeds, live stocks or other...",
      },
      processingWithoutAidAgricultural: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title:
          "Sec.80P(2)(a)(v) Processing , without the aid of power, of the agricultural Produce...",
      },
      collectiveDisposalOfLabour: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title: "Sec.8OP(2)(a)(vi) Collective disposal of Labour of its members",
      },
      fishingOrAlliedActivities: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title:
          "Sec.8OP(2)(a)(vii) Fishing or allied activities for the purpose of supplying...",
      },
      primaryCooperativeSociety: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title:
          "Sec.8OP(2)(b)Primary cooperative society engaged in supplying Milk, oilseeds...",
      },
      consumerCooperativeSociety: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title:
          "Sec.8OP(2)(c)(i)Consumer Cooperative Society Other than specified in 8OP...",
      },
      otherCooperativeSociety: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title:
          "Sec.8OP(2)(c)(ii)Other Cooperative Society engaged in activities Other than...",
      },
      interestDividend: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title:
          "Sec.8OP(2)(d)Interest / Dividend from Investment in other co-operative society",
      },
      incomeFromLettingGodowns: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title:
          "Sec.8OP(2)(e)Income from Letting of godowns / warehouses for storage...",
      },
      otherIncome: {
        natureBusinessCode: "",
        income: "",
        eligibleAmount: "",
        title: "Sec.8OP(2)(f) others",
      },
    };
    if (data?.id) {
      const updatedDeduction80P = Object.keys(baseData).reduce((acc, key) => {
        acc[key] = {
          ...baseData[key],
          natureBusinessCode:
            data[key]?.natureBusinessCode?.id ||
            data[key]?.natureBusinessCode ||
            "",
          income: data[key]?.income || "",
          eligibleAmount: data[key]?.eligibleAmount || "",
        };
        return acc;
      }, {});

      setFormData({
        itrId: ItrData.id,
        deduction80P: updatedDeduction80P,
      });
    } else {
      setFormData({
        itrId: ItrData.id,
        deduction80P: baseData,
      });
    }
  };

  const getDeduction80DataAPI = () => {
    setLoader(true);

    dispatch(
      getDeduction80PAction(ItrData.id, (e) => {
        console.log("getDeduction80Action =========>", e.data);
        if (e.status == 200) {
          setCategoryData(e.data.category);
          setData(e.data.deduction80P);
        } else {
          toast.error(e.message);
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    getDeduction80DataAPI();
  }, []);

  console.log("formData ===>", formData);
  return (
    <div>
      <LoadingBox open={loader} />

      {data?.id && (
        <div
          className="my-3"
          style={{
            background: addOpacity(theme.primary_bg, 0.2),
            borderRadius: "10px",
          }}
        >
          <div className="flex gap-2 p-3 align-center">
            <h4 className="mr-auto">Income</h4>
            <h4>{data.totalIncome}</h4>
          </div>
          <div className="flex gap-2 p-3 align-center">
            <h4 className="mr-auto">Amount eligible for deduction</h4>
            <h4>{data.totalEligibleAmount}</h4>
          </div>
        </div>
      )}

      <button
        className="primary_button"
        style={{ flex: 0 }}
        onClick={() => {
          updateEditKeys(data);
          setFormBox(!formBox);
        }}
      >
        {data ? strings.button.edit : strings.button.add}
      </button>

      {/* Deduction under section 80P */}
      <FormDialogBox
        open={formBox}
        formTitle={"Deduction under section 80P"}
        handleClose={() => {
          setFormBox(false);
        }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            saveSection80PApi(removeSpecialKey(values));
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <div>
              {Object.entries(values?.deduction80P || {}).map(
                ([key, section]) => (
                  <AccordianBox
                    title={section?.title}
                    initial={false}
                    key={key}
                  >
                    {/* Nature of Business Code */}
                    <div className="flex_input my-2 items-center">
                      <label htmlFor={`${key}-natureBusinessCode`}>
                        Nature of Business Code (Only in relation to
                        Co-operative societies Activities)*
                      </label>
                      <Select
                        id={`${key}-natureBusinessCode`}
                        name={`deduction80P.${key}.natureBusinessCode`}
                        value={
                          values?.deduction80P[key]?.natureBusinessCode || ""
                        }
                        onChange={handleChange}
                      >
                        <option value="">{strings.label.choose_option}</option>
                        {categoryData?.map((i, ix) => (
                          <option value={i.id} key={ix}>
                            {i.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                    {/* Income */}
                    <div className="flex_input my-2 items-center">
                      <label htmlFor={`${key}-income`}>Income*</label>
                      <Input
                        id={`${key}-income`}
                        inputType="currency"
                        name={`deduction80P.${key}.income`}
                        value={values?.deduction80P[key]?.income || ""}
                        error={errors?.deduction80P?.[key]?.income}
                        onChange={handleChange}
                      />
                    </div>

                    {/* Amount Eligible for Deduction */}
                    <div className="flex_input my-2 items-center">
                      <label htmlFor={`${key}-eligibleAmount`}>
                        Amount eligible for deduction*
                      </label>
                      <Input
                        id={`${key}-eligibleAmount`}
                        inputType="currency"
                        name={`deduction80P.${key}.eligibleAmount`}
                        value={values?.deduction80P[key]?.eligibleAmount || ""}
                        error={errors?.deduction80P?.[key]?.eligibleAmount}
                        onChange={handleChange}
                      />
                    </div>
                  </AccordianBox>
                )
              )}

              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
