import styled from "styled-components";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
export default function TaxOptimizer() {
    const {theme } = useSelector((s) => s.platformSetting);
    return (
        <Root theme={theme}>
            <div className="banner-section">
                <div className="container_box">
                    <div className="banner-main flex items-center">
                        <div className="banner-left-content">
                            <div className="banner-left-heading">
                                <span>India's first</span>
                                <h2>Tax Planning Optimizer</h2>
                                <h6>Helps Salaried Employees Save Tax Beyond Section 80C</h6>
                            </div>
                            <div className="content-main">
                                <div className="content">
                                    <p><FaRegCheckCircle />Tax Savings Without Spending Money</p>
                                    <p><FaRegCheckCircle />Reduce Tax Liability up to Rs. 2 Lakh</p>
                                    <p><FaRegCheckCircle />Increases Take Home Salary</p>
                                </div>
                                <div className="btm-btn">
                                    <button className="btn" type="button">Save Tax Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="banner-right-img">
                            <img src="Images/banner.png"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tax-saved-section">
                <div className="container_box flex justify-center zuro">
                    <div className="tax-saved-box">
                        <div className="flex justify-center box-icon">
                            <div className="first-card">
                                <div className="icon-box">
                                    <img src="Images/tax-saved.png"></img>
                                </div>
                                <div className="content-tax">
                                    <p>Tax Saved 100 Cr+</p>
                                </div>
                            </div>
                            <div className="first-card">
                                <div className="icon-box">
                                    <img src="Images/tax-saved.png"></img>
                                </div>
                                <div className="content-tax">
                                    <p>Trusted by 1+ Million Users</p>
                                </div>
                            </div>
                            <div className="first-card">
                                <div className="icon-box">
                                    <img src="Images/tax-saved.png"></img>
                                </div>
                                <div className="content-tax">
                                    <p>4.8 Google User Rating</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="planning-optimizer-section">
                <div className="container_box">
                    <div className="optimizer-content-main">
                        <div className="optimizer-cntn">
                            <h5>What Is Tax Saving & Planning Optimizer?</h5>
                            <p>Tax Planning Optimizer is an effective tool that helps you maximize your tax savings. You can plan your investments and save taxes using recommendations provided by the tool. For instance, if you are still determining the most suitable tax regime, it will suggest the most tax-effective tax regime based on your income and investments. This tool will also analyze the additional deductions available to obtain tax relief and get a higher in-hand salary.</p>
                        </div>
                        <div className="optimizer-cntn">
                            <h5>What Is The Purpose Of Using Tax Saving & Planning Optimizer?</h5>
                            <p>This product aims to reduce tax liability by taking advantage of all the income tax laws, provisions, and deductions applicable to your situation.</p>
                        </div>
                        <div className="optimizer-cntn">
                            <h5>How Does Tax Saving & Planning Optimizer Work?</h5>
                            <p>The tool understands your tax situation by asking simple questions regarding your income, savings, insurance premium amount, etc. Later, it analyses all the income tax rules applicable to your case that can help you save maximum taxes. The tool does the following -</p><br></br>
                            <p><span>Income Tax Calculation:</span> Suggest a suitable tax regime and provide all the tax calculations so you can know how much you need to pay.</p><br></br>
                            <p><span>Tax Investment Options:</span> Shares numerous options of tax saving instruments in detail that helps you in making a smart decision.</p><br></br>
                            <p>Is your tax situation a little complicated, and don’t know which tax-saving investment option to choose? Worry Not! Book a Tax Consultation and get all your tax-related problems answered.</p><br></br>
                            <p><span>Savings without Spending:</span> Reduce tax burden by suggesting tax saving with no investments or tax investment options with the available liquidity</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="benefits-tax-section">
                <div className="container_box">
                    <div className="benefits-main">
                        <div className="benefits-left">
                            <h4>Explore Your Tax Saving Potential Beyond 80C</h4>
                            <p>One of the most popular tax-saving options is Section 80C, and generally, everyone is aware of this section; still, in addition to this section, there are other tax-saving deductions also available that you may avail and save taxes.</p><br></br>
                            <p>Using our tool, you can identify various tax savings options apart from Section 80C and a customized report for your case.</p>
                            <button type="button">Save Tax Now</button>
                        </div>
                        <div className="benefits-right">
                            <h4>Benefits Of Using <span>Tax Saving & Planning Optimizer</span></h4>
                            <div className="benefits-btm-content">
                                <p>There are numerous benefits of tax planning optimizer, some of which are:</p>
                                <ul>
                                    <li><IoMdCheckmarkCircleOutline />Helps you save taxes throughout the year</li>
                                    <li><IoMdCheckmarkCircleOutline />Authentic and accurate report on tax saving</li>
                                    <li><IoMdCheckmarkCircleOutline />Saves you from the last-minute manual scramble at the end of the year to save taxes on your income</li>
                                    <li><IoMdCheckmarkCircleOutline />You can get recommendations to restructure your CTC for more savings</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tax-save-banner">
                <div className="tax-save-main">
                    <div className="container_box flex">
                        <div className="tax-save-left">
                            <img src="Images/sts.png"></img>
                        </div>
                        <div className="tax-save-right">
                            <div className="savetax-content">
                                <h2>Start Tax Saving Now</h2>
                                <p>Questions? Visit the Support Center, or get in touch</p>
                            </div>
                            <div className="save-tax-btn">
                                <button type="button">Get Started</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </Root>
    );
}

const Root = styled.section`
.banner-section {
    width:100%;
    margin-bottom:40px;
    .banner-main {
        width:100%;
    .banner-left-content {
        width:60%;
        .banner-left-heading {
            width:100%;
            span {
                color:#000;
                background:#fff;
                padding:8px 4px;
                border-radius:4px;
            }
            h2 {
                font-size:42px;
                font-weight:700;
                margin:15px 0;
            }
            h6 {
                font-size:20px;
                font-weight:700;
                margin-bottom:15px;
            }
        }
        .content-main {
            width:100%;
            .content {
                p {
                    font-size:16px;
                    font-weight:400;
                    display:flex;
                    align-items:center;
                    gap:10px;
                    margin-bottom:10px;
                }
            }
            .btm-btn {
                .btn {
                    width:210px;
                    padding:15px 7px;
                    background-color:#FFA53B;
                    color:#fff;
                    border-radius:8px;
                }
            }
        }
        @media (max-width:768px) {
            width:80%;
        }
    }
    .banner-right-img {
        width:40%;
        img {
            width:100%;
        }
    }
    @media (max-width:768px) {
        flex-direction:column;
        gap:40px;
    }

  }
}
.tax-saved-section {
    width:100%;
    margin-bottom:40px;
    .tax-saved-box {
        .box-icon {
           width:920px;
           box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
           background: ${(p) => p.theme.secandary_bg};
           padding:40px 15px;
           border-radius:10px;
          .first-card {
              width:30%;
              display:grid;
              justify-content:center;
                .icon-box {
                  text-align:center;
                  display:block;
                   margin:0 auto;
                   img {
                    width:40px;
                    }
            }
            .content-tax {
                width:100%;
                p{
                    font-size:18px;
                    font-weight:500;
                }
            }
        }
           @media (max-width:1024px) {
               flex-direction:column;
              align-items:center;
              margin:0 auto;
              width:auto;
            }
        
      } 
            @media (max-width:1024px) {
              width:100%;
             text-align:center;
            }
    }
    
 
}
.planning-optimizer-section {
    background: ${(p) => p.theme.secandary_bg};
    .optimizer-content-main {
        width:100%;
        .optimizer-cntn {
            width:100%;
            margin-bottom:20px;
            h5 {
                font-size:25px;
                font-weight:700;
            }
            p {
                font-size:16px;
                font-weight:400;
                span {
                    font-weight:600;
                }
            }
        }
    }
}
.benefits-tax-section {
    width:100%;
    margin: 50px 0;
    .benefits-main {
        display:flex;
        width:100%;
        .benefits-left {
            width:50%;
            padding: 15px 7px;
            h4 {
                font-size:28px;
                font-weight:700;
                margin-bottom:20px;
            }
            p {
                font-size:16px;
                font-weight:400;
            }
            button {
                width:160px;
                padding:15px 7px;
                background:#FFA53B;
                color:#fff;
                border-radius:10px;
                margin-top:20px;
            }
            @media (max-width:991px) {
                width:100%;
            }
        }
        .benefits-right {
            width:50%;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            padding:20px 10px;
            background: ${(p) => p.theme.secandary_bg};
            border-radius:12px;
            h4 {
                font-weight:700;
                font-size:28px;
                span {
                    color: ${(p) => p.theme.primary_bg};;
                }
            }
            .benefits-btm-content {
                p {
                    font-size:16px;
                    font-weight:400;
                }
                 li {
                    border-bottom: 1px dashed rgba(0, 0, 0, .24);
                    padding: 16px 0 16px 5px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }
            @media (max-width:991px) {
                width:100%;
            }
        }
        @media (max-width:991px) {
            flex-direction:column;
        }
    }
}
.tax-save-banner {
    width:100%;
    background-color:#FFA53B;
    .tax-save-main {
        width:100%;
        .tax-save-left {
            width:40%;
            img {
                width:100%;
                @media (max-width:991px) {
                    display:none;
                }
            }
            @media (max-width:991px) {
                width:0;
            }
            
        }
        .tax-save-right {
            width:60%;
            .savetax-content {
                width:100%;
                margin-top: 80px;
                h2 {
                    font-size:42px;
                    font-weight:700;
                    color:#fff;
                }
                p {
                    color:#fff;
                    font-size:18px;
                    font-weight:400;
                }
                @media (max-width:991px) {
                    margin-top:0px;
                }
            }
            .save-tax-btn {
                margin-top:10px;
                button {
                    width: 140px;
                    background: #fff;
                    padding: 12px;
                    border-radius: 10px;
                }
            }
            @media (max-width:991px) {
                width:100%;
            }
        }
    }
    
}

`