import React, { useEffect, useState } from "react";
import Input from "../../../../styledComponent/Inputs/Input";
import { FieldArray, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getScheduleALAction,
  saveScheduleALAction,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import { addOpacity, removeEmptyKeys } from "../../../../utils/helpers";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Select from "../../../../styledComponent/Inputs/Select";

export default function ScheduleAL1() {
  const [formData, setFormData] = useState({});
  const [formBox, setFormBox] = useState(false);
  const [loader, setLoader] = useState(false);
  const [optionData, setOptionData] = useState({});
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const handleFormSubmit = (obj) => {
    setLoader(true);
    delete obj.id;
    dispatch(
      saveScheduleALAction({ itrId: ItrData.id, ...obj }, (e) => {
        if (e.status == 200) {
          let data = e.data;
          setFormData({
            scheduleAL_id: data?.id || "",
            scheduleAL_A: data.scheduleAL_A,
            scheduleAL_B: data.scheduleAL_B,
            scheduleAL_C: data.scheduleAL_C,
            scheduleAL_D: data.scheduleAL_D,
            scheduleAL_E: data.scheduleAL_E,
            scheduleAL_F: data.scheduleAL_F,
            scheduleAL_G: data.scheduleAL_G,
            scheduleAL_H: data.scheduleAL_H,
            scheduleAL_I: data.scheduleAL_I,
            scheduleAL_J: data.scheduleAL_J,
          });
          toast.success(`Successfully Added data`);
          setFormBox(false);
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const getScheduleALData = () => {
    setLoader(true);
    dispatch(
      getScheduleALAction(ItrData.id, (e) => {
        if (e.status == 200) {
          let data = e.data;
          setOptionData({
            assetType: data.assetType,
            assetUsePurpose: data.assetUsePurpose,
            equityShare: data.equityShare,
            listedOrUnlisted: data.listedOrUnlisted,
            purposeOfUseA: data.purposeOfUseA,
            purposeOfUseB: data.purposeOfUseB,
            typeOfSecurities: data.typeOfSecurities,
            typeOfVehicle: data.typeOfVehicle,
            vehicleUsePurpose: data.vehicleUsePurpose,
          });
          setFormData({
            scheduleAL_id: data?.id || "",
            scheduleAL_A:
              data.scheduleAL_A.map((item) => ({
                address: item.address,
                pincode: item.pincode,
                dateOfAcquisition: item.dateOfAcquisition,
                costOfAcquisition: item.costOfAcquisition,
                purposeOfUse: item.purposeOfUse,
              })) || [],
            scheduleAL_B:
              data.scheduleAL_B.map((item) => ({
                address: item.address,
                pincode: item.pincode,
                dateOfAcquisition: item.dateOfAcquisition,
                costOfAcquisition: item.costOfAcquisition,
                purposeOfUse: item.purposeOfUse,
              })) || [],
            scheduleAL_C:
              data.scheduleAL_C.map((item) => ({
                openingBalShares: item.openingBalShares,
                openingBalShareType: item.openingBalShareType,
                openingBalSharecost: item.openingBalSharecost,
                acquiredShares: item.acquiredShares,
                acquiredShareType: item.acquiredShareType,
                purchasePricePerShare: item.purchasePricePerShare,
                transferredShares: item.transferredShares,
                transferredShareType: item.transferredShareType,
                transferredShareSalePrice: item.transferredShareSalePrice,
                closingBalShares: item.closingBalShares,
                closingBalShareType: item.closingBalShareType,
                closingBalShareAcquisitionCost:
                  item.closingBalShareAcquisitionCost,
              })) || [],

            scheduleAL_D:
              data.scheduleAL_D.map((item) => ({
                nameOfCompany: item.nameOfCompany,
                panOfCompany: item.panOfCompany,
                openingBalShares: item.openingBalShares,
                openingBalShareAcquisitionCost:
                  item.openingBalShareAcquisitionCost,
                acquiredShares: item.acquiredShares,
                dateOfPurchase: item.dateOfPurchase,
                faceValuePerShare: item.faceValuePerShare,
                issueValuePerShare: item.issueValuePerShare,
                purchasePricePerShare: item.purchasePricePerShare,
                transferredShares: item.transferredShares,
                transferredShareSalePrice: item.transferredShareSalePrice,
                closingBalShares: item.closingBalShares,
                closingBalShareAcquisitionCost:
                  item.closingBalShareAcquisitionCost,
              })) || [],

            scheduleAL_E:
              data.scheduleAL_E.map((item) => ({
                openingBalSecurity: item.openingBalSecurity,
                openingBalSecurityAcquisitionCost:
                  item.openingBalSecurityAcquisitionCost,
                acquiredSecurity: item.acquiredSecurity,
                dateOfPurchase: item.dateOfPurchase,
                faceValuePerSecurity: item.faceValuePerSecurity,
                issueValuePerSecurity: item.issueValuePerSecurity,
                purchasePricePerSecurity: item.purchasePricePerSecurity,
                transferredSecurity: item.transferredSecurity,
                transferredSecuritySalePrice: item.transferredSecuritySalePrice,
                closingBalSecurity: item.closingBalSecurity,
                closingBalSecurityAcquisitionCost:
                  item.closingBalSecurityAcquisitionCost,
                typeOfSecurities: item.typeOfSecurities,
                listedOrUnlisted: item.listedOrUnlisted,
              })) || [],

            scheduleAL_F:
              data.scheduleAL_F.map((item) => ({
                nameOfEntity: item.nameOfEntity,
                pan: item.pan,
                amountContributed: item.amountContributed,
                amountWithdrawn: item.amountWithdrawn,
                openingBalance: item.openingBalance,
                profitOrLoss: item.profitOrLoss,
                closingBalance: item.closingBalance,
              })) || [],

            scheduleAL_G:
              data.scheduleAL_G.map((item) => ({
                nameOfPerson: item.nameOfPerson,
                pan: item.pan,
                openingBalance: item.openingBalance,
                amountReceived: item.amountReceived,
                amountPaid: item.amountPaid,
                interestCredited: item.interestCredited,
                closingBalance: item.closingBalance,
                rateOfInterest: item.rateOfInterest,
              })) || [],

            scheduleAL_H:
              data.scheduleAL_H.map((item) => ({
                typeOfVehicle: item.typeOfVehicle,
                registrationNumber: item.registrationNumber,
                purposeOfUse: item.purposeOfUse,
                costOfAcquisition: item.costOfAcquisition,
                dateOfAcquisition: item.dateOfAcquisition,
              })) || [],

            scheduleAL_I:
              data.scheduleAL_I.map((item) => ({
                typeOfAsset: item.typeOfAsset,
                quantity: item.quantity,
                purposeOfUse: item.purposeOfUse,
                costOfAcquisition: item.costOfAcquisition,
                dateOfAcquisition: item.dateOfAcquisition,
              })) || [],

            scheduleAL_J:
              data.scheduleAL_J.map((item) => ({
                nameOfPerson: item.nameOfPerson,
                pan: item.pan,
                openingBalance: item.openingBalance,
                amountReceived: item.amountReceived,
                amountPaid: item.amountPaid,
                interestCredited: item.interestCredited,
                closingBalance: item.closingBalance,
                rateOfInterest: item.rateOfInterest,
              })) || [],
          });
        } else {
          toast.error(e.message);
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    getScheduleALData();
  }, []);
  console.log("optionData", optionData, formData);
  return (
    <div>
      <LoadingBox open={loader} />
      <div>
        <button
          className="primary_button mt-4"
          onClick={() => setFormBox(true)}
        >
          {formData.scheduleAL_id > 0 ? "Add" : "Edit"} Schedule AL-1
        </button>
      </div>

      <FormDialogBox
        open={formBox}
        formTitle={"Schedule AL-1 Details"}
        handleClose={() => {
          setFormBox(false);
        }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            handleFormSubmit(removeEmptyKeys(values));
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <div className="mt-4">
              {/* A: Details of building or land appurtenant there to, or both, being a residential house */}
              <FieldArray name="scheduleAL_A">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">
                      A: Details of building or land appurtenant there to, or
                      both, being a residential house
                    </h4>

                    {values?.scheduleAL_A?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Address</label>
                              <Input
                                value={item.address}
                                name={`scheduleAL_A.${index}.address`}
                                error={
                                  errors?.scheduleAL_A &&
                                  errors?.scheduleAL_A[index]?.address
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Pincode</label>
                              <Input
                                value={item.pincode}
                                name={`scheduleAL_A.${index}.pincode`}
                                error={
                                  errors?.scheduleAL_A &&
                                  errors?.scheduleAL_A[index]?.pincode
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Data of Acquisition</label>
                              <Input
                                inputType={"date"}
                                value={item.dateOfAcquisition}
                                name={`scheduleAL_A.${index}.dateOfAcquisition`}
                                error={
                                  errors?.scheduleAL_A &&
                                  errors?.scheduleAL_A[index]?.dateOfAcquisition
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Cost of Aquisition</label>
                              <Input
                                value={item.costOfAcquisition}
                                name={`scheduleAL_A.${index}.costOfAcquisition`}
                                error={
                                  errors?.scheduleAL_A &&
                                  errors?.scheduleAL_A[index]?.costOfAcquisition
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Purpose of Use</label>

                              <Select
                                value={item.purposeOfUse}
                                name={`scheduleAL_A.${index}.purposeOfUse`}
                                error={
                                  errors?.scheduleAL_A &&
                                  errors?.scheduleAL_A[index]?.purposeOfUse
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.purposeOfUseA?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            address: "",
                            pincode: "",
                            dateOfAcquisition: "",
                            costOfAcquisition: "",
                            purposeOfUse: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* B: Details of land or building or both not being in the nature of residential house */}
              <FieldArray name="scheduleAL_B">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">
                      B: Details of land or building or both not being in the
                      nature of residential house
                    </h4>

                    {values?.scheduleAL_B?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Address</label>
                              <Input
                                value={item.address}
                                name={`scheduleAL_B.${index}.address`}
                                error={
                                  errors?.scheduleAL_B &&
                                  errors?.scheduleAL_B[index]?.address
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Pincode</label>
                              <Input
                                value={item.pincode}
                                name={`scheduleAL_B.${index}.pincode`}
                                error={
                                  errors?.scheduleAL_B &&
                                  errors?.scheduleAL_B[index]?.pincode
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Data of Acquisition</label>
                              <Input
                                inputType={"date"}
                                value={item.dateOfAcquisition}
                                name={`scheduleAL_B.${index}.dateOfAcquisition`}
                                error={
                                  errors?.scheduleAL_B &&
                                  errors?.scheduleAL_B[index]?.dateOfAcquisition
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Cost of Aquisition</label>
                              <Input
                                value={item.costOfAcquisition}
                                name={`scheduleAL_B.${index}.costOfAcquisition`}
                                error={
                                  errors?.scheduleAL_B &&
                                  errors?.scheduleAL_B[index]?.costOfAcquisition
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Purpose of Use</label>

                              <Select
                                value={item.purposeOfUse}
                                name={`scheduleAL_B.${index}.purposeOfUse`}
                                error={
                                  errors?.scheduleAL_B &&
                                  errors?.scheduleAL_B[index]?.purposeOfUse
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.purposeOfUseB?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            address: "",
                            pincode: "",
                            dateOfAcquisition: "",
                            costOfAcquisition: "",
                            purposeOfUse: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* C: Details of listed equity shares */}
              <FieldArray name="scheduleAL_C">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">C: Details of listed equity shares</h4>
                    {values?.scheduleAL_C?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Opening Bal Shares</label>
                              <Input
                                value={item.openingBalShares}
                                name={`scheduleAL_C.${index}.openingBalShares`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]?.openingBalShares
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Opening Bal Share Type</label>
                              <Select
                                value={item.openingBalShareType}
                                name={`scheduleAL_C.${index}.openingBalShareType`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]
                                    ?.openingBalShareType
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.equityShare?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>

                            <div>
                              <label>Opening Bal Shares Cost</label>
                              <Input
                                value={item.openingBalSharecost}
                                name={`scheduleAL_C.${index}.openingBalSharecost`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]
                                    ?.openingBalSharecost
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Acquired Shares</label>
                              <Input
                                value={item.acquiredShares}
                                name={`scheduleAL_C.${index}.acquiredShares`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]?.acquiredShares
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Acquired Share Type</label>

                              <Select
                                value={item.acquiredShareType}
                                name={`scheduleAL_C.${index}.acquiredShareType`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]?.acquiredShareType
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.equityShare?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>

                            <div>
                              <label>Purchase Price Per Share</label>
                              <Input
                                value={item.purchasePricePerShare}
                                name={`scheduleAL_C.${index}.purchasePricePerShare`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]
                                    ?.purchasePricePerShare
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Transferred Shares</label>
                              <Input
                                value={item.transferredShares}
                                name={`scheduleAL_C.${index}.transferredShares`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]?.transferredShares
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Transferred Shares Type</label>

                              <Select
                                value={item.transferredShareType}
                                name={`scheduleAL_C.${index}.transferredShareType`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]
                                    ?.transferredShareType
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.equityShare?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                            <div>
                              <label>Transferred Shares Sale Price</label>
                              <Input
                                value={item.transferredShareSalePrice}
                                name={`scheduleAL_C.${index}.transferredShareSalePrice`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]
                                    ?.transferredShareSalePrice
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Closing Bal Shares</label>
                              <Input
                                value={item.closingBalShares}
                                name={`scheduleAL_C.${index}.closingBalShares`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]?.closingBalShares
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Closing Bal Share Type</label>

                              <Select
                                value={item.closingBalShareType}
                                name={`scheduleAL_C.${index}.closingBalShareType`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]
                                    ?.closingBalShareType
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.equityShare?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                            <div>
                              <label>Closing Bal Shares Acquisition Cost</label>
                              <Input
                                value={item.closingBalShareAcquisitionCost}
                                name={`scheduleAL_C.${index}.closingBalShareAcquisitionCost`}
                                error={
                                  errors?.scheduleAL_C &&
                                  errors?.scheduleAL_C[index]
                                    ?.closingBalShareAcquisitionCost
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            openingBalShares: "",
                            openingBalShareType: "",
                            openingBalSharecost: "",
                            acquiredShares: "",
                            acquiredShareType: "",
                            purchasePricePerShare: 55,
                            transferredShares: 55,
                            transferredShareType: 800,
                            transferredShareSalePrice: 55,
                            closingBalShares: 55,
                            closingBalShareType: 800,
                            closingBalShareAcquisitionCost: 55,
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* D: Details of unlisted equity shares */}
              <FieldArray name="scheduleAL_D">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">
                      D: Details of unlisted equity shares
                    </h4>
                    {values?.scheduleAL_D?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Name of Company</label>
                              <Input
                                value={item.nameOfCompany}
                                name={`scheduleAL_D.${index}.nameOfCompany`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]?.nameOfCompany
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Pan of Company</label>
                              <Input
                                value={item.panOfCompany}
                                name={`scheduleAL_D.${index}.panOfCompany`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]?.panOfCompany
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Opening Bal Shares</label>
                              <Input
                                value={item.openingBalShares}
                                name={`scheduleAL_D.${index}.openingBalShares`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]?.openingBalShares
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Opening Bal Shares Acquisition Cost</label>
                              <Input
                                value={item.openingBalShareAcquisitionCost}
                                name={`scheduleAL_D.${index}.openingBalShareAcquisitionCost`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]
                                    ?.openingBalShareAcquisitionCost
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Acquired Share</label>
                              <Input
                                value={item.acquiredShares}
                                name={`scheduleAL_D.${index}.acquiredShares`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]?.acquiredShares
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Date Of Purchase</label>
                              <Input
                                inputType={"date"}
                                value={item.dateOfPurchase}
                                name={`scheduleAL_D.${index}.dateOfPurchase`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]?.dateOfPurchase
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Face Value Per Share</label>
                              <Input
                                value={item.faceValuePerShare}
                                name={`scheduleAL_D.${index}.faceValuePerShare`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]?.faceValuePerShare
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Issue Value Per Share</label>
                              <Input
                                value={item.issueValuePerShare}
                                name={`scheduleAL_D.${index}.issueValuePerShare`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]
                                    ?.issueValuePerShare
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Purchase Price Per Share</label>
                              <Input
                                value={item.purchasePricePerShare}
                                name={`scheduleAL_D.${index}.purchasePricePerShare`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]
                                    ?.purchasePricePerShare
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Transferred Shares</label>
                              <Input
                                value={item.transferredShares}
                                name={`scheduleAL_D.${index}.transferredShares`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]?.transferredShares
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Transferred Shares Sale Price</label>
                              <Input
                                value={item.transferredShareSalePrice}
                                name={`scheduleAL_D.${index}.transferredShareSalePrice`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]
                                    ?.transferredShareSalePrice
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Closing Bal Shares</label>
                              <Input
                                value={item.closingBalShares}
                                name={`scheduleAL_D.${index}.closingBalShares`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]?.closingBalShares
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Closing Bal Shares Acquisition Cost</label>
                              <Input
                                value={item.closingBalShareAcquisitionCost}
                                name={`scheduleAL_D.${index}.closingBalShareAcquisitionCost`}
                                error={
                                  errors?.scheduleAL_D &&
                                  errors?.scheduleAL_D[index]
                                    ?.closingBalShareAcquisitionCost
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            nameOfCompany: "",
                            panOfCompany: "",
                            openingBalShares: "",
                            openingBalShareAcquisitionCost: "",
                            acquiredShares: "",
                            dateOfPurchase: "",
                            faceValuePerShare: "",
                            issueValuePerShare: "",
                            purchasePricePerShare: "",
                            transferredShares: "",
                            transferredShareSalePrice: "",
                            closingBalShares: "",
                            closingBalShareAcquisitionCost: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* E: Details of other securities */}
              <FieldArray name="scheduleAL_E">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">E: Details of other securities</h4>
                    {values?.scheduleAL_E?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Opening Bal Security</label>
                              <Input
                                value={item.openingBalSecurity}
                                name={`scheduleAL_E.${index}.openingBalSecurity`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]
                                    ?.openingBalSecurity
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>
                                Opening Bal Security Acquisition Cost
                              </label>
                              <Input
                                value={item.openingBalSecurityAcquisitionCost}
                                name={`scheduleAL_E.${index}.openingBalSecurityAcquisitionCost`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]
                                    ?.openingBalSecurityAcquisitionCost
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Acquired Security</label>
                              <Input
                                value={item.acquiredSecurity}
                                name={`scheduleAL_E.${index}.acquiredSecurity`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]?.acquiredSecurity
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Date Of Purchase</label>
                              <Input
                                inputType={"date"}
                                value={item.dateOfPurchase}
                                name={`scheduleAL_E.${index}.dateOfPurchase`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]?.dateOfPurchase
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Face Value Per Security</label>
                              <Input
                                value={item.faceValuePerSecurity}
                                name={`scheduleAL_E.${index}.faceValuePerSecurity`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]
                                    ?.faceValuePerSecurity
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Issue Value Per Security</label>
                              <Input
                                value={item.issueValuePerSecurity}
                                name={`scheduleAL_E.${index}.issueValuePerSecurity`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]
                                    ?.issueValuePerSecurity
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Purchase Price Per Security</label>
                              <Input
                                value={item.purchasePricePerSecurity}
                                name={`scheduleAL_E.${index}.purchasePricePerSecurity`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]
                                    ?.purchasePricePerSecurity
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Transferred Security</label>
                              <Input
                                value={item.transferredSecurity}
                                name={`scheduleAL_E.${index}.transferredSecurity`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]
                                    ?.transferredSecurity
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Transferred Security Sale Price</label>
                              <Input
                                value={item.transferredSecuritySalePrice}
                                name={`scheduleAL_E.${index}.transferredSecuritySalePrice`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]
                                    ?.transferredSecuritySalePrice
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Closing Bal Security</label>
                              <Input
                                value={item.closingBalSecurity}
                                name={`scheduleAL_E.${index}.closingBalSecurity`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]
                                    ?.closingBalSecurity
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>
                                Closing Bal Security Acquisition Cost
                              </label>
                              <Input
                                value={item.closingBalSecurityAcquisitionCost}
                                name={`scheduleAL_E.${index}.closingBalSecurityAcquisitionCost`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]
                                    ?.closingBalSecurityAcquisitionCost
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Type Of Securities</label>
                              <Select
                                value={item.typeOfSecurities}
                                name={`scheduleAL_E.${index}.typeOfSecurities`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]?.typeOfSecurities
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.typeOfSecurities?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Listed Or Unlisted</label>

                              <Select
                                value={item.listedOrUnlisted}
                                name={`scheduleAL_E.${index}.listedOrUnlisted`}
                                error={
                                  errors?.scheduleAL_E &&
                                  errors?.scheduleAL_E[index]?.listedOrUnlisted
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.listedOrUnlisted?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            openingBalSecurity: "",
                            openingBalSecurityAcquisitionCost: "",
                            acquiredSecurity: "",
                            dateOfPurchase: "",
                            faceValuePerSecurity: "",
                            issueValuePerSecurity: "",
                            purchasePricePerSecurity: "",
                            transferredSecurity: "",
                            transferredSecuritySalePrice: "",
                            closingBalSecurity: "",
                            closingBalSecurityAcquisitionCost: "",
                            typeOfSecurities: "",
                            listedOrUnlisted: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* F: Details of capital contribution to any other entity */}

              <FieldArray name="scheduleAL_F">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">
                      F: Details of capital contribution to any other entity
                    </h4>

                    {values?.scheduleAL_F?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Name Of Entity</label>
                              <Input
                                value={item.nameOfEntity}
                                name={`scheduleAL_F.${index}.nameOfEntity`}
                                error={
                                  errors?.scheduleAL_F &&
                                  errors?.scheduleAL_F[index]?.nameOfEntity
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Pan</label>
                              <Input
                                value={item.pan}
                                name={`scheduleAL_F.${index}.pan`}
                                error={
                                  errors?.scheduleAL_F &&
                                  errors?.scheduleAL_F[index]?.pan
                                }
                                onChange={(e) => {
                                  if (e.target.value.length <= 10) {
                                    setFieldValue(`scheduleAL_F.${index}.pan`, e.target.value.toUpperCase());
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>Amount Contributed</label>
                              <Input
                                value={item.amountContributed}
                                name={`scheduleAL_F.${index}.amountContributed`}
                                error={
                                  errors?.scheduleAL_F &&
                                  errors?.scheduleAL_F[index]?.amountContributed
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Amount Withdrawn</label>
                              <Input
                                value={item.amountWithdrawn}
                                name={`scheduleAL_F.${index}.amountWithdrawn`}
                                error={
                                  errors?.scheduleAL_F &&
                                  errors?.scheduleAL_F[index]?.amountWithdrawn
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Opening Balance</label>
                              <Input
                                value={item.openingBalance}
                                name={`scheduleAL_F.${index}.openingBalance`}
                                error={
                                  errors?.scheduleAL_F &&
                                  errors?.scheduleAL_F[index]?.openingBalance
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Profit Or Loss</label>
                              <Input
                                value={item.profitOrLoss}
                                name={`scheduleAL_F.${index}.profitOrLoss`}
                                error={
                                  errors?.scheduleAL_F &&
                                  errors?.scheduleAL_F[index]?.profitOrLoss
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Closing Balance</label>
                              <Input
                                value={item.closingBalance}
                                name={`scheduleAL_F.${index}.closingBalance`}
                                error={
                                  errors?.scheduleAL_F &&
                                  errors?.scheduleAL_F[index]?.closingBalance
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            nameOfEntity: "",
                            pan: "",
                            amountContributed: "",
                            amountWithdrawn: "",
                            openingBalance: "",
                            profitOrLoss: "",
                            closingBalance: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* G: Details of Loans & Advances to any other concern (If money lending is not assessee’s substantial business ) */}
              <FieldArray name="scheduleAL_G">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">
                      G: Details of Loans & Advances to any other concern (If
                      money lending is not assessee’s substantial business )
                    </h4>

                    {values?.scheduleAL_G?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Name Of Person</label>
                              <Input
                                value={item.nameOfPerson}
                                name={`scheduleAL_G.${index}.nameOfPerson`}
                                error={
                                  errors?.scheduleAL_G &&
                                  errors?.scheduleAL_G[index]?.nameOfPerson
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Pan</label>
                              <Input
                                value={item.pan}
                                name={`scheduleAL_G.${index}.pan`}
                                error={
                                  errors?.scheduleAL_G &&
                                  errors?.scheduleAL_G[index]?.pan
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Opening Balance</label>
                              <Input
                                value={item.openingBalance}
                                name={`scheduleAL_G.${index}.openingBalance`}
                                error={
                                  errors?.scheduleAL_G &&
                                  errors?.scheduleAL_G[index]?.openingBalance
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Amount Received</label>
                              <Input
                                value={item.amountReceived}
                                name={`scheduleAL_G.${index}.amountReceived`}
                                error={
                                  errors?.scheduleAL_G &&
                                  errors?.scheduleAL_G[index]?.amountReceived
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Amount Paid</label>
                              <Input
                                value={item.amountPaid}
                                name={`scheduleAL_G.${index}.amountPaid`}
                                error={
                                  errors?.scheduleAL_G &&
                                  errors?.scheduleAL_G[index]?.amountPaid
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Interest Credited</label>
                              <Input
                                value={item.interestCredited}
                                name={`scheduleAL_G.${index}.interestCredited`}
                                error={
                                  errors?.scheduleAL_G &&
                                  errors?.scheduleAL_G[index]?.interestCredited
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Closing Balance</label>
                              <Input
                                value={item.closingBalance}
                                name={`scheduleAL_G.${index}.closingBalance`}
                                error={
                                  errors?.scheduleAL_G &&
                                  errors?.scheduleAL_G[index]?.closingBalance
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Rate Of Interest</label>
                              <Input
                                value={item.rateOfInterest}
                                name={`scheduleAL_G.${index}.rateOfInterest`}
                                error={
                                  errors?.scheduleAL_G &&
                                  errors?.scheduleAL_G[index]?.rateOfInterest
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            nameOfPerson: "",
                            pan: "",
                            openingBalance: "",
                            amountReceived: "",
                            amountPaid: "",
                            interestCredited: "",
                            closingBalance: "",
                            rateOfInterest: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* H: Details of motor vehicle, aircraft, yacht or other mode of transport */}
              <FieldArray name="scheduleAL_H">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">
                      H: Details of motor vehicle, aircraft, yacht or other mode
                      of transport
                    </h4>

                    {values?.scheduleAL_H?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Type Of Vehicle</label>

                              <Select
                                value={item.typeOfVehicle}
                                name={`scheduleAL_H.${index}.typeOfVehicle`}
                                error={
                                  errors?.scheduleAL_H &&
                                  errors?.scheduleAL_H[index]?.typeOfVehicle
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.typeOfVehicle?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                            <div>
                              <label>Registration Number</label>
                              <Input
                                value={item.registrationNumber}
                                name={`scheduleAL_H.${index}.registrationNumber`}
                                error={
                                  errors?.scheduleAL_H &&
                                  errors?.scheduleAL_H[index]
                                    ?.registrationNumber
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Purpose Of Use</label>

                              <Select
                                value={item.purposeOfUse}
                                name={`scheduleAL_H.${index}.purposeOfUse`}
                                error={
                                  errors?.scheduleAL_H &&
                                  errors?.scheduleAL_H[index]?.purposeOfUse
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.vehicleUsePurpose?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Cost of Aquisition</label>
                              <Input
                                value={item.costOfAcquisition}
                                name={`scheduleAL_H.${index}.costOfAcquisition`}
                                error={
                                  errors?.scheduleAL_H &&
                                  errors?.scheduleAL_H[index]?.costOfAcquisition
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Date Of Acquisition</label>
                              <Input
                                inputType={"date"}
                                value={item.dateOfAcquisition}
                                name={`scheduleAL_H.${index}.dateOfAcquisition`}
                                error={
                                  errors?.scheduleAL_H &&
                                  errors?.scheduleAL_H[index]?.dateOfAcquisition
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            typeOfVehicle: "",
                            registrationNumber: "",
                            purposeOfUse: "",
                            costOfAcquisition: "",
                            dateOfAcquisition: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* I: Details of Jewellery, archaeological collections, drawings, paintings, sculptures, any work of art or bullion */}
              <FieldArray name="scheduleAL_I">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">
                      I: Details of Jewellery, archaeological collections,
                      drawings, paintings, sculptures, any work of art or
                      bullion
                    </h4>

                    {values?.scheduleAL_I?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Type Of Asset</label>

                              <Select
                                value={item.typeOfAsset}
                                name={`scheduleAL_I.${index}.typeOfAsset`}
                                error={
                                  errors?.scheduleAL_I &&
                                  errors?.scheduleAL_I[index]?.typeOfAsset
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.assetType?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                            <div>
                              <label>Quantity</label>
                              <Input
                                value={item.quantity}
                                name={`scheduleAL_I.${index}.quantity`}
                                error={
                                  errors?.scheduleAL_I &&
                                  errors?.scheduleAL_I[index]?.quantity
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Purpose Of Use</label>

                              <Select
                                value={item.purposeOfUse}
                                name={`scheduleAL_I.${index}.purposeOfUse`}
                                error={
                                  errors?.scheduleAL_I &&
                                  errors?.scheduleAL_I[index]?.purposeOfUse
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  {strings.label.choose_option}
                                </option>
                                {optionData.assetUsePurpose?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Cost of Aquisition</label>
                              <Input
                                value={item.costOfAcquisition}
                                name={`scheduleAL_I.${index}.costOfAcquisition`}
                                error={
                                  errors?.scheduleAL_I &&
                                  errors?.scheduleAL_I[index]?.costOfAcquisition
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Date Of Acquisition</label>
                              <Input
                                inputType={"date"}
                                value={item.dateOfAcquisition}
                                name={`scheduleAL_I.${index}.dateOfAcquisition`}
                                error={
                                  errors?.scheduleAL_I &&
                                  errors?.scheduleAL_I[index]?.dateOfAcquisition
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            typeOfAsset: "",
                            quantity: "",
                            purposeOfUse: "",
                            costOfAcquisition: "",
                            dateOfAcquisition: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* J: Details of liabilities */}
              <FieldArray name="scheduleAL_J">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">J: Details of liabilities</h4>

                    {values?.scheduleAL_J?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Name Of Person</label>
                              <Input
                                value={item.nameOfPerson}
                                name={`scheduleAL_J.${index}.nameOfPerson`}
                                error={
                                  errors?.scheduleAL_J &&
                                  errors?.scheduleAL_J[index]?.nameOfPerson
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Pan</label>
                              <Input
                                inputType={"pan"}
                                value={item.pan}
                                name={`scheduleAL_J.${index}.pan`}
                                error={
                                  errors?.scheduleAL_J &&
                                  errors?.scheduleAL_J[index]?.pan
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Opening Balance</label>
                              <Input
                                value={item.openingBalance}
                                name={`scheduleAL_J.${index}.openingBalance`}
                                error={
                                  errors?.scheduleAL_J &&
                                  errors?.scheduleAL_J[index]?.openingBalance
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Amount Received</label>
                              <Input
                                value={item.amountReceived}
                                name={`scheduleAL_J.${index}.amountReceived`}
                                error={
                                  errors?.scheduleAL_J &&
                                  errors?.scheduleAL_J[index]?.amountReceived
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Amount Paid</label>
                              <Input
                                value={item.amountPaid}
                                name={`scheduleAL_J.${index}.amountPaid`}
                                error={
                                  errors?.scheduleAL_J &&
                                  errors?.scheduleAL_J[index]?.amountPaid
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Interest Credited</label>
                              <Input
                                value={item.interestCredited}
                                name={`scheduleAL_J.${index}.interestCredited`}
                                error={
                                  errors?.scheduleAL_J &&
                                  errors?.scheduleAL_J[index]?.interestCredited
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Closing Balance</label>
                              <Input
                                value={item.closingBalance}
                                name={`scheduleAL_J.${index}.closingBalance`}
                                error={
                                  errors?.scheduleAL_J &&
                                  errors?.scheduleAL_J[index]?.closingBalance
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Rate Of Interest</label>
                              <Input
                                value={item.rateOfInterest}
                                name={`scheduleAL_J.${index}.rateOfInterest`}
                                error={
                                  errors?.scheduleAL_J &&
                                  errors?.scheduleAL_J[index]?.rateOfInterest
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            nameOfPerson: "",
                            pan: "",
                            openingBalance: "",
                            amountReceived: "",
                            amountPaid: "",
                            interestCredited: "",
                            closingBalance: "",
                            rateOfInterest: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
