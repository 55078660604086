import React, { useEffect, useRef, useState } from "react";
import OtpScreen from "../component/LogIn/OtpScreen";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { addOpacity } from "../utils/helpers";
import CreateUser from "../component/LogIn/CreateUser";
import PanVerify from "../component/LogIn/PanVerify";
import OtpField from "../styledComponent/Inputs/OtpField";
import StyleFormBox from "../styledComponent/Form/StyleFormBox";
import {
  sendVerificationOtpAction,
  verifyOtpAction,
} from "../redux/profile/action";
import LoadingBox from "../component/DialogBox/LoadingBox";
export default function RegisterUser() {
  const [userData, setUserData] = useState({});
  const [activeScreen, setActiveScreen] = useState("create");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpHash, setOtpHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const { strings, theme } = useSelector((s) => s.platformSetting);
  const dispatch = useDispatch();

  const handleCallback = (e) => {
    if (e.status === 200) {
      setOtpHash(e.data);
    }
    setLoading(false);
    setSeconds(60);
  };

  const getOtp = (email) => {
    setLoading(true);
    dispatch(sendVerificationOtpAction(email, handleCallback));
  };

  const sendOtp = () => {
    const data = {
      hash: otpHash,
      otp:Number(otp.join("")),
    };
    dispatch(
      verifyOtpAction(data, (e) => {
        console.log("verify otp  =======", e);
      })
    );
  };
  

  useEffect(() => {
    if (seconds === 0) return; // Stop the timer when seconds reach 0
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds])

  return (
    <Root theme={theme}>
      {activeScreen == "create" ? (
        <CreateUser
          handleClick={(data) => {
            setUserData(data);
            getOtp(data.userEmail);
            setActiveScreen("otp");
          }}
        />
      ) : activeScreen == "otp" ? (
        <StyleFormBox>
          <h3
            className="font-medium text-center mt-8"
            style={{ color: theme.primary_txt }}
          >
            {strings.signUp.enter_opt_sent_to} {userData?.userEmail}
          </h3>
          <OtpField
            handleClick={(e) => {
              setOtp(e);
            }}
            otpLength={4}
          />
          <p className="text-center my-4">
            {strings.signUp.if_dont_receive_code} ?{" "}
            <button
              style={{ color: theme.primary_bg }}
              onClick={() => {
                getOtp("arun.kumar@sdnatech.com");
              }}
              disabled={seconds !== 0 ? true : false}
            >
              {strings.button.resend}
            </button>
            {seconds !== 0 && ` in 00:${seconds} Seconds`}
          </p>
          {/* <p className="text-center my-4">
            {strings.signUp.if_dont_receive_code} ?{" "} 
            <button style={{ color: theme.primary_bg }} onClick={handleSendOtpApi} disabled={seconds !== 0 ? true :false}>
              {strings.button.resend} {seconds !== 0 && `in 00:${seconds}`}
            </button>
          </p> */}



          <button
            onClick={sendOtp}
            className="primary_button mt-5 max-w-[500px] mx-auto my-4 block"
            disabled={!otp.every((value) => value !== "")}
          >
            {strings.button.continue}
          </button>
        

        
        </StyleFormBox>
      ) : activeScreen == "pan" ? (
        <PanVerify />
      ) : (
        ""
      )}
      <LoadingBox open={loading} />
    </Root>
  );
}
const Root = styled.section`
  max-width: 800px;
  margin: auto;
`;
