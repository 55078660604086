import React, { useEffect, useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import Input from "../../../../styledComponent/Inputs/Input";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../styledComponent/Inputs/Select";
import CheckBox from "../../../../styledComponent/Inputs/CheckBox";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import { toast } from "react-toastify";
import {
  deleteCompanyOrganizationAction,
  saveCompanyDirectorsInfoAction,
  saveNatureOfCompanyAction,
} from "../../../../redux/itr/action";
import LoadingBox from "../../../DialogBox/LoadingBox";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { addOpacity, removeEmptyKeys } from "../../../../utils/helpers";
import SubsidiaryCompany from "./SubsidiaryCompany";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import HoldingCompany from "./HoldingCompany";
import BusinessOrganization from "./BusinessOrganization";

export default function NatureOfCompany({
  companyOrganization,
  data,
  holdingStatus,
  optForTaxation,
  businessOrganizationType,
}) {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [subsidiaryFormBox, setSubsidiaryFormBox] = useState(false);
  const [holdingFormBox, setHoldingFormBox] = useState(false);
  const [organizationFormBox, setOrganizationFormBox] = useState(false);
  const [stackHolderData, setStackHolderData] = useState({
    isSubsidary: [],
    holdingCompany: [],
    businessOrganization: [],
  });
  const [optionData, setOptionData] = useState({});
  const [editFormData, setEditFormData] = useState({});
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);

  const dispatch = useDispatch();

  const handleFormSubmit = (value) => {
    setLoading(true);
    dispatch(
      saveNatureOfCompanyAction(value, (e) => {
        if (e.status == 200) {
          toast.success(
            `Data has been successfully ${value.id ? "updated" : "added"}`
          );
        } else {
          toast.error(e.message);
        }
        setLoading(false);
      })
    );
  };
  const handleDeleteCompany = async (type, id) => {
    setLoading(true);
    try {
      const ItrId = ItrData.id;
      const actions = {
        isSubsidary: {
          action: deleteCompanyOrganizationAction,
          key: "isSubsidary",
        },
        holdingCompany: {
          action: deleteCompanyOrganizationAction,
          key: "holdingCompany",
        },
        businessOrganization: {
          action: deleteCompanyOrganizationAction,
          key: "businessOrganization",
        },
      };

      if (actions[type]) {
        const { action, key } = actions[type];
        await new Promise((resolve, reject) => {
          dispatch(
            action({ id, ItrId }, (response) => {
              if (response.status === 200) {
                setStackHolderData((prevData) => ({
                  ...prevData,
                  [key]: prevData[key].filter((item) => item.id !== id),
                }));
                toast.success("Successfully deleted data");
                resolve();
              } else {
                toast.error("Failed to delete data");
                reject(new Error("Failed to delete"));
              }
            })
          );
        });
      } else {
        toast.error("Invalid delete type");
      }
    } catch (error) {
      toast.error("An error occurred while deleting data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setFormData({
      itrId: 5,
      domesticCompany: data?.domesticCompany || false,
      holdingStatus: data?.holdingStatus?.id || "",
      companyUnlisted: data?.companyUnlisted || false,
      companyLTDByGuarantee: data?.companyLTDByGuarantee || false,
      recognizedMSME: data?.recognizedMSME || false,
      MSMEDNumber: data?.MSMEDNumber || "",
      DPIITNumber: data?.DPIITNumber || "",
      DPIITCertificationNumber: data?.DPIITCertificationNumber || "",
      DPIITFilingDate: data?.DPIITFilingDate || "",
      optedTaxation: data?.optedTaxation?.id || "",
      choosingToOptTaxation: data?.choosingToOptTaxation || false,
      yearTaxationOpted: data?.yearTaxationOpted || "",
      acknowledgementNumber: data?.acknowledgementNumber || "",
      taxFilingDate: data?.taxFilingDate || "",
      turnoverAbove400CR: data?.turnoverAbove400CR || false,
      forexDrivenIncome: data?.forexDrivenIncome || false,
      bankingCompany: data?.bankingCompany || false,
      indianAccountingStandardCompany:
        data?.indianAccountingStandardCompany || false,
      RBIOwnerShares40: data?.RBIOwnerShares40 || false,
      IRDARegistered: data?.IRDARegistered || false,
      nonBankingFinancialInstitution:
        data?.nonBankingFinancialInstitution || false,
      publicSectorCompany: data?.publicSectorCompany || false,
      reserveBankOwned: data?.reserveBankOwned || false,
      scheduledBank: data?.scheduledBank || false,
    });
    setLoading(false);
  }, [data]);

  useEffect(() => {
    if (companyOrganization) {
      setStackHolderData({
        isSubsidary: companyOrganization.filter((i) => i.isSubsidary == true),
        holdingCompany: companyOrganization.filter(
          (i) => i.holdingCompany == true
        ),
        businessOrganization: companyOrganization.filter(
          (i) => i.businessOrganization == true
        ),
      });
    }
  }, [companyOrganization]);



  return (
    <div>
      <LoadingBox open={loading} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={(values) => {
          handleFormSubmit(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <div>
            <AccordianBox title={"Nature of Company"}>
              {/* General Information */}
              <StyleFormBox>
                <h4>General Information </h4>
                <div className="flex_input">
                  <div>
                    <label>Is this a domestic company? </label>
                    <Select
                      name="domesticCompany"
                      error={errors.domesticCompany}
                      value={values.domesticCompany}
                      onChange={handleChange}
                    >
                      <option value="">{strings.label.choose_option}</option>
                      {[true, false].map((item, index) => (
                        <option value={item} key={index}>
                          {item ? "Yes" : "No"}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <label>Is this company unlisted? </label>
                    <Select
                      name="companyUnlisted"
                      error={errors.companyUnlisted}
                      value={values.companyUnlisted}
                      onChange={handleChange}
                    >
                      <option value="">{strings.label.choose_option}</option>
                      {[true, false].map((item, index) => (
                        <option value={item} key={index}>
                          {item ? "Yes" : "No"}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div>
                  <label>
                    Holding status: is this a holding company, a subsidiary
                    company or both?{" "}
                  </label>
                  <Select
                    name="holdingStatus"
                    error={errors.holdingStatus}
                    value={values.holdingStatus}
                    onChange={handleChange}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {holdingStatus?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div>
                  <label>
                    Are you a section 8 Co./ Section 25 Co./ Company Limited by
                    Guarantee?
                  </label>
                  <Select
                    name="companyLTDByGuarantee"
                    error={errors.companyLTDByGuarantee}
                    value={values.companyLTDByGuarantee}
                    onChange={handleChange}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {[true, false].map((item, index) => (
                      <option value={item} key={index}>
                        {item ? "Yes" : "No"}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="flex_input">
                  <label className="mb-3">
                    Whether you are recognized as MSME
                  </label>
                  <CheckBox
                    value={values?.recognizedMSME}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        recognizedMSME: !values.recognizedMSME,
                      })
                    }
                  />
                </div>

                {/* Based on Above conditions */}
                <div>
                  <label>
                    If yes, please provide registration number allotted as per
                    MSMED Act, 2006
                  </label>
                  <Input
                    name={"MSMEDNumber"}
                    error={errors.MSMEDNumber}
                    value={values.MSMEDNumber}
                    onChange={handleChange}
                  />
                </div>
              </StyleFormBox>

              {/* DPIIT Information */}
              <StyleFormBox>
                <h4>DPIIT Information</h4>

                <div>
                  <label>
                    If you are recognized as startup by DPIIT enter the
                    recognition number allotted by DPIIT
                  </label>
                  <Input
                    name={"DPIITNumber"}
                    inputType={"number"}
                    error={errors.DPIITNumber}
                    value={values.DPIITNumber}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>
                    Certificate number received from inter ministerial board
                  </label>
                  <Input
                    name={"DPIITCertificationNumber"}
                    inputType={"number"}
                    error={errors.DPIITCertificationNumber}
                    value={values.DPIITCertificationNumber}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label>
                    If declaration in Form-2 in accordance with para 5 of DPIIT
                    notification date 19/02/2019 if filed enter the date of
                    filing
                  </label>
                  <Input
                    name={"DPIITFilingDate"}
                    type={"date"}
                    error={errors.DPIITFilingDate}
                    value={values.DPIITFilingDate}
                    format={"YYYY-MM-DD"}
                    onChange={handleChange}
                  />
                </div>
              </StyleFormBox>

              {/* Nature of Company */}
              <StyleFormBox>
                <h4>Nature of Company</h4>
                <div>
                  <label>
                    Have you opted for taxation under section
                    115BA/115BAA/115BAB in any of the Preceding Previous years?
                  </label>
                  <Select
                    name="optedTaxation"
                    error={errors.optedTaxation}
                    value={values.optedTaxation}
                    onChange={handleChange}
                  >
                    <option value="">None</option>
                    {optForTaxation?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>

                {values.optedTaxation ? (
                  <>
                    <div>
                      <label>
                        In which year did you opt for taxation under section
                        115BA/115BAA/115BAB?
                      </label>
                      <Select
                        name="yearTaxationOpted"
                        error={errors.yearTaxationOpted}
                        value={values.yearTaxationOpted}
                        onChange={handleChange}
                      >
                        <option value="">{strings.label.choose_option}</option>
                        {/* {states?.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      ))} */}
                      </Select>
                    </div>

                    <div className="flex_input">
                      <div>
                        <label>
                          Acknowledgment No. of form 10IB/10IC/10ID{" "}
                        </label>
                        <Input
                          name={"acknowledgementNumber"}
                          error={errors.acknowledgementNumber}
                          value={values.acknowledgementNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label>Filing Date</label>
                        <Input
                          name={"taxFilingDate"}
                          type={"date"}
                          error={errors.taxFilingDate}
                          value={values.taxFilingDate}
                          format={"YYYY-MM-DD"}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <label>
                      Whether you are choosing to opt for taxation under section
                      115BA/115BAA/115BAB this year?
                    </label>
                    <Select
                      name="choosingToOptTaxation"
                      error={errors.choosingToOptTaxation}
                      value={values.choosingToOptTaxation}
                      onChange={handleChange}
                    >
                      <option value="">{strings.label.choose_option}</option>
                      {[true, false].map((item, index) => (
                        <option value={item} key={index}>
                          {item ? "Yes" : "No"}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}

                <div className="flex_input">
                  <label className="mb-3">
                    Whether total turnover/ gross receipts in the previous year
                    2021-2022 exceeds 400 crore rupees? Applicable only if “None
                    of above” is selected above.
                  </label>
                  <CheckBox
                    value={values?.turnoverAbove400CR}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        turnoverAbove400CR: !values.turnoverAbove400CR,
                      })
                    }
                  />
                </div>

                <div className="flex_input">
                  <label className="mb-3">
                    Is located in an International Financial Services Centre and
                    derives income solely in FOREX?
                  </label>
                  <CheckBox
                    value={values?.forexDrivenIncome}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        forexDrivenIncome: !values.forexDrivenIncome,
                      })
                    }
                  />
                </div>

                <div className="flex_input">
                  <label className="mb-3">
                    Banking Company as defined in clause (c) of section 5 of
                    Banking Regulatory Act 1949
                  </label>
                  <CheckBox
                    value={values?.bankingCompany}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        bankingCompany: !values.bankingCompany,
                      })
                    }
                  />
                </div>

                <div className="flex_input">
                  <label className="mb-3">
                    Whether the financial statements of the company are drawn up
                    in compliance to the Indian Accounting Standards.
                  </label>
                  <CheckBox
                    value={values?.indianAccountingStandardCompany}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        indianAccountingStandardCompany:
                          !values.indianAccountingStandardCompany,
                      })
                    }
                  />
                </div>

                <div className="flex_input">
                  <label className="mb-3">
                    40% or more of the company's shares are owned by RBI or a
                    corporation owned by RBI
                  </label>
                  <CheckBox
                    value={values?.RBIOwnerShares40}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        RBIOwnerShares40: !values.RBIOwnerShares40,
                      })
                    }
                  />
                </div>

                <div className="flex_input">
                  <label className="mb-3">
                    Company registered with IRDA (established under sub-section
                    (1) of section 3 of the IRDA Act, 1999)
                  </label>
                  <CheckBox
                    value={values?.IRDARegistered}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        IRDARegistered: !values.IRDARegistered,
                      })
                    }
                  />
                </div>

                <div className="flex_input">
                  <label className="mb-3">
                    Company being a non-banking financial institution
                  </label>
                  <CheckBox
                    value={values?.nonBankingFinancialInstitution}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        nonBankingFinancialInstitution:
                          !values.nonBankingFinancialInstitution,
                      })
                    }
                  />
                </div>

                <div className="flex_input">
                  <label className="mb-3">
                    Public Sector Company as defined in section 2(36A)
                  </label>
                  <CheckBox
                    value={values?.publicSectorCompany}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        publicSectorCompany: !values.publicSectorCompany,
                      })
                    }
                  />
                </div>

                <div className="flex_input">
                  <label className="mb-3">Owned by Reserve Bank of India</label>
                  <CheckBox
                    value={values?.reserveBankOwned}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        reserveBankOwned: !values.reserveBankOwned,
                      })
                    }
                  />
                </div>

                <div className="flex_input">
                  <label className="mb-3">
                    Scheduled Bank being a bank included in the second schedule
                    to the RBI Act
                  </label>
                  <CheckBox
                    value={values?.scheduledBank}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        scheduledBank: !values.scheduledBank,
                      })
                    }
                  />
                </div>
              </StyleFormBox>

              {/* Company Organization */}

              <StyleFormBox>
                <h4>Company Organization</h4>

                <StyleFormBox>
                  <div className="flex items-center justify-between">
                    <label>
                      To enter details about the holding company, if nature of
                      company is "Subsidiary Company" or "Both".
                    </label>
                    <button
                      className="add_button"
                      onClick={() => setSubsidiaryFormBox(true)}
                    >
                      {strings.button.add}
                    </button>
                  </div>

                  {stackHolderData?.isSubsidary?.map((item, ix) => (
                    <div
                      key={ix}
                      className="flex gap-2 p-3 my-3 align-center"
                      style={{
                        background: addOpacity(theme.primary_bg, 0.2),
                        borderRadius: "10px",
                      }}
                    >
                      <h4 className="mr-auto">Name / Percentage</h4>

                      <h4>
                        {item.name} / {item.percentageShare}
                      </h4>
                      <div className="action_buttons">
                        <button
                          className="edit_btn"
                          onClick={() => {
                            setEditFormData(item);
                            setSubsidiaryFormBox(true);
                          }}
                        >
                          <FaRegEdit />
                        </button>
                        <button
                          className="delete_btn"
                          onClick={() =>
                            handleDeleteCompany("isSubsidary", item.id)
                          }
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    </div>
                  ))}
                </StyleFormBox>

                <StyleFormBox>
                  <div className="flex items-center justify-between">
                    <label>
                      To enter details about the subsidiary companies, if nature
                      of company is "Holding Company" or "Both"
                    </label>
                    <button
                      className="add_button"
                      onClick={() => setHoldingFormBox(true)}
                    >
                      {strings.button.add}
                    </button>
                  </div>

                  {stackHolderData?.holdingCompany?.map((item, ix) => (
                    <div
                      key={ix}
                      className="flex gap-2 p-3 my-3 align-center"
                      style={{
                        background: addOpacity(theme.primary_bg, 0.2),
                        borderRadius: "10px",
                      }}
                    >
                      <h4 className="mr-auto">Name / Percentage</h4>

                      <h4>
                        {item.name} / {item.percentageShare}
                      </h4>
                      <div className="action_buttons">
                        <button
                          className="edit_btn"
                          onClick={() => {
                            setEditFormData(item);
                            setHoldingFormBox(true);
                          }}
                        >
                          <FaRegEdit />
                        </button>
                        <button
                          className="delete_btn"
                          onClick={() =>
                            handleDeleteCompany("holdingCompany", item.id)
                          }
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    </div>
                  ))}
                </StyleFormBox>

                <StyleFormBox>
                  <div className="flex items-center justify-between">
                    <label>
                      To enter details about the business organization.
                    </label>
                    <button
                      className="add_button"
                      onClick={() => setOrganizationFormBox(true)}
                    >
                      {strings.button.add}
                    </button>
                  </div>
                  {stackHolderData?.businessOrganization?.map((item, ix) => (
                    <div
                      key={ix}
                      className="flex gap-2 p-3 my-3 align-center"
                      style={{
                        background: addOpacity(theme.primary_bg, 0.2),
                        borderRadius: "10px",
                      }}
                    >
                      <h4 className="mr-auto">Name / Percentage</h4>

                      <h4>
                        {item.name} / {item.percentageShare}
                      </h4>
                      <div className="action_buttons">
                        <button
                          className="edit_btn"
                          onClick={() => {
                            setEditFormData(item);
                            setOrganizationFormBox(true);
                          }}
                        >
                          <FaRegEdit />
                        </button>
                        <button
                          className="delete_btn"
                          onClick={() =>
                            handleDeleteCompany("businessOrganization", item.id)
                          }
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    </div>
                  ))}
                </StyleFormBox>
              </StyleFormBox>

              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </AccordianBox>
          </div>
        )}
      </Formik>

      <FormDialogBox
        open={subsidiaryFormBox}
        formTitle={"Details of Holding company"}
        handleClose={() => {
          setSubsidiaryFormBox(false);
        }}
      >
        <SubsidiaryCompany
          data={editFormData}
          handleCallback={(type, data) => {
            if (type == "edit") {
              setStackHolderData({
                ...stackHolderData,
                isSubsidary: stackHolderData.isSubsidary.map((i) =>
                  i.id == data.id ? data : i
                ),
              });
            } else {
              setStackHolderData({
                ...stackHolderData,
                isSubsidary: [...stackHolderData.isSubsidary, data],
              });
            }
            setSubsidiaryFormBox(false);
          }}
        />
      </FormDialogBox>

      <FormDialogBox
        open={holdingFormBox}
        formTitle={"Details of Subsidiary companies"}
        handleClose={() => {
          setHoldingFormBox(false);
        }}
      >
        <HoldingCompany
          data={editFormData}
          handleCallback={(type, data) => {
            if (type == "edit") {
              setStackHolderData({
                ...stackHolderData,
                holdingCompany: stackHolderData.holdingCompany.map((i) =>
                  i.id == data.id ? data : i
                ),
              });
            } else {
              setStackHolderData({
                ...stackHolderData,
                holdingCompany: [...stackHolderData.holdingCompany, data],
              });
            }
            setHoldingFormBox(false);
          }}
        />
      </FormDialogBox>

      <FormDialogBox
        open={organizationFormBox}
        formTitle={"Details of Subsidiary companies"}
        handleClose={() => {
          setOrganizationFormBox(false);
        }}
      >
        <BusinessOrganization
          data={editFormData}
          businessOrganizationType={businessOrganizationType}
          handleCallback={(type, data) => {
            if (type == "edit") {
              setStackHolderData({
                ...stackHolderData,
                businessOrganization: stackHolderData.businessOrganization.map(
                  (i) => (i.id == data.id ? data : i)
                ),
              });
            } else {
              setStackHolderData({
                ...stackHolderData,
                businessOrganization: [
                  ...stackHolderData.businessOrganization,
                  data,
                ],
              });
            }
            setOrganizationFormBox(false);
          }}
        />
      </FormDialogBox>
    </div>
  );
}
