import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FieldArray, Formik } from "formik";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import Input from "../../../styledComponent/Inputs/Input";
import LoadingBox from "../../DialogBox/LoadingBox";
import {
  getDeduction80Action,
  saveDeduction80Action,
} from "../../../redux/itr/action";
import AccordianBox from "../../../styledComponent/Form/AccordianBox";
import FormDialogBox from "../../DialogBox/FormDialogBox";
import { addOpacity } from "../../../utils/helpers";

export default function Deduction80IA({ formsData }) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({});
  const [formBox, setFormBox] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const saveDeduction80IAApi = async (value) => {
    setLoader(true);
    dispatch(
      saveDeduction80Action({ itrId: ItrData.id, ...value }, (e) => {
        if (e.status === 200) {
          setData(e.data);
        }
        setFormBox(false)
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    dispatch(
      getDeduction80Action(ItrData.id, (e) => {
        if (e.status === 200) {
          setData(e.data);
        } else {
          toast.error("Something Went Wrong");
        }
        setLoader(false);
      })
    );
  }, [ItrData]);

  const sumOfUndertakings = (items) => {
    // length has more than one item
    if(items?.length >= 1) {
      // if first value blank the ignore others
      if(items[0] != "") {
        return items.reduce((a, b) => { 
          if(b != "")
            return parseInt(a, 0) + parseInt(b, 0);
          else
            return a;
        });
      } else {
        return 0;
      }
    } else {
      // no item then 0 and in case of single value return the same value
      return items?.length && items[0] != "" ? items[0] : 0; 
    } 
  }

  return (
    <div>
      <LoadingBox open={loader} />

      {data.id ? (
        <div
          style={{
            background: addOpacity(theme.primary_bg, 0.2),
            borderRadius: "10px",
          }}
        >
          <div className="flex gap-2 p-3 my-3 align-center">
            <h4 className="mr-auto">Total deductions under section 8O-IA</h4>
            <h4>{data?.sec80IA_total || 0}</h4>
          </div>
          <div className="flex gap-2 p-3 my-3 align-center">
            <h4 className="mr-auto">Toral deduction under section 8O-IB</h4>
            <h4>{data?.sec80IB_total || 0}</h4>
          </div>
          <div className="flex gap-2 p-3 my-3 align-center">
            <h4 className="mr-auto">
              Total deduction under section 8O-IC/8OIE
            </h4>
            <h4>{data?.total80IC_IE || 0}</h4>
          </div>
          <div className="flex gap-2 p-3 my-3 align-center">
            <h4 className="mr-auto">Total deduction in North East</h4>
            <h4>{data?.total_nortEast || 0}</h4>
          </div>
        </div>
      ) : (
        ""
      )}

      <button
        className="primary_button"
        style={{ flex: 0 }}
        onClick={() => {
          if (data?.id) {
            setFormData({
              sec80IA_infrastructure: data?.sec80IA_infrastructure,
              sec80IA_power: data?.sec80IA_power,
              sec80IB_Industrial: data?.sec80IB_Industrial,
              sec80IB_commercial: data?.sec80IB_commercial,
              sec80IB_housing: data?.sec80IB_housing,
              sec80IB_dairy: data?.sec80IB_dairy,
              sec80IB_foodgrains: data?.sec80IB_foodgrains,
              sec80IC_IE_sikkim: data?.sec80IC_IE_sikkim,
              sec80IC_IE_HP: data?.sec80IC_IE_HP,
              sec80IC_IE_uttaranchal: data?.sec80IC_IE_uttaranchal,
              sec80ICIE_NE_assam: data?.sec80ICIE_NE_assam,
              sec80ICIE_NE_arunachalPradesh:
                data?.sec80ICIE_NE_arunachalPradesh,
              sec80ICIE_NE_manipur: data?.sec80ICIE_NE_manipur,
              sec80ICIE_NE_mizoram: data?.sec80ICIE_NE_mizoram,
              sec80ICIE_NE_meghalaya: data?.sec80ICIE_NE_meghalaya,
              sec80ICIE_NE_nagaland: data?.sec80ICIE_NE_nagaland,
              sec80ICIE_NE_tripura: data?.sec80ICIE_NE_tripura,
            });
          } else {
            setFormData({
              sec80IA_infrastructure: [],
              sec80IA_power: [],
              sec80IB_Industrial: [],
              sec80IB_commercial: [],
              sec80IB_housing: [],
              sec80IB_dairy: [],
              sec80IB_foodgrains: [],
              sec80IC_IE_sikkim: [],
              sec80IC_IE_HP: [],
              sec80IC_IE_uttaranchal: [],
              sec80ICIE_NE_assam: [],
              sec80ICIE_NE_arunachalPradesh: [],
              sec80ICIE_NE_manipur: [],
              sec80ICIE_NE_mizoram: [],
              sec80ICIE_NE_meghalaya: [],
              sec80ICIE_NE_nagaland: [],
              sec80ICIE_NE_tripura: [],
            });
          }
          setFormBox(!formBox);
        }}
      >
        {data?.id ? strings.button.edit : strings.button.add}
      </button>

      <FormDialogBox
        open={formBox}
        formTitle={"Deduction under section 80"}
        handleClose={() => {
          setFormBox(false);
        }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            saveDeduction80IAApi(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <div>
              {/* --------------- Deductions under section 8O-IA  ------------------ */}

              <AccordianBox title={"Deductions under section 8O-IA"}>
                <FieldArray name="sec80IA_infrastructure">
                  {({ remove, push }) => (
                    <div>
                      <label>
                        a. Deduction in respect of profits of an enterprise
                        referred to in section 8O-IA(4)(i) (Infrastructure
                        facility)
                      </label>
                      {values.sec80IA_infrastructure.map((item, index) => (
                        <div key={index}>
                          <div className="flex_input my-2 items-center">
                            <label>Undertaking {index + 1}</label>
                            <Input
                              name={`sec80IA_infrastructure.${index}`}
                              value={item}
                              type="number"
                              onChange={handleChange}
                            />
                            <button
                              type="button"
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          type="button"
                          className="add_item_button"
                          onClick={() => push("")}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <hr className="my-4" />
                <label>
                  b. Deduction in respect of profits of an undertaking referred
                  to in section 8O-IA(4)(iv) (Power)
                </label>

                {/* Power Section */}
                <FieldArray name="sec80IA_power">
                  {({ remove, push }) => (
                    <div>
                      {values.sec80IA_power.map((item, index) => (
                        <div key={index}>
                          <div className="flex_input my-2 items-center">
                            <label>Undertaking {index + 1}</label>
                            <Input
                              name={`sec80IA_power.${index}`}
                              value={item}
                              type="number"
                              onChange={handleChange}
                            />
                            <button
                              type="button"
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          type="button"
                          className="add_item_button"
                          onClick={() => push("")}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                {/* Total Section */}
                <div className="flex_input my-2 ">
                  <h4>c. Total deductions under section 8O-IA (a + b)</h4>
                  <h4 className="" style={{ flex: 0 }}>
                    {sumOfUndertakings(values.sec80IA_infrastructure) +
                      sumOfUndertakings(values.sec80IA_power)}
                  </h4>
                </div>
              </AccordianBox>

              {/* ---------- Deductions under section 8O-IB ------------ */}

              <AccordianBox title={"Deductions under section 8O-IB"}>
                <label>
                  a. Deduction in respect of industrial undertaking located in
                  Jammu & Kashmir or Ladakh [Section 80-1B(4)]
                </label>

                <FieldArray name="sec80IB_Industrial">
                  {({ insert, remove, push }) => (
                    <div>
                      {values?.sec80IB_Industrial?.map((item, index) => (
                        <div key={index}>
                          <div className="flex_input my-2 items-center">
                            <label>Undertaking {index + 1}</label>
                            <Input
                              name={`sec80IB_Industrial.${index}`}
                              value={item}
                              type={"number"}
                              onChange={handleChange}
                            />
                            <button
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push("");
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <hr className="my-4" />
                <label>
                  b. Deduction in the case of undertaking which begins
                  commercial production or refining of mineral oil [Section
                  80-1B(9)]
                </label>

                <FieldArray name="sec80IB_commercial">
                  {({ insert, remove, push }) => (
                    <div>
                      {values?.sec80IB_commercial?.map((item, index) => (
                        <div key={index}>
                          <div className="flex_input my-2 items-center">
                            <label>Undertaking {index + 1}</label>
                            <Input
                              name={`sec80IB_commercial.${index}`}
                              value={item}
                              type={"number"}
                              onChange={handleChange}
                            />
                            <button
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push("");
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <hr className="my-4" />
                <label>
                  c. Deduction in the case of an undertaking developing and
                  building housing projects [Section 80-1B(10)]
                </label>

                <FieldArray name="sec80IB_housing">
                  {({ insert, remove, push }) => (
                    <div>
                      {values?.sec80IB_housing?.map((item, index) => (
                        <div key={index}>
                          <div className="flex_input my-2 items-center">
                            <label>Undertaking {index + 1}</label>
                            <Input
                              name={`sec80IB_housing.${index}`}
                              value={item}
                              type={"number"}
                              onChange={handleChange}
                            />
                            <button
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push("");
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <hr className="my-4" />
                <label>
                  d. Deduction in the case of an undertaking engaged in
                  processing, preservation and packaging of fruits and
                  vegetables, meat, meat products, poultry, marine or dairy
                  products [Section 80-1B(1 IA)]
                </label>

                <FieldArray name="sec80IB_dairy">
                  {({ insert, remove, push }) => (
                    <div>
                      {values?.sec80IB_dairy?.map((item, index) => (
                        <div key={index}>
                          <div className="flex_input my-2 items-center">
                            <label>Undertaking {index + 1}</label>
                            <Input
                              name={`sec80IB_dairy.${index}`}
                              value={item}
                              type={"number"}
                              onChange={handleChange}
                            />
                            <button
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push("");
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <hr className="my-4" />
                <label>
                  e. Deduction in the case of an undertaking engaged in
                  integrated business of handling storage and transportation of
                  foodgrains [Section 80-1B(11A)]
                </label>

                <FieldArray name="sec80IB_foodgrains">
                  {({ insert, remove, push }) => (
                    <div>
                      {values?.sec80IB_foodgrains?.map((item, index) => (
                        <div key={index}>
                          <div className="flex_input my-2 items-center">
                            <label>Undertaking {index + 1}</label>
                            <Input
                              name={`sec80IB_foodgrains.${index}`}
                              value={item}
                              type={"number"}
                              onChange={handleChange}
                            />
                            <button
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push("");
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <div className="flex_input my-2 ">
                  <h4>
                    f. Total deductions under section 80-IB (Total of a to e)
                  </h4>
                  <h4 className="" style={{ flex: 0 }}>
                    {
                      (
                      sumOfUndertakings(values?.sec80IB_Industrial) +
                      sumOfUndertakings(values?.sec80IB_commercial) +
                      sumOfUndertakings(values?.sec80IB_housing) +
                      sumOfUndertakings(values?.sec80IB_dairy)
                      ) 
                    }
                  </h4>
                </div>
              </AccordianBox>

              {/* --------- Deductions under section 80-IC or 80-IE ------------  */}

              <AccordianBox title={"Deductions under section 80-IC or 80-IE"}>
                <label>
                  a. Deduction in respect of industrial undertaking located in
                  Sikkim
                </label>

                <FieldArray name="sec80IC_IE_sikkim">
                  {({ insert, remove, push }) => (
                    <div>
                      {values?.sec80IC_IE_sikkim?.map((item, index) => (
                        <div key={index}>
                          <div className="flex_input my-2 items-center">
                            <label>Undertaking {index + 1}</label>
                            <Input
                              name={`sec80IC_IE_sikkim.${index}`}
                              value={item}
                              type={"number"}
                              onChange={handleChange}
                            />

                            <button
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push("");
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <hr className="my-4" />
                <label>
                  b. Deduction in respect of industrial undertaking located in
                  Himachal Pradesh
                </label>

                <FieldArray name="sec80IC_IE_HP">
                  {({ insert, remove, push }) => (
                    <div>
                      {values?.sec80IC_IE_HP?.map((item, index) => (
                        <div key={index}>
                          <div className="flex_input my-2 items-center">
                            <label>Undertaking {index + 1}</label>
                            <Input
                              name={`sec80IC_IE_HP.${index}`}
                              value={item}
                              type={"number"}
                              onChange={handleChange}
                            />
                            <button
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push("");
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <hr className="my-4" />
                <label>
                  c. Deduction in respect of industrial undertaking located in
                  Uttaranchal
                </label>

                <FieldArray name="sec80IC_IE_uttaranchal">
                  {({ insert, remove, push }) => (
                    <div>
                      {values?.sec80IC_IE_uttaranchal?.map((item, index) => (
                        <div key={index}>
                          <div className="flex_input my-2 items-center">
                            <label>Undertaking {index + 1}</label>
                            <Input
                              name={`sec80IC_IE_uttaranchal.${index}`}
                              value={item}
                              type={"number"}
                              onChange={handleChange}
                            />
                            <button
                              className="delete_button mb-2 ml-auto block"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({ keyname: "", value: 0 });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <hr className="my-4" />
                <label>
                  d. Deduction in respect of industrial undertaking located in
                  North East
                </label>

                <StyleFormBox>
                  <FieldArray name="sec80ICIE_NE_assam">
                    {({ insert, remove, push }) => (
                      <div className="mt-4">
                        <h5>a. Assam</h5>
                        {values?.sec80ICIE_NE_assam?.map((item, index) => (
                          <div key={index}>
                            <div className="flex_input my-2 items-center">
                              <label>Undertaking {index + 1}</label>
                              <Input
                                name={`sec80ICIE_NE_assam.${index}`}
                                value={item}
                                type={"number"}
                                onChange={handleChange}
                              />
                              <button
                                className="delete_button mb-2 ml-auto block"
                                onClick={() => remove(index)}
                              >
                                <RiDeleteBin6Fill />
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="text-right mt-5">
                          <button
                            className="add_item_button"
                            onClick={(e) => {
                              e.preventDefault();
                              push("");
                            }}
                          >
                            {strings.button.add_more_item}
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <FieldArray name="sec80ICIE_NE_arunachalPradesh">
                    {({ insert, remove, push }) => (
                      <div className="mt-4">
                        <h5>b. Arunanchal Pradesh</h5>
                        {values?.sec80ICIE_NE_arunachalPradesh?.map(
                          (item, index) => (
                            <div key={index}>
                              <div className="flex_input my-2 items-center">
                                <label>Undertaking {index + 1}</label>
                                <Input
                                  name={`sec80ICIE_NE_arunachalPradesh.${index}`}
                                  value={item}
                                  type={"number"}
                                  onChange={handleChange}
                                />
                                <button
                                  className="delete_button mb-2 ml-auto block"
                                  onClick={() => remove(index)}
                                >
                                  <RiDeleteBin6Fill />
                                </button>
                              </div>
                            </div>
                          )
                        )}
                        <div className="text-right mt-5">
                          <button
                            className="add_item_button"
                            onClick={(e) => {
                              e.preventDefault();
                              push("");
                            }}
                          >
                            {strings.button.add_more_item}
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <FieldArray name="sec80ICIE_NE_manipur">
                    {({ insert, remove, push }) => (
                      <div className="mt-4">
                        <h5>c. Manipur</h5>
                        {values?.sec80ICIE_NE_manipur?.map((item, index) => (
                          <div key={index}>
                            <div className="flex_input my-2 items-center">
                              <label>Undertaking {index + 1}</label>
                              <Input
                                name={`sec80ICIE_NE_manipur.${index}`}
                                value={item}
                                type={"number"}
                                onChange={handleChange}
                              />
                              <button
                                className="delete_button mb-2 ml-auto block"
                                onClick={() => remove(index)}
                              >
                                <RiDeleteBin6Fill />
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="text-right mt-5">
                          <button
                            className="add_item_button"
                            onClick={(e) => {
                              e.preventDefault();
                              push("");
                            }}
                          >
                            {strings.button.add_more_item}
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <FieldArray name="sec80ICIE_NE_mizoram">
                    {({ insert, remove, push }) => (
                      <div className="mt-4">
                        <h5>d. Mizoram</h5>
                        {values?.sec80ICIE_NE_mizoram?.map((item, index) => (
                          <div key={index}>
                            <div className="flex_input my-2 items-center">
                              <label>Undertaking {index + 1}</label>
                              <Input
                                name={`sec80ICIE_NE_mizoram.${index}`}
                                value={item}
                                type={"number"}
                                onChange={handleChange}
                              />
                              <button
                                className="delete_button mb-2 ml-auto block"
                                onClick={() => remove(index)}
                              >
                                <RiDeleteBin6Fill />
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="text-right mt-5">
                          <button
                            className="add_item_button"
                            onClick={(e) => {
                              e.preventDefault();
                              push("");
                            }}
                          >
                            {strings.button.add_more_item}
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <FieldArray name="sec80ICIE_NE_meghalaya">
                    {({ insert, remove, push }) => (
                      <div className="mt-4">
                        <h5>e. Meghalaya</h5>
                        {values?.sec80ICIE_NE_meghalaya?.map((item, index) => (
                          <div key={index}>
                            <div className="flex_input my-2 items-center">
                              <label>Undertaking {index + 1}</label>
                              <Input
                                name={`sec80ICIE_NE_meghalaya.${index}`}
                                value={item}
                                type={"number"}
                                onChange={handleChange}
                              />
                              <button
                                className="delete_button mb-2 ml-auto block"
                                onClick={() => remove(index)}
                              >
                                <RiDeleteBin6Fill />
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="text-right mt-5">
                          <button
                            className="add_item_button"
                            onClick={(e) => {
                              e.preventDefault();
                              push("");
                            }}
                          >
                            {strings.button.add_more_item}
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <FieldArray name="sec80ICIE_NE_nagaland">
                    {({ insert, remove, push }) => (
                      <div className="mt-4">
                        <h5>f. Nagaland</h5>
                        {values?.sec80ICIE_NE_nagaland?.map((item, index) => (
                          <div key={index}>
                            <div className="flex_input my-2 items-center">
                              <label>Undertaking {index + 1}</label>
                              <Input
                                name={`sec80ICIE_NE_nagaland.${index}`}
                                value={item}
                                type={"number"}
                                onChange={handleChange}
                              />
                              <button
                                className="delete_button mb-2 ml-auto block"
                                onClick={() => remove(index)}
                              >
                                <RiDeleteBin6Fill />
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="text-right mt-5">
                          <button
                            className="add_item_button"
                            onClick={(e) => {
                              e.preventDefault();
                              push("");
                            }}
                          >
                            {strings.button.add_more_item}
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <FieldArray name="sec80ICIE_NE_tripura">
                    {({ insert, remove, push }) => (
                      <div className="mt-4">
                        <h5>g. Tripura</h5>
                        {values?.sec80ICIE_NE_tripura?.map((item, index) => (
                          <div key={index}>
                            <div className="flex_input my-2 items-center">
                              <label>Undertaking {index + 1}</label>
                              <Input
                                name={`sec80ICIE_NE_tripura.${index}`}
                                value={item}
                                type={"number"}
                                onChange={handleChange}
                              />
                              <button
                                className="delete_button mb-2 ml-auto block"
                                onClick={() => remove(index)}
                              >
                                <RiDeleteBin6Fill />
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="text-right mt-5">
                          <button
                            className="add_item_button"
                            onClick={(e) => {
                              e.preventDefault();
                              push("");
                            }}
                          >
                            {strings.button.add_more_item}
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <div className="flex_input my-2 ">
                    <h4>
                      h. Total of deduction for undertakings located in
                      North-East(Total of 4a to 4g)
                    </h4>
                    <h4 className="" style={{ flex: 0 }}>
                      {
                      sumOfUndertakings(values?.sec80ICIE_NE_assam) +
                      sumOfUndertakings(values?.sec80ICIE_NE_arunachalPradesh) +
                      sumOfUndertakings(values?.sec80ICIE_NE_manipur) +
                      sumOfUndertakings(values?.sec80ICIE_NE_mizoram) +
                      sumOfUndertakings(values?.sec80ICIE_NE_meghalaya) +
                      sumOfUndertakings(values?.sec80ICIE_NE_nagaland) +
                      sumOfUndertakings(values?.sec80ICIE_NE_tripura)
                      }
                    </h4>
                  </div>
                </StyleFormBox>

                <div className="flex_input my-2 ">
                  <h4>
                    e. Total deductions under section 80-IC/80-IE (a + b + c +
                    dh)
                  </h4>
                  <h4 className="" style={{ flex: 0 }}>
                    {
                    sumOfUndertakings(values?.sec80IC_IE_sikkim) +
                    sumOfUndertakings(values?.sec80IC_IE_HP) +
                    sumOfUndertakings(values?.sec80IC_IE_uttaranchal) +
                    sumOfUndertakings(values?.sec80ICIE_NE_assam) +
                    sumOfUndertakings(values?.sec80ICIE_NE_arunachalPradesh) +
                    sumOfUndertakings(values?.sec80ICIE_NE_manipur) +
                    sumOfUndertakings(values?.sec80ICIE_NE_mizoram) +
                    sumOfUndertakings(values?.sec80ICIE_NE_meghalaya) +
                    sumOfUndertakings(values?.sec80ICIE_NE_nagaland) +
                    sumOfUndertakings(values?.sec80ICIE_NE_tripura)
                    }
                  </h4>
                </div>
              </AccordianBox>

              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
