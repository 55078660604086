
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
export default function CheckBox({ value, handleChange, label }) {
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
        <div
          className={value == true ? 'active !flex-initial': "!flex-initial"}
          onClick={() => {
            handleChange(!value)
          }}
        >
          <span>
            <FaCheck />
          </span>
          {label}
        </div>
    </Root>
  );
}
const Root = styled.div`
  display: flex;
  flex:1;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 14px;
  margin:10px 0px;
  > div {
    flex:1;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    min-width: fit-content;
    > span {
      min-width: 24px;
      height: 24px;
      border: 1px solid ${(p) =>p.theme.primary_txt};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      svg{
        display:none;
        color:${(p) =>p.theme.primary_bg};
      }
    }
    &.active{
        span{
            svg{
                display:block;
            }
        }
    }
  }
`;
