import React, { useEffect, useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import { useDispatch, useSelector } from "react-redux";
import { addOpacity } from "../../../../utils/helpers";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import {
  deleteaddGSTDetailsAction,
  deleteBusinessDetails44AEAction,
  deleteGoodsCarriagesAction,
  deleteIncome44AD,
  deleteIncome44ADA,
  delProfessionalBusiness3,
  getProfessionalBusiness,
  getProfessionalBusiness3,
  deleteLiabilitiesAndAssetssAction,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import FinancialParticular from "./FinancialParticular";
import AddGst from "./AddGst";
import { useNavigate } from "react-router-dom";
import CompLoadingBox from "../../../DialogBox/CompLoadingBox";
import Select from "../../../../styledComponent/Inputs/Select";

export default function ProfessionalIncome({
  incomeData,
  gstData,
  financialParticularsData,
  handleCallback,
}) {
  const [professionalIncomeForm, setProfessionalIncomeForm] = useState(false);
  const [financialParticularForm, setFinancialParticularForm] = useState(false);
  const [gstFormBox, setGstFormBox] = useState(false);
  const [optionData, setOptionData] = useState({});
  const [activeOption, setActiveOption] = useState();
  const [editFormData, setEditFormData] = useState({});
  const [propertyObj, setPropertyObj] = useState([]);
  const [gstFormData, setGstFormData] = useState([]);
  const [professionalIncomeData, setProfessionalIncomeData] = useState({});
  const [gstFinancialParticularData, setFinancialParticularData] = useState({});
  const [loading, setLoading] = useState(false);
  const { strings, theme, categories } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getProfessionalBusinessApi = async () => {
    // setLoading(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness(parm, (e) => {
        if (e.status == 200) {
          setOptionData(e.data);
          setPropertyObj(e?.data?.businessIncomeType?.slice(0, 3));
          setActiveOption(e?.data?.businessIncomeType?.slice(0, 3)[0]);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const getProfessionalBusiness3Api = async () => {
    // setLoading(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness3(parm, (e) => {
        if (e.status == 200) {
          setOptionData(e.data);
          setPropertyObj(e?.data?.businessIncomeType?.slice(3, 5));
          setActiveOption(e?.data?.businessIncomeType?.slice(3, 5)[0]);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteProfessionalIncomeDataApi = async (id, type, busiId) => {
    setLoading(true);
    if (type == "gstDetails") {
      dispatch(
        deleteaddGSTDetailsAction({ id }, (e) => {
          if (e.status == 200) {
            setGstFormData(gstFormData.filter((i) => i.id != id));
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "otherBusinessIncome") {
      dispatch(
        delProfessionalBusiness3({ id, type: "OtherBusinessIncome" }, (e) => {
          if (e.status == 200) {
            setProfessionalIncomeData({
              ...professionalIncomeData,
              otherBusinessIncome: {},
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "regularBusinessIncome") {
      dispatch(
        delProfessionalBusiness3({ id, type: "RegularBusinessIncome" }, (e) => {
          if (e.status == 200) {
            setProfessionalIncomeData({
              ...professionalIncomeData,
              otherBusinessIncome: {},
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "professionalIncome") {
      dispatch(
        deleteIncome44ADA({ id }, (e) => {
          if (e.status == 200) {
            setProfessionalIncomeData({
              ...professionalIncomeData,
              professionalIncome: {},
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "businessIncome") {
      dispatch(
        deleteIncome44AD({ id }, (e) => {
          if (e.status == 200) {
            setProfessionalIncomeData({
              ...professionalIncomeData,
              businessIncome: {},
            });
            toast.success(e?.data);
          } else {
            toast.error(e.message);
          }
          setLoading(false);
        })
      );
    } else if (type == "goodsCarriage") {
      dispatch(
        deleteBusinessDetails44AEAction(
          { id, professionalBusinessId: busiId },
          (e) => {}
        )
      );
      dispatch(
        deleteGoodsCarriagesAction(
          { id, professionalBusinessId: busiId },
          (e) => {}
        )
      );
      setProfessionalIncomeData({
        ...professionalIncomeData,
        businessDetail44AE: {},
      });
      toast.success("Successfully delete the data ");
      setLoading(false);
    } else if (type == "financial") {
      dispatch(deleteLiabilitiesAndAssetssAction({ id },
        (e) => {}
      ));
      toast.success("Successfully delete the data ");
      setFinancialParticularData({});
      handleCallback();
      setLoading(false);
    }
  };
  const checkRedirection = () => {
    navigate("/form/filing/ITR/grossincome/professionalincome");
  };

  // useEffect(() => {
  //   ItrData.form === "ITR_3"
  //     ? getProfessionalBusiness3Api()
  //     : getProfessionalBusinessApi();
  // }, []);
  useEffect(() => {
    setLoading(true); // Show loader when the API calls start
    const fetchApis = async () => {
      try {
        // Build the array of promises conditionally
        const apiCalls =
          ItrData.form !== "ITR_4"
            ? [getProfessionalBusiness3Api()][getProfessionalBusinessApi()]
            : [getProfessionalBusinessApi()];

        await Promise.all(apiCalls); // Wait for the API call to resolve
      } catch (error) {
        console.error("Error fetching data from APIs:", error);
      } finally {
        setLoading(false); // Hide loader after the API call finishes
      }
    };

    fetchApis();
  }, [ItrData.form]);

  useEffect(() => gstData && setGstFormData(gstData), [gstData]);
  useEffect(
    () => incomeData && setProfessionalIncomeData(incomeData),
    [incomeData]
  );

  useEffect(
    () =>
      financialParticularsData?.id &&
      setFinancialParticularData(financialParticularsData),
    [financialParticularsData]
  );

  return (
    <>
      {/* --------- Professional, Freelancing and Business Income ------------ */}
      {loading ? (
        <CompLoadingBox open={loading} />
      ) : (
        <AccordianBox
          title={strings.income.professional_freelencing_business_income}
          description={strings.income.for_all_professionals}
        >
          {/* professional Income */}
          {professionalIncomeData?.professionalIncome?.id && (
            <div
              className="flex gap-2 p-3 my-3 items-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <p>
                Professional Income (Total Expenses) :{" "}
                {professionalIncomeData?.professionalIncome.totalExpenses}
              </p>
              <div className="action_buttons">
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(
                      professionalIncomeData?.professionalIncome?.id,
                      "professionalIncome"
                    )
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          )}

          {/* Business Income */}
          {professionalIncomeData?.businessIncome?.id && (
            <div
              className="flex gap-2 p-3 my-3 items-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <p>
                Business Income (Total Expenses) :{" "}
                {professionalIncomeData?.businessIncome.totalIncome}
              </p>
              <div className="action_buttons">
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(
                      professionalIncomeData?.businessIncome?.id,
                      "businessIncome"
                    )
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          )}

          {/* Goods Carriages Under Section 44AE -----  */}
          {(professionalIncomeData?.businessDetail44AE?.goodsCarriageDetails
            ?.length > 0 ||
            professionalIncomeData?.businessDetail44AE?.businessDetails
              ?.length > 0) && (
            <div
              className="flex gap-2 p-3 my-3 items-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <p>Goods Carriages Details</p>
              <div className="action_buttons">
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(
                      professionalIncomeData?.businessDetail44AE?.id,
                      "goodsCarriage",
                      professionalIncomeData?.id
                    )
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          )}

          {/* regularBusinessAndProfession  */}
          {professionalIncomeData?.regularBusinessAndProfession?.active && (
            <div
              className="flex gap-2 p-3 my-3 items-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <p>Regular Business And Profession Income</p>
              <div className="action_buttons">
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(
                      professionalIncomeData?.regularBusinessAndProfession?.id,
                      "regularBusinessIncome"
                    )
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          )}

          {/* other Business Income */}
          {professionalIncomeData?.otherBusinessIncome?.active && (
            <div
              className="flex gap-2 p-3 my-3 items-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <p>Other Business Income</p>
              <div className="action_buttons">
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(
                      professionalIncomeData?.otherBusinessIncome?.id,
                      "otherBusinessIncome"
                    )
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          )}

          <button className="secandary_button" onClick={checkRedirection}>
            {strings.button.add_edit_entry}
          </button>

          <hr className="mt-4" />

          {/* --------- Financial Particulars -------------------- */}
          <div className="flex justify-between items-center">
            <div className="my-5">
              <h4>{strings.income.financial_particulars}</h4>
              <p>{strings.income.mandatory_presumptive_income}</p>
            </div>
            <button
                className="add_button"
                onClick={() => {
                  setFinancialParticularForm(true);
                }}
              >
                { gstFinancialParticularData.id ? strings.button.edit : strings.button.add }
              </button>
          </div>
          {gstFinancialParticularData.id ? (
            <div
              className="flex gap-2 p-3 my-3 align-center justify-between"
              style={{
                background: addOpacity(theme.primary_bg, 0.2),
                borderRadius: "10px",
              }}
            >
              <div>
                <p>
                  Total Assets :{" "}
                  {gstFinancialParticularData.fpAssets.totalAssets}
                </p>
                <p>
                  Total Liabilities :{" "}
                  {gstFinancialParticularData.fpLiabilities.totalLiabilities}
                </p>
              </div>

              <div className="action_buttons">
                {/* <button
                  className="edit_btn"
                  onClick={() => {
                    setGstFormBox(true);
                    setEditFormData(gstFormData);
                  }}
                >
                  <FaRegEdit />
                </button> */}
                <button
                  className="delete_btn"
                  onClick={() =>
                    deleteProfessionalIncomeDataApi(gstFinancialParticularData.id , "financial")
                  }
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          ) : (
            ""
          )}

          <hr className="mt-4" />
          
          {/* --------------- Add GST Details --------- */}
          <div className="flex items-center justify-between my-4">
            <h4>{strings.income.add_gst_details}</h4>
            <button
              className="add_button"
              onClick={() => {
                gstFormData.length > 0 && setEditFormData(gstFormData);
                setGstFormBox(true);
              }}
            >
              {gstFormData.length > 0
                ? `${strings.button.add} / ${strings.button.edit}`
                : strings.button.add}
            </button>
          </div>

          <div className="mt-4">
            {gstFormData?.map((item) => (
              <div
                className="flex gap-2 p-3 my-3 align-center justify-between"
                style={{
                  background: addOpacity(theme.primary_bg, 0.2),
                  borderRadius: "10px",
                }}
              >
                <div>
                  <p>GST Number : {item.gstnNumber}</p>
                  <p>Gross Receipts : {item.grossReceipts}</p>
                </div>

                <div className="action_buttons">
                  <button
                    className="delete_btn"
                    onClick={() =>
                      deleteProfessionalIncomeDataApi(item?.id, "gstDetails")
                    }
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* -----------------------------------------------  Financial Particulars ------------------------------------------------- */}
          <FormDialogBox
            open={financialParticularForm}
            data={editFormData}
            formTitle={strings.income.financial_particulars}
            handleClose={() => {
              setFinancialParticularForm(false);
            }}
          >
            <FinancialParticular formsData={gstFinancialParticularData}  handleClose={() => {
              handleCallback();
              setFinancialParticularForm(false);
            }}/>
          </FormDialogBox>

          {/* ----- Add GST Details ----- */}
          <FormDialogBox
            open={gstFormBox}
            formTitle={strings.income.add_gst_details}
            handleClose={() => {
              setGstFormBox(false);
              setEditFormData({});
            }}
          >
            <AddGst
              data={editFormData}
              handleCallback={(type, e) => {
                setGstFormData(e);
                setGstFormBox(false);
                setEditFormData({});
              }}
            />
          </FormDialogBox>
        </AccordianBox>
      )}
    </>
  );
}
