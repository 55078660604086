import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { removeEmptyKeys } from "../../../../../utils/helpers";
import { toast } from "react-toastify";
import {
  editDetailOfSection40And43B,
  saveDetailOfSection40And43B,
} from "../../../../../redux/itr/action";
import Select from "../../../../../styledComponent/Inputs/Select";
import Input from "../../../../../styledComponent/Inputs/Input";
import * as Yup from "yup";


export default function SummarySection43And40({ isSection40,section, data, handleCallback }) {
  const [formData, setFormData] = useState({});
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const handleSubmitForm = (obj) => {
    setLoader(true);
    let { type, ...data } = obj;
    const action =
      type == "edit" ? editDetailOfSection40And43B : saveDetailOfSection40And43B;
    dispatch(
      action(data, (e) => {
        if (e.status === 200) {
          handleCallback(type , e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const validationSchema = Yup.object().shape(
    {
      section: Yup.number().required(strings.validation_message.required),
      particulars: Yup.string(),
      broughtForwardFY: Yup.string().matches(/^\d{4}-\d{4}$/, "Format must be YYYY-YYYY"),
      amountBroughtForward: Yup.number(),
      amountAllowableForCurrentYear: Yup.number(),
      amountDebitedToPAndL: Yup.number(),
      amountDisallowedForCurrentYear: Yup.number(),
    }
  );

  useEffect(() => {
    setLoader(true);
    setFormData({
      section: data?.section?.id || "",
      particulars: data?.particulars || "",
      broughtForwardFY: data?.broughtForwardFY || "",
      amountBroughtForward: data?.amountBroughtForward || "",
      amountAllowableForCurrentYear: data?.amountAllowableForCurrentYear || "",
      amountDebitedToPAndL: data?.amountDebitedToPAndL || "",
      amountDisallowedForCurrentYear:data?.amountDisallowedForCurrentYear || "",
      isSection40: isSection40,
      itrId: data?.id ? "" :ItrData?.id,
      id: data?.id || "",
      type: data?.id ? "edit" : "add",
    });
    setLoader(false);
  }, [data]);
  const handleYears = (value) => {
    const cleanedNumber = value?.replace(/[^\d]/g, ""); // Remove non-numeric characters
    const chunks = cleanedNumber.match(/.{1,4}/g); // Split into groups of 4
    const formattedNumber = chunks ? chunks.join("-") : value;
    return formattedNumber;
  };
  console.log("uuu",isSection40)
  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <StyleFormBox>
              <div>
                <label>{strings.income.section}</label>
                <Select
                  name={"section"}
                  error={errors.section}
                  value={values?.section}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {section?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <label>{strings.income.particulars}</label>
                <Input
                  inputType={"message"}
                  name={"particulars"}
                  error={errors.particulars}
                  value={values.particulars}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>{strings.income.brought_forward_financial_year}</label>
                <Input
                  name={"broughtForwardFY"}
                  error={errors.broughtForwardFY}
                  value={values.broughtForwardFY && values.broughtForwardFY.length > 1 ? handleYears(values.broughtForwardFY) : values.broughtForwardFY}
                  onChange={handleChange}
                  maxlength={9}
                />
              </div>
              <div>
                <label>{strings.income.amount_brought_forward}</label>
                <Input
                  inputType={"currency"}
                  name={"amountBroughtForward"}
                  error={errors.amountBroughtForward}
                  value={values.amountBroughtForward}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>
                  {strings.income.amount_disallowed_preceding_for_current_year}
                </label>
                <Input
                  inputType={"currency"}
                  name={"amountAllowableForCurrentYear"}
                  error={errors.amountAllowableForCurrentYear}
                  value={values.amountAllowableForCurrentYear}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>{strings.income.amount_debited_to_pl_account}</label>
                <Input
                  inputType={"currency"}
                  name={"amountDebitedToPAndL"}
                  error={errors.amountDebitedToPAndL}
                  value={values.amountDebitedToPAndL}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>
                  {
                    strings.income
                      .amount_debited_to_pl_disallowed_for_current_year
                  }
                </label>
                <Input
                  inputType={"currency"}
                  name={"amountDisallowedForCurrentYear"}
                  error={errors.amountDisallowedForCurrentYear}
                  value={values.amountDisallowedForCurrentYear}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            <button className="primary_button my-4" type="submit">
              {strings.button.save_and_close}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}
