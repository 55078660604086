import React, { useEffect } from 'react'
import { IoClose } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import styled from 'styled-components'
export default function FormDialogBox({open,formTitle,handleClose,children,width}) {
    const { theme ,strings} = useSelector((state) => state.platformSetting);

    const handleScroll = ()=>{
      document.body.style.overflow = open ? 'hidden' : 'auto';
    }
    useEffect(()=>{
      handleScroll()
    },[open])
  return (
    <Root theme={theme} className={open && 'active'} formWidth={width}>
    <button onClick={() => {handleClose()}} className="close_btn_bg"></button>
    <div className="dialogBox">
        <div className="title">
          <h1 style={{color:theme.primary_txt, fontWeight:600, fontSize:"20px"}}>{formTitle}</h1>
          <button className="close_btn" onClick={() => {handleClose()}} style={{color:theme.primary_txt}}><IoClose /></button>
        </div>
        {open &&  children }
    </div>
  </Root>
  )
}
const Root = styled.section`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000042;
  backdrop-filter: blur(2px);
  top: 0px;
  left: 0px;
  display:none;
  z-index:10;
  &.active{
    display:unset
  }
  .close_btn_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: transparent;
  }
  .close_btn{
    background: ${p => p.theme.tertiary_bg};
    border: none;
    font-size: 20px;
    border-radius: 4px;
  }
  .dialogBox {
    position: absolute;
    left:50%;
    top:20px;
    transform: translateX(-50%);
    background: ${(p) => p.theme.secandary_bg};
    width: 100%;
    max-width: ${props => props.formWidth || '800px'};
    border-radius: 10px;
    padding: 0px 20px;
    overflow: scroll;
    max-height: 94%;
    /* max-height: 94vh; */
    border:1px solid ${(p) => p.theme.border_clr};
    .title{
      display:flex;
      align-items:center;
      justify-content:space-between;
      border: 2px solid ${(p) => p.theme.border_clr};
      background: ${(p) => p.theme.secandary_bg};
      position:sticky;
      top:0px;
      border-width: 0px 0px 2px 0px;
      padding: 14px 0px;
      z-index: 9;
    }
  }
`