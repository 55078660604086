import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaRegSquareCaretRight } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import { useSelector } from "react-redux";
export default function GratuityCalculator() {
    const {theme } = useSelector((s) => s.platformSetting);
    return (
        <Root theme={theme}>
            <div className="header-section">
                <div className="header-content flex justify-center items-center">
                    <div className="text-line">
                        <h5>Filing for AY 2024-25 is coming soon</h5>
                    </div>
                    <div className="pre-button">
                        <button type="button">Pre-Book Now</button>
                        <span className="off_coupon">Grab 25% OFF</span>
                    </div>
                </div>
            </div>
            <div className="tds-Calculator-section">
                <div className="container_box">
                    <div className="tds-main">
                        <div className="tds-left">
                            <div className="tds-left-heading">
                                <h2> Gratuity Calculator</h2>
                                <p>This Gratuity Calculator lets you calculate Gratuity Tax Exemption. Gratuity is the amount of payment made by an employer to an employee in appreciation of the past services rendered by the employee, which is received at the time of retirement or in the event of death. The maximum gratuity tax exemption that an employee can avail of is Rs. 20,00,000.</p>
                                <h5>Gratuity-Exemption is dependent upon two criteria:</h5>
                                <p>Status of Employee, i.e., whether Government Employee or Non-Government Employee</p>
                                <p>Whether the employee is covered under Gratuity Act, 1972 or not.</p>
                            </div>
                        </div>
                        <div className="tds-right">
                            <div className="tds-box">
                                <div className="tds-heading-topbar">
                                    <strong>Financial Year :2021-2022 </strong>
                                </div>
                                <div className="first-field"><div className="abc"><label className="abc" for="css">Employer Type:</label></div><select name="cars" id="cars">
                                    <option value="volvo">Government or Local Authorities</option>
                                    <option value="volvo">Gratuity Act Applies</option>
                                    <option value="saab">Gratuity Act does not Apply</option>

                                </select></div>
                                <div className="second-field">
                                    <div className="second-main">
                                        <div className="abc">
                                            <label className="abc" for="css">Basic Pay:</label>
                                        </div>

                                        <input type="text" id="fname" name="fname" placeholder="Amount of Payment"></input>
                                        <button className="btns" type="text"><span className="icon-search"><FaRupeeSign /></span></button>
                                    </div>
                                    <div className="second-main">
                                        <div className="abc">
                                            <label className="abc" for="css">Dearness Allowance:</label>
                                        </div>
                                        <input type="text" id="fname" name="fname" placeholder="Amount of Payment"></input>
                                        <button className="btns" type="text"><span className="icon-search"><FaRupeeSign /></span></button>

                                    </div>
                                </div>
                                <div className="second-field">
                                    <div className="second-main">
                                        <div className="abc">
                                            <label className="abc" for="css">Number of years of Service:</label>
                                        </div>
                                        <input type="text" id="fname" name="fname" placeholder="Amount of Payment"></input>
                                    </div>
                                    <div className="second-main">
                                        <div className="abc">
                                            <label className="abc" for="css">Gratuity received:</label>
                                        </div>
                                        <input type="text" id="fname" name="fname" placeholder="Amount of Payment"></input>
                                        <button className="btns" type="text"><span className="icon-search"><FaRupeeSign /></span></button>

                                    </div>
                                </div>

                                <div className="cal-btn">
                                    <button type="button">Calculate</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="btm-img-main">
                        <div className="img-23">
                            <img src="Images/tds-calculator.png"></img>
                        </div>

                    </div>


                </div>
            </div>
            <div className="gratuity-section">
                <div className="gratuity-box">
                    <div className="container_box">
                        <div className="gratuity-content">
                            <h4>What is gratuity?</h4>
                            <p>It is the amount received by the salaried person from their employer as a mark of recognition for their long-term service to the organization. An employee who has worked in an organization for a minimum period of 5 years is eligible for gratuity.</p><br></br>
                            <p>But not everyone is aware that gratuity is part of our salary. Gratuity is a reward paid by the employer to Employee for services rendered by him. In simple words, it is a retirement benefit received by an employee when he leaves his job.</p><br></br>
                            <hr></hr>
                            <h4>Who gets Gratuity?</h4>
                            <p>An employee who completed 5 or more years of service is eligible for gratuity. This condition of 5 years of service is not applicable in case of death or any disability of an employee.</p><br></br>
                            <hr></hr>
                            <h4>When is the gratuity received?</h4>
                            <h6>After completing five years of continuous service, you will be eligible to receive the gratuity benefit. It is payable: </h6>
                            <ul>
                                <li><IoMdCheckmarkCircleOutline />On Superannuation (or) Retirement.</li>
                                <li><IoMdCheckmarkCircleOutline />On your Resignation (or) Termination (the gratuity can be rejected in case the termination is on account of the misconduct of the employee)</li>
                                <li><IoMdCheckmarkCircleOutline />On death or Disablement due to accident or disease. (the sum of gratuity will be paid to the nominee or legal heir, in this case, continuous service of five years is not applicable in this case)</li>
                                <hr></hr>
                            </ul>
                            <h4>Is the amount of gratuity received my Income?</h4>
                            <p>Gratuity received by you on your retirement or during your service period is taxable under the head "Income from Salary. In case of the death of an employee, Gratuity will be paid to the legal heir/nominee of an employee which is taxable under the head” Income from other sources".</p>
                            <p>Our experts are here to help you claim all the available tax exemptions and deductions & file your IT return in a hassle-free manner. Connect now</p>
                            <hr></hr>
                            <h4>How to calculate gratuity tax treatment?</h4>
                            <p>Gratuity is taxable when it is received under the head Income from salary. An employee can claim an exemption maximum of up to Rs. 20,00,000. However, the calculation of gratuity varies according to various categories of employees as follows:</p><br></br>
                            <h6>You can use our free Gratuity calculator to calculate your exemption amount.</h6>
                            <div className="table-responsive">
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <h5>Categories</h5>
                                            </th>
                                            <th>
                                                <h5>Tax treatment</h5>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td><strong>Government employee</strong></td>
                                            <td><strong>Fully Exempt</strong></td>
                                        </tr>
                                        <tr>
                                            <td id="option1">Non-government employee covered under Payment of gratuity act 1972 </td>
                                            <td>
                                                Least of the following:
                                                <ul>
                                                    <li>Actual Gratuity received</li>
                                                    <li>Rs. 20,00,000</li>
                                                    <li>No. of completed years of service or part thereof in excess of 6 month * 15/26* salary
                                                        last drawn </li>
                                                </ul>
                                                <i>Meaning of Salary = Basic Salary + D.A</i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td id="option2">Non-government employee not covered under Gratuity Act 1972</td>
                                            <td> Maximum exemption from tax –
                                                <p><b>Least</b> of the following:</p>
                                                <ul>
                                                    <li>Actual Gratuity received</li>
                                                    <li>Rs. 20,00,000</li>
                                                    <li>Half of Average salary * completed year of service (part of the month not considered
                                                    </li>
                                                    <li>Average salary means average of salary in last 10 months preceding the month of
                                                        retirement</li>
                                                </ul>
                                                <i>Meaning of Salary = Basic Salary + D.A + percentage wise fixed commission on turnover</i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </Root>
    );
}

const Root = styled.section`
.header-section {
    width:100%;
    background:linear-gradient(90.09deg, #fbd63f 1.01%, #fc466b 99.06%);
    padding:20px 0;
    .header-content {
        width:100%;
        .text-line {
            width:30%;
            h5 {
                color:#fff;
                text-align:center;
            }
            @media (max-width:576px) {
                margin-bottom:10px;
                width:100%;
            }
        }
        .pre-button {
            button {
                width: 150px;
                background: ${(p) => p.theme.secandary_bg};
                padding: 12px;
                border-radius: 5px;
            }
            .off_coupon {
                border: dashed 2px #fff;
                font-weight: 600;
                background: rgba(255, 255, 255, .1);
                margin-left: 1rem;
                border-radius: .5rem;
                padding: .5rem 1rem;
                color:#fff;
            }
        }
        @media (max-width:576px) {
            flex-direction:column;
        }
    }
}
.tds-Calculator-section {
    width:100%;
    background: ${(p) => p.theme.primary_bg};
    padding:50px 0;
    .tds-main {
        width:100%;
        display:flex;
        .tds-left {
            width:40%;
            .tds-left-heading {
                h2 {
                    font-size:32px;
                    font-weight:700;
                    color:#fff;
                    
                }
                p{
                    font-size:18px;
                    font-weight:400;
                    color:#fff;
                }
                h5 {
                    font-size:20px;
                    font-weight:700;
                    color:#fff;
                    margin:10px 0;
                }
            }
            @media (max-width:991px) {
                width: 100%;
                text-align: center;
                margin-bottom:20px;
            }
        }
        .tds-right {
            width:60%;
            .tds-box {
              background:#fff;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              padding:30px 15px;
              border-radius:10px;
                .tds-heading-topbar {
                    width:100%;
                    text-align:center;
                    margin-bottom:15px;
               }
                
                .second-field {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    margin:10px 0;
                    .second-main {
                        width:50%;
                        position:relative;
                        .abc {
                            margin: 10px 0;
                            font-size: 12px;
                        }
                        select {
                            margin-bottom: 10px;
                            padding: 10px 15px;
                            height: auto;
                            width: 100%;
                            border-radius: 3px;
                            background-clip: padding-box;
                            border-color: #d9dbde;
                            box-shadow: 0 0 10px #f3f3f3;
                            transition: .5s;
                            font-size:12px;
                            option {
                                font-size:14px;
                                
                            }
                        }
                        input {
                            margin-bottom: 10px;
                            padding: 10px 15px;
                            height: auto;
                            width: 100%;
                            border-radius: 3px;
                            background-clip: padding-box;
                            border-color: #d9dbde;
                            box-shadow: 0 0 10px #f3f3f3;
                            transition: .5s;
                            font-size:12px;
                        }
                        .btns {
                            top: 49px;
                            position: absolute;
                             right: 10px;
                        }
                    }
                }
                .first-field {
                    .abc {
                        margin: 10px 0;
                        font-size: 12px;
                    }
                    select {
                        margin-bottom: 10px;
                        padding: 10px 15px;
                        height: auto;
                        width: 100%;
                        border-radius: 3px;
                        background-clip: padding-box;
                        border-color: #d9dbde;
                        box-shadow: 0 0 10px #f3f3f3;
                        transition: .5s;
                        font-size:12px;
                        color:red;
                        option {
                            font-size:12px;
                            color:red;
                        }
                    }
                }
                .cal-btn {
                    button {
                        width: 140px;
                       background: #ffa53b;
                       padding: 10px;
                       color: #fff;
                       border-radius: 4px;
                    }
                }
            }
            @media (max-width:991px) {
                width:80%;
            }
        }
        @media (max-width:991px) {
            flex-direction:column;
            align-items:center;

        }
    }
    .btm-img-main {
        position:relative;
        .img-23 {
            position: absolute;
            bottom: -141px;
            width: 20%;
            left: 60px;
        }
    }  
    
}
.gratuity-section {
    width:100%;
    margin:50px 0;
    .gratuity-box {
        width:100%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding:40px 0;
        background: ${(p) => p.theme.secandary_bg};
        .gratuity-content {
            h4 {
                font-size:24px;
                font-weight:700;
                margin:15px; 
            }
            p{
                font-weight:400;
                font-size:14px;
            }
            h6 {
                font-size:18px;
                font-weight:700;
                margin-bottom:10px;
            }
            li {
                display:flex;
                align-items:center;
                font-weight:400;
                font-size:14px;
            }
             table tr th {
                border: solid 1px #e6e6e6;
                padding: 10px 15px;
            }
             table tr td,  table tr th {
                border: solid 1px #e6e6e6;
                padding: 10px 15px;
            }
             ul {
                padding: 0 0 0 20px;
            }
             ul li {
                padding: 5px 20px 5px 35px;
                list-style-type: none;
                font-size: 14px;
                font-weight: 500;
                line-height: 25px;
                
            }
        }
    }
}
`