import React, { useEffect, useState } from "react";
import Input from "../../../../styledComponent/Inputs/Input";
import { FieldArray, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteScheduleFDAction,
  getScheduleSHAction,
  saveScheduleSHAction,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import { addOpacity, removeEmptyKeys } from "../../../../utils/helpers";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Select from "../../../../styledComponent/Inputs/Select";

export default function ScheduleSH1() {
  const [formData, setFormData] = useState({});
  const [formBox, setFormBox] = useState(false);
  const [loader, setLoader] = useState(false);
  const [residentType, setResidentType] = useState([]);
  const [typeOfShare, setTypeOfShare] = useState([]);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const handleFormSubmit = (obj) => {
    setLoader(true);
    delete obj.id;
    console.log("obj=============>", obj);
    if (!obj.scheduleSH_A && !obj.scheduleSH_B && !obj.scheduleSH_C) {
      return toast.error("Please fill atleast one section");
    }

    dispatch(
      saveScheduleSHAction(obj, (e) => {
        if (e.status == 200) {
          let data = e.data;
          setFormData({
            itrId: ItrData.id,
            id: data?.id || "0",
            paymentCapitalAccount: data?.paymentCapitalAccount || "",
            paymentReveueAccount: data?.paymentReveueAccount || "",
            receiptsCapitalAccount: data?.receiptsCapitalAccount || "",
            receiptsRevenueAccount: data?.receiptsRevenueAccount || "",
          });
          toast.success(`Successfully Added data`);
          setFormBox(false);
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const getScheduleSHData = () => {
    setLoader(true);
    dispatch(
      getScheduleSHAction(ItrData.id, (e) => {
        if (e.status == 200) {
          let data = e.data;
          setResidentType(data.residentType);
          setTypeOfShare(data.typeOfShare);
          setFormData({
            scheduleSH_id: data.id || "",
            itrId: ItrData.id,
            scheduleSH_A:
              data.scheduleSH_A?.map((item) => ({
                nameOfShareholder: item.nameOfShareholder || "",
                residentialStatus: item.residentialStatus || "",
                typeOfShare: item.typeOfShare || "",
                pan: item.pan || "",
                dateOfAllotment: item.dateOfAllotment || "",
                noOfSharesHeld: item.noOfSharesHeld || "",
                faceValuePerShare: item.faceValuePerShare || "",
                issuePricePerShare: item.issuePricePerShare || "",
                amountReceived: item.amountReceived || "",
              })) || [],
            scheduleSH_B:
              data.scheduleSH_B?.map((item) => ({
                nameOfApplicant: item.nameOfApplicant || "",
                residentialStatus: item.residentialStatus || "",
                typeOfShare: item.typeOfShare || "",
                pan: item.pan || "",
                dateOfApplication: item.dateOfApplication || "",
                noOfSharesApplied: item.noOfSharesApplied || "",
                applicationMoneyReceived: item.applicationMoneyReceived || "",
                faceValuePerShare: item.faceValuePerShare || "",
                proposedIssuePrice: item.proposedIssuePrice || "",
              })) || [],

            scheduleSH_C:
              data.scheduleSH_C?.map((item) => ({
                nameOfShareholder: item.nameOfShareholder || "",
                residentialStatus: item.residentialStatus || "",
                typeOfShare: item.typeOfShare || "",
                pan: item.pan || "",
                noOfSharesHeld: item.noOfSharesHeld || "",
                faceValuePerShare: item.faceValuePerShare || "",
                issuePricePerShare: item.issuePricePerShare || "",
                amountReceived: item.amountReceived || "",
                dateOfAllotment: item.dateOfAllotment || "",
                exitDate: item.exitDate || "",
                modeOfCessation: item.modeOfCessation || "",
                transfereePan: item.transfereePan || "",
              })) || [],
          });
        } else {
          toast.error(e.message);
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    getScheduleSHData();
  }, []);

  console.log("formData ======>", formData);
  return (
    <div>
      <LoadingBox open={loader} />

      {(formData.scheduleSH_id > 0) &
      (formData.scheduleSH_A?.length > 0 ||
        formData.scheduleSH_B?.length > 0 ||
        formData.scheduleSH_C?.length > 0) ? (
        <div
          className="flex gap-2 p-3 my-3 align-center justify-between"
          style={{
            background: addOpacity(theme.primary_bg, 0.2),
            borderRadius: "10px",
          }}
        >
          <div>
            {formData?.scheduleSH_A?.[0]?.nameOfShareholder && (
              <p>
                A : Name of Shareholder:{" "}
                {formData?.scheduleSH_A[0].nameOfShareholder}
              </p>
            )}

            {formData?.scheduleSH_B?.[0]?.nameOfApplicant && (
              <p>
                B : Name of Applicant:{" "}
                {formData?.scheduleSH_B[0].nameOfApplicant}
              </p>
            )}

            {formData?.scheduleSH_C?.[0]?.nameOfShareholder && (
              <p>
                C : Name of shareholders who is not a shareholder at the end of
                the previous year: {formData?.scheduleSH_C[0].nameOfShareholder}
              </p>
            )}
          </div>

          <div className="action_buttons">
            <button
              className="edit_btn"
              onClick={() => {
                setFormBox(true);
              }}
            >
              <FaRegEdit />
            </button>
          </div>
        </div>
      ) : (
        <div>
          <button
            className="primary_button mt-4"
            onClick={() => setFormBox(true)}
          >
            Add Schedule SH-1
          </button>
        </div>
      )}
      <FormDialogBox
        open={formBox}
        formTitle={"Schedule SH-1 Details"}
        handleClose={() => {
          setFormBox(false);
        }}
      >
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            handleFormSubmit(removeEmptyKeys(values));
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <div className="mt-4">
              {/* A: Details of shareholding at the end of the previous year */}
              <FieldArray name="scheduleSH_A">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">
                      A: Details of shareholding at the end of the previous year
                    </h4>

                    {values?.scheduleSH_A?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Name of Shareholder</label>
                              <Input
                                value={item.nameOfShareholder}
                                name={`scheduleSH_A.${index}.nameOfShareholder`}
                                error={
                                  errors?.scheduleSH_A &&
                                  errors?.scheduleSH_A[index]?.nameOfShareholder
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Residential Status </label>
                              <Select
                                value={item.residentialStatus}
                                name={`scheduleSH_A.${index}.residentialStatus`}
                                error={
                                  errors?.scheduleSH_A &&
                                  errors?.scheduleSH_A[index]?.residentialStatus
                                }
                                onChange={handleChange}
                              >
                                <option value={""} disabled>
                                  {strings.label.choose_option}
                                </option>
                                {residentType?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>

                            <div>
                              <label>Type of Share</label>
                              <Select
                                value={item.typeOfShare}
                                name={`scheduleSH_A.${index}.typeOfShare`}
                                error={
                                  errors?.scheduleSH_A &&
                                  errors?.scheduleSH_A[index]?.typeOfShare
                                }
                                onChange={handleChange}
                              >
                                <option value={""} disabled>
                                  {strings.label.choose_option}
                                </option>
                                {typeOfShare?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>PAN</label>
                              <Input
                                inputType={"pan"}
                                value={item.pan}
                                name={`scheduleSH_A.${index}.pan`}
                                error={
                                  errors?.scheduleSH_A &&
                                  errors?.scheduleSH_A[index]?.pan
                                }
                                onChange={(e) => {
                                  if (e.target.value.length <= 10) {
                                    setFieldValue(`scheduleSH_A.${index}.pan`, e.target.value.toUpperCase());
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <label>Date of Allotment</label>
                              <Input
                                inputType={"date"}
                                value={item.dateOfAllotment}
                                name={`scheduleSH_A.${index}.dateOfAllotment`}
                                error={
                                  errors?.scheduleSH_A &&
                                  errors?.scheduleSH_A[index]?.dateOfAllotment
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>No of Shares Held</label>
                              <Input
                                type={"Number"}
                                value={item.noOfSharesHeld}
                                name={`scheduleSH_A.${index}.noOfSharesHeld`}
                                error={
                                  errors?.scheduleSH_A &&
                                  errors?.scheduleSH_A[index]?.noOfSharesHeld
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Face Value Per Share</label>
                              <Input
                                type={"Number"}
                                value={item.faceValuePerShare}
                                name={`scheduleSH_A.${index}.faceValuePerShare`}
                                error={
                                  errors?.scheduleSH_A &&
                                  errors?.scheduleSH_A[index]?.faceValuePerShare
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Issue Price per Share</label>
                              <Input
                                type={"Number"}
                                value={item.issuePricePerShare}
                                name={`scheduleSH_A.${index}.issuePricePerShare`}
                                error={
                                  errors?.scheduleSH_A &&
                                  errors?.scheduleSH_A[index]
                                    ?.issuePricePerShare
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>Amount Received</label>
                              <Input
                                type={"Number"}
                                value={item.amountReceived}
                                name={`scheduleSH_A.${index}.amountReceived`}
                                error={
                                  errors?.scheduleSH_A &&
                                  errors?.scheduleSH_A[index]?.amountReceived
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            nameOfShareholder: "",
                            residentialStatus: "",
                            typeOfShare: "",
                            pan: "",
                            dateOfAllotment: "",
                            noOfSharesHeld: "",
                            faceValuePerShare: "",
                            issuePricePerShare: "",
                            amountReceived: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* B: Details of share application money pending allotment at the end of the previous year */}
              <FieldArray name="scheduleSH_B">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">
                      B: Details of share application money pending allotment at
                      the end of the previous year
                    </h4>

                    {values?.scheduleSH_B?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Name of Applicant</label>
                              <Input
                                value={item.nameOfApplicant}
                                name={`scheduleSH_B.${index}.nameOfApplicant`}
                                error={
                                  errors?.scheduleSH_B &&
                                  errors?.scheduleSH_B[index]?.nameOfApplicant
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Residential Status </label>
                              <Select
                                value={item.residentialStatus}
                                name={`scheduleSH_B.${index}.residentialStatus`}
                                error={
                                  errors?.scheduleSH_B &&
                                  errors?.scheduleSH_B[index]?.residentialStatus
                                }
                                onChange={handleChange}
                              >
                                <option value={""} disabled>
                                  {strings.label.choose_option}
                                </option>
                                {residentType?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>

                            <div>
                              <label>Type of Share</label>
                              <Select
                                value={item.typeOfShare}
                                name={`scheduleSH_B.${index}.typeOfShare`}
                                error={
                                  errors?.scheduleSH_B &&
                                  errors?.scheduleSH_B[index]?.typeOfShare
                                }
                                onChange={handleChange}
                              >
                                <option value={""} disabled>
                                  {strings.label.choose_option}
                                </option>
                                {typeOfShare?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>PAN</label>
                              <Input
                                inputType={"pan"}
                                value={item.pan}
                                name={`scheduleSH_B.${index}.pan`}
                                error={
                                  errors?.scheduleSH_B &&
                                  errors?.scheduleSH_B[index]?.pan
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Date of Application</label>
                              <Input
                                inputType={"date"}
                                value={item.dateOfApplication}
                                name={`scheduleSH_B.${index}.dateOfApplication`}
                                error={
                                  errors?.scheduleSH_B &&
                                  errors?.scheduleSH_B[index]?.dateOfApplication
                                }
                                onChange={handleChange}
                              />
                            </div>
                            <div>
                              <label>No of Shares Application</label>
                              <Input
                                type={"Number"}
                                value={item.noOfSharesApplied}
                                name={`scheduleSH_B.${index}.noOfSharesApplied`}
                                error={
                                  errors?.scheduleSH_B &&
                                  errors?.scheduleSH_B[index]?.noOfSharesApplied
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Application Money Received</label>
                              <Input
                                type={"Number"}
                                value={item.applicationMoneyReceived}
                                name={`scheduleSH_B.${index}.applicationMoneyReceived`}
                                error={
                                  errors?.scheduleSH_B &&
                                  errors?.scheduleSH_B[index]
                                    ?.applicationMoneyReceived
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Face Value Per Share</label>
                              <Input
                                type={"Number"}
                                value={item.faceValuePerShare}
                                name={`scheduleSH_B.${index}.faceValuePerShare`}
                                error={
                                  errors?.scheduleSH_B &&
                                  errors?.scheduleSH_B[index]?.faceValuePerShare
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Proposed Issue Price</label>
                              <Input
                                type={"Number"}
                                value={item.proposedIssuePrice}
                                name={`scheduleSH_B.${index}.proposedIssuePrice`}
                                error={
                                  errors?.scheduleSH_B &&
                                  errors?.scheduleSH_B[index]
                                    ?.proposedIssuePrice
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            nameOfApplicant: "",
                            residentialStatus: "",
                            typeOfShare: "",
                            pan: "",
                            dateOfApplication: "",
                            noOfSharesApplied: "",
                            applicationMoneyReceived: "",
                            faceValuePerShare: "",
                            proposedIssuePrice: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              {/* C: Details of shareholders who is not a shareholder at the end of the previous year but was a shareholder at any time during the previous year */}
              <FieldArray name="scheduleSH_C">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4 className="mt-1">
                      C: Details of shareholders who is not a shareholder at the
                      end of the previous year but was a shareholder at any time
                      during the previous year
                    </h4>

                    {values?.scheduleSH_C?.map((item, index) => (
                      <div key={index}>
                        <StyleFormBox>
                          <div className="flex justify-between items-center">
                            <h1 className="font-bold text-xl ">{index + 1}</h1>
                            <button
                              className="delete_button"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Name Of Shareholder</label>
                              <Input
                                value={item.nameOfShareholder}
                                name={`scheduleSH_C.${index}.nameOfShareholder`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]?.nameOfShareholder
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Residential Status </label>
                              <Select
                                value={item.residentialStatus}
                                name={`scheduleSH_C.${index}.residentialStatus`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]?.residentialStatus
                                }
                                onChange={handleChange}
                              >
                                <option value={""} disabled>
                                  {strings.label.choose_option}
                                </option>
                                {residentType?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>

                            <div>
                              <label>Type of Share</label>
                              <Select
                                value={item.typeOfShare}
                                name={`scheduleSH_C.${index}.typeOfShare`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]?.typeOfShare
                                }
                                onChange={handleChange}
                              >
                                <option value={""} disabled>
                                  {strings.label.choose_option}
                                </option>
                                {typeOfShare?.map((i, ix) => (
                                  <option value={i.id} key={ix}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>PAN</label>
                              <Input
                                inputType={"pan"}
                                value={item.pan}
                                name={`scheduleSH_C.${index}.pan`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]?.pan
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>No of Shares Application</label>
                              <Input
                                type={"Number"}
                                value={item.noOfSharesHeld}
                                name={`scheduleSH_C.${index}.noOfSharesHeld`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]?.noOfSharesHeld
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Face Value Per Share</label>
                              <Input
                                type={"Number"}
                                value={item.faceValuePerShare}
                                name={`scheduleSH_C.${index}.faceValuePerShare`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]?.faceValuePerShare
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Issue Price Per Share</label>
                              <Input
                                type={"Number"}
                                value={item.issuePricePerShare}
                                name={`scheduleSH_C.${index}.issuePricePerShare`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]
                                    ?.issuePricePerShare
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Amount Received</label>
                              <Input
                                type={"Number"}
                                value={item.amountReceived}
                                name={`scheduleSH_C.${index}.amountReceived`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]?.amountReceived
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Date Of Allotment</label>
                              <Input
                                inputType={"date"}
                                value={item.dateOfAllotment}
                                name={`scheduleSH_C.${index}.dateOfAllotment`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]?.dateOfAllotment
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex_input my-2 items-center">
                            <div>
                              <label>Exit Date</label>
                              <Input
                                inputType={"date"}
                                value={item.exitDate}
                                name={`scheduleSH_C.${index}.exitDate`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]?.exitDate
                                }
                                onChange={handleChange}
                              />
                            </div>

                            <div>
                              <label>Mode Of Cessation</label>
                              <Select
                                value={item.modeOfCessation}
                                name={`scheduleSH_A.${index}.modeOfCessation`}
                                error={
                                  errors?.scheduleSH_A &&
                                  errors?.scheduleSH_A[index]?.modeOfCessation
                                }
                                onChange={handleChange}
                              >
                                <option value={""} disabled>
                                  {strings.label.choose_option}
                                </option>
                                {/* {otherIncome?.map((i, ix) => (
                              <option value={i.id} key={ix}>
                                {i.name}
                              </option>
                            ))} */}
                              </Select>
                            </div>

                            <div>
                              <label>Transferee PAN (In case of Sale)</label>
                              <Input
                                inputType={"pan"}
                                value={item.transfereePan}
                                name={`scheduleSH_C.${index}.transfereePan`}
                                error={
                                  errors?.scheduleSH_C &&
                                  errors?.scheduleSH_C[index]?.transfereePan
                                }
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </StyleFormBox>
                      </div>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            nameOfApplicant: "",
                            residentialStatus: "",
                            typeOfShare: "",
                            pan: "",
                            dateOfApplication: "",
                            noOfSharesApplied: "",
                            applicationMoneyReceived: "",
                            faceValuePerShare: "",
                            proposedIssuePrice: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>

              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
