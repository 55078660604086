import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Input from "../../../../styledComponent/Inputs/Input";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { FieldArray, Formik } from "formik";
import { saveScheduleESRAction, getScheduleESRAction } from "../../../../redux/itr/action";
import { removeEmptyKeys } from "../../../../utils/helpers";
import { toast } from "react-toastify";

export default function ScheduleEsr({ data, handleCallback }) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const { ItrData } = useSelector((s) => s.itr);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const dispatch = useDispatch();
  const handleSubmitForm = (values) => {
    setLoader(true);
    let { type, ...data } = values;
    if (type === "add") {
      delete data.id;
    }

    const result = data.data.map((item) => {
      let { createdAt, updatedAt, isActive, isDeleted, section, professionalBusiness, ...rest } = item;
      return removeEmptyKeys(rest);
    });

    dispatch(
      saveScheduleESRAction({ ...data, data: result }, (e) => {
        if (e.status === 200) {
          handleCallback(type, data);
          toast.success(
            `Successfully ${type === "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    dispatch(
      getScheduleESRAction(ItrData.id, (res)=> {
        setFormData({
          id: data?.id || "",
          type: data.id ? "edit" : "add",
          itrId: ItrData.id,
          data: res.data,
        });
        setLoader(false);

      })
    )
  }, []);
  return (
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <div>
            <h4>{strings.balance_sheet.esr_details}</h4>

            <FieldArray name="data">
              {({ insert, remove, push }) => (
                <>
                  {values?.data?.map((item, index) => (
                    <div key={index}>
                      <StyleFormBox>
                        <h4>{item?.section?.name}</h4>
                        <div className="flex_input my-2">
                          <label>{strings.balance_sheet.amount_debited}</label>
                          <Input
                            inputType={"currency"}
                            name={`data.${index}.amountDebitedToPL`}
                            value={item?.amountDebitedToPL}
                            error={
                              errors?.data &&
                              errors?.data[index]?.amountDebitedToPL
                            }
                            onChange={handleChange}
                          />
                        </div>
                        <div className="flex_input my-2">
                          <label>
                            {strings.balance_sheet.amount_deduction_allowable}
                          </label>
                          <Input
                            inputType={"currency"}
                            name={`data.${index}.amountOfDeductionAllowable`}
                            value={item?.amountOfDeductionAllowable}
                            error={
                              errors?.data &&
                              errors?.data[index]?.amountOfDeductionAllowable
                            }
                            onChange={handleChange}
                          />
                        </div>
                      </StyleFormBox>
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
