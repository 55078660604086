import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TicketsBox from "../component/Tickets/TicketsBox";
import styled from "styled-components";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoMdArrowRoundUp } from "react-icons/io";
export default function TicketsChatBox() {
  const [allChats, setAllChats] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { id } = useParams();

  const dummyData = {
    id: "1",
    ticketNumber: "UTPBO",
    service: "ITR 1",
    date: "20/01/2024",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    status: "Closed",
  };
  const mockChats = [
    { id: 1, message: "Hello!", isMine: false },
    { id: 2, message: "Hi there!", isMine: true },
    { id: 3, message: "How are you?", isMine: false },
    { id: 4, message: "I'm good, thanks!", isMine: true },
    { id: 5, message: "What's up?", isMine: false },
    { id: 6, message: "Not much, just working on some code.", isMine: true },
  ];

  const addMessage = () => {
    const message = {
      id: allChats.length + 1,
      message: newMessage,
      isMine: true,
    };
    setAllChats([...allChats,message]);
    setNewMessage('')
  };
  useEffect(() => {
    setAllChats(mockChats);
  }, []);
  console.log("id  ---", id);

  return (
    <ChatBox theme={theme}>
      <TicketsBox data={dummyData} />
      <p className="text-center mt-6">Friday, 6:12 PM</p>
      <div className="chat_box mt-4">
        {allChats.map((chat, index) => {
          return (
            <div
              key={index}
              className={chat.isMine ? "chat sender" : "chat receiver"}
            >
              {" "}
              {chat.message}{" "}
            </div>
          );
        })}
      </div>
      <div className="action_buttons flex items-center gap-4">
        <button>
          <IoIosAddCircleOutline />
        </button>
        <div className="message_box flex items-center">
          <input
            type="text"
            value={newMessage}
            onKeyDown={(event)=>{event.key == 'Enter' && addMessage()}}
            onChange={(e) => {
              setNewMessage(e.target.value);
            }}
          />
          <button onClick={addMessage}>
            <IoMdArrowRoundUp />
          </button>
        </div>
      </div>
    </ChatBox>
  );
}
const ChatBox = styled.div`
  max-width: 800px;
  margin: auto;
  margin-top: 30px;
  background-color: ${(p) => p.theme.secandary_bg};
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  height:85vh;
  display:flex;
  flex-direction:column;
  .chat_box {
    flex:auto;
    overflow:scroll;
    .chat {
      width: fit-content;
      margin: 10px 0px;
      max-width: 300px;
      padding: 4px;
      &.sender {
        margin-left: auto;
        border-radius: 10px 10px 0px 10px;
        background-color: ${(p) => p.theme.primary_bg};
      }
      &.receiver {
        border-radius: 0px 10px 10px 10px;
        background-color: ${(p) => p.theme.tertiary_bg};
      }
    }
  }
  .action_buttons {
    > button {
      font-size: 30px;
    }
    .message_box {
      /* background:red; */
      border-radius: 50px;
      overflow: hidden;
      border: 1px solid ${(p) => p.theme.border_clr};
      width: 100%;
      input {
        padding: 4px;
        background: none;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
      > button {
        background-color: ${(p) => p.theme.tertiary_bg};
        padding: 8px;
        border-radius: 50%;
        margin: 2px;
      }
    }
  }
`;
