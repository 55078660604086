import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { FaCheck } from "react-icons/fa";
export default function ProgressBar({active,stepDone,options,handleClick}) {
  const [currentActive, setCurrentActive] = useState(1);
  const [stepsDone, setStepsDone] = useState([]);
  const [steps,setSteps] = useState([])

  const { theme } = useSelector((state) => state.platformSetting);

  useEffect(()=>{
    if(active || stepsDone){
        setCurrentActive(active);
        setStepsDone(stepDone);
        setSteps(options)
    }
  },[active,stepDone,options])
  
  return (
    <Root theme={theme}>
      <div className="progress_container">
        {steps?.map((i, ix) => {
          return (
            <div className={stepsDone.includes(i.id) ? "progress_bar step_done" : "progress_bar"} key={ix}>
              <div
                className={
                  currentActive == i.id ? "text_wrap active" : "text_wrap"
                }
              >
                <div className="circle"><FaCheck /></div>
                <p className="text cursor-pointer" onClick={()=>{handleClick(i.link)}}>{i.title}</p>
              </div>
            </div>
          );
        })}
      </div>
    </Root>
  );
}
const Root = styled.section`
  .progress_container {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 30px;
    max-width: 100%;
    >div{
        flex:1;
        text-align: center;
    }
  }
  .text_wrap p {
    font-weight: 600;
    font-size: 14px;
    color: ${p=>addOpacity(p.theme.primary_txt,0.4)};
    transition: 400ms ease;
  }

  .text_wrap.active p {
    color: ${p=>p.theme.primary_bg};
  }

  .circle {
    margin:auto;
    background-color: ${p => p.theme.tertiary_bg};
    border: 3px solid;
    border-color:${p=>addOpacity(p.theme.primary_txt,0.4)};
    color: #000;
    font-weight: 700;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    position: relative; /* Position the child element */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 400ms ease;
    z-index: 9;
    color:${p => p.theme.tertiary_bg};
  }

  .text_wrap.active div.circle {
    border-color: ${p=>p.theme.primary_bg};
  }
  .progress_bar{
    position:relative;
    &::after {
        content: '';
        background: ${p=>p.theme.green_txt};
        height: 4px;
        width: 0%;
        position: absolute;
        left: 50%;
        top: 10px;
        z-index: 1;
        transition:all 2s;
    }
    &::before {
        content: '';
        background: ${p=>p.theme.border_clr};
        height: 4px;
        width: 100%;
        position: absolute;
        left: 50%;
        top: 10px;
        z-index: 1;
        transition:all 2s;
    }
    &:last-child{
        &::before {
            width:0px;
        }
    }
  }
  
  .progress_bar.step_done::after{
    width:100%;
  }
  .step_done{
    .circle{
    border-color: ${p=>p.theme.green_txt};
    color: ${p=>p.theme.green_txt};
    }
    .text{
        color:${p=>p.theme.green_txt};
    }
  }
`;
