import React, { useEffect, useState,useDispatch } from "react";
import { useSelector } from "react-redux";
import StyleFormBox from "../styledComponent/Form/StyleFormBox";
import { FaWhatsapp } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import styled from "styled-components";
import { toast } from "react-toastify";
import axios from "axios";
import Urls from "../utils/Urls";

export default function Itr7Form() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [timeSlots, setTimeSlots] =  useState([]);
  const [methods, setMethods] =  useState([]);
  const [expertList, setExpertList] =  useState({});

  const [formData, setFormData] = useState({
    fullName: "",
    timeSlot: "",
    selectedMethod: "",
    phoneNumber: "",
    query: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    timeSlot: "",
    selectedMethod: "",
    phoneNumber: "",
    query: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }; 
  const handleSubmit = async (e) => {
    console.log(e.target, formData)
    e.preventDefault();
    let formIsValid = true;
    const newErrors = {};

    // Validate Name
    if (!formData.fullName) {
      formIsValid = false;
      newErrors.fullName = "Name is required.";
    }

    // Validate timeslot
    if (!formData.timeSlot) {
      formIsValid = false;
      newErrors.timeSlot = "Time Slot is required.";
    }
    if (!formData.selectedMethod) {
      formIsValid = false;
      newErrors.selectedMethod = "Method is required.";
    }

    // Validate phoneNumber
    if (!formData.phoneNumber) {
      formIsValid = false;
      newErrors.phoneNumber = "Phone number is required.";
    } 

    setErrors(newErrors);

    if (formIsValid) {
      formData.itrId=ItrData.id;
      await axios.post(Urls.saveExpertAssist, formData)
      .then((response) => {
        toast.success("Request Submitted Successfully!");
        getExperts();
      })
      .catch((error) => console.error(error));
      // If the form is valid, handle form submission (e.g., API call)
      console.log("Request Submitted Successfully", formData);
      // Reset the form
      setFormData({
        fullName: "",
        timeSlot: "",
        selectedMethod: "",
        phoneNumber: "",
        query: "",
      });
    }
  };
  const getExperts = async () => {
    await axios.get(Urls.getExpertAssist+ItrData.id)
    .then((res)=> {
      if(res?.data?.status === 200) {
        setTimeSlots(res?.data?.data?.timeSlots);
        setMethods(res?.data?.data?.methods);
        setExpertList(res?.data?.data?.experts);
      }
    })
    .catch((error) => console.error(error));;
  }
  useEffect(() => {
    getExperts();
  }, []);
  
  return (
    <Root className="container w-2/6 mx-auto mt-20" theme={theme}> 
        <div className="upload_form">
            {expertList?.id ? (
             <StyleFormBox>
                <div>
                  <h4>{strings.form.connect_ca}</h4>
                  <h6>{expertList?.expert?.userFullName} | Tax Consultant</h6>
                  <div className="button-container">
                    <button className="primary_button button">
                        <IoCallOutline className="bold-icon" />{strings.button.call_now}
                    </button>
                    <button className="secandary_button button">
                      <span style={{color:"#25d366"}}><FaWhatsapp className="bold-icon" /></span> <span style={{color: "#000"}}>WhatsApp</span>
                    </button>
                    <button className="secandary_button button">
                        <CiMail className="bold-icon" /><span style={{color: "#000"}}><a href={`mailto:${expertList?.expert?.userEmail}`}>{strings.button.mail}</a></span>
                    </button>
                  </div>
                </div>
             </StyleFormBox>
            ) : ""}
            {!expertList?.id ? (
              <StyleFormBox>
                <h4>{strings.form.request_callback}</h4>
                <form onSubmit={handleSubmit}>
                <div className="pt-4">
                  <div>
                    <input
                      name="fullName"
                      placeholder={strings.form.your_name}
                      value={formData.fullName}
                      onChange={handleInputChange}
                    />
                     {errors.fullName && <p className="error">{errors.fullName}</p>}
                  </div>
                  <div>
                    <input
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      placeholder={strings.label.phone_number}
                      pattern="\d{10}" 
                      maxlength="10"
                    />
                    {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
                  </div>
                  <div>
                    <select
                      name="timeSlot"
                      value={formData.timeSlot}
                      placeholder={strings.form.time_slot}
                      onChange={handleInputChange}
                    ><option value="" selected className="placeholder" disabled >{strings.form.time_slot}</option>
                      {timeSlots?.map((item, index) => (
                        <option value={item} key={`methos${index}`}>{item}</option>
                      ))}
                    </select>
                    {errors.timeSlot && <p className="error">{errors.timeSlot}</p>}
                  </div>
                  <div>
                    <select
                      name="selectedMethod"
                      value={formData.selectedMethod}
                      onChange={handleInputChange}
                    >
                      <option value="" selected className="placeholder" disabled>Preffered Method</option>
                      {methods?.map((item, index) => (
                        <option value={item} key={`methos${index}`}>{item}</option>
                      ))}
                    </select>
                    {errors.timeSlot && <p className="error">{errors.timeSlot}</p>}
                  </div>
                    <textarea
                      className="w-full"
                      name="query"
                      value={formData.query}
                      placeholder={strings.form.any_query}
                      onChange={handleInputChange}
                    ></textarea>

                </div>
                <button
                  type="submit"
                  className="primary_button"
                >
                  {strings.form.request_callback}
                </button>
                </form>
             </StyleFormBox>
            ) : ""}
        </div>
    </Root>
  );
}
const Root = styled.section`
select:required:invalid{
 color: #ababab;
}
option {
  color: #000;
}
.placeholder{
  color: #ababab;
}
.error{
  position: relative;
  bottom: 5px;
}
.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
}
.bold-icon {
  font-weight: 900;
  font-size: 30px;
  margin-right: 5px;
}
`;