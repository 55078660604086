import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { LuEye, LuEyeOff } from "react-icons/lu";
export default function PasswordField({
  placeholder,
  error,
  handleChange,
  value,
}) {
  const [showPassword, setShowPassword] = useState(true);
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
      <div className="relative">
        <input
          type={showPassword ? "password" : "text"}
          value={value}
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.value)}
        />
        <span
          className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <LuEyeOff /> : <LuEye />}
        </span>
      </div>
      <p className="error">{error}</p>
    </Root>
  );
}
const Root = styled.div`
  width: 100%;
  position: relative;
  input {
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.border_clr};
    color: ${(p) => p.theme.primary_txt};
    background-color: transparent;
    &::placeholder {
      color: ${(p) => p.theme.primary_txt};
      font-weight: 400;
      font-size: 14px;
    }
  }
  span {
    color: ${(p) => p.theme.primary_txt};
  }
`;
