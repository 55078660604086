import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import { FieldArray, Formik } from "formik";
import {
  editSpeculativeIncome,
  getSpeculativeIncome,
  saveSpeculativeIncome,
} from "../../../../../redux/itr/action";
import { removeEmptyKeys } from "../../../../../utils/helpers";
import { toast } from "react-toastify";
import { RiDeleteBin6Fill } from "react-icons/ri";
import Input from "../../../../../styledComponent/Inputs/Input";
import LoadingBox from "../../../../DialogBox/LoadingBox";
import * as Yup from "yup";

export default function SpeculativeIncome({
  data,
  businessType,
  handleCallback,
}) {
  const [formData, setFormData] = useState({});
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    turnoverSpeculativeIncome: Yup.string().required(
      strings.validation_message.required
    ),
    grossProfit: Yup.string().required(
      strings.validation_message.required
    ),
  });
  const handleSubmitForm = (obj) => {
    setLoader(true);
    let { type, ...data } = obj;
    const action =
      type == "edit" ? editSpeculativeIncome : saveSpeculativeIncome;
    if(!data.businessDetails){
      data.businessDetails = [];
    }
    dispatch(
      action(data, (e) => {
        if (e.status == 200) {
          handleCallback(type, e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const getSpeculativeIncomeData = () => {
    dispatch(
      getSpeculativeIncome(ItrData.id, (e) => {
        if (e.status == 200) {
          if (e.status == 200) {
            const data = e.data;
            setFormData({
              turnoverSpeculativeIncome: data?.turnoverSpeculativeIncome || "",
              grossProfit: data?.grossProfit || "",
              expenses: data?.expenses || "",
              businessDetails: data?.businessDetails
                ? data.businessDetails.map((i) => ({
                    natureOfBusiness: i?.natureOfBusiness?.id,
                    tradeName: i?.tradeName,
                    description: i?.description,
                  }))
                : [],

              itrId: ItrData?.id,
              id: data?.id || "",
              type: "edit",
            });
          } else {
            toast.error("Something went wrong");
          }
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    if (data.id) {
      getSpeculativeIncomeData();
    } else {
      setFormData({
        turnoverSpeculativeIncome: "",
        grossProfit: "",
        expenses: "",
        businessDetails: [],
        itrId: ItrData?.id,
        type: "add",
      });
    }
    setLoader(false);
  }, [data]);
  return (
    <>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <FieldArray name="businessDetails">
                {({ insert, remove, push }) => (
                  <StyleFormBox>
                    <h4>{strings.income.book_of_account_not_maintained}</h4>
                    <hr />
                    <div className="flex_input my-2 items-center">
                      <label>{strings.income.nature_of_business}</label>
                      <label>{strings.income.trade_name}</label>
                      <label>{strings.label.description}</label>
                    </div>
                    {values?.businessDetails?.map((item, index) => (
                      <div className="flex_input my-2 items-center" key={index}>
                        <select
                          name={`businessDetails.${index}.natureOfBusiness`}
                          value={item.natureOfBusiness}
                          error={
                            errors?.businessDetails &&
                            errors?.businessDetails[index]?.natureOfBusiness
                          }
                          onChange={handleChange}
                        >
                          <option value="">
                            {strings.label.choose_option}
                          </option>

                          {businessType?.map((i, ix) =>
                            i.parentId === 0 ? (
                              <optgroup key={ix} label={i.name}>
                                {i.subCategory?.map((j, subIx) => (
                                  <option value={j.id} key={`${ix}-${subIx}`}>
                                    {j.name}
                                  </option>
                                ))}
                              </optgroup>
                            ) : (
                              <option value={i.id} key={ix}>
                                {i.name}
                              </option>
                            )
                          )}
                        </select>

                        <Input
                          name={`businessDetails.${index}.tradeName`}
                          value={item.tradeName}
                          error={
                            errors?.businessDetails &&
                            errors?.businessDetails[index]?.tradeName
                          }
                          onChange={handleChange}
                        />

                        <Input
                          name={`businessDetails.${index}.description`}
                          value={item.description}
                          error={
                            errors?.businessDetails &&
                            errors?.businessDetails[index]?.description
                          }
                          onChange={handleChange}
                        />

                        <button
                          className="delete_button mb-2"
                          onClick={(e) => {
                            e.preventDefault();
                            remove(index);
                          }}
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    ))}
                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            natureOfBusiness: "",
                            tradeName: "",
                            description: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </StyleFormBox>
                )}
              </FieldArray>
              <StyleFormBox>
                <div className="flex_input my-3">
                  <label>
                    {"1i : " +
                      strings.income.turnover_from_speculative_activity}
                  </label>
                  <Input
                    name={"turnoverSpeculativeIncome"}
                    inputType={"currency"}
                    error={errors.turnoverSpeculativeIncome}
                    value={values?.turnoverSpeculativeIncome}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>{"1ii : " + strings.income.gross_profit}</label>
                  <Input
                    name={"grossProfit"}
                    inputType={"currency"}
                    error={errors.grossProfit}
                    value={values?.grossProfit}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>{"1iii : " + strings.income.expenditure_if_any}</label>
                  <Input
                    name={"expenses"}
                    inputType={"currency"}
                    error={errors.expenses}
                    value={values?.expenses}
                    onChange={handleChange}
                  />
                </div>
                <hr />
                <div className="flex justify-between my-4">
                  <h4>{strings.income.net_speculative_income}</h4>
                  <h4>
                    {Number(values?.grossProfit) - Number(values?.expenses)}
                  </h4>
                </div>
              </StyleFormBox>

              <button className="primary_button my-4" type="submit">
                {strings.button.save_and_close}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
