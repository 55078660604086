import React, { useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useSelector } from "react-redux";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import AddCryptoIncome from "./AddCryptoIncome";
import AddGainNft from "./AddGainNft";

export default function CryptoIncome() {
  const [addCryptoIncome, setAddCryptoIncome] = useState(false);
  const [addGainsBox, setAddGainsBox] = useState(false);

  const { strings } = useSelector((s) => s.platformSetting);

  return (
    <div>
      <AccordianBox
        title={strings.income.crypto_income}
        description={
          strings.income.add_income_from_crypto_trades_staking_minting
        }
      >
        <StyleFormBox>
          <div className="flex_input">
            <div>
              <h4>{strings.income.add_crypto_income_yourself}</h4>
              <p>{strings.income.choose_option_shared_by_crypto_exchange} </p>
            </div>
            <button
              className="add_button"
              style={{ flex: 0 }}
              onClick={() => setAddCryptoIncome(true)}
            >
              {strings.button.add}
            </button>
          </div>
        </StyleFormBox>
        <StyleFormBox>
          <div className="flex_input">
            <div>
              <h4>{strings.income.add_gains_nft}</h4>
              <p>{strings.income.gains_from_sale_of_vested_restricted_stock_units} </p>
            </div>
            <button
              className="add_button"
              style={{ flex: 0 }}
              onClick={() => setAddGainsBox(true)}
            >
              {strings.button.add}
            </button>
          </div>
        </StyleFormBox>

      </AccordianBox>

      <FormDialogBox
        open={addCryptoIncome}
        formTitle={strings.income.add_crypto_income_yourself}
        handleClose={() => {
          setAddCryptoIncome(false);
        }}
      >
        <AddCryptoIncome
        // handleCallback={() => {
        //   setAddCryptoIncome(false);
        // }}
        // mutualFundsAssets={capitalGainData?.typeOfAssetsMutualFunds}
        // stocksAssets={capitalGainData?.typeOfAssetsShare}
        />
      </FormDialogBox>

      <FormDialogBox
        open={addGainsBox}
        formTitle={strings.income.add_gains_nft}
        handleClose={() => {
          setAddGainsBox(false);
        }}
      >
        <AddGainNft
        // handleCallback={() => {
        //   setAddCryptoIncome(false);
        // }}
        // mutualFundsAssets={capitalGainData?.typeOfAssetsMutualFunds}
        // stocksAssets={capitalGainData?.typeOfAssetsShare}
        />
      </FormDialogBox>
    </div>
  );
}
