import React, { useCallback, useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import ToggelButton from "../../../../styledComponent/Button/ToggelButton";
import { useDispatch, useSelector } from "react-redux";

import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import CheckBox from "../../../../styledComponent/Inputs/CheckBox";
import Select from "../../../../styledComponent/Inputs/Select";
import Input from "../../../../styledComponent/Inputs/Input";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { saveCapitalGainsAction } from "../../../../redux/itr/action";
import { removeEmptyKeys } from "../../../../utils/helpers";
import { toast } from "react-toastify";

export default function DeemedCaptialGains({
  handleCallback,
  previousYear,
  editForm,
  capitalGainDeductionSections,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [longTermEnabled, setLongTermEnabled] = useState(editForm?.longTermEnabled ?? false);
  const [shortTermEnabled, setShortTermEnabled] = useState(editForm?.shortTermEnabled ?? false);

  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData} = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    longTerm: Yup.lazy(value => 
      value && Object.keys(value).length > 0 ? 
        Yup.object().shape({
          amountUsedOnAssetTransfer: Yup.string().required(
            strings.validation_message.required
          ),
          anyOtherAmountDeemed: Yup.string().required(
            strings.validation_message.required
          ),
          deemedDetails: Yup.array().when('amountUsedOnAssetTransfer', {
            is: (value) => value === 'yes', // Replace 'ony' with the desired condition
            then: () => Yup.array().of(
                Yup.object().shape({
                  prevYearAssetTransferred: Yup.string().required(
                    strings.validation_message.required
                  ),
                  deductionClaimedSection: Yup.string().required(
                    strings.validation_message.required
                  ),
                  newAssetAcquiredYear: Yup.string().required(
                    strings.validation_message.required
                  ),
                  amountUtilizedForNewAsset: Yup.string().required(
                    strings.validation_message.required
                  ),
                  amountNotUtilized: Yup.string().required(
                    strings.validation_message.required
                  ),
                })
              )
          }),          
        }) : Yup.object().shape({})
    ),
    shortTerm: Yup.lazy(value => 
      value && Object.keys(value).length > 0 ? 
        Yup.object().shape({
          amountUsedOnAssetTransfer: Yup.string().required(
            strings.validation_message.required
          ),
          anyOtherAmountDeemed: Yup.string().required(
            strings.validation_message.required
          ),
          deemedDetails: Yup.array().when('amountUsedOnAssetTransfer', {
            is: (value) => value === 'yes', // Replace 'ony' with the desired condition
            then: () => Yup.array().of(
              Yup.object().shape({
                prevYearAssetTransferred: Yup.string().required(
                  strings.validation_message.required
                ),
                deductionClaimedSection: Yup.string().required(
                  strings.validation_message.required
                ),
                newAssetAcquiredYear: Yup.string().required(
                  strings.validation_message.required
                ),
                amountUtilizedForNewAsset: Yup.string().required(
                  strings.validation_message.required
                ),
                amountNotUtilized: Yup.string().required(
                  strings.validation_message.required
                ),
              })
            ),
          }) 
        }) : Yup.object().shape({})
    ),
  });
  const validationSchema5 = Yup.object().shape({
    longTerm: Yup.lazy(value => 
      value && Object.keys(value).length > 0 ? 
        Yup.object().shape({
          amountUsedOnAssetTransfer: Yup.string().required(
            strings.validation_message.required
          ),
          deemedDetails: Yup.array().when('amountUsedOnAssetTransfer', {
            is: (value) => value === 'yes', // Replace 'ony' with the desired condition
            then: () => Yup.array().of(
                Yup.object().shape({
                  prevYearAssetTransferred: Yup.string().required(
                    strings.validation_message.required
                  ),
                  deductionClaimedSection: Yup.string().required(
                    strings.validation_message.required
                  ),
                  amountNotUtilized: Yup.string().required(
                    strings.validation_message.required
                  ),
                })
              )
          }),          
        }) : Yup.object().shape({})
    ),
    shortTerm: Yup.lazy(value => 
      value && Object.keys(value).length > 0 ? 
        Yup.object().shape({
          amountUsedOnAssetTransfer: Yup.string().required(
            strings.validation_message.required
          ),
          deemedDetails: Yup.array().when('amountUsedOnAssetTransfer', {
            is: (value) => value === 'yes', // Replace 'ony' with the desired condition
            then: () => Yup.array().of(
              Yup.object().shape({
                prevYearAssetTransferred: Yup.string().required(
                  strings.validation_message.required
                ),
                deductionClaimedSection: Yup.string().required(
                  strings.validation_message.required
                ),
                amountNotUtilized: Yup.string().required(
                  strings.validation_message.required
                ),
              })
            ),
          }) 
        }) : Yup.object().shape({})
    ),
  });

  const handleSubmitForm = (values) => {
    setLoader(true);
    dispatch(
      saveCapitalGainsAction(removeEmptyKeys(values), (e) => {
        if (e.status === 200) {
          handleCallback(e.data);
          toast.success("Successfully upload data");
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
    );
  };

  useEffect(() => { 
    console.log(editForm);
    setLoader(true);
    setFormData({
      itrId: ItrData.id,
      longTerm: editForm?.longTerm
        ? {
            amountUsedOnAssetTransfer:
              editForm?.longTerm?.amountUsedOnAssetTransfer || "",
            anyOtherAmountDeemed:
              editForm?.longTerm?.anyOtherAmountDeemed || "",
            totalAmountDeemed: editForm?.longTerm?.totalAmountDeemed || "",
            deemedDetails: editForm?.longTerm?.deemedDetails || [
              {
                prevYearAssetTransferred: "",
                deductionClaimedSection: "",
                newAssetAcquiredYear: "",
                amountUtilizedForNewAsset: "",
                amountNotUtilized: "",
              },
            ],
          }
        : {},
      shortTerm: editForm?.shortTerm
        ? {
            amountUsedOnAssetTransfer:
              editForm?.shortTerm?.amountUsedOnAssetTransfer || "",
            anyOtherAmountDeemed:
              editForm?.shortTerm?.anyOtherAmountDeemed || "",
            totalAmountDeemed: editForm?.shortTerm?.totalAmountDeemed || "",
            deemedDetails: editForm?.shortTerm?.deemedDetails || [
              {
                prevYearAssetTransferred: "",
                deductionClaimedSection: "",
                newAssetAcquiredYear: "",
                amountUtilizedForNewAsset: "",
                amountNotUtilized: "",
              },
            ],
          }
        : {},
    });
    setLoader(false);
  }, [editForm]);

  const shortTermTotal = useCallback((values) => {
    let totalAmountNotUtilized = 0;
    if(values?.shortTerm?.amountUsedOnAssetTransfer === "yes") {
      totalAmountNotUtilized = values?.shortTerm?.deemedDetails?.reduce((i, {amountNotUtilized}) => i + amountNotUtilized, 0);
    }
    return Number(values?.shortTerm?.anyOtherAmountDeemed) + Number(totalAmountNotUtilized);
  });

  const longTermTotal = useCallback((values) => {
    let totalAmountNotUtilized = 0;
    if(values?.longTerm?.amountUsedOnAssetTransfer === "yes") {
      totalAmountNotUtilized = values?.longTerm?.deemedDetails?.reduce((i, {amountNotUtilized}) => i + amountNotUtilized, 0);
    }
    return Number(values?.longTerm?.anyOtherAmountDeemed) + Number(totalAmountNotUtilized);
  });


  console.log("x7x7x7", formData.longTerm)

  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={ItrData.form === "ITR_5" ? validationSchema5 : validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <div>
            <StyleFormBox>
              <div className="flex_input my-2 justify-between">
                <h4>{strings.income.short_term_capital_gain}</h4>
                <ToggelButton
                  value={shortTermEnabled}
                  handleClick={() => {
                    setValues({
                      ...values,
                      shortTerm: values?.shortTerm?.amountUsedOnAssetTransfer
                        ? {}
                        : {
                            amountUsedOnAssetTransfer: "yes",
                            anyOtherAmountDeemed: "",
                            totalAmountDeemed: "",
                            deemedDetails: [
                              {
                                prevYearAssetTransferred: "",
                                deductionClaimedSection: "",
                                newAssetAcquiredYear: "",
                                amountUtilizedForNewAsset: "",
                                amountNotUtilized: "",
                              },
                            ],
                          },
                    });
                    setShortTermEnabled(!shortTermEnabled);
                    }
                  }
                />
              </div>
              {shortTermEnabled ? (
                <div>
                  <p className="mb-3">
                    {
                      strings.income
                        .whether_amount_captial_gain_during_previous_year
                    }
                  </p>

                  <CheckBox
                    className="w-5"
                    value={
                      values?.shortTerm?.amountUsedOnAssetTransfer === "yes"
                    }
                    label={strings.placeholder.yes}
                    handleChange={(e) => {
                      setValues({
                        ...values,
                        shortTerm: {
                          ...values.shortTerm,
                          amountUsedOnAssetTransfer: e ? "yes" : "",
                          deemedDetails: [
                            {
                              prevYearAssetTransferred: "",
                              deductionClaimedSection: "",
                              newAssetAcquiredYear: "",
                              amountUtilizedForNewAsset: "",
                              amountNotUtilized: "",
                            },
                          ],
                        },
                      })
                      setFieldValue("shortTerm.totalAmountDeemed", shortTermTotal(values));
                    }}
                  />
                  {values?.shortTerm?.amountUsedOnAssetTransfer === "yes" ? (
                    <>
                      <FieldArray name="shortTerm.deemedDetails">
                        {({ insert, remove, push }) => (
                          <>
                            {values?.shortTerm?.deemedDetails?.map(
                              (item, index) => (
                                <div key={index}>
                                  <StyleFormBox>
                                    <div className="text-right">
                                      <button
                                        className="delete_button mb-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          remove(index);
                                        }}
                                      >
                                        <RiDeleteBin6Fill />
                                      </button>
                                    </div>

                                    <div className="flex_input mt-2">
                                      <label>
                                        {
                                          strings.income
                                            .prev_year_asset_transferred
                                        } *
                                      </label>
                                      <Select
                                        name={`shortTerm.deemedDetails.${index}.prevYearAssetTransferred`}
                                        value={item.prevYearAssetTransferred}
                                        error={
                                          errors?.shortTerm?.deemedDetails?.[
                                            index
                                          ]?.prevYearAssetTransferred
                                        }
                                        onChange={handleChange}
                                      >
                                        <option value={""}>
                                          {strings.label.choose_option}
                                        </option>
                                        {previousYear?.map((i, ix) => (
                                          <option value={i.id} key={ix}>
                                            {i.name}
                                          </option>
                                        ))}
                                      </Select>
                                    </div>
                                    <div className="flex_input mt-2">
                                      <label>
                                        {
                                          strings.income
                                            .section_under_deduction_claimed_in_year
                                        } *
                                      </label>
                                      <Select
                                        name={`shortTerm.deemedDetails.${index}.deductionClaimedSection`}
                                        value={item.deductionClaimedSection}
                                        error={
                                          errors?.shortTerm?.deemedDetails?.[
                                            index
                                          ]?.deductionClaimedSection
                                        }
                                        onChange={handleChange}
                                      >
                                        <option value={""}>
                                          {strings.label.choose_option}
                                        </option>
                                        {capitalGainDeductionSections?.map((i, ix) => (
                                          <option value={i.id} key={ix}>
                                            {i.name}
                                          </option>
                                        ))}
                                      </Select>
                                    </div>
                                    <div className="flex_input mt-2">
                                      <label>
                                        {
                                          strings.income
                                            .year_which_new_asset_acquired
                                        } {ItrData.form === "ITR_5" ? '': '*'}
                                      </label>
                                      <Select
                                        name={`shortTerm.deemedDetails.${index}.newAssetAcquiredYear`}
                                        value={item.newAssetAcquiredYear}
                                        error={
                                          errors?.shortTerm?.deemedDetails?.[
                                            index
                                          ]?.newAssetAcquiredYear
                                        }
                                        onChange={handleChange}
                                      >
                                        <option value={""} disabled>
                                          {strings.label.choose_option}
                                        </option>
                                        {previousYear?.map((i, ix) => (
                                          <option value={i.id} key={ix}>
                                            {i.name}
                                          </option>
                                        ))}
                                      </Select>
                                    </div>
                                    <div className="flex_input mt-2">
                                      <label>
                                        {
                                          strings.income
                                            .amount_utilised_captial_gains
                                        } {ItrData.form === "ITR_5" ? '': '*'}
                                      </label>
                                      <Input
                                        inputType={"currency"}
                                        name={`shortTerm.deemedDetails.${index}.amountUtilizedForNewAsset`}
                                        value={item.amountUtilizedForNewAsset}
                                        error={
                                          errors?.shortTerm?.deemedDetails?.[
                                            index
                                          ]?.amountUtilizedForNewAsset
                                        }
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div className="flex_input mt-2">
                                      <label>
                                        {
                                          strings.income
                                            .amount_used_for_new_asset
                                        } *
                                      </label>
                                      <Input
                                        inputType={"currency"}
                                        name={`shortTerm.deemedDetails.${index}.amountNotUtilized`}
                                        value={item.amountNotUtilized}
                                        error={
                                          errors?.shortTerm?.deemedDetails?.[
                                            index
                                          ]?.amountNotUtilized
                                        }
                                        onChange={handleChange}
                                        onBlur={() => {
                                          setFieldValue("shortTerm.totalAmountDeemed", shortTermTotal(values));
                                        }}
                                      />
                                    </div>
                                  </StyleFormBox>
                                </div>
                              )
                            )}
                            <div className="text-right mt-5">
                              <button
                                className="add_item_button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  push({
                                    prevYearAssetTransferred: "",
                                    deductionClaimedSection: "",
                                    newAssetAcquiredYear: "",
                                    amountUtilizedForNewAsset: "",
                                    amountNotUtilized: "",
                                  });
                                }}
                              >
                                {strings.button.add_more_item}
                              </button>
                            </div>
                          </>
                        )}
                      </FieldArray>

                      <hr className="my-3" />
                    </>
                  ) : (
                    ""
                  )}

                  <CheckBox
                    value={
                      values?.shortTerm?.amountUsedOnAssetTransfer === "no"
                    }
                    label={strings.placeholder.no}
                    handleChange={(e) => {
                      setValues({
                        ...values,
                        shortTerm: {
                          ...values.shortTerm,
                          amountUsedOnAssetTransfer:  e ? "no" : "",
                          deemedDetails: [],
                        },
                      })
                      setFieldValue("shortTerm.totalAmountDeemed", shortTermTotal(values));
                    }}
                  />
                  <CheckBox
                    value={
                      values?.shortTerm?.amountUsedOnAssetTransfer ===
                      "notApplicable"
                    }
                    label={strings.label.not_applicable}
                    handleChange={(e) => {
                      setValues({
                        ...values,
                        shortTerm: {
                          ...values.shortTerm,
                          amountUsedOnAssetTransfer:  e ? "notApplicable" : "",
                          deemedDetails: [],
                        },
                      })
                      setFieldValue("shortTerm.totalAmountDeemed", shortTermTotal(values));
                    }}
                  />

                  <StyleFormBox>
                    <div className="flex_input mt-2">
                      <label>
                        {
                          strings.income
                            .amount_deemed_to_short_term_captial_gain
                        } {ItrData.form === "ITR_5" ? '': '*'}
                      </label>

                      <Input
                        inputType={"currency"}
                        name={"shortTerm.anyOtherAmountDeemed"}
                        value={values?.shortTerm?.anyOtherAmountDeemed}
                        error={errors?.shortTerm?.anyOtherAmountDeemed}
                        onChange={handleChange}
                        onBlur={(e) => {
                          setFieldValue("shortTerm.totalAmountDeemed", shortTermTotal(values));
                        }}
                      />
                    </div>
                    <div className="flex_input mt-2">
                      <label>
                        {
                          strings.income
                            .total_amount_deemed_to_short_term_captial
                        } {ItrData.form === "ITR_5" ? '': '*'}
                      </label>
                      <Input
                        inputType={"currency"}
                        value={values?.shortTerm?.totalAmountDeemed}
                        name={"shortTerm.totalAmountDeemed"}
                        disabled
                      />
                    </div>
                  </StyleFormBox>
                </div>
              ) : (
                ""
              )}
            </StyleFormBox>

            <StyleFormBox>
              <div className="flex_input my-2 justify-between">
                <h4>{strings.income.long_term_captial_gain}</h4>
                <ToggelButton
                  value={longTermEnabled}
                  handleClick={() => {
                    setValues({
                      ...values,
                      longTerm: values?.longTerm?.amountUsedOnAssetTransfer
                        ? {}
                        : {
                            amountUsedOnAssetTransfer: "",
                            anyOtherAmountDeemed: "",
                            totalAmountDeemed: "",
                            deemedDetails: [
                              {
                                prevYearAssetTransferred: "",
                                deductionClaimedSection: "",
                                newAssetAcquiredYear: "",
                                amountUtilizedForNewAsset: "",
                                amountNotUtilized: "",
                              },
                            ],
                          },
                    });
                    setLongTermEnabled(!longTermEnabled);
                    }
                  }
                />
              </div>
              {longTermEnabled ? (
                <div>
                  <p className="mb-3">
                    {
                      strings.income
                        .whether_amount_captial_gain_during_previous_year
                    }
                  </p>

                  <CheckBox
                    value={
                      values?.longTerm?.amountUsedOnAssetTransfer === "yes"
                    }
                    label={strings.placeholder.yes}
                    handleChange={(e) =>
                    {
                      setValues({
                        ...values,
                        longTerm: {
                          ...values?.longTerm,
                          amountUsedOnAssetTransfer: e ? "yes" : "",
                          deemedDetails: [
                            {
                              prevYearAssetTransferred: "",
                              deductionClaimedSection: "",
                              newAssetAcquiredYear: "",
                              amountUtilizedForNewAsset: "",
                              amountNotUtilized: "",
                            },
                          ],
                        },
                      })
                      setFieldValue("longTerm.totalAmountDeemed", longTermTotal(values));
                    }
                    }
                  />
                  {values?.longTerm?.amountUsedOnAssetTransfer === "yes" ? (
                    <>
                      <FieldArray name="longTerm.deemedDetails">
                        {({ insert, remove, push }) => (
                          <>
                            {values?.longTerm?.deemedDetails?.map(
                              (item, index) => (
                                <div key={index}>
                                <StyleFormBox>
                                  <div className="text-right">
                                    <button
                                      className="delete_button mb-2"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        remove(index);
                                      }}
                                    >
                                      <RiDeleteBin6Fill />
                                    </button>
                                  </div>

                                  <div className="flex_input mt-2">
                                    <label>
                                      {
                                        strings.income
                                          .prev_year_asset_transferred
                                      } *
                                    </label>
                                    <Select
                                      name={`longTerm.deemedDetails.${index}.prevYearAssetTransferred`}
                                      value={item.prevYearAssetTransferred}
                                      error={
                                        errors?.longTerm?.deemedDetails?.[index]
                                          ?.prevYearAssetTransferred
                                      }
                                      onChange={handleChange}
                                    >
                                      <option value={""} disabled>
                                        {strings.label.choose_option}
                                      </option>
                                      {previousYear?.map((i, ix) => (
                                        <option value={i.id} key={ix}>
                                          {i.name}
                                        </option>
                                      ))}
                                    </Select>
                                  </div>
                                  <div className="flex_input mt-2">
                                    <label>
                                      {
                                        strings.income
                                          .section_under_deduction_claimed_in_year
                                      } *
                                    </label>
                                    <Select
                                      name={`longTerm.deemedDetails.${index}.deductionClaimedSection`}
                                      value={item.deductionClaimedSection}
                                      error={
                                        errors?.longTerm?.deemedDetails?.[index]
                                          ?.deductionClaimedSection
                                      }
                                      onChange={handleChange}
                                    >
                                      <option value={""}>
                                        {strings.label.choose_option}
                                      </option>
                                      {capitalGainDeductionSections?.map((i, ix) => (
                                        <option value={i.id} key={ix}>
                                          {i.name}
                                        </option>
                                      ))}
                                    </Select>
                                  </div>
                                  <div className="flex_input mt-2">
                                    <label>
                                      {
                                        strings.income
                                          .year_which_new_asset_acquired
                                      } {ItrData.form === "ITR_5" ? '': '*'}
                                    </label>
                                    <Select
                                      name={`longTerm.deemedDetails.${index}.newAssetAcquiredYear`}
                                      value={item.newAssetAcquiredYear}
                                      error={
                                        errors?.longTerm?.deemedDetails?.[index]
                                          ?.newAssetAcquiredYear
                                      }
                                      onChange={handleChange}
                                    >
                                      <option value={""} disabled>
                                        {strings.label.choose_option}
                                      </option>
                                      {previousYear?.map((i, ix) => (
                                        <option value={i.id} key={ix}>
                                          {i.name}
                                        </option>
                                      ))}
                                    </Select>
                                  </div>
                                  <div className="flex_input mt-2">
                                    <label>
                                      {
                                        strings.income
                                          .amount_utilised_captial_gains
                                      } {ItrData.form === "ITR_5" ? '': '*'}
                                    </label>
                                    <Input
                                      inputType={"currency"}
                                      name={`longTerm.deemedDetails.${index}.amountUtilizedForNewAsset`}
                                      value={item.amountUtilizedForNewAsset}
                                      error={
                                        errors?.longTerm?.deemedDetails?.[index]
                                          ?.amountUtilizedForNewAsset
                                      }
                                      onChange={handleChange}
                                      onBlur={() => {
                                        setFieldValue("longTerm.totalAmountDeemed", longTermTotal(values));
                                      }}
                                    />
                                  </div>
                                  <div className="flex_input mt-2">
                                    <label>
                                      {strings.income.amount_used_for_new_asset} *
                                    </label>
                                    <Input
                                      inputType={"currency"}
                                      name={`longTerm.deemedDetails.${index}.amountNotUtilized`}
                                      value={item.amountNotUtilized}
                                      error={
                                        errors?.longTerm?.deemedDetails?.[index]
                                          ?.amountNotUtilized
                                      }
                                      onChange={handleChange}
                                    />
                                  </div>
                                </StyleFormBox>
                                </div>
                                
                              )
                            )}
                            <div className="text-right mt-5">
                              <button
                                className="add_item_button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  push({
                                    prevYearAssetTransferred: "",
                                    deductionClaimedSection: "",
                                    newAssetAcquiredYear: "",
                                    amountUtilizedForNewAsset: "",
                                    amountNotUtilized: "",
                                  });
                                }}
                              >
                                {strings.button.add_more_item}
                              </button>
                            </div>
                          </>
                        )}
                      </FieldArray>

                      <hr className="my-3" />
                    </>
                  ) : (
                    ""
                  )}

                  <CheckBox
                    value={values?.longTerm?.amountUsedOnAssetTransfer === "no"}
                    label={strings.placeholder.no}
                    handleChange={(e) =>
                    {
                      setValues({
                        ...values,
                        longTerm: {
                          ...values.longTerm,
                          amountUsedOnAssetTransfer: e ? "no" : "",
                          deemedDetails: [],
                        },
                      })
                      setFieldValue("longTerm.totalAmountDeemed", longTermTotal(values));
                    }
                    }
                  />
                  <CheckBox
                    value={
                      values?.longTerm?.amountUsedOnAssetTransfer ===
                      "notApplicable"
                    }
                    label={strings.label.not_applicable}
                    handleChange={(e) =>
                    {
                      setValues({
                        ...values,
                        longTerm: {
                          ...values.longTerm,
                          amountUsedOnAssetTransfer: e ? "notApplicable" : "",
                          deemedDetails: [],
                        },
                      })
                      setFieldValue("longTerm.totalAmountDeemed", longTermTotal(values));
                    }

                    }
                  />

                  <StyleFormBox>
                    <div className="flex_input mt-2">
                      <label>
                        {
                          strings.income
                            .amount_deemed_to_long_term_captial_gain
                        } {ItrData.form === "ITR_5" ? '': '*'}
                      </label>

                      <Input
                        inputType={"currency"}
                        name={"longTerm.anyOtherAmountDeemed"}
                        value={values?.longTerm?.anyOtherAmountDeemed}
                        error={errors?.longTerm?.anyOtherAmountDeemed}
                        onChange={handleChange}
                        onBlur={(e)=>{
                          setFieldValue("longTerm.totalAmountDeemed", longTermTotal(values));
                        }}
                      />
                    </div>
                    <div className="flex_input mt-2">
                      <label>
                        {
                          strings.income
                            .total_amount_deemed_to_long_term_captial
                        } {ItrData.form === "ITR_5" ? '': '*'}
                      </label>
                      {/* <Input
                        inputType={"currency"}
                        name={"longTerm.totalAmountDeemed"}
                        value={values?.longTerm?.totalAmountDeemed}
                        onChange={handleChange}
                      /> */}
                      <Input
                        inputType={"currency"}
                        value={values?.longTerm?.totalAmountDeemed}
                        name={"longTerm.totalAmountDeemed"}
                        disabled
                      />
                    </div>
                  </StyleFormBox>
                </div>
              ) : (
                ""
              )}
            </StyleFormBox>

            <button className="primary_button mt-4" onClick={handleSubmit}>
              {strings.button.submit}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
