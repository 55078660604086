import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
export default function FAQ() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
   
    <Root theme={theme} className="abc">
       <div className="main">
      <div className="faq_section row-start-1 row-end-3 active">
        <span>Insights</span>
        <img src='/images/insights.png' />
        <div className="content_box p-4">
          <h6>December 4, 2023</h6>
          <h4>
            Tennessee Taxpayers Beware: Tennessee’ Tax Law has Changed Filing
            Requirements for the 2023 Business Tax
          </h4>
          <p>
            Earlier this year, Tennessee made changes to its Business Tax, which
            is separate and distinct from its Franchise & Excise Tax.
          </p>
          <button>Read More</button>
        </div>
      </div>
      <div className="faq_section">
        <span>Insights</span>
        <div className="content_box p-4">
          <h6>December 4, 2023</h6>
          <h4>
            Tennessee Taxpayers Beware: Tennessee’ Tax Law has Changed Filing
            Requirements for the 2023 Business Tax
          </h4>
          <p>
            Earlier this year, Tennessee made changes to its Business Tax, which
            is separate and distinct from its Franchise & Excise Tax.
          </p>
          <button>Read More</button>
        </div>
      </div>
      <div className="faq_section">
        <span>Insights</span>
        <div className="content_box p-4">
          <h6>December 4, 2023</h6>
          <h4>
            Tennessee Taxpayers Beware: Tennessee’ Tax Law has Changed Filing
            Requirements for the 2023 Business Tax
          </h4>
          <p>
            Earlier this year, Tennessee made changes to its Business Tax, which
            is separate and distinct from its Franchise & Excise Tax.
          </p>
          <button>Read More</button>
        </div>
      </div>
      <div className="faq_section">
        <span>Insights</span>
        <div className="content_box p-4">
          <h6>December 4, 2023</h6>
          <h4>
            Tennessee Taxpayers Beware: Tennessee’ Tax Law has Changed Filing
            Requirements for the 2023 Business Tax
          </h4>
          <p>
            Earlier this year, Tennessee made changes to its Business Tax, which
            is separate and distinct from its Franchise & Excise Tax.
          </p>
          <button>Read More</button>
        </div>
      </div>
      <div className="faq_section">
        <span>Insights</span>
        <div className="content_box p-4">
          <h6>December 4, 2023</h6>
          <h4>
            Tennessee Taxpayers Beware: Tennessee’ Tax Law has Changed Filing
            Requirements for the 2023 Business Tax
          </h4>
          <p>
            Earlier this year, Tennessee made changes to its Business Tax, which
            is separate and distinct from its Franchise & Excise Tax.
          </p>
          <button>Read More</button>
        </div>
      </div>
      </div>
    </Root>
  );
}
const Root = styled.section`



    


    .main {
      background: ${(p) => p.theme.secandary_bg};
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      padding: 40px 20px;
      max-width: 1200px;
      margin: auto;
      @media (min-width:320px) and (max-width:768px)
      {
        grid-template-columns: none;
      }
  }



    .content_box{
        padding-top:70px;
    }
    &.active .content_box{
            padding-top:0px;
        }
  .faq_section {
    background-color: ${(p) => p.theme.tertiary_bg};
    border-radius:20px;
    position:relative;
    overflow: hidden;
    .active{

    }
    >span{
        background-color: ${(p) => p.theme.primary_bg};
        color:${(p) => p.theme.secandary_txt};
        font-weight:600;
        padding: 6px 10px;
        border-radius: 30px;
        position: absolute;
        top: 20px;
        left: 20px;
    }
    button{
        color: ${(p) => p.theme.primary_bg};
    }
    >img{
        width: 100%;
    } 
  }
  


`;
