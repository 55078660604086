import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
export default function CompLoadingBox({ open = true, height = "90px" }) {
  const handleScroll = () => {
    // document.body.style.overflow = open ? 'hidden' : 'auto';
  };
  const { theme } = useSelector((s) => s.platformSetting);

  useEffect(() => {
    handleScroll();
  }, [open]);
  return (
    <LoadingStyle open={open} theme={theme} height={height}>
      {open && (
        <div className="loading_box">
          <div className="loader loader--style1">
            <svg
              version="1.1"
              id="loader-1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="40px"
              height="40px"
              viewBox="0 0 40 40"
              enableBackground="new 0 0 40 40"
              xmlSpace="preserve"
            >
              <path
                opacity="0.2"
                fill="#000"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
  s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
  c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
              />
              <path
                fill="#000"
                d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0C22.32,8.481,24.301,9.057,26.013,10.047z"
              >
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 20 20"
                  to="360 20 20"
                  dur="0.5s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
        </div>
      )}
    </LoadingStyle>
  );
}
const LoadingStyle = styled.section`
  position: relative;
  .loading_box {
    margin: 10px 0px;
    background-color: ${(p) => p.theme.secandary_bg};
    color: ${(p) => p.theme.primary_txt};
    border: 1px solid ${(p) => p.theme.border_clr};
    border-radius: 10px;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    backdrop-filter: blur(2px);
    z-index: 99999;
    padding-top: ${(p) => p.height};
    padding-bottom: ${(p) => p.height};
    display: block;
    position: relative;
  }
  .loader {
    margin: 0 0 2em;
    height: 100%;
    width: 100%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /*
  Set the color of the icon
*/
  svg {
    margin: auto;
  }
  svg path,
  svg rect {
    fill: #ff6700;
  }
`;
