import React from 'react'
import { FaCheck } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import SecandaryButton from '../../styledComponent/Button/SecandaryButton';
import PrimaryButton from '../../styledComponent/Button/PrimaryButton';
import { CiWarning } from "react-icons/ci";

export default function DeleteConfirmation({open,handleClose, hendleDelete}) {
    const { theme ,strings} = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme} className={open && 'active'}>
    <button onClick={() => {handleClose()}} className="close_btn_bg"></button>
    <div className="dialogBox">
        <div className="title">
          <button className="close_btn" onClick={() => {handleClose()}} style={{color:theme.primary_txt}}><IoClose /></button>
        </div>
      <div className="text-center">
        <div className='warning_div'>
        <CiWarning />
        </div>
          <h4 style={{fontSize:"23px"}}>
            {strings.message.are_you_sure}
          </h4>
          <p style={{fontSize:"14px"}}>{strings.message.delete_sub_message}</p>
          <div className="flex items-center gap-4 mt-4">
            <SecandaryButton title={strings.button.cancel} handleClick={()=>{handleClose()}}/>
            <PrimaryButton title={strings.button.delete} handleClick={()=>{hendleDelete()}}/>
          </div>
        </div>

    </div>
  </Root>
  )
}

const Root = styled.section`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000042;
  backdrop-filter: blur(2px);
  top: 0px;
  left: 0px;
  display:none;
  z-index:10;
  &.active{
    display:unset
  }
  .close_btn_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: transparent;
  }
  .close_btn{
    background: ${p => p.theme.tertiary_bg};
    border: none;
    font-size: 20px;
    border-radius: 4px;
    display: block;
    margin-left: auto;
  }
  .dialogBox {
    position: absolute;
    left:50%;
    top:20px;
    transform: translateX(-50%);
    background: ${(p) => p.theme.secandary_bg};
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    padding: 10px;
    overflow: scroll;
  }
  .warning_div{
    display:flex;
    justify-content:center;
    align-items:center;
    color: red;
    svg{
        font-size: 65px;
    }
  }
`
