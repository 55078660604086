import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { addOpacity } from '../../utils/helpers';
export default function PrimaryButton({title , handleClick ,addClass ,disable}) {
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme} onClick={()=>{handleClick()}} className={`${addClass || 'my-2.5'}`} disabled={disable}>{title}</Root>
  )
}
const Root = styled.button`
    width:100%;
    background-color:${p => p.theme.primary_bg};
    color:${p => p.theme.secandary_txt};
    border-radius: 4px;
    padding: 10px;
    cursor:pointer;
    font-weight:500;
    &:disabled {
      background-color:${p=> addOpacity(p.theme.primary_bg , 0.6)};
      color:${p=> p.theme.secandary_txt};
      cursor: not-allowed;
    }
`