import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import Select from "../../../../styledComponent/Inputs/Select";
import Input from "../../../../styledComponent/Inputs/Input";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { RiDeleteBin6Fill } from "react-icons/ri";
import {
  delDetailOfProfessionBusinessAction,
  deleteDepreciationEntryAction,
  deleteScheduleESRAction,
  deleteScheduleICDSAction,
  getDetailOfProfessionBusinessAction,
  saveDetailOfProfessionBusinessAction,
  updateDetailOfProfessionBusinessAction,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import BalanceSheet from "./BalanceSheet";
import ProfitAndLoss from "./ProfitAndLoss";
import { FaRegEdit } from "react-icons/fa";
import Depreciation from "./Depreciation";
import ScheduleICDS from "./ScheduleICDS";
import ScheduleEsr from "./ScheduleEsr";
import { addOpacity } from "../../../../utils/helpers";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";

export default function RegularBusiness({
  data,
  businessType,
  depreciationBlock,
}) {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const [regularBusinessData, setRegularBusinessData] = useState({});
  const [balanceSheetForm, setBalanceSheetForm] = useState(false);
  const [profitLossForm, setProfitLossForm] = useState(false);
  const [depreciationForm, setDepreciationForm] = useState(false);
  const [icdsFormBox, setIcdsFormBox] = useState(false);
  const [esrFormBox, setEsrFormBox] = useState(false);
  const { ItrData } = useSelector((s) => s.itr);
  const [formData, setFormData] = useState({detailsOfProfession: [{
    businessType: "",
    natureOfBusiness: "",
    nameOfBusiness: "",
    description: "",
  }]});
  const [editFormData, setEditFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [activeBusinessType, setActiveBusinessType] = useState(0);
  const [test, setTest] = useState();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    // totalExpenses: Yup.number().required(strings.validation_message.required),
    // grossRevenue: Yup.number().required(strings.validation_message.required),

    detailsOfProfession: Yup.array().of(
      Yup.object().shape({
        businessType: Yup.string().required(
          strings.validation_message.required
        ),
        natureOfBusiness: Yup.string().required(
          strings.validation_message.required
        ),
        nameOfBusiness: Yup.string().required(
          strings.validation_message.required
        ),
      })
    ),
  });

  const handleDelete = (type, id) => {
    if (type == "detailsOfProfession") {
      if(id) {
        setLoader(true);
        dispatch(
          delDetailOfProfessionBusinessAction({ id }, (e) => {
            if (e.status == 200) {
              toast.success(e.data);
            } else {
              toast.error(e.message);
            }
            setLoader(false);
          })
        );
      }
    } else if (type == "ICDS") {
      setLoader(true);
      dispatch(
        deleteScheduleICDSAction({ id }, (e) => {
          if (e.status == 200) {
            setRegularBusinessData({
              ...regularBusinessData,
              scheduleICDS: regularBusinessData.scheduleICDS.filter(
                (i) => i.id != id
              ),
            });
            toast.success(e.data);
          } else {
            toast.error(e.message);
          }
          setLoader(false);
        })
      );
    } else if (type == "ESR") {
      setLoader(true);
      dispatch(
        deleteScheduleESRAction({ professionalBusinessId: id }, (e) => {
          if (e.status == 200) {
            setRegularBusinessData({
              ...regularBusinessData,
              scheduleESR: [],
            });
            toast.success(e.data);
          } else {
            toast.error(e.message);
          }
          setLoader(false);
        })
      );
    } else if (type == "depreciation") {
      setLoader(true);
      dispatch(
        deleteDepreciationEntryAction({ id }, (e) => {
          if (e.status == 200) {
            setRegularBusinessData({
              ...regularBusinessData,
              depreciation: regularBusinessData.depreciation.filter(
                (i) => i.id != id
              ),
            });
            toast.success(e.data);
          } else {
            toast.error(e.message);
          }
          setLoader(false);
        })
      );
    }
  };

  const handleCallback = (data) => {
    setLoader(true);
    const sendData = { ...data, itrId: ItrData.id };
    
    const check = sendData?.detailsOfProfession?.find((items) => items.id);
    if (typeof check?.id == "number") {
      sendData.detailsOfProfession.map((items) => {
        delete items["createdAt"];
        delete items["isActive"];
        delete items["isDeleted"];
        delete items["professionalBusiness"];
        delete items["updatedAt"];
      });
      dispatch(
        updateDetailOfProfessionBusinessAction(sendData, (e) => {
          if (e.status == 200) {
            setFormData({
              detailsOfProfession: e.data.map((items) => {
                items.businessType = items?.businessType?.id;
                items.natureOfBusiness = items?.natureOfBusiness?.id;
                items.id = items?.id;
                return items;
              })
            });
            toast.success("Data added successfully");
          } else {
            toast.error(e.message);
          }
          setLoader(false);
        })
      );
    } else {
      dispatch(
        saveDetailOfProfessionBusinessAction(sendData, (e) => {
          if (e.status == 200) {
            setFormData({
              detailsOfProfession: e.data.map((items) => {
                items.businessType = items?.businessType?.id;
                items.natureOfBusiness = items?.natureOfBusiness?.id;
                items.id = items?.id;
                return items;
              })
            });
          } else {
            toast.error(e.message);
          }
          setLoader(false);
        })
      );
    }
  };

  useEffect(() => {
    setLoader(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getDetailOfProfessionBusinessAction(parm, (e) => {
        if (e.status == 200) {
          setLoader(false);
          if (e.data.length > 0) {
            setFormData({
              detailsOfProfession: e.data.map((items) => {
                items.businessType = items?.businessType?.id;
                items.natureOfBusiness = items?.natureOfBusiness?.id;
                return items;
              }),
            });
          }
        } else {
          toast.error(e.message);
          setLoader(false);
        }
      })
    );
    // if( data.detailsOfProfession.length>0){
    //   setFormData({
    //     detailsOfProfession: data?.detailsOfProfession.map((items)=>{
    //       items.businessType = items?.businessType?.id
    //       items.natureOfBusiness = items?.natureOfBusiness?.id
    //       return items
    //     }
    //   )
    //   });
    // }
    setLoader(false);
  }, []);

  useEffect(() => {
    if (data) {
      setRegularBusinessData(data);
    }
  }, [data]);

  console.log("regularBusinessData =====>", formData);
  return (
    <div>
      <LoadingBox open={loader} />
      
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleCallback(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <div>
            <AccordianBox
              title={strings?.income.businessDetails}
              initial={false}
            >
              <h4 style={{ color: theme.primary_txt }}>
                {/* <h4>{strings?.income.businessDetails} </h4> */}
              </h4>
              <div>
                <div className="flex_input my-2">
                  {/* <label>{strings.income.business_type} *</label> */}
                  <StyleFormBox>
                    <FieldArray name="detailsOfProfession">
                      {({ insert, remove, push }) => (
                        <div>
                          {values?.detailsOfProfession?.map((item, index) => (
                            <StyleFormBox key={index}>
                              <button
                                className="delete_button mb-2 block ml-auto"
                                onClick={() => (
                                  handleDelete("detailsOfProfession", item.id),
                                  remove(index)
                                )}
                              >
                                <RiDeleteBin6Fill />
                              </button>

                              <div className="flex_input my-2">
                                <label>{strings.income.business_type} *</label>

                                <Select
                                  name={`detailsOfProfession.${index}.businessType`}
                                  error={
                                    errors?.detailsOfProfession &&
                                    errors?.detailsOfProfession[index]
                                      ?.businessType
                                  }
                                  value={item.businessType}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                >
                                  <option value="" disabled>
                                    {strings.label.choose_option}
                                  </option>
                                  {businessType?.map((i, ix) => (
                                    <option value={i.id} key={ix}>
                                      {i.name}
                                    </option>
                                  ))}
                                </Select>
                              </div>

                              <div className="flex_input my-2">
                                <label>
                                  {strings.income.nature_of_business} *
                                </label>

                                <Select
                                  name={`detailsOfProfession.${index}.natureOfBusiness`}
                                  error={
                                    errors?.detailsOfProfession &&
                                    errors?.detailsOfProfession[index]
                                      ?.natureOfBusiness
                                  }
                                  value={item.natureOfBusiness}
                                  onChange={handleChange}
                                >
                                  <option value="">
                                    {strings.label.choose_option}
                                  </option>
                                  {businessType
                                    ?.find(
                                      (itemss) =>
                                        itemss.id == item?.businessType
                                    )
                                    ?.subCategory?.map((i, ix) => (
                                      <option value={i.id} key={ix}>
                                        {i.name}
                                      </option>
                                    ))}
                                </Select>
                              </div>

                              <div className="flex_input my-2">
                                <label>
                                  {strings.income.name_business_company} *
                                </label>

                                <Input
                                  name={`detailsOfProfession.${index}.nameOfBusiness`}
                                  value={item.nameOfBusiness}
                                  error={
                                    errors?.detailsOfProfession &&
                                    errors?.detailsOfProfession[index]
                                      ?.nameOfBusiness
                                  }
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="flex_input mt-4">
                                <label>{strings.label.description}</label>

                                <Input
                                  inputType={"message"}
                                  name={`detailsOfProfession.${index}.description`}
                                  value={item.description}
                                  onChange={handleChange}
                                />
                              </div>
                            </StyleFormBox>
                          ))}

                          <div className="text-right mt-5">
                            <button
                              className="add_item_button"
                              onClick={(e) => {
                                e.preventDefault();
                                push({
                                  businessType: "",
                                  natureOfBusiness: "",
                                  nameOfBusiness: "",
                                  description: "",
                                });
                              }}
                            >
                              {strings.button.add_more_item}
                            </button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </StyleFormBox>
                </div>
              </div>
              {values?.detailsOfProfession?.length ? (
                <button
                  className="primary_button my-4"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {strings.button.save_and_close}
                </button>
              ) : ""}
            </AccordianBox>

            <StyleFormBox>
              <div className="flex items-center justify-between">
                <h4>{strings.income.balance_sheet}</h4>

                <button
                  className="add_button"
                  onClick={() => setBalanceSheetForm(!balanceSheetForm)}
                >
                  {" "}
                  {regularBusinessData?.balanceSheet?.length == 0
                    ? strings.button.add
                    : strings.button.edit}
                </button>
              </div>
              <p>{strings.income.details_of_assets_liabilities_of_business}</p>
              <hr />

              {regularBusinessData?.balanceSheet?.length > 0
                ? regularBusinessData?.balanceSheet.map((item) => (
                    <div className="mt-4">
                      {/* <div className="flex action_buttons text-2xl width-full justify-end">
                  <button className="delete_btn">
                    <RiDeleteBin6Fill />
                  </button>
                </div> */}
                      <div className="flex items-center justify-between items-end">
                        <h4>
                          {strings.balance_sheet.assets} : {strings.label.rupee}
                          {item?.totalAssets || 0}
                        </h4>
                        <h4>
                          {strings.balance_sheet.liabilities} :{" "}
                          {strings.label.rupee}
                          {item?.totalLiabilities || 0}
                        </h4>
                      </div>
                    </div>
                  ))
                : ""}

              {balanceSheetForm ? <BalanceSheet /> : ""}
            </StyleFormBox>

            {/* ===========  P&L Account ==============  */}
            <StyleFormBox>
              <div className="flex items-center justify-between">
                <h4>{strings.income.p_l_account}</h4>
                <button
                  className="add_button"
                  onClick={() => setProfitLossForm(!profitLossForm)}
                >
                  {" "}
                  {regularBusinessData?.profitAndLoss?.length == 0
                    ? strings.button.add
                    : strings.button.edit}
                </button>
              </div>
              <p>
                {
                  strings.income
                    .details_income_expenses_of_your_business_profession_for_period
                }
              </p>
              <hr />
              {regularBusinessData?.profitAndLoss?.length > 0
                ? regularBusinessData?.profitAndLoss.map((item) => {
                    <div className="mt-4">
                      {/* <div className="flex action_buttons text-2xl width-full justify-end">
  <button className="delete_btn">
    <RiDeleteBin6Fill />
  </button>
</div> */}
                      <div className="flex items-center justify-between items-end">
                        <h4>
                          {strings.balance_sheet.expenses} :{" "}
                          {strings.label.rupee}
                          {regularBusinessData?.profitAndLoss[0]
                            ?.totalExpenses || 0}
                        </h4>
                        <h4>
                          {strings.balance_sheet.income} : {strings.label.rupee}
                          {regularBusinessData?.profitAndLoss[0]?.totalIncome ||
                            0}
                        </h4>
                      </div>
                    </div>;
                  })
                : ""}

              {profitLossForm ? <ProfitAndLoss /> : ""}
            </StyleFormBox>

            {/* ============ Depreciation ============= */}
            <StyleFormBox>
              <div className="flex items-center justify-between">
                <h4>{strings.income.depreciation}</h4>
                <button
                  className="add_button"
                  onClick={() => setDepreciationForm(true)}
                >
                  {" "}
                  {strings.button.add}
                </button>
              </div>
              <p>
                {
                  strings.income
                    .summary_depreciation_of_assets_under_income_tax_act
                }
              </p>
              <hr />

              {regularBusinessData.depreciation?.length > 0 ? (
                <StyleFormBox>
                  {regularBusinessData.depreciation?.map((item) => (
                    <div
                      className="flex gap-2 p-3 my-3 align-center justify-between"
                      style={{
                        background: addOpacity(theme.primary_bg, 0.2),
                        borderRadius: "10px",
                      }}
                    >
                      <div>
                        <p>Opening Amount : {item.openingAmount}</p>
                        <p> Description : {item.description}</p>
                      </div>

                      <div className="action_buttons">
                        <button
                          className="edit_btn"
                          onClick={() => {
                            setDepreciationForm(true);
                            setEditFormData(item);
                          }}
                        >
                          <FaRegEdit />
                        </button>
                        <button
                          className="delete_btn"
                          onClick={() => handleDelete("depreciation", item.id)}
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    </div>
                  ))}
                </StyleFormBox>
              ) : (
                ""
              )}
            </StyleFormBox>

            {/* ============= Schedule ICDS ============== */}
            <StyleFormBox>
              <div className="flex items-center justify-between">
                <h4>{strings.income.schedule_icds}</h4>

                <button
                  className="add_button"
                  onClick={() => setIcdsFormBox(true)}
                >
                  {" "}
                  {strings.button.add}
                </button>
              </div>
              <p>{strings.income.effect_of_income_computation}</p>
              {regularBusinessData.scheduleICDS?.length > 0 ? (
                <StyleFormBox>
                  {regularBusinessData.scheduleICDS?.map((item) => (
                    <div
                      className="flex gap-2 p-3 my-3 align-center justify-between"
                      style={{
                        background: addOpacity(theme.primary_bg, 0.2),
                        borderRadius: "10px",
                      }}
                    >
                      <div>
                        {strings.balance_sheet.accounting_policies} :{" "}
                        {item.accountPolicies}
                      </div>

                      <div className="action_buttons">
                        <button
                          className="edit_btn"
                          onClick={() => {
                            setIcdsFormBox(true);
                            setEditFormData(item);
                          }}
                        >
                          <FaRegEdit />
                        </button>
                        <button
                          className="delete_btn"
                          onClick={() => handleDelete("ICDS", item.id)}
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    </div>
                  ))}
                </StyleFormBox>
              ) : (
                ""
              )}
            </StyleFormBox>

            {/* ============= Schedule ESR  ========== */}
            <StyleFormBox>
              <div className="flex items-center justify-between">
                <h4>{strings.income.schedule_esr}</h4>
                <button
                  className="add_button"
                  onClick={() => setEsrFormBox(true)}
                >
                  {strings.button.add}
                </button>
              </div>
              <p>{strings.income.deduction_under_section_35}</p>

              {regularBusinessData.scheduleESR?.length > 0 ? (
                <StyleFormBox>
                  <div
                    className="flex gap-2 p-3 my-3 align-center justify-between"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                  >
                    <div>Schedule ESR Data</div>

                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEsrFormBox(true);
                          setEditFormData(regularBusinessData.scheduleESR);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDelete(
                            "ESR",
                            regularBusinessData.scheduleESR[0]
                              .professionalBusiness
                          )
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                </StyleFormBox>
              ) : (
                ""
              )}
            </StyleFormBox>
          </div>
        )}
      </Formik>

      {/*========  Schedule ICDS ========== */}
      <FormDialogBox
        open={icdsFormBox}
        formTitle={"Schedule ICDS"}
        handleClose={() => {
          setIcdsFormBox(false);
          setEditFormData({});
        }}
      >
        <ScheduleICDS
          data={editFormData}
          handleCallback={(type, e) => {
            const updatedscheduleICDS =
              type === "edit"
                ? regularBusinessData.scheduleICDS.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...regularBusinessData.scheduleICDS, e];

            setRegularBusinessData((prev) => ({
              ...prev,
              scheduleICDS: updatedscheduleICDS,
            }));
            setEditFormData({});
            setIcdsFormBox(false);
          }}
        />
      </FormDialogBox>

      {/*======= Schedule ESR ==========  */}
      <FormDialogBox
        open={esrFormBox}
        formTitle={"Schedule ESR "}
        handleClose={() => {
          setEsrFormBox(false);
          setEditFormData({});
        }}
      >
        <ScheduleEsr
          data={editFormData}
          handleCallback={(type, e) => {
            const updatedData =
              type === "edit"
                ? regularBusinessData.scheduleESR.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...regularBusinessData.scheduleESR, e];

            setRegularBusinessData((prev) => ({
              ...prev,
              scheduleESR: updatedData,
            }));
            setEditFormData({});
            setEsrFormBox(false);
          }}
        />
      </FormDialogBox>

      {/* ========= Depreciation ============= */}
      <FormDialogBox
        open={depreciationForm}
        formTitle={strings.balance_sheet.add_depreciation_entry}
        handleClose={() => {
          setDepreciationForm(false);
          setEditFormData({});
        }}
      >
        <Depreciation
          data={editFormData}
          depreciationBlock={depreciationBlock}
          handleCallback={(type, e) => {
            const updatedData =
              type === "edit"
                ? regularBusinessData.depreciation.map((item) =>
                    item.id === e.id ? e : item
                  )
                : [...regularBusinessData.depreciation, e];

            setRegularBusinessData((prev) => ({
              ...prev,
              depreciation: updatedData,
            }));
            setEditFormData({});
            setDepreciationForm(false);
          }}
        />
      </FormDialogBox>
    </div>
  );
}
