import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
export default function Banner() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <div className="content_section">

      </div>
    </Root>
  );
}

const Root = styled.section`

  .content_section {
    height:650px;
    background-image: url("/Images/banner-1.png");
    background-position: center;
}
`;
