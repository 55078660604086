import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from '../../utils/helpers';
export default function InputField(props) {
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
      <input {...props}/>
      {/* <input type={type =='number' ? 'number':"text" } name={name} value={value} placeholder={placeholder} onChange={handleChange}/> */}
      <p className="error">{props.error}</p>
    </Root>
  );
}
const Root = styled.div`
  width: 100%;
  position: relative;
  input {
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid ${(p) =>p.theme.border_clr};
    color: ${(p) => p.theme.primary_txt};
    background-color:transparent;
    
  }

`;
