import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "../../../../styledComponent/Inputs/Select";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import LoadingBox from "../../../DialogBox/LoadingBox";
import Phone from "../../../../styledComponent/Inputs/Phone";
import { toast } from "react-toastify";
import {
  addOpacity,
  formatAadhaarEnrolmentNo,
} from "../../../../utils/helpers";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import {
  deleteCompanyDirectorsInfoAction,
  deleteOwnerAndShareHolderAction,
  getPersonalInfoAction,
  setPersonalInfoAction,
} from "../../../../redux/itr/action";
import Input from "../../../../styledComponent/Inputs/Input";
import CompanyDirectors from "./CompanyDirectors";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import CompanyShare from "./CompanyShare";
import CompanyOwner from "./CompanyOwner";
import CheckBox from "../../../../styledComponent/Inputs/CheckBox";
import SubsidiaryCompany from "./SubsidiaryCompany";
import NatureOfCompany from "./NatureOfCompany";

export default function PersonalInfoCompany({ handleNext }) {
  const [formData, setFormData] = useState({});
  const [citiesList, setCitiesList] = useState([]);
  const [activeState, setActiveState] = useState(1);

  const [loading, setLoading] = useState(false);
  const [addBankBox, setAddBankBox] = useState(false);

  //   ------- Company Directors
  const [companyFormBox, setCompanyFormBox] = useState(false);
  const [companyFormData, setCompanyFormData] = useState({});

  //   -------- Company Share Holder Information
  const [companyShareFormBox, setCompanyShareFormBox] = useState(false);
  const [companyShareFormData, setCompanyShareFormData] = useState({});

  //    Company Owner Information
  const [companyOwnerFormBox, setCompanyOwnerFormBox] = useState(false);
  const [companyOwnerFormData, setCompanyOwnerFormData] = useState({});

  const [natureCompanyData, setNatureCompanyData] = useState({});

  //   Stack Holder Data
  const [stackHolderData, setStackHolderData] = useState({
    companyDirectors: [],
    ownerDetails: [],
    shareHolderDetails: [],
  });
  const [optionData, setOptionData] = useState({});

  const [editFormData, setEditFormData] = useState({});
  const [companyOrganizationData, setCompanyOrganizationData] = useState([]);

  const { strings, theme, states } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    hufName: Yup.string().required(strings.validation_message.required),

    dateOfIncorporation: Yup.date()
      .required(strings.validation_message.required)
      .typeError(strings.validation_message.invalid_date),

    dateofCommencementBusiness: Yup.date()
      .required(strings.validation_message.required)
      .typeError(strings.validation_message.invalid_date),

    CINNumber: Yup.string()
      .required(strings.validation_message.required)
      .matches(
        /^[LU]/,
        `${strings.validation_message.invalid} CINNumber (must start with L or U)`
      )
      .matches(
        /^[LU][0-9]{5}/,
        `${strings.validation_message.invalid} CINNumber (invalid industry code)`
      )
      .matches(
        /^[LU][0-9]{5}[A-Z]{2}/,
        `${strings.validation_message.invalid} CINNumber (invalid state code)`
      )
      .matches(
        /^[LU][0-9]{5}[A-Z]{2}[0-9]{4}/,
        `${strings.validation_message.invalid} CINNumber (invalid year of incorporation)`
      )
      .matches(
        /^[LU][0-9]{5}[A-Z]{2}[0-9]{4}(PLC|PTC|GOI|NPL|OPC|LLP|FLC)/,
        `${strings.validation_message.invalid} CINNumber (invalid ownership type)`
      )
      .matches(
        /^[LU][0-9]{5}[A-Z]{2}[0-9]{4}(PLC|PTC|GOI|NPL|OPC|LLP|FLC)[0-9]{6}$/,
        `${strings.validation_message.invalid} CINNumber (invalid unique company code)`
      )
      .length(
        21,
        `${strings.validation_message.invalid} CINNumber (must be exactly 21 characters)`
      ),

    assessmentYear: Yup.string().required(strings.validation_message.required),
    PAN: Yup.string()
      .required(strings.validation_message.required)
      .matches(
        /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
        `${strings.validation_message.invalid_pan}`
      ),

    address: Yup.object().shape({
      flatDoorNo: Yup.string().required(strings.validation_message.required),
      premiseName: Yup.string(),
      street: Yup.string(),
      area: Yup.string().required(strings.validation_message.required),
      city: Yup.number()
        .required(strings.validation_message.required)
        .typeError(strings.validation_message.must_be_integer),
      state: Yup.number()
        .required(strings.validation_message.required)
        .typeError(strings.validation_message.must_be_integer),

      pinCode: Yup.string()
        .required(strings.validation_message.required)
        .matches(
          /^[1-9][0-9]{5}$/, strings.validation_message.invalid_pin
        ),
      mobileNo: Yup.string().required(strings.validation_message.required).min(12, "Field must be 10 digits"),
      emailAddress: Yup.string()
        .email(strings.validation_message.invalid_email)
        .nullable(true)
        .optional(),
    }),
  });

  const handleFormSubmit = async (data) => {
    let updateData = {
      ...data,
      mobileNo: data.mobileNo?.slice(data.countryCodeMobile.length),
      itrId: ItrData.id,
      address: {
        ...data.address,
        mobileNo: data.address.mobileNo?.slice(
          data.address?.countryCodeMobile?.length
        ),
      },
    };
    if (updateData?.aadhaarCardNo?.length > 12) {
      delete updateData.aadhaarEnrollmentNo;
    }
    setLoading(true);
    dispatch(
      setPersonalInfoAction(updateData, (e) => {
        if (e.status === 200) {
          setLoading(false);
          toast.success("successfully upload data");
          handleNext();
        } else {
          setLoading(false);
        }
      })
    );
  };

  const handleDelete = async (type, id) => {
    setLoading(true);
    try {
      const ItrId = ItrData.id;
      const actions = {
        companyDirectors: {
          action: deleteCompanyDirectorsInfoAction,
          key: "companyDirectors",
        },
        shareHolderDetails: {
          action: deleteOwnerAndShareHolderAction,
          key: "shareHolderDetails",
        },
        ownerDetails: {
          action: deleteOwnerAndShareHolderAction,
          key: "ownerDetails",
        },
      };

      if (actions[type]) {
        const { action, key } = actions[type];
        await new Promise((resolve, reject) => {
          dispatch(
            action({ id, ItrId }, (response) => {
              if (response.status === 200) {
                setStackHolderData((prevData) => ({
                  ...prevData,
                  [key]: prevData[key].filter((item) => item.id !== id),
                }));
                toast.success("Successfully deleted data");
                resolve();
              } else {
                toast.error("Failed to delete data");
                reject(new Error("Failed to delete"));
              }
            })
          );
        });
      } else {
        toast.error("Invalid delete type");
      }
    } catch (error) {
      toast.error("An error occurred while deleting data");
    } finally {
      setLoading(false);
    }
  };

  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };

  //  API : Get api for personal info
  const getPersonalInfoApi = () => {
    setLoading(true);
    dispatch(
      getPersonalInfoAction(ItrData.id, (e) => {
        let info = e?.data?.personalInfo;
        setStackHolderData({
          companyDirectors: info?.companyDirectors,
          ownerDetails: info?.ownerDetails,
          shareHolderDetails: info?.shareHolderDetails,
        });
        setNatureCompanyData(info?.natureOfCompany);
        setCompanyOrganizationData(info?.companyOrganization);
        setFormData({
          assessmentYear: info?.assessmentYear || "",
          CINNumber: info?.CINNumber || "",
          hufName: info?.hufName || "",
          PAN: info?.PAN || "",
          dateOfIncorporation: info?.dateOfIncorporation || "",
          dateofCommencementBusiness: info?.dateofCommencementBusiness || "",

          address: {
            flatDoorNo: info?.address?.flatDoorNo || "",
            premiseName: info?.address?.premiseName || "",
            street: info?.address?.street || "",
            area: info?.address?.area || "",
            city: info?.address?.city?.id,
            state: info?.address?.state?.id || 1,
            country: "101",
            pinCode: info?.address?.pinCode || "",
            countryCodeMobile: info?.address?.countryCodeMobile || "+91",
            mobileNo: info?.address?.mobileNo
              ? info?.address?.countryCodeMobile + info?.address?.mobileNo
              : "",
            emailAddress: info?.address?.emailAddress || "",
          },
        });

        if (e.status === 200) {
          let { personalInfo, ...allOptions } = e?.data;
          setOptionData(allOptions);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    getPersonalInfoApi();
  }, []);

  return (
    <Root>
      <LoadingBox open={loading} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues, setFieldValue }) => (
          <div>
            <h4 className="mb-5" style={{ color: theme.primary_txt }}>
              {strings.tax.assessment_year} *
            </h4>

            <StyleFormBox>
              <Select
                name={"assessmentYear"}
                error={errors?.assessmentYear}
                value={values?.assessmentYear}
                onChange={handleChange}
              >
                <option value="" disabled>
                  {strings.label.choose_option}
                </option>
                {optionData?.defaultAssessmentYear?.map((i, ix) => (
                  <option value={i.id} key={ix}>
                    {i.name}
                  </option>
                ))}
              </Select>
            </StyleFormBox>

            {/* Personal Information */}
            <AccordianBox title={strings.tax.personal_information}>
              <div className="flex_input">
                <div>
                  <label>{strings.other_disclosures.name_of_company} *</label>
                  <Input
                    name={"hufName"}
                    error={errors.hufName}
                    value={values.hufName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Date of Incorporation *</label>
                  <Input
                    name={"dateOfIncorporation"}
                    type={"date"}
                    error={errors.dateOfIncorporation}
                    value={values.dateOfIncorporation}
                    format={"YYYY-MM-DD"}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>Date of Commencement of Business *</label>
                  <Input
                    name={"dateofCommencementBusiness"}
                    type={"date"}
                    error={errors.dateofCommencementBusiness}
                    value={values.dateofCommencementBusiness}
                    format={"YYYY-MM-DD"}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label> PAN number of Company *</label>
                  <Input
                    name={"PAN"}
                    className={"uppercase"}
                    placeholder={strings.placeholder.enter_10_digit}
                    error={errors.PAN}
                    value={values.PAN}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setFieldValue("PAN", e.target.value.toUpperCase());
                      }
                    }}
                  />
                </div>
              </div>

              <div>
                <label>CIN number of Company *</label>
                <Input
                  name={"CINNumber"}
                  className={"uppercase"}
                  error={errors.CINNumber}
                  value={values.CINNumber}
                  onChange={handleChange}
                />
              </div>
            </AccordianBox>

            {/* Your Address */}
            <AccordianBox
              title={strings.tax.your_address}
              description={
                strings.tax
                  .you_can_provide_either_your_current_address_or_permanent_address
              }
            >
              <div className="flex_input">
                <div>
                  <label>
                    {strings.address.flat} / {strings.address.door_no} *
                  </label>
                  <Input
                    name={"address.flatDoorNo"}
                    placeholder={`${strings.address.flat} / ${strings.address.door_no}`}
                    error={errors.address && errors.address.flatDoorNo}
                    value={values.address && values.address.flatDoorNo}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.address.premise_name}</label>
                  <Input
                    name={"address.premiseName"}
                    placeholder={strings.address.premise_name}
                    error={errors.address && errors.address.premiseName}
                    value={values.address && values.address.premiseName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>
                    {strings.address.road} / {strings.address.street}
                  </label>
                  <Input
                    name={"address.street"}
                    placeholder={`${strings.address.road} / ${strings.address.street}`}
                    error={errors.address && errors.address.street}
                    value={values.address && values.address.street}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.address.area_locality} *</label>
                  <Input
                    name={"address.area"}
                    placeholder={strings.address.area_locality}
                    error={errors.address && errors.address.area}
                    value={values.address && values.address.area}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.address.pincode} *</label>
                  <Input
                    name={"address.pinCode"}
                    type={"number"}
                    placeholder={strings.address.pincode}
                    error={errors.address && errors.address.pinCode}
                    value={values.address && values.address.pinCode}
                    onChange={(e) => {
                      if (e.target.value.length < 7) {
                        handleChange(e);
                      }
                    }}
                  />
                </div>
                <div>
                  <label>{strings.address.state} *</label>
                  <Select
                    name="address.state"
                    error={errors.address && errors.address.state}
                    value={values.address && values.address.state}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        address: {
                          ...values.address,
                          state: e.target.value,
                          city: "",
                        },
                      });
                      setActiveState(Number(e.target.value));
                    }}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {states?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.address.city} *</label>
                  <Select
                    name={"address.city"}
                    error={errors.address && errors.address.city}
                    value={values.address && values.address.city}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>
                    {citiesList?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div>
                  <label>{strings.label.phone_number} *</label>
                  <Input
                    inputType={"phone"}
                    name={"address.mobileNo"}
                    placeholder={strings.placeholder.enter_10_digit}
                    error={errors.address && errors.address.mobileNo}
                    value={values.address && values.address.mobileNo}
                    onChange={(phoneNumber, country) =>
                      setValues({
                        ...values,
                        address: {
                          ...values.address,
                          mobileNo: phoneNumber.replace(/\D/g, ""),
                          countryCodeMobile: country.dialCode?.replace(
                            /\D/g,
                            ""
                          ),
                        },
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.label.email} *</label>
                  <Input
                    name={"address.emailAddress"}
                    placeholder={strings.placeholder.email_address}
                    error={errors.address && errors.address.emailAddress}
                    value={values.address && values.address.emailAddress}
                    onChange={handleChange}
                  />
                </div>
                <div></div>
              </div>
            </AccordianBox>

            {/* Stake Holders */}
            <AccordianBox title={"Stake Holders"}>
              {/*----------  Company Directors */}
              <StyleFormBox>
                <h4>Company Directors</h4>
                <p>Provide Partner/Member Details Here</p>
                {stackHolderData?.companyDirectors?.map((item, ix) => (
                  <div
                    key={ix}
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                  >
                    <h4 className="mr-auto">Name / Designation</h4>

                    <h4>
                      {item.firstName} {item.lastName} /{" "}
                      {item?.designation?.name}
                    </h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditFormData(item);
                          setCompanyFormBox(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDelete("companyDirectors", item.id)
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                ))}

                <div className="flex_input mt-4">
                  <button className="secandary_button">
                    {strings.button.upload_file}
                  </button>
                  <button
                    className="secandary_button"
                    onClick={() => setCompanyFormBox(true)}
                  >
                    {strings.button.add_manually}
                  </button>
                </div>
              </StyleFormBox>

              {/* -------- Company Share Holder Information  */}
              <StyleFormBox>
                <h4>Company Share Holder Information</h4>
                <p>Would you like to add a ShareHolder?</p>

                {stackHolderData?.shareHolderDetails?.map((item, ix) => (
                  <div
                    key={ix}
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                  >
                    <h4 className="mr-auto">Name / Percentage</h4>

                    <h4>
                      {item.fullName} / {item.percentageShare}
                    </h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditFormData(item);
                          setCompanyShareFormBox(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() =>
                          handleDelete("shareHolderDetails", item.id)
                        }
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                ))}

                <div className="flex_input mt-4">
                  <button className="secandary_button">
                    {strings.button.upload_file}
                  </button>
                  <button
                    className="secandary_button"
                    onClick={() => setCompanyShareFormBox(true)}
                  >
                    {strings.button.add_manually}
                  </button>
                </div>
              </StyleFormBox>

              {/* ----------- Company Owner Information */}
              <StyleFormBox>
                <h4>Company Owner Information</h4>
                <p>Would you like to add a Owner?</p>

                {stackHolderData?.ownerDetails?.map((item, ix) => (
                  <div
                    key={ix}
                    className="flex gap-2 p-3 my-3 align-center"
                    style={{
                      background: addOpacity(theme.primary_bg, 0.2),
                      borderRadius: "10px",
                    }}
                  >
                    <h4 className="mr-auto">Name / Percentage</h4>

                    <h4>
                      {item.fullName} / {item.percentageShare}
                    </h4>
                    <div className="action_buttons">
                      <button
                        className="edit_btn"
                        onClick={() => {
                          setEditFormData(item);
                          setCompanyOwnerFormBox(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>
                      <button
                        className="delete_btn"
                        onClick={() => handleDelete("ownerDetails", item.id)}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  </div>
                ))}

                <div className="flex_input mt-4">
                  <button className="secandary_button">
                    {strings.button.upload_file}
                  </button>
                  <button
                    className="secandary_button"
                    onClick={() => setCompanyOwnerFormBox(true)}
                  >
                    {strings.button.add_manually}
                  </button>
                </div>
              </StyleFormBox>
            </AccordianBox>

            <NatureOfCompany
              data={natureCompanyData}
              companyOrganization={companyOrganizationData}
              holdingStatus={optionData?.holdingStatus}
              optForTaxation={optionData?.optForTaxation}
              businessOrganizationType={optionData?.businessOrganizationType}
            />

            {/* Your Address */}
            <AccordianBox
              title={strings.tax.pan_details}
              description={strings.tax.pan_linking_is_mandatory_requirement}
            >
              <div className="flex_input">
                <div>{strings.label.name}</div>
                <div>Rahul Singh</div>
              </div>
              <div className="flex_input">
                <div>{strings.label.date_of_birth}</div>
                <div>27/01/2000</div>
              </div>
              <div className="flex_input">
                <div>{strings.label.pan_number}</div>
                <div>DRPUTB755865GJ</div>
              </div>
              <div className="flex_input">
                <div>{strings.label.father_name}</div>
                <div>Mohan Lal</div>
              </div>
            </AccordianBox>

            {/* Form Link your PAN*/}
            <StyleFormBox>
              <div className="flex-1 flex items-center justify-between">
                <h4 style={{ color: theme.primary_txt }}>
                  {strings.tax.add_bank_details}
                </h4>

                <button
                  className="add_button"
                  onClick={() => {
                    setAddBankBox(true);
                  }}
                >
                  {strings.button.add}
                </button>
              </div>
            </StyleFormBox>

            <button className="primary_button mt-5" onClick={handleSubmit}>
              {strings.button.save_and_continue}
            </button>
          </div>
        )}
      </Formik>

      {/*  */}

      <FormDialogBox
        open={companyFormBox}
        formTitle={"Add Director Information"}
        handleClose={() => {
          setCompanyFormBox(false);
          setEditFormData({});
        }}
      >
        <CompanyDirectors
          data={editFormData}
          empCategorys={optionData?.defaultEmpCategory}
          personFiling={optionData?.CapacityOfPersonFiling}
          handleCallback={(type, data) => {
            if (type == "edit") {
              setStackHolderData({
                ...stackHolderData,
                companyDirectors: stackHolderData.companyDirectors.map((i) =>
                  i.id == data.id ? data : i
                ),
              });
            } else {
              setStackHolderData({
                ...stackHolderData,
                companyDirectors: [...stackHolderData.companyDirectors, data],
              });
            }
            setEditFormData({});
            setCompanyFormBox(false);
          }}
        />
      </FormDialogBox>

      <FormDialogBox
        open={companyShareFormBox}
        formTitle={"Company Share Holder Information"}
        handleClose={() => {
          setCompanyShareFormBox(false);
          setEditFormData({});
        }}
      >
        <CompanyShare
          data={editFormData}
          handleCallback={(type, data) => {
            if (type == "edit") {
              setStackHolderData({
                ...stackHolderData,
                shareHolderDetails: stackHolderData.shareHolderDetails.map(
                  (i) => (i.id == data.id ? data : i)
                ),
              });
            } else {
              setStackHolderData({
                ...stackHolderData,
                shareHolderDetails: [
                  ...stackHolderData.shareHolderDetails,
                  data,
                ],
              });
            }
            setEditFormData({});
            setCompanyShareFormBox(false);
          }}
        />
      </FormDialogBox>

      <FormDialogBox
        open={companyOwnerFormBox}
        formTitle={"Add Owner Information"}
        handleClose={() => {
          setCompanyOwnerFormBox(false);
          setEditFormData({});
        }}
      >
        <CompanyOwner
          data={editFormData}
          handleCallback={(type, data) => {
            if (type == "edit") {
              setStackHolderData({
                ...stackHolderData,
                ownerDetails: stackHolderData.ownerDetails.map((i) =>
                  i.id == data.id ? data : i
                ),
              });
            } else {
              setStackHolderData({
                ...stackHolderData,
                ownerDetails: [...stackHolderData.ownerDetails, data],
              });
            }
            setEditFormData({});
            setCompanyOwnerFormBox(false);
          }}
        />
      </FormDialogBox>

      {/* dialog box for adding bank details */}
      {/* <AddBankDetails
        open={addBankBox}
        handleClose={() => {
          setAddBankBox(false);
        }}
        handleCallback={() => {}}
      /> */}
    </Root>
  );
}
const Root = styled.section``;
