
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { IoClose } from "react-icons/io5";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import { FaCloudUploadAlt } from "react-icons/fa";
export default function UploadDocuments({open,handleClose,handleCallback}) {
    const [userImage, setUserImage] = useState();

  const { theme ,strings} = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme} className={open && 'active'}>
      <button onClick={() => {handleClose()}} className="close_btn_bg"></button>
      <div className="dialogBox">
      <div className="title">
          <h1 style={{color:theme.primary_txt}}>Upload Document</h1>
          <button className="close_btn" onClick={() => {handleClose()}} style={{color:theme.primary_txt}}><IoClose /></button>
        </div>
        <div className="content text-center">
        <StyleFormBox>
        <div className="upload_cloud w-fit text-5xl  p-8 rounded-full mx-auto">
          <FaCloudUploadAlt />
        </div>
        <p className="my-4">Upload Documents in your profile</p>
        <input type="file" />
        <PrimaryButton title={strings.button.submit} addClass={"mt-5"} />
      </StyleFormBox>
      
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000042;
  backdrop-filter: blur(2px);
  top: 0px;
  left: 0px;
  display:none;
  z-index:10;
  &.active{
    display:unset
  }
  .close_btn_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: transparent;
  }
  .close_btn{
    background: ${p => p.theme.tertiary_bg};
    border: none;
    font-size: 20px;
    border-radius: 4px;
  }
  .dialogBox {
    position: absolute;
    left:50%;
    top:20px;
    transform: translateX(-50%);
    background: ${(p) => p.theme.secandary_bg};
    width:50%;
    border-radius: 10px;
    padding: 16px;
    .title{
      display:flex;
      align-items:center;
      justify-content:space-between;
      border: 2px solid ${(p) => p.theme.border_clr};
      border-width: 0px 0px 2px 0px;
      padding-bottom: 14px;
    }
    .content{
      margin-top:20px;
     
    }
  }
`;
